import React from 'react'; // eslint-disable-line
import ReactDOM from 'react-dom';
import { css } from '@emotion/react';

import t from '../theme/newstyles';
import { CloseIcon } from '../icons/CloseIcon';

/** @jsxImportSource @emotion/react */

const closeIconSize = css`
  width: 44px;
  height: 44px;
`;

const overlayBackgroundColor = css`
  background-color: #00000075;
`;

export const TransparentModal = (props: { children?: React.ReactNode; onClose?: (event: React.MouseEvent) => void }) => {
  const modalRoot = document.getElementById('modalRoot');
  if (!modalRoot) return null;
  return ReactDOM.createPortal(
    <div role="dialog" css={[t.fixed, t.top_0, t.bottom_0, t.left_0, t.right_0, overlayBackgroundColor, t.z_50]}>
      <div css={[t.h_full, t.relative, t.overflow_y_auto]}>
        {props.onClose ? (
          <button css={[t.fixed, t.top_0, t.right_0, closeIconSize, t.mt_6, t.mr_6, t.text_dark_1, t.bg_transparent, t.cursor_pointer]} onClick={props.onClose}>
            <CloseIcon css={[t.fill_current]} />
          </button>
        ) : null}
        {props.children}
      </div>
    </div>,
    modalRoot
  );
};
