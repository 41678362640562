import { css } from '@emotion/react';

export const appearance = {
  appearance_none: css`
    appearance: none;
  `,
  appearance_auto: css`
    appearance: appearance_auto;
  `,
};
