import React from 'react'; // eslint-disable-line

/** @jsxImportSource @emotion/react */
import t from '../../theme/newstyles';
import { Formik, Form, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { CheckboxGroup } from '../../components/CheckboxGroup';
import { FormCheckbox } from '../../components/FormCheckbox';

const Schema = Yup.object().shape({
  single: Yup.boolean().oneOf([true], 'Required Field'),
  group: Yup.array().required('Please select one or more'),
});

interface TestDescriptor {
  single: boolean;
  group: (keyof typeof options)[];
}

const options = { group1: 'Group 1', group2: 'Group 2', group3: 'Group 3' };

export const CheckboxTest = () => {
  const handleSubmit = async (formData: TestDescriptor, actions: FormikHelpers<TestDescriptor>) => {
    console.log('test');
  };

  const initialValues: TestDescriptor = {
    single: false,
    group: [],
  };

  return (
    <div css={[t.w_full, t.flex, t.flex_col]}>
      <Formik<TestDescriptor> initialValues={initialValues} validationSchema={Schema} validateOnMount={true} onSubmit={handleSubmit}>
        {() => {
          return (
            <Form>
              <div css={[t.pb_8]}>
                <div css={[t.gridTypeStyle_4, t.my_5]}>Checkboxes</div>
                <div css={[t.flex, t.flex_col]}>
                  <div css={[t.mb_7, t.flex, t.flex_row]}>
                    <FormCheckbox<TestDescriptor> name="single" label="Single" />
                  </div>

                  <div css={[t.mb_7, t.flex, t.flex_row]}>
                    <CheckboxGroup<TestDescriptor, typeof options>
                      name="group"
                      options={options}
                      tagRender={(checkboxProps) => {
                        return (
                          <FormCheckbox<TestDescriptor>
                            name="group"
                            value={checkboxProps.value}
                            checked={checkboxProps.checked}
                            label={checkboxProps.label}
                            suppressError={true}
                          />
                        );
                      }}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};
