import { css } from '@emotion/react';

export const zIndex = {
  z_minus_1: css`
    z-index: -1;
  `,
  z_0: css`
    z-index: 0;
  `,
  z_10: css`
    z-index: 10;
  `,
  z_20: css`
    z-index: 20;
  `,
  z_30: css`
    z-index: 30;
  `,
  z_40: css`
    z-index: 40;
  `,
  z_50: css`
    z-index: 50;
  `,
  z_60: css`
    z-index: 60;
  `,
  z_70: css`
    z-index: 70;
  `,
  z_80: css`
    z-index: 80;
  `,
  z_auto: css`
    z-index: auto;
  `,
};
