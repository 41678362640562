import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { AdminDetailsDialog } from '../components/Dialogs/AdminDetailsDialog';
import { SongProvider, useSong } from '../../../contexts/SongContext';
import { AdminSongDetailsHeader } from './AdminSongDetailsHeader';
import { AdminSongDetailsInfo } from './AdminSongDetailsInfo';
import { AdminSongDetailsPlayer } from './AdminSongDetailsPlayer';
import { UserAccountProvider, useUserAccount } from '../../../contexts/UserAccountContext';
import { ArtistProfileProvider, useArtistProfile } from '../../../contexts/ArtistProfileContext';
import { SongMetadataProvider } from '../../../contexts/SongMetadataContext';
import { AdminSongDetailsHistory } from './AdminSongDetailsHistory';
import { AdminDialogPage } from '../components/Dialogs/AdminDialogPage';

/** @jsxImportSource @emotion/react */

export const AdminSongDetailsDialog = (props: {
  isOpen: boolean;
  songId?: string | null;
  onClose: (event: React.MouseEvent | React.KeyboardEvent) => void;
}) => {
  if (!props.songId) return null;

  return (
    <SongProvider id={props.songId}>
      <SongMetadataProvider id={props.songId}>
        <AdminDetailsDialog isOpen={props.isOpen} onRequestClose={props.onClose} dialogStyle={[t.max_w('640px'), t.h_auto]}>
          <AdminSongDetailsWrapper />
        </AdminDetailsDialog>
      </SongMetadataProvider>
    </SongProvider>
  );
};

const AdminSongDetailsWrapper = () => {
  const song = useSong();

  if (!song) return null;
  return (
    <UserAccountProvider userId={song.customerId}>
      <ArtistProfileProvider artistId={song.artistId}>
        <AdminSongDetails />
      </ArtistProfileProvider>
    </UserAccountProvider>
  );
};

const AdminSongDetails = () => {
  const song = useSong();
  const commissionerAccount = useUserAccount();
  const artistProfile = useArtistProfile();

  if (!song || !commissionerAccount || !artistProfile || !artistProfile.Profile) return null;

  return (
    <React.Fragment>
      <AdminSongDetailsHeader />
      <AdminDialogPage pageStyle={[t.mt_0]}>
        <AdminSongDetailsInfo />
        <AdminSongDetailsPlayer />
        <AdminSongDetailsHistory />
      </AdminDialogPage>
    </React.Fragment>
  );
};
