import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';

/** @jsxImportSource @emotion/react */

export const AdminPage = (props: { pageHeading: string; tabChildren?: React.ReactNode; headChildren?: React.ReactNode; children?: React.ReactNode }) => {
  return (
    <div css={[t.flex, t.flex_col, t.flex1, t.pb_8]}>
      <div css={[t.mt_8, t.mx_8, t.mb_4, t.flex, t.flex_row]}>
        <h1 css={[t.text_3xl, t.font_medium, t.text_dark_3]}>{props.pageHeading}</h1>
        {props.headChildren ? props.headChildren : null}
      </div>
      <div css={[t.mx_8, t.border_b, t.border_tint_2]}>{props.tabChildren}</div>
      <div css={[t.flex, t.flex_col, t.flex_auto]}>
        <div css={[t.mt_6]}>
          <div css={[t.px_8, t.max_w_6xl, t.flex, t.flex_col, t.justify_center, t.mx_auto, t.flex_wrap]}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};
