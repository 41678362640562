import React from 'react'; // eslint-disable-line

import t from '../../theme/newstyles';
import { MainContainer } from '../../components/MainContainer';
import { Button } from '../../components/Button';
import { CloseIcon } from '../../icons/CloseIcon';
import { TabSection } from '../../components/TabSection';
import { GenreTagDictionary, InstrumentTagDictionary } from '../../data/ProfileData';
import { ArtistFilterList } from './ArtistFilterList';

/** @jsxImportSource @emotion/react */

export const ArtistFilterDialogContents = (props: { toggleFilterModal: () => void }) => {
  return (
    <div css={[t.fixed, t.inset_0, t.flex, t.flex_col]}>
      <div css={[t.flex_initial, t.flex, t.flex_row, t.justify_between, t.items_center, t.py_4, t.px_5]}>
        <div css={[t.gridTypeStyle_3]}>Filters</div>
        <div css={[t.cursor_pointer]} onClick={props.toggleFilterModal}>
          <CloseIcon css={[t.w_5, t.h_5, t.fill_current]} />
        </div>
      </div>
      <div css={[t.flex_auto, t.flex, t.overflow_y_hidden]}>
        <TabSection tabLabels={['Genres', 'Instruments', 'Tags']}>
          <div>
            <ArtistFilterList attribute="genreTags" options={GenreTagDictionary} />
          </div>
          <div>
            <ArtistFilterList attribute="instrumentTags" options={InstrumentTagDictionary} />
          </div>
        </TabSection>
      </div>

      <div css={[t.pt_5, t.pb_5, t.border_t, t.border_solid, t.border_tint_3, t.bg_tint_5, t.z_50, t.shadow_header]}>
        <MainContainer>
          <Button type="button" label="Apply filter" fullWidth={true} onClick={props.toggleFilterModal} />
        </MainContainer>
      </div>
    </div>
  );
};

export default ArtistFilterDialogContents;
