import React from 'react'; // eslint-disable-line

import t, { CSSStyle } from '../../theme/newstyles';
import { MainContainer } from '../MainContainer';
import { Link } from 'react-router-dom';
import { InstagramIcon } from '../../icons/InstagramIcon';
import { FacebookIcon } from '../../icons/FacebookIcon';
import { TwitterIcon } from '../../icons/TwitterIcon';
import * as dayjs from 'dayjs';
import { Button } from '../Button';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { TextField } from '../TextField';
import { FirebaseObjects } from '../../contexts/FirebaseContext';
import { ThemeProvider, useThemeController } from '../../contexts/ThemeContext';

/** @jsxImportSource @emotion/react */

interface NewsletterSignUp {
  email: string;
}

const Schema = Yup.object().shape({
  email: Yup.string().email().required('Please enter a valid email address'),
});

export const Footer = () => {
  const { dark } = useThemeController();
  const [submitted, setSubmitted] = React.useState(false);
  const [submitInProgress, setSubmitInProgress] = React.useState(false);
  const textColor = dark ? t.text_tint_5 : t.text_tint_1;

  const initialValues: NewsletterSignUp = {
    email: '',
  };

  const handleSubmit = async (formData: NewsletterSignUp, actions: FormikHelpers<NewsletterSignUp>) => {
    setSubmitInProgress(true);
    await FirebaseObjects.visitorRequest({ action: 'mailingListSignup', data: { email: formData.email } });
    setSubmitted(true);
  };

  return (
    <footer css={[dark ? t.bg_dark_1 : t.bg_tint_4, t.pt_5, t.pb_6, t.md([t.pt_6]), t.lg([t.pt_5, t.pb_6])]}>
      <MainContainer>
        <div css={[t.flex, t.flex_col, t.lg([t.breakpointGrid_12])]}>
          <div css={[t.lg([t.col_span_6]), textColor]}>
            <ul css={[t.flex, t.flex_wrap, t.mb_4, t.lastType('li', [t.after(t.content_none)]), t.lg(t.mb_5)]}>
              <FooterListItem to="/about" label="About" />
              <FooterListItem mailTo to="mailto:info@downwrite.com" label="Contact" />
              <FooterListItem to="/help-center" label="Help" />
              <FooterListItem to="/terms" label="Terms" />
              <FooterListItem to="/privacy" label="Privacy" />
              <FooterListItem to="/community-guidelines" label="Community guidelines" />
            </ul>
            <ul css={[t.flex, t.flex_wrap, t.mb_4, t.lg(t.mb_5)]}>
              <li css={t.mr_5}>
                <a href="https://www.instagram.com/getdownwrite/" target="_blank" rel="noopener noreferrer" css={[textColor]}>
                  <InstagramIcon />
                </a>
              </li>
              <li css={t.mr_5}>
                <a href="https://www.facebook.com/getdownwrite" target="_blank" rel="noopener noreferrer" css={[textColor]}>
                  <FacebookIcon />
                </a>
              </li>
              <li css={t.mr_5}>
                <a href="https://x.com/getdownwrite" target="_blank" rel="noopener noreferrer" css={[textColor]}>
                  <TwitterIcon />
                </a>
              </li>
            </ul>
            <div>{`Downwrite, LLC ${dayjs.utc().format('YYYY')} \u00A9`}</div>
          </div>
          <div css={[t.order_first, t.mb_6, t.md([t.mb_7]), t.lg([t.order_last, t.mb_0, t.col_span_6, t.col_start_7])]}>
            <div css={[t.typeStyle_lg8, t.font_medium, t.mb_2, dark ? t.text_tint_5 : t.text_dark_1]}>Sign up for our newsletter</div>
            {!submitted ? (
              <Formik<NewsletterSignUp> initialValues={initialValues} validationSchema={Schema} validateOnMount={false} onSubmit={handleSubmit}>
                {(formikProps: FormikProps<NewsletterSignUp>) => {
                  return (
                    <Form css={[t.sm([t.flex])]}>
                      <div css={[t.w_full]}>
                        <TextField formName="newsletter" name="email" placeholder="Email address" containerStyle={[t.min_w_unset]} />
                      </div>
                      <div css={[t.mt_4, t.sm([t.mt_0, t.ml_2]), t.md([t.ml_4])]}>
                        <Button
                          type="button"
                          label="Subscribe"
                          styleType="secondary"
                          buttonType="submit"
                          outerStyle={[t.w_full, t.sm(t.w_auto)]}
                          innerStyle={[t.w_full, t.sm(t.w_auto)]}
                          isInProgress={submitInProgress}
                        />
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            ) : (
              <div css={[t.text_tint_1, t.gridTypeStyle_6]}>Thanks for subscribing to our newsletter</div>
            )}
          </div>
        </div>
      </MainContainer>
    </footer>
  );
};

export const DarkFooter = () => {
  return (
    <ThemeProvider dark>
      <Footer />
    </ThemeProvider>
  );
};

const FooterListItem = (props: { to: string; label: string; mailTo?: boolean }) => {
  const { dark } = useThemeController();

  const linkStyle: CSSStyle = [dark ? t.text_tint_5 : t.text_tint_1, t.typeStyle_lg8, t.no_underline];

  return (
    <li css={[t.flex, t.after([t.content('/'), t.mx_4, t.typeStyle_lg8])]}>
      {props.mailTo ? (
        <a href={props.to} target="_blank" rel="noopener noreferrer" css={[linkStyle]}>
          {props.label}
        </a>
      ) : (
        <Link to={props.to} css={[linkStyle]}>
          {props.label}
        </Link>
      )}
    </li>
  );
};
