import React from 'react'; // eslint-disable-line
import { motion } from 'framer-motion';

import t from '../../theme/newstyles';
import { MainContainer } from '../../components/MainContainer';
import { useArtistSearch } from '../../contexts/ArtistSearchContext';
import { Button } from '../../components/Button';
import { GenreTagDictionary, InstrumentTagDictionary } from '../../data/ProfileData';
import { GetTotalArtistFilters } from './ArtistSearch';
import { MultiSelect } from '../../components/MultiSelect';
import { Checkbox } from '../../components/Checkbox';
import { SearchIcon } from '../../icons/SearchIcon';
import { FilterIcon } from '../../icons/FilterIcon';
import { ExpandingSearchBox } from '../../components/ExpandingSearchBox';
import { ArtistSearchDialogContents } from './ArtistSearchDialogContents';
import { ArtistFilterDialogContents } from './ArtistFilterDialogContents';
import { FullScreenDialog } from '../../components/dialogs/FullScreenDialog';

/** @jsxImportSource @emotion/react */

export const DiscoverySearchBox = (props: { toggleSearchModal?: () => void }) => {
  const discoverySearch = useArtistSearch();
  if (!discoverySearch.state) return null;

  return <ExpandingSearchBox discoverySearch={discoverySearch} toggleSearchModal={props.toggleSearchModal} />;
};

export const ArtistSearchControls = (props: { filterMenuActive: boolean; onFilterToggle: () => void }) => {
  const discoverySearch = useArtistSearch();
  const [showSearchModal, setSearchModal] = React.useState(false);

  const toggleSearchModal = () => {
    console.log(`showSearchModal to ${!showSearchModal}`);
    setSearchModal(!showSearchModal);
  }

  if (!discoverySearch.state) return null;

  const hasActiveFilters = GetTotalArtistFilters(discoverySearch) > 0;

  return (
    <MainContainer containerCSS={[t.flex_initial, t.border_b, t.border_solid, t.border_tint_3, t.bg_tint_5, t.pt_5, t.sm(t.pt_4), t.md(t.pt_5), t.z_50]}>
      <motion.div {...t.motion_fadeIn} css={[hasActiveFilters ? t.pb_5 : t.pb_6, t.flex, t.items_center]}>
        <motion.div {...t.motion_scaleIn_2} css={[t.hidden, t.md(t.block), t.mr_4, t.text_primary_4]}>
          <MultiSelect
            id="GenreTagInput"
            label="Genre"
            options={GenreTagDictionary}
            values={discoverySearch.state.genreFilters}
            listItemRender={(checkboxProps) => {
              return (
                <Checkbox
                  name="genreTags"
                  value={checkboxProps.value}
                  checked={checkboxProps.checked}
                  label={checkboxProps.label}
                  focused={checkboxProps.focused}
                  onChange={() => discoverySearch.toggleGenre(checkboxProps.value)}
                  fullWidth={true}
                />
              );
            }}
          />
        </motion.div>
        <motion.div {...t.motion_scaleIn_2} css={[t.hidden, t.md(t.block), t.mr_4, t.text_primary_4]}>
          <MultiSelect
            id="InstrumentTagInput"
            label="Instrument"
            options={InstrumentTagDictionary}
            values={discoverySearch.state.instrumentFilters}
            listItemRender={(checkboxProps) => {
              return (
                <Checkbox
                  name="instrumentTags"
                  value={checkboxProps.value}
                  checked={checkboxProps.checked}
                  label={checkboxProps.label}
                  focused={checkboxProps.focused}
                  onChange={() => discoverySearch.toggleInstrument(checkboxProps.value)}
                  fullWidth={true}
                />
              );
            }}
          />
        </motion.div>
        <div css={[t.relative, t.flex_initial, t.mr_4, t.sm([t.hidden])]}>
          <Button
            type="button"
            styleType="secondary"
            label="Filters"
            color={props.filterMenuActive ? t.primary_4 : undefined}
            icon={<FilterIcon />}
            onClick={props.onFilterToggle}
            innerStyle={[t.w_full]}
          />
        </div>
        <div css={[t.hidden, t.sm([t.relative, t.flex, t.flex_auto, t.mr_4]), t.md([t.hidden])]}>
          <Button
            type="button"
            styleType="secondary"
            label="Filters"
            color={props.filterMenuActive ? t.primary_4 : undefined}
            icon={<FilterIcon />}
            onClick={props.onFilterToggle}
            outerStyle={[t.w_full]}
            innerStyle={[t.w_full]}
          />
        </div>
        <motion.div {...t.motion_scaleIn_2} css={[t.hidden, t.md([t.flex, t.flex_row, t.items_center, t.flex_auto])]}>
          <DiscoverySearchBox />
        </motion.div>
        <div css={[t.flex_initial, t.sm(t.hidden)]}>
          <Button type="button" styleType="secondary" icon={<SearchIcon />} onClick={toggleSearchModal} outerStyle={[t.w_full]} innerStyle={[t.w_full]} />
        </div>
        <div css={[t.hidden, t.sm([t.flex, t.flex_auto]), t.md(t.hidden)]}>
          <Button
            type="button"
            styleType="secondary"
            label="Search"
            icon={<SearchIcon />}
            onClick={toggleSearchModal}
            outerStyle={[t.w_full]}
            innerStyle={[t.w_full]}
          />
        </div>
      </motion.div>
      <FullScreenDialog isOpen={props.filterMenuActive} onRequestClose={props.onFilterToggle} overlayStyle={[t.md(t.hidden)]}>
        <ArtistFilterDialogContents toggleFilterModal={props.onFilterToggle} />
      </FullScreenDialog>
      <FullScreenDialog isOpen={showSearchModal} onRequestClose={toggleSearchModal}>
        <ArtistSearchDialogContents toggleSearchModal={toggleSearchModal} />
      </FullScreenDialog>
    </MainContainer>
  );
};

export default ArtistSearchControls;
