import { css } from '@emotion/react';
import { breakpoints } from './breakpoints';
import { padding } from './padding';
import { size } from './utilities';

export const custom = {
  size: size,

  content_container: [
    css`
      width: 100%;
    `,
    padding.px_5,
    breakpoints.md([padding.px('6vw')]),
  ],

  hidden_input: css`
    width: 1px;
    height: 1px;
    margin: -1px;
    position: absolute;
    overflow: hidden;
    padding: 0;
    border: 0;
    clip: rect(0 0 0 0);
  `,

  offscreen: css`
    border: 0 !important;
    clip: rect(1px 1px 1px 1px);
    height: 1px !important;
    overflow: hidden;
    padding: 0 !important;
    position: absolute;
    white-space: nowrap;
    width: 1px !important;
  `,

  focusIndicator: css`
    position: absolute;
    top: -4px;
    bottom: -12px;
    right: -12px;
    left: -12px;
    border-width: 3px;
    border-radius: 3px;
    opacity: 0.6;
  `,
};
