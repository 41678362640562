import React from 'react'; // eslint-disable-line

import t from '../../../../theme/admin/adminStyles';
import { CSSStyle } from '../../../../theme/newstyles';
import ReactPlayer from 'react-player';
import dayjs from 'dayjs';
import { SongPlayingController, useSongPlayingController } from '../../../../contexts/SongPlayingContext';
import { useFileDownload } from '../../../../hooks/useFileDownload';

/** @jsxImportSource @emotion/react */

interface ReactPlayerState {
  played: number;
  playedSeconds: number;
  loaded: number;
  loadedSeconds: number;
}

export type AdminSongPlayerProps = {
  isPlaying: boolean;
  isLoading: boolean;
  timeRemaining: string;
  playedSeconds: number;
  percentProgress: number;
  handlePlayPause: (event: React.MouseEvent) => Promise<void>;
  handleRestart: (event: React.MouseEvent) => void;
};

export const AdminSongPlayer = (props: {
  songDuration: number;
  children?: (songPlayerProps: AdminSongPlayerProps) => React.ReactNode;
  containerStyle?: CSSStyle;
  fixedUrl: string | null;
}) => {
  const fixedUrl = props.fixedUrl;
  const [playing, setPlaying] = React.useState(false);
  const [duration, setDuration] = React.useState(props.songDuration || 0);
  const [playerState, setPlayerState] = React.useState<ReactPlayerState>({ played: 0, playedSeconds: 0, loaded: 0, loadedSeconds: 0 });
  const [playerUrl, setPlayerUrl] = React.useState(fixedUrl || '');
  const songUrl = useFileDownload(playerUrl);
  const playerRef = React.useRef<ReactPlayer>(null);

  const songPlayingController: SongPlayingController = useSongPlayingController();
  const songIsPlayingMatches = songPlayingController.songPlaying === playerUrl;

  const songDuration = props.songDuration;
  React.useEffect(() => {
    setPlaying(false);
    setPlayerUrl(fixedUrl || '');
    setDuration(songDuration);
    setPlayerState(fixedUrl ? { played: 0, playedSeconds: 0, loaded: 1, loadedSeconds: 0 } : { played: 0, playedSeconds: 0, loaded: 0, loadedSeconds: 0 });
  }, [songDuration, fixedUrl]);

  const handleDuration = React.useCallback((duration: number) => {
    setDuration(duration);
  }, []);

  const handleRestart = React.useCallback(
    (event: React.MouseEvent) => {
      if (playerRef.current) {
        playerRef.current.seekTo(0);
        setPlayerState({ played: 0, playedSeconds: 0, loaded: playerState.loaded, loadedSeconds: playerState.loadedSeconds });
      }
    },
    [playerState, playerRef]
  );

  const handleProgress = React.useCallback(
    (state: ReactPlayerState) => {
      if (playing) {
        setPlayerState(state);
      }
    },
    [playing]
  );

  const handlePlayPause = React.useCallback(
    async (event: React.MouseEvent) => {
      event.preventDefault();
      if (!playing) {
        songPlayingController.setSongPlaying(playerUrl);
        setPlaying(true);
      } else setPlaying(false);
    },
    [playing, playerUrl, songPlayingController]
  );

  React.useEffect(() => {
    if (!songIsPlayingMatches) setPlaying(false);
  }, [songIsPlayingMatches, songPlayingController]);

  const handleEnded = React.useCallback(() => {
    setPlaying(false);
    setPlayerState({ played: 1, playedSeconds: duration, loaded: playerState.loaded, loadedSeconds: playerState.loadedSeconds });
    songPlayingController.setSongPlaying('');
  }, [duration, playerState.loaded, playerState.loadedSeconds, songPlayingController]);

  const timeRemaining = dayjs.utc((duration - playerState.playedSeconds) * 1000).format('mm:ss');
  const percentProgress = duration ? playerState.played * 100 : 0;

  return (
    <React.Fragment>
      <ReactPlayer
        ref={playerRef}
        url={songUrl}
        playing={playing && playerState.loaded > 0}
        controls={false}
        width="0px"
        height="0px"
        onDuration={handleDuration}
        onProgress={handleProgress}
        onEnded={handleEnded}
        config={{ file: { forceAudio: true } }}
      />
      <div css={[t.flex, props.containerStyle]}>
        {props.children &&
          props.children({
            isPlaying: playing && !!playerUrl && !!songUrl,
            isLoading: playing && (!playerUrl || !songUrl),
            timeRemaining: timeRemaining,
            playedSeconds: playerState.playedSeconds,
            percentProgress: percentProgress,
            handlePlayPause: handlePlayPause,
            handleRestart: handleRestart,
          })}
      </div>
    </React.Fragment>
  );
};
