import { spacing } from './spacing';
import { margin } from './margin';
import { padding } from './padding';
import { border } from './border';
import { sizing } from './sizing';
import { background } from './background';
import { lineHeight } from './lineHeight';
import { textColor } from './textColor';
import { fill } from '../fill';
import { flex } from '../flex';
import { cursor } from '../cursor';
import { display } from '../display';
import { fontWeight } from '../fontWeight';
import { breakpoints } from '../breakpoints';
import { pseudoClass } from '../pseudoClass';
import { text } from './text';
import { boxShadow } from './boxShadow';
import { textDecoration } from '../textDecoration';
import { gridColumn } from '../gridColumn';
import { overflow } from '../overflow';
import { position } from '../position';
import { resize } from '../resize';
import { stroke } from '../stroke';
import { zIndex } from '../zIndex';
import { whitespace } from '../whitespace';
import { wordBreak } from '../wordBreak';
import { outline } from '../outline';
import { custom } from '../custom';
import { contentStyles } from '../content';
import { userSelect } from '../userSelect';
import { visibility } from '../visibility';
import { textAlign } from '../textAlign';
import { textTransform } from '../textTransform';
import { grid } from '../grid';
import { gridRow } from '../gridRow';
import { pointerEvents } from '../pointerEvents';
import { easing } from '../easing';
import { introVariants } from '../motionVariants';
import { motion } from '../motion';

export default {
  ...flex,
  ...spacing,
  ...margin,
  ...padding,
  ...breakpoints,
  ...display,
  ...text,
  ...fontWeight,
  ...border,
  ...sizing,
  ...background,
  ...boxShadow,
  ...lineHeight,
  ...textColor,
  ...pseudoClass,
  ...cursor,
  ...fill,
  ...textDecoration,
  ...gridColumn,
  ...overflow,
  ...position,
  ...resize,
  ...stroke,
  ...zIndex,
  ...whitespace,
  ...wordBreak,
  ...outline,
  ...custom,
  ...contentStyles,
  ...userSelect,
  ...visibility,
  ...textAlign,
  ...textTransform,
  ...grid,
  ...gridRow,
  ...pointerEvents,
  ...easing,
  ...introVariants,
  ...motion,
};
