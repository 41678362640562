import React from 'react'; // eslint-disable-line
import { useMediaQuery } from 'react-responsive';
import t from '../theme/newstyles';

export function useBreakpoint(breakpoint: number) {
  return useMediaQuery({ query: `screen and (min-width: ${breakpoint}px)` });
}

export function useSM() {
  return useMediaQuery({ query: `screen and (min-width: ${t.smPX}px)` });
}

export function useSMRetina() {
  return useMediaQuery({
    query: `screen and (min-width: ${t.smPX}px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: ${t.smPX}px) and (min--moz-device-pixel-ratio: 2), screen and (min-width: ${t.smPX}px) and (-o-min-device-pixel-ratio: 2/1), screen and (min-width: ${t.smPX}px) and (min-device-pixel-ratio: 2), screen and (min-width: ${t.smPX}px) and (min-resoultion: 192dpi), screen and (min-width: ${t.smPX}px) and (min-resolution: 2dppx)`,
  });
}

export function useMD() {
  return useMediaQuery({ query: `screen and (min-width: ${t.mdPX}px)` });
}

export function useMDRetina() {
  return useMediaQuery({
    query: `screen and (min-width: ${t.mdPX}px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: ${t.mdPX}px) and (min--moz-device-pixel-ratio: 2), screen and (min-width: ${t.mdPX}px) and (-o-min-device-pixel-ratio: 2/1), screen and (min-width: ${t.mdPX}px) and (min-device-pixel-ratio: 2), screen and (min-width: ${t.mdPX}px) and (min-resoultion: 192dpi), screen and (min-width: ${t.mdPX}px) and (min-resolution: 2dppx)`,
  });
}

export function useLG() {
  return useMediaQuery({ query: `screen and (min-width: ${t.lgPX}px)` });
}

export function useLGRetina() {
  return useMediaQuery({
    query: `screen and (min-width: ${t.lgPX}px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: ${t.lgPX}px) and (min--moz-device-pixel-ratio: 2), screen and (min-width: ${t.lgPX}px) and (-o-min-device-pixel-ratio: 2/1), screen and (min-width: ${t.lgPX}px) and (min-device-pixel-ratio: 2), screen and (min-width: ${t.lgPX}px) and (min-resoultion: 192dpi), screen and (min-width: ${t.lgPX}px) and (min-resolution: 2dppx)`,
  });
}

export function useXL() {
  return useMediaQuery({ query: `screen and (min-width: ${t.xlPX}px)` });
}

export function useXLRetina() {
  return useMediaQuery({
    query: `screen and (min-width: ${t.xlPX}px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: ${t.xlPX}px) and (min--moz-device-pixel-ratio: 2), screen and (min-width: ${t.xlPX}px) and (-o-min-device-pixel-ratio: 2/1), screen and (min-width: ${t.xlPX}px) and (min-device-pixel-ratio: 2), screen and (min-width: ${t.xlPX}px) and (min-resoultion: 192dpi), screen and (min-width: ${t.xlPX}px) and (min-resolution: 2dppx)`,
  });
}

export function useWindowDimensions() {
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  const [windowHeight, setWindowHeight] = React.useState(window.innerHeight);

  React.useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
      setWindowHeight(window.innerHeight);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { windowWidth, windowHeight };
}
