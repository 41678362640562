import { envPrefix } from '../FirebaseContext';

export const DEFAULT_SEARCH_INDEX_NAME = `${envPrefix}ARTISTPACKAGES`;
export const DEFAULT_PACKAGE_SEARCH_CONTEXT_STATE = {
  hitsPerPage: 200,
  query: '',
  priceMin: undefined,
  priceMax: undefined,
  pageBump: 0,
  genreFilters: [],
  instrumentFilters: [],
  packageTagFilters: [],
  availableFilters: ['true', 'false'],
  sortIndex: DEFAULT_SEARCH_INDEX_NAME,
};
