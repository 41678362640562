import React, { ComponentType } from 'react'; // eslint-disable-line
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch';

import t from '../../../theme/admin/adminStyles';
import { AdminSearchIcon } from '../icons/AdminSearchIcon';

/** @jsxImportSource @emotion/react */

interface SearchBoxProps extends UseSearchBoxProps {
  placeholder?: string,
  defaultRefinement?: string,
}

export const AdminCustomSearchBox = (props: SearchBoxProps) => {
  const { refine, query } = useSearchBox(props);

  const HandleSearchBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    refine(event.target.value);
  };

  const HandleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') refine(event.currentTarget.value);
  };

  return (
    <div css={[t.flex, t.flex_row, t.items_center, t.flex_auto]}>
      <div css={[t.mr_4, t.ml_2, t.text_tint_1]}>
        <AdminSearchIcon css={[t.size('16px')]} />
      </div>
      <div css={[t.w_full, t.flex, t.flex_col]}>
        <input
          css={[t.h('56px'), t.min_h('56px'), t.lineHeight('56px'), t.bg_transparent, t.flex_auto, t.text_sm, t.text_dark_2]}
          type="text"
          placeholder={props.placeholder ? props.placeholder : 'Search by email or user ID'}
          onKeyUp={(event) => HandleKeyUp(event)}
          onBlur={(event) => HandleSearchBlur(event)}
          defaultValue={query}
        />
      </div>
    </div>
  );
};
