import { css } from '@emotion/react';

export function orderNumber(index: number) {
  return css`
    order: ${index};
  `;
}

export const order = {
  order: orderNumber,

  order_first: orderNumber(-9999),
  order_last: orderNumber(9999),
  order_none: orderNumber(0),

  order_inherit: css`
    order: inherit;
  `,
  order_initial: css`
    order: initial;
  `,
  order_unset: css`
    order: unset;
  `,
};
