import { css } from '@emotion/react';
import { palette } from './palette';

export const textColor = {
  text_transparent: css`
    color: transparent;
  `,
  text_black: css`
    color: #000;
  `,
  text_white: css`
    color: #fff;
  `,

  text_dark_1: css`
    color: ${palette.dark_1};
  `,
  text_dark_2: css`
    color: ${palette.dark_2};
  `,
  text_dark_3: css`
    color: ${palette.dark_3};
  `,
  text_dark_4: css`
    color: ${palette.dark_4};
  `,

  text_primary_1: css`
    color: ${palette.primary_1};
  `,
  text_primary_2: css`
    color: ${palette.primary_2};
  `,
  text_primary_3: css`
    color: ${palette.primary_3};
  `,
  text_primary_4: css`
    color: ${palette.primary_4};
  `,
  text_primary_5: css`
    color: ${palette.primary_5};
  `,

  text_tint_1: css`
    color: ${palette.tint_1};
  `,
  text_tint_2: css`
    color: ${palette.tint_2};
  `,
  text_tint_3: css`
    color: ${palette.tint_3};
  `,
  text_tint_4: css`
    color: ${palette.tint_4};
  `,
  text_tint_5: css`
    color: ${palette.tint_5};
  `,

  text_error_1: css`
    color: ${palette.error_1};
  `,
  text_error_2: css`
    color: ${palette.error_2};
  `,

  text_disabledPage_1: css`
    color: ${palette.disabledPage_1};
  `,
};
