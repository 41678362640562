import { css } from '@emotion/react';

export const wordBreak = {
  break_normal: css`
    word-break: normal;
    overflow-wrap: normal;
  `,
  break_words: css`
    overflow-wrap: break-word;
  `,
  break_all: css`
    word-break: break-all;
  `,
  truncate: css`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  `,
};
