import React from 'react'; // eslint-disable-line

import t, { CSSStyle } from '../../theme/newstyles';

/** @jsxImportSource @emotion/react */

export function DialogTitle(props: { id?: string; children?: React.ReactNode; titleStyle?: CSSStyle }) {
  return (
    <h1 id={props.id} css={[t.px_5, t.py_4, t.gridTypeStyle_4, t.sm([t.pt_5]), t.md([t.px_7, t.pt_7]), props.titleStyle]}>
      {props.children}
    </h1>
  );
}
