import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const SpotifyIcon = (props: {}) => {
  return (
    <svg role="img" aria-labelledby="spotifyIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="spotifyIconTitleID">Spotify Icon</title>
      <path
        d="M15.3937 16.1896C15.5327 15.9097 15.4613 15.5432 15.234 15.3722C13.1974 13.8353 10.6728 13.4775 7.72961 14.3084C7.47013 14.3815 7.30851 14.7004 7.36775 15.0208C7.42699 15.3404 7.68518 15.5408 7.94402 15.4677C10.6335 14.7091 12.9173 15.0184 14.7318 16.3876C14.9584 16.5594 15.2552 16.4711 15.3937 16.1896Z"
        css={[t.fill_current]}
      />
      <path
        d="M16.3395 13.592C16.5134 13.2429 16.4245 12.7841 16.1412 12.5694C13.7563 10.7589 10.2678 10.25 7.46175 11.3012C7.14368 11.4213 6.96404 11.8355 7.05998 12.2291C7.15656 12.6219 7.49331 12.8437 7.81138 12.7245C10.2684 11.8037 13.4344 12.2609 15.5109 13.8369C15.7942 14.0515 16.1644 13.9418 16.3395 13.592Z"
        css={[t.fill_current]}
      />
      <path
        d="M17.4109 10.5745C17.6144 10.1507 17.5017 9.6036 17.1585 9.35155C14.2914 7.25003 9.7546 7.05204 7.02521 8.07537C6.64274 8.2185 6.42769 8.71704 6.54359 9.18855C6.65884 9.66006 7.06255 9.92642 7.44437 9.7833C9.82221 8.89197 13.9301 9.05974 16.4207 10.8861C16.7638 11.1382 17.2075 10.9983 17.4109 10.5745Z"
        css={[t.fill_current]}
      />
      <circle cx="12" cy="12" r="10.5" css={[t.stroke_current]} />
    </svg>
  );
};
