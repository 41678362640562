import { css } from '@emotion/react';

export const resize = {
  resize_none: css`
    resize: none;
  `,
  resize: css`
    resize: both;
  `,
  resize_y: css`
    resize: vertical;
  `,
  resize_x: css`
    resize: horizontal;
  `,
};
