import * as React from 'react';
import { publicDownloadUrlBase } from './FirebaseContext';
import { ArtistSample, ArtistApproach, ArtistAvailability, ArtistMetaData, ArtistOrderRating } from './ArtistProfileContext';
import { ArtistPackage } from '../types/shared-types';

export enum ArtistPageState {
  Unknown = 'Unknown',
  Loading = 'Loading',
  Ready = 'Ready',
  Missing = 'Missing',
}

export interface ArtistPageData {
  pageDisabled: boolean;
  pageDeactivated: boolean;
  availability: ArtistAvailability;
  complete: boolean;
  route: string;
  name: string;
  photoURL: string;
  additionalImages?: string[];
  genreTags: string[];
  instrumentTags: string[];
  shortBio: string;
  websiteURL?: string;
  facebookURL?: string;
  instagramURL?: string;
  youtubeURL?: string;
  twitterURL?: string;
  spotifyURL?: string;
  packages: { [key: string]: ArtistPackage };
  packageOrder: string[];
  samples: { [key: string]: ArtistSample };
  sampleOrder: string[];
  approach?: ArtistApproach;
  relatedProjects?: string[];
  partnered: boolean;
  metaData: ArtistMetaData;
  artistRatings: ArtistOrderRating[];
  isNoted: boolean;
}

export class ArtistPage {
  constructor(state: ArtistPageState, route: string, pageDocument: ArtistPageData | undefined) {
    this.State = state;
    if (pageDocument) {
      if (pageDocument) {
        const samples: { [key: string]: ArtistSample } = pageDocument.samples;
        const sampleOrder = Object.keys(samples).sort((a, b) => samples[a].order - samples[b].order);

        const packages: { [key: string]: ArtistPackage } = pageDocument.packages;
        const packageOrder = Object.keys(packages).sort((a, b) => packages[a].order - packages[b].order);

        this.Page = {
          ...pageDocument,
          route: route,
          sampleOrder,
          packageOrder,
        };
      }
    }
  }

  public State = ArtistPageState.Unknown;
  public Page: ArtistPageData | null = null;

  public Ready() {
    return this.State === ArtistPageState.Ready;
  }

  public GetPage() {
    return this.Page;
  }
}

export const ArtistPageContext = React.createContext<ArtistPage>({} as ArtistPage);
interface ArtistPageProviderProps {
  route: string;
  children: React.ReactNode;
}

interface ArtistPageProviderState {
  contextState: ArtistPage;
}

export class ArtistPageProvider extends React.Component<ArtistPageProviderProps, ArtistPageProviderState> {
  constructor(props: ArtistPageProviderProps) {
    super(props);
    this.state = {
      contextState: new ArtistPage(ArtistPageState.Loading, '', undefined),
    };
  }

  private async fetchPage() {
    const lowercaseRoute = this.props.route.toLowerCase();
    const url = `${publicDownloadUrlBase}/pages/artists/${lowercaseRoute}`;
    try {
      const fetchResult = await fetch(url);
      const fetchJson = await fetchResult.json();
      this.setState({
        contextState: new ArtistPage(ArtistPageState.Ready, lowercaseRoute, fetchJson),
      });
    } catch (e) {
      this.setState({
        contextState: new ArtistPage(ArtistPageState.Missing, lowercaseRoute, undefined),
      });
    }
  }

  async componentDidMount() {
    await this.fetchPage();
  }

  async componentDidUpdate(prevProps: ArtistPageProviderProps) {
    if (prevProps.route !== this.props.route) {
      this.setState({
        contextState: new ArtistPage(ArtistPageState.Loading, '', undefined),
      });
      await this.fetchPage();
    }
  }

  render() {
    return <ArtistPageContext.Provider value={this.state.contextState}>{this.props.children}</ArtistPageContext.Provider>;
  }
}

export function useArtistPage() {
  return React.useContext(ArtistPageContext);
}
