import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';

import { ProcessPartner, usePartnerRequest } from '../../../contexts/PartnerRequestContext';
import { AdminButton } from '../components/AdminButton';
import { useUserAccount } from '../../../contexts/UserAccountContext';

/** @jsxImportSource @emotion/react */

export const AdminArtistDetailsRequest = () => {
  const request = usePartnerRequest();
  const artistAccount = useUserAccount();

  if (!artistAccount) return null;
  if (!request) return null;
  if (!request.Active) return null;

  const HandleAccept = async () => {
    await ProcessPartner(artistAccount.id, true);
  };

  const HandleReject = async () => {
    await ProcessPartner(artistAccount.id, false);
  };

  return (
    <div css={[t.border_b, t.border_solid, t.border_tint_3, t.bg_tint_3, t.py_5, t.px_6, t.flex, t.flex_col]}>
      <div css={[t.flex, t.flex_row]}>
        <div css={[t.flex, t.items_center, t.mr_3]}>
          <div css={[t.bg_primary_4, t.size('20px'), t.rounded_full]}></div>
        </div>
        <div css={[t.text_primary_4, t.flex_auto, t.flex, t.items_center]}>Partner Request Pending</div>
        <AdminButton type="button" label="Reject" styleType="critical" onClick={HandleReject} />
        <AdminButton type="button" styleType="secondary" label="Accept" onClick={HandleAccept} outerStyle={[t.ml_3]} />
      </div>
    </div>
  );
};
