import React, { Children, ReactNode } from 'react'; // eslint-disable-line

import t from '../../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const HeaderNavLinks = (props: { align: 'left' | 'right'; children?: ReactNode }) => {
  return (
    <nav css={[t.hidden, t.md([t.w_full, t.flex, t.flex_row, props.align === 'left' ? [t.ml('30px'), t.justify_start] : t.justify_end])]}>
      <ul css={[t.flex, t.flex_row, props.align === 'left' ? t.lastType('li', t.mr_0) : t.lastType('li', t.mr_0)]}>
        {Children.map(props.children, (child) => {
          return <li css={[t.flex, t.flex_col, t.justify_center, t.mr_5]}>{child}</li>;
        })}
      </ul>
    </nav>
  );
};
