import { css } from '@emotion/react';

export const gridRow = {
  grid_rows_none: css`
    grid-template-rows: none;
  `,
  grid_rows_full: css`
    grid-template-rows: 100%;
  `,

  grid_auto_rows_equal: css`
    grid-auto-rows: 1fr;
  `,

  row_auto: css`
    grid-row: auto;
  `,
  row_span_1: css`
    grid-row: span 1 / span 1;
  `,
  row_span_2: css`
    grid-row: span 2 / span 2;
  `,
  row_span_3: css`
    grid-row: span 3 / span 3;
  `,
  row_span_4: css`
    grid-row: span 4 / span 4;
  `,
  row_span_5: css`
    grid-row: span 5 / span 5;
  `,
  row_span_6: css`
    grid-row: span 6 / span 6;
  `,
  row_span_7: css`
    grid-row: span 7 / span 7;
  `,
  row_span_8: css`
    grid-row: span 8 / span 8;
  `,
  row_span_9: css`
    grid-row: span 9 / span 9;
  `,
  row_span_10: css`
    grid-row: span 10 / span 10;
  `,
  row_span_11: css`
    grid-row: span 11 / span 11;
  `,
  row_span_12: css`
    grid-row: span 12 / span 12;
  `,

  row_start_1: css`
    grid-row-start: 1;
  `,
  row_start_2: css`
    grid-row-start: 2;
  `,
  row_start_3: css`
    grid-row-start: 3;
  `,
  row_start_4: css`
    grid-row-start: 4;
  `,
  row_start_5: css`
    grid-row-start: 5;
  `,
  row_start_6: css`
    grid-row-start: 6;
  `,
  row_start_7: css`
    grid-row-start: 7;
  `,
  row_start_8: css`
    grid-row-start: 8;
  `,
  row_start_9: css`
    grid-row-start: 9;
  `,
  row_start_10: css`
    grid-row-start: 10;
  `,
  row_start_11: css`
    grid-row-start: 11;
  `,
  row_start_12: css`
    grid-row-start: 12;
  `,
  row_start_auto: css`
    grid-row-start: auto;
  `,
};
