import { CreateDocumentContext, FirebaseObjects } from '../contexts/FirebaseContext';

export enum SongState {
  requested = 'requested',
  uploaded = 'uploaded',
  submitted = 'submitted',
  complete = 'complete',
}

export interface Song {
  id: string;
  state: SongState;
  orderId?: string;
  artistId: string;
  customerId: string;
  name: string;
  duration: number;
  lastModified: string;
  public: boolean;
  playCount: number;
  playHistory: { [key: string]: number };
  isrc: string;
  genre: string;
}

const SongDocumentContext = CreateDocumentContext<Song>('/songs');
export const SongProvider = SongDocumentContext.Provider;
export const useSong = SongDocumentContext.Use;

export async function DownloadSong(songId: string, format: 'mp3' | 'wav') {
  const result = await FirebaseObjects.userRequest({ action: 'getSongDownloadUrl', data: { songId, format } });
  if (result.data.url) {
    const link = document.createElement('a');
    link.href = result.data.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

export async function StreamSong(songId: string): Promise<string | null> {
  const result = await FirebaseObjects.visitorRequest({ action: 'getSongStreamUrl', data: { songId } });
  return result.data.url;
}

export async function AdminUseSong(songId: string, format: 'stream' | 'mp3' | 'flac' | 'wav'): Promise<string | null> {
  const result = await FirebaseObjects.adminRequest({ action: 'adminUseSong', data: { songId, format } });
  if (format !== 'stream' && result.data.url) {
    const link = document.createElement('a');
    link.href = result.data.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  return result.data.url;
}
