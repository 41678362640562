import * as React from 'react';
import { Transition } from 'framer-motion';

export type DiscoverTokens = {
  headlinerImage: MotionComponentToken;
  headliner: MotionComponentToken;
  genres: MotionComponentToken;
  h1: MotionComponentToken;
  h2: MotionComponentToken;
  card: MotionComponentToken;
};

export type ArtistPageTokens = {
  h1: MotionComponentToken;
  info: MotionComponentToken;
  genres: MotionComponentToken;
  collage: MotionComponentToken;
};

export type SearchTokens = {
  h1: MotionComponentToken;
  h2: MotionComponentToken;
  grid: MotionComponentToken;
  card: MotionComponentToken;
};

export type MotionCollectionForm = {
  active: string;
  collections: { [key: string]: MotionTokenCollection };
};

export type MotionTokenCollection = {
  discovery: DiscoverTokens;
  artistPage: ArtistPageTokens;
  search: SearchTokens;
};

export type MotionPageTokens<T> = {
  page: string;
  tokens: T;
};

export type MotionComponentToken = {
  tokenKey: string;
  transition?: Transition;
};

export interface AnimationTestController {
  key: number;
  refreshKey: () => void;
}

export const AnimationTestContext = React.createContext({} as AnimationTestController);
export const AnimationTestProvider = (props: { children: React.ReactNode }) => {
  const [key, setRefreshKey] = React.useState(0);

  return (
    <AnimationTestContext.Provider
      value={{
        key: key,
        refreshKey: () => {
          setRefreshKey(Math.random());
        },
      }}
    >
      {props.children}
    </AnimationTestContext.Provider>
  );
};

export function useAnimationTestController() {
  return React.useContext(AnimationTestContext);
}
