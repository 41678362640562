import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { PaymentSplitContent } from '../../../contexts/PaymentSplitsContentContext';
import { Form, Formik, FormikProps } from 'formik';
import { AdminTextField } from './AdminTextField';

/** @jsxImportSource @emotion/react */

export interface SplitData {
  percentage: number;
}

export const AdminSplit = (props: { split: PaymentSplitContent; onEdit: (percentage: number) => void }) => {
  const { percentage } = props.split;
  const onEdit = props.onEdit;

  const handleSubmit = React.useCallback(
    (values: SplitData) => {
      onEdit(values.percentage);
    },
    [onEdit]
  );

  const initialValues: SplitData = {
    percentage: percentage,
  };

  const formName = `paymentSplit`;
  return (
    <Formik<SplitData> initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true}>
      {(formikProps: FormikProps<SplitData>) => {
        const handleBlur = () => {
          if (formikProps.dirty) onEdit(formikProps.values.percentage);
        };

        return (
          <div css={[t.mb_2]}>
            <Form>
              <div css={[t.flex, t.items_center]}>
                <div>
                  <AdminTextField<SplitData> type="number" formName={formName} name="percentage" formikValues={formikProps.values} onBlur={handleBlur} />
                </div>
              </div>
            </Form>
          </div>
        );
      }}
    </Formik>
  );
};
