import React, { JSX } from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { Orientation, AdminTabProps } from './AdminTab';

/** @jsxImportSource @emotion/react */

interface AdminTabsProps {
  orientation?: Orientation;
  value: number;
  onChange?: (event: React.ChangeEvent<unknown>, newValue: number) => void;
  children?: React.ReactElement<AdminTabProps>[] | React.ReactElement<AdminTabProps>
}

export const AdminTabs = (props: AdminTabsProps) => {
  const children = React.Children.map(props.children, (child, i) => {
    if (!React.isValidElement(child)) {
      return null;
    }

    const selected = i === props.value;

    return React.cloneElement(child, { selected: selected, value: i, orientation: props.orientation, onChange: props.onChange });
  });

  return <div css={[t.flex, props.orientation === 'vertical' ? t.flex_col : t.flex_row]}>{children}</div>;
};