import t from '../../theme/newstyles';
import { MotionIntroToken } from '../../theme/motionUtilities';
import { MotionCollectionForm } from './AnimationTestContext';

export const AnimationTokens: { [key: string]: MotionIntroToken } = {
  None: t.motion_empty,
  Rise: t.motion_rise,
  RiseFadeIn: t.motion_riseFadeIn,
  FadeIn: t.motion_fadeIn,
  ScaleIn_1: t.motion_scaleIn_1,
  ScaleIn_2: t.motion_scaleIn_2,
  ScaleIn_3: t.motion_scaleIn_3,
  ScaleInReveal_1: t.motion_scaleInReveal_1,
  Reveal_1: t.motion_reveal_1,
};

export const InitialAnimationTestValues: MotionCollectionForm = {
  active: 'harmony_03',
  collections: {
    harmony_03: {
      discovery: {
        headlinerImage: { tokenKey: 'ScaleIn_1' },
        headliner: { tokenKey: 'RiseFadeIn' },
        genres: { tokenKey: 'FadeIn' },
        h1: { tokenKey: 'RiseFadeIn' },
        h2: { tokenKey: 'RiseFadeIn' },
        card: { tokenKey: 'RiseFadeIn', transition: { delay: 0.2 } },
      },
      artistPage: {
        h1: { tokenKey: 'RiseFadeIn' },
        info: { tokenKey: 'RiseFadeIn', transition: { delay: 0.2 } },
        genres: { tokenKey: 'ScaleIn_3' },
        collage: { tokenKey: 'Reveal_1' },
      },
      search: {
        h1: { tokenKey: 'RiseFadeIn' },
        h2: { tokenKey: 'FadeIn' },
        grid: { tokenKey: 'Rise' },
        card: { tokenKey: 'ScaleInReveal_1' },
      },
    },
    reveal: {
      discovery: {
        headlinerImage: { tokenKey: 'Reveal_1' },
        headliner: { tokenKey: 'Reveal_1' },
        genres: { tokenKey: 'FadeIn' },
        h1: { tokenKey: 'Reveal_1' },
        h2: { tokenKey: 'RiseFadeIn' },
        card: { tokenKey: 'RiseFadeIn', transition: { delay: 0.2 } },
      },
      artistPage: {
        h1: { tokenKey: 'Reveal_1' },
        info: { tokenKey: 'RiseFadeIn', transition: { delay: 0.2 } },
        genres: { tokenKey: 'ScaleIn_3' },
        collage: { tokenKey: 'Reveal_1' },
      },
      search: {
        h1: { tokenKey: 'Reveal_1' },
        h2: { tokenKey: 'Reveal_1' },
        grid: { tokenKey: 'Rise' },
        card: { tokenKey: 'ScaleInReveal_1' },
      },
    },
    custom: {
      discovery: {
        headlinerImage: { tokenKey: 'None' },
        headliner: { tokenKey: 'None' },
        genres: { tokenKey: 'None' },
        h1: { tokenKey: 'None' },
        h2: { tokenKey: 'None' },
        card: { tokenKey: 'None' },
      },
      artistPage: {
        h1: { tokenKey: 'None' },
        info: { tokenKey: 'None' },
        genres: { tokenKey: 'None' },
        collage: { tokenKey: 'None' },
      },
      search: {
        h1: { tokenKey: 'None' },
        h2: { tokenKey: 'None' },
        grid: { tokenKey: 'None' },
        card: { tokenKey: 'None' },
      },
    },
  },
};
