import Dinero from 'dinero.js';

type UserEventCommon = {
  time: string;
  userId: string;
};

export type NewAccountEvent = UserEventCommon & {
  type: 'NewAccount';
};

export type ArtistWizardEvent = UserEventCommon & {
  type: 'ArtistWizard';
};

export type ArtistPaidEvent = UserEventCommon & {
  type: 'ArtistPaid';
  orderId: string;
  amount: Dinero.DineroObject;
  customerName: string;
};

export type ArtistCommissionedEvent = UserEventCommon & {
  type: 'ArtistCommissioned';
  orderId: string;
  customerName: string;
};

export type ArtistPartnerStatus = UserEventCommon & {
  type: 'ArtistPartnerStatus';
  adminEmail: string;
  state: boolean;
};

export type AccountDeleted = UserEventCommon & {
  type: 'AccountDeleted';
};

export type UserEvent = NewAccountEvent | ArtistWizardEvent | ArtistPaidEvent | ArtistCommissionedEvent | ArtistPartnerStatus | AccountDeleted;

export function GetUserEventLabel(event: UserEvent) {
  switch (event.type) {
    case 'NewAccount':
      return 'Account created';
    case 'ArtistWizard':
      return 'Profile wizard completed';
    case 'ArtistPaid':
      return `Paid ${Dinero(event.amount).toFormat('$0.00')} by ${event.customerName}`;
    case 'ArtistCommissioned':
      return event.customerName ? `Commissioned by ${event.customerName}` : 'Commissioned';
    case 'ArtistPartnerStatus': {
      const action = event.state ? 'Partnered' : 'Unpartnered';
      return `${action} by ${event.adminEmail}`;
    }
    case 'AccountDeleted':
      return 'Account deleted';
  }
}
