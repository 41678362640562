import React from 'react'; // eslint-disable-line

import { AdminListControl, ListColumnDescriptor } from '../components/AdminListControl';
import { AdminSearchContext } from '../../../contexts/AdminSearchContext';
import { useParams } from 'react-router-dom';
import { HiddenFilter } from '../components/AdminFilter';
import t from '../../../theme/admin/adminStyles';
import { AdminSearchTableCell } from '../components/AdminSearchTableCell';
import { useHits, UseHitsProps } from 'react-instantsearch';
import { AdminSearchTableRow, AdminSearchRowProps } from '../components/AdminSearchTableRow';
import { AdminArtistDetailsDialog } from './AdminArtistDetailsDialog';
import { AdminSearchTableActionCell } from '../components/AdminSearchTableActionCell';
import { AdminIconButton } from '../components/AdminIconButton';
import { PopupIcon } from '../icons/PopupIcon';
import { ArtistSearchRecord } from '../../../types/shared-types';

/** @jsxImportSource @emotion/react */

const TableRows = (props: UseHitsProps<ArtistSearchRecord>) => {
  const [detailsActive, SetDetailsActive] = React.useState(false);
  const [objectID, SetObjectID] = React.useState<string | null>(null);
  const { items } = useHits(props);

  function HandleClose(event: React.MouseEvent | React.KeyboardEvent) {
    event.stopPropagation();
    SetDetailsActive(false);
    SetObjectID(null);
  }

  return (
    <tbody css={[t.p_0, t.text_sm, t.h_12]}>
      {items.map((hit, i: number) => {
        return (
          <AdminSearchTableRow key={i}>
            {(rowProps: AdminSearchRowProps) => {
              function HandleClick() {
                SetObjectID(hit.objectID);
                SetDetailsActive(true);
              }

              const statusElement: React.ReactNode | null = null;

              return (
                <React.Fragment>
                  <AdminSearchTableCell>{statusElement}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.name}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.artistId}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.orderStats.pendingOrders}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.orderStats.activeOrders}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.orderStats.completedOrders}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.objectID}</AdminSearchTableCell>

                  <AdminSearchTableActionCell>
                    {rowProps.isHovered && (
                      <AdminIconButton id="artistDetailsButton" onClick={HandleClick}>
                        <PopupIcon css={[t.size('16px')]} />
                      </AdminIconButton>
                    )}
                  </AdminSearchTableActionCell>
                </React.Fragment>
              );
            }}
          </AdminSearchTableRow>
        );
      })}
      <AdminArtistDetailsDialog isOpen={detailsActive} userId={objectID} onClose={HandleClose} />
    </tbody>
  );
};

const COLUMNS: ListColumnDescriptor<string>[] = [
  { width: '2' },
  { label: 'Name', width: '180' },
  { label: 'Artist ID', width: '20%' },
  { label: 'Pending', width: '10%' },
  { label: 'Active', width: '10%' },
  { label: 'Closed', width: '10%' },
  { label: 'User ID', width: '25%' },
  { width: '66' },
];

export const AdminInstantArtistsList = () => {
  const [refreshKey, setRefreshKey] = React.useState(0);
  const onRefresh = React.useCallback(() => {
    setRefreshKey(refreshKey + 1);
  }, [refreshKey]);

  const params = useParams<{ userId: string; filters?: string }>();
  // NOTE ensure that if the attribute is changed, the 'Facets' settings for the different ARTISTS indexes in Algolia are updated also
  const filters = [{ label: 'Instant Status', attribute: 'isInstantBook', options: ['true', 'false'], defaults: ['true'] }];

  return (
    <AdminSearchContext indexName="ARTISTS">
      <AdminListControl
        listColumns={COLUMNS}
        sortColumn="name"
        defaultSearch={params.userId}
        filters={filters}
        placeholderSearch="Search by artist or user id"
        onRefresh={onRefresh}
      >
        <HiddenFilter attribute="wizardState" defaultRefinement={['complete']} />
        <TableRows key={`search_${refreshKey}`} />
      </AdminListControl>
    </AdminSearchContext>
  );
};
