import React from 'react'; // eslint-disable-line

import t, { CSSStyle } from '../theme/newstyles';
import { motion } from 'framer-motion';

/** @jsxImportSource @emotion/react */

export function ProgressBar(props: { progress: number; outerStyle?: CSSStyle; backgroundStyle?: CSSStyle; barStyle?: CSSStyle }) {
  return (
    <div css={[t.relative, t.h('4px'), props.outerStyle]}>
      <div css={[t.bg_tint_3, t.h_full, props.backgroundStyle]}></div>
      <div css={[t.absolute, t.inset_0, t.overflow_hidden]}>
        <motion.div
          css={[t.bg_primary_4, t.h_full, props.progress === 0 ? t.hidden : t.flex, props.barStyle]}
          initial={{ x: '-100%' }}
          animate={{ x: `${props.progress - 100}%` }}
          transition={{ ease: 'linear', duration: 0.25 }}
        />
      </div>
    </div>
  );
}

export default ProgressBar;
