import React from 'react'; // eslint-disable-line
import { css } from '@emotion/react';

import t from '../theme/newstyles';
import { CloseIcon } from '../icons/CloseIcon';

/** @jsxImportSource @emotion/react */

const borderRadius = css`
  border-radius: 50px;
`;

export function FilterTag(props: {
  value: string;
  formName?: string;
  name?: string;
  label?: string;
  large?: boolean;
  checked?: boolean;
  disabled?: boolean;
  removable?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent) => void;
}) {
  const inputElement = React.useRef<HTMLInputElement | null>(null);
  const fieldId = `field-${String(props.formName)}-${String(props.name)}-${String(props.value)}`;

  const HandleKeyPress = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    event.preventDefault();
    if (event.key === ' ') {
      if (inputElement.current) {
        inputElement.current.click();
      }
    }
  };

  const HandleInnerFocus = (event: React.FocusEvent) => {
    event.stopPropagation();
    event.preventDefault();
  };

  const HandleBlur = (event: React.FocusEvent) => {
    if (inputElement.current) {
      inputElement.current.blur();
    }
  };

  return (
    <label
      onKeyPress={HandleKeyPress}
      onFocus={HandleInnerFocus}
      tabIndex={0}
      onBlur={HandleBlur}
      css={[
        t.outline_none,
        props.disabled ? t.cursor_not_allowed : t.cursor_pointer,
        t.hoverSelector('> div', [t.text_primary_4, t.border_primary_4]),
        t.focusSelector('> div', [t.text_primary_4, t.border_primary_1]),
        t.focusSelector('> div::before', t.content_some),
      ]}
    >
      <input
        ref={inputElement}
        tabIndex={-1}
        css={[
          t.hidden_input,
          t.outline_none,
          t.checkedSelector('+ div', [t.border_primary_4, t.text_primary_4]),
          t.activeSelector('+ div', [t.text_primary_1, t.border_primary_1]),
          t.disabledSelector('+ div', t.text_tint_2),
          t.disabledSelector('+ div', t.border_tint_2),
        ]}
        id={fieldId}
        type="checkbox"
        name={props.name}
        value={props.value}
        checked={props.checked}
        disabled={props.disabled}
        onChange={props.onChange}
        onFocus={HandleInnerFocus}
        onBlur={props.onBlur}
      />
      <div
        css={[
          t.inline_flex,
          props.large ? t.px_5 : t.px_4,
          props.large ? t.py_2 : [t.pt('5px'), t.pb('7px')],
          t.items_center,
          t.text_dark_1,
          t.outline_none,
          t.select_none,
          t.relative,
          t.outline_none,
          t.border_dark_1,
          t.border_1,
          t.border_solid,
          borderRadius,
          t.before([t.focusIndicator, t.pos('-4px'), t.border_primary_4, borderRadius, t.content_none]),
        ]}
        onFocus={HandleInnerFocus}
      >
        <div css={props.large ? t.typeStyle_lg5 : t.typeStyle_lg7}>{props.label}</div>
        {props.removable && (
          <div css={[t.ml_2, t.mb('-2px')]}>
            <CloseIcon css={[t.fill_current, t.w('10px')]} />
          </div>
        )}
      </div>
    </label>
  );
}
