export enum palette {
  dark_1 = '#111111',
  dark_1_a40 = '#11111166',
  dark_2 = '#303030',

  primary_1 = '#000D80',
  primary_2 = '#183CA9',
  primary_3 = '#004BDA',
  primary_4 = '#1163FF',

  tint_1 = '#99948F',
  tint_2 = '#B8B2AC',
  tint_3 = '#F4F0EC',
  tint_3_a50 = '#F4F0EC50',
  tint_4 = '#FBF8F5',
  tint_5 = '#FFFEFC',

  error_1 = '#FF2828',
  error_2 = '#DD0000',

  success = '#08C269',
}
