import React, { JSX } from 'react'; // eslint-disable-line

import t from '../theme/newstyles';
import { Link } from 'react-router-dom';
import * as H from 'history';
import { useThemeController } from '../contexts/ThemeContext';

/** @jsxImportSource @emotion/react */

type SharedButtonProps = {
  label?: string;
  disabled?: boolean;
  icon?: JSX.Element;
  id?: string;
};

type ButtonLinkProps<S> = {
  type: 'link';
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
} & SharedButtonProps;

type ButtonProps<S> =
  | ({
      type: 'button';
      buttonType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
      onClick?: (event: React.MouseEvent) => void;
    } & SharedButtonProps)
  | ButtonLinkProps<S>
  | ({ type: 'mailto'; to: string } & SharedButtonProps)
  | ({
      type: 'input';
      formName: string;
      inputType?: React.InputHTMLAttributes<HTMLInputElement>['type'];
      accept?: string;
      onChange?: (event: React.FormEvent<HTMLInputElement>) => void;
    } & SharedButtonProps);

export function ListButton<S = H.LocationState>(props: ButtonProps<S>) {
  const { dark } = useThemeController();
  const inputElement = React.useRef<HTMLInputElement | null>(null);

  function HandleInnerFocus(event: React.FocusEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  const contents = (
    <span
      css={[
        t.relative,
        t.inline_flex,
        t.flex_row,
        t.w_full,
        t.outline_none,
        t.typeStyle_lg6,
        t.bg_transparent,
        dark ? t.text_tint_5 : t.text_dark_1,
        t.py_1,
        t.px_4,
        t.hover(dark ? t.bg_dark_2 : t.bg_tint_3),
        t.active([t.bg_primary_4, t.text_tint_5]),
        t.before([t.focusIndicator, t.pos('-3px'), t.border_primary_4, t.content_none]),
      ]}
      tabIndex={-1}
      onFocus={HandleInnerFocus}
    >
      {props.label && <span css={[t.whitespace_no_wrap, t.flex_auto, t.text_left]}>{props.label}</span>}
      {props.icon && (
        <div css={[t.inline_flex, t.flex_none, t.items_center, t.justify_center]}>
          <div css={[t.size('20px')]}>{props.icon}</div>
        </div>
      )}
    </span>
  );

  const outerStyle = [
    t.relative,
    t.bg_transparent,
    t.outline_none,
    t.cursor_pointer,
    t.no_underline,
    t.p_0,
    t.w_full,
    t.disabled(t.cursor_not_allowed),
    t.disabledSelector('> span', [t.bg_tint_2, t.text_tint_5]),
    t.focusSelector('> span::before', t.content_some),
    t.focusSelector('> span', [t.bg_primary_4, t.text_tint_5]),
  ];

  switch (props.type) {
    case 'button': {
      const buttonType = props.buttonType || 'button';

      const HandleClick = (event: React.MouseEvent) => {
        if (props.onClick) props.onClick(event);
      };

      return (
        <button type={buttonType} css={outerStyle} disabled={props.disabled} aria-disabled={props.disabled} onClick={HandleClick} id={props.id}>
          {contents}
        </button>
      );
    }
    case 'link': {
      if (!props.disabled) {
        return (
          <Link to={props.to} css={outerStyle}>
            {contents}
          </Link>
        );
      } else {
        return (
          <button css={outerStyle} disabled={props.disabled} aria-disabled={props.disabled}>
            {contents}
          </button>
        );
      }
    }
    case 'mailto': {
      return (
        <a href={props.to} css={[outerStyle]} target="_blank" rel="noopener noreferrer">
          {contents}
        </a>
      );
    }
    case 'input': {
      const id = `button-${String(props.formName)}`;

      const HandleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
          if (inputElement.current) {
            inputElement.current.click();
          }
        }
      };

      return (
        <div css={[t.relative]}>
          <label htmlFor={id} tabIndex={0} onKeyPress={HandleKeyPress} css={outerStyle}>
            {contents}
            <input id={id} ref={inputElement} tabIndex={-1} css={[t.hidden_input]} type={props.inputType} accept={props.accept} onChange={props.onChange} />
          </label>
        </div>
      );
    }
  }
}
