import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { useSong } from '../../../contexts/SongContext';

/** @jsxImportSource @emotion/react */

export const AdminSongDetailsHeader = () => {
  const song = useSong();

  if (!song) return null;

  let statusColor = t.bg_dark_3;
  switch (song.state) {
    case 'requested': {
      statusColor = t.bg_tint_2;
      break;
    }
    case 'complete': {
      statusColor = t.bg_primary_4;
      break;
    }
  }
  return (
    <div css={[t.flex, t.flex_row, t.items_center, t.text_sm, t.h('55px'), t.border_b, t.border_solid, t.border_tint_3]}>
      <div css={[t.flex, t.flex_row, t.items_center, statusColor, t.text_tint_5, t.uppercase, t.h_full, t.px_6]}>{song.state}</div>
    </div>
  );
};
