import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';

/** @jsxImportSource @emotion/react */

export const AdminSearchIcon = (props: {}) => {
  return (
    <svg width="45.21" height="48" viewBox="0 0 45.21 48" fill="none" {...props}>
      <path
        css={[t.fill_current]}
        d={
          'M27.28.05A19.29,19.29,0,0,0,11.8,32.47l.32.34L.19,44.74a.66.66,0,0,0,0,.93L2.28,47.8a.68.68,0,0,0,1,0L15.49,35.55l.32.2A19.3,19.3,0,1,0,27.28.05ZM40.9,19.31a15,15,0,1,1-15-15A15,15,0,0,1,40.9,19.31Z'
        }
      />
    </svg>
  );
};
