import React from 'react'; // eslint-disable-line
import { css } from '@emotion/react';

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

const flip = css`
  transform: scaleX(-1);
`;

export const AltArrowIcon = (props: { direction?: 'left' | 'right' }) => {
  const { direction, ...rest } = props;
  const dir = direction ? direction : 'right';
  switch (dir) {
    case 'left':
      return (
        <div css={[flip]}>
          <svg width="12" height="10" viewBox="0 0 12 10" fill="none" {...rest}>
            <path css={[t.stroke_current]} d="M7 1L11 5L7 9" />
            <path css={[t.stroke_current]} d="M11 5L-1.19209e-07 5" />
          </svg>
        </div>
      );

    default:
      return (
        <svg width="12" height="10" viewBox="0 0 12 10" fill="none" {...rest}>
          <path css={[t.stroke_current]} d="M7 1L11 5L7 9" />
          <path css={[t.stroke_current]} d="M11 5L-1.19209e-07 5" />
        </svg>
      );
  }
};
