import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { AdminListToolbar } from './AdminListToolbar';
import { AdminList } from './AdminList';
import { Configure } from 'react-instantsearch';
import { AdminFilterDescriptor } from './AdminFilter';

/** @jsxImportSource @emotion/react */

type AdminListSortDirection = 'asc' | 'desc';
export interface ListColumnDescriptor<T extends string | number | symbol> {
  name?: T;
  label?: string;
  center?: boolean;
  width: string;
}

const RESULTS_PER_PAGE = 10;

export function AdminListControl<T extends string | number | symbol>(props: {
  listColumns: ListColumnDescriptor<T>[];
  sortColumn: T;
  sortDirections?: { [key in T]: AdminListSortDirection };
  onRefresh?: () => void;
  onColumnSort?: (column: T, direction: AdminListSortDirection) => void;
  defaultSearch?: string;
  filters?: AdminFilterDescriptor[];
  placeholderSearch?: string;
  children?: React.ReactNode;
}) {
  return (
    <div css={[t.max_w_5xl]}>
      <div css={[t.w_full, t.bg_white, t.shadow]}>
        <Configure hitsPerPage={RESULTS_PER_PAGE} />
        <AdminListToolbar
          defaultSearch={props.defaultSearch}
          filters={props.filters || []}
          placeholderSearch={props.placeholderSearch}
          onRefresh={props.onRefresh}
        />
        <AdminList
          listColumns={props.listColumns}
          sortColumn={props.sortColumn}
          sortDirections={props.sortDirections}
          onColumnSort={props.onColumnSort}
          resultsPerPage={RESULTS_PER_PAGE}
        >
          {props.children}
        </AdminList>
      </div>
    </div>
  );
}
