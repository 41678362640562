import { css } from '@emotion/react';
import { palette } from './palette';
import { background_color } from './utilities';

export const background = {
  bg_transparent: css`
    background-color: transparent;
  `,
  bg_black: css`
    background-color: black;
  `,
  bg_white: css`
    background-color: white;
  `,

  bg_color: background_color,

  bg_dark_1: background_color(palette.dark_1),
  bg_dark_1_a40: background_color(palette.dark_1_a40),
  bg_dark_2: background_color(palette.dark_2),

  bg_primary_1: background_color(palette.primary_1),
  bg_primary_2: background_color(palette.primary_2),
  bg_primary_3: background_color(palette.primary_3),
  bg_primary_4: background_color(palette.primary_4),

  bg_tint_1: background_color(palette.tint_1),
  bg_tint_2: background_color(palette.tint_2),
  bg_tint_3: background_color(palette.tint_3),
  bg_tint_3_a50: background_color(palette.tint_3_a50),
  bg_tint_4: background_color(palette.tint_4),
  bg_tint_5: background_color(palette.tint_5),

  bg_error_1: background_color(palette.error_1),
  bg_error_2: background_color(palette.error_2),

  bg_success: background_color(palette.success),

  bg_gradient_white: css`
    background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%);
  `,
};
