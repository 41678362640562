import React from 'react'; // eslint-disable-line

import t from '../../../../theme/admin/adminStyles';
import ReactModal from 'react-modal';
import { CSSStyle } from '../../../../theme/newstyles';
import { AdminDialog } from './AdminDialog';

/** @jsxImportSource @emotion/react */

export function AdminDetailsDialog(props: {
  isOpen: boolean;
  aria?: ReactModal.Aria;
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  children?: React.ReactNode;
  overlayStyle?: CSSStyle;
  dialogStyle?: CSSStyle;
}) {
  const { dialogStyle, ...rest } = props;

  const detailsDialogSyle: CSSStyle = [t.my('48px'), t.max_w('980px'), t.h_full, t.overflow_y_hidden];

  return <AdminDialog dialogStyle={[detailsDialogSyle, dialogStyle]} {...rest} />;
}
