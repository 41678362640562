import { css } from '@emotion/react';
import { spacing } from './spacing';
import { w, min_w, max_w } from './utilities';

export const width = {
  w: w,
  min_w: min_w,
  max_w: max_w,

  min_w_0: min_w(spacing.s0),
  min_w_1: min_w(spacing.s1),
  min_w_2: min_w(spacing.s2),
  min_w_3: min_w(spacing.s3),
  min_w_4: min_w(spacing.s4),
  min_w_5: min_w(spacing.s5),
  min_w_6: min_w(spacing.s6),
  min_w_7: min_w(spacing.s7),
  min_w_8: min_w(spacing.s8),
  min_w_9: min_w(spacing.s9),

  w_0: w(spacing.s0),
  w_1: w(spacing.s1),
  w_2: w(spacing.s2),
  w_3: w(spacing.s3),
  w_4: w(spacing.s4),
  w_5: w(spacing.s5),
  w_6: w(spacing.s6),
  w_7: w(spacing.s7),
  w_8: w(spacing.s8),
  w_9: w(spacing.s9),

  max_w_0: max_w(spacing.s0),
  max_w_1: max_w(spacing.s1),
  max_w_2: max_w(spacing.s2),
  max_w_3: max_w(spacing.s3),
  max_w_4: max_w(spacing.s4),
  max_w_5: max_w(spacing.s5),
  max_w_6: max_w(spacing.s6),
  max_w_7: max_w(spacing.s7),
  max_w_8: max_w(spacing.s8),
  max_w_9: max_w(spacing.s9),

  w_auto: css`
    width: auto;
  `,
  w_px: css`
    width: 1px;
  `,
  w_1of12: css`
    width: 8.333333%;
  `,
  w_2of12: css`
    width: 16.666667%;
  `,
  w_3of12: css`
    width: 25%;
  `,
  w_4of12: css`
    width: 33.333333%;
  `,
  w_5of12: css`
    width: 41.666667%;
  `,
  w_6of12: css`
    width: 50%;
  `,
  w_7of12: css`
    width: 58.333333%;
  `,
  w_8of12: css`
    width: 66.666667%;
  `,
  w_9of12: css`
    width: 75%;
  `,
  w_10of12: css`
    width: 83.333333%;
  `,
  w_11of12: css`
    width: 91.666667;
  `,
  w_full: css`
    width: 100%;
  `,
  w_screen: css`
    width: 100vw;
  `,

  max_w_xs: css`
    max-width: 20rem;
  `,
  max_w_sm: css`
    max-width: 24rem;
  `,
  max_w_md: css`
    max-width: 28rem;
  `,
  max_w_lg: css`
    max-width: 32rem;
  `,
  max_w_xl: css`
    max-width: 36rem;
  `,
  max_w_2xl: css`
    max-width: 42rem;
  `,
  max_w_4xl: css`
    max-width: 56rem;
  `,
  max_w_6xl: css`
    max-width: 72rem;
  `,

  min_w_full: css`
    min-width: 100%;
  `,
  min_w_screen: css`
    min-width: 100vw;
  `,
  min_w_unset: css`
    min-width: unset;
  `,
  max_w_full: css`
    max-width: 100%;
  `,
  max_w_page: css`
    max-width: 1440px;
  `,
  max_w_screen: css`
    max-width: 100vw;
  `,
  max_w_unset: css`
    max-width: unset;
  `,
};
