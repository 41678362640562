import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import dayjs from 'dayjs';
import { withAdminAccount } from '../../../hocs/withAdminAccount';
import { AdminPage } from '../components/AdminPage';
import { AdminListControl } from '../components/AdminListControl';
import { ListColumnDescriptor } from '../components/AdminListControl';
import { AdminSongsSearchHits } from './AdminSongsSearchHits';
import { AdminSearchContext } from '../../../contexts/AdminSearchContext';
import { useParams } from 'react-router-dom';
import { FirebaseObjects } from '../../../contexts/FirebaseContext';
import { AdminButton } from '../components/AdminButton';
import { AdminCard } from '../components/AdminCard';
import { AdminDatePicker } from '../components/AdminDatePicker';
import { HiddenFilter } from '../components/AdminFilter';
import { AdminLinkTab } from '../components/AdminLinkTab';

/** @jsxImportSource @emotion/react */

const SongListColumns: ListColumnDescriptor<string>[] = [
  { label: 'Name', width: '20%' },
  { label: 'Artist', width: '20%' },
  { label: 'Status', width: '90', center: true },
  { label: 'Review', width: '72', center: true },
  { label: 'Plays', width: '7%', center: true },
  { label: 'Modified', width: '110' },
  { label: 'Song ID', width: '25%' },
  { width: '66' },
];

export async function DownloadSongReport(startDate?: string, endDate?: string, withSongs?: boolean, songFormat?: string) {
  const result = await FirebaseObjects.adminRequest({
    action: 'exportSongs',
    data: { startDate: startDate ? startDate : '', endDate: endDate ? endDate : '', withSongs, songFormat: songFormat ? songFormat : 'wav' },
  });
  if (result.data.url) {
    const link = document.createElement('a');
    link.href = result.data.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

const AdminSongsComponent = () => {
  const [startDate, setStartDate] = React.useState<string>(dayjs().subtract(6, 'month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = React.useState<string>(dayjs().format('YYYY-MM-DD'));
  const [refreshKey, setRefreshKey] = React.useState(0);
  const params = useParams<{ userId: string; filters?: string }>();
  const stateFilterDefault: string[] = params.filters ? JSON.parse(params.filters) : [];

  const onStartChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  }, []);

  const onEndChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  }, []);

  const onRefresh = React.useCallback(() => {
    setRefreshKey(refreshKey + 1);
  }, [refreshKey]);

  const filters = [{ label: 'Reviewed', attribute: 'reviewState', options: ['reviewed', 'new', 'attention'], defaults: stateFilterDefault }];

  return (
    <AdminSearchContext indexName="SONGS">
      <HiddenFilter attribute="state" defaultRefinement={['uploaded', 'submitted', 'complete']} />
      <AdminPage
        pageHeading="Songs"
        tabChildren={
          <div css={[t.flex, t.flex_row]}>
            <AdminLinkTab label="Needs Review" to={`/admin/songs/["new","attention"]`} />
            <AdminLinkTab label="All Songs" to={`/admin/songs`} />
          </div>
        }
      >
        <AdminListControl
          key={params.filters}
          listColumns={SongListColumns}
          defaultSearch={params.userId}
          sortColumn="createdDate"
          placeholderSearch="Search by song name, artist, or song id"
          filters={filters}
          onRefresh={onRefresh}
        >
          <AdminSongsSearchHits key={`search_${refreshKey}`} />
        </AdminListControl>

        <AdminCard containerStyle={[t.mb_0, t.mt_8]}>
          <div css={[t.p_6]}>
            <div css={[t.text_dark_3, t.text_base, t.pb_2]}>Publishing</div>
            <div css={[t.text_tint_1, t.text_xs, t.pb_4]}>
              Download a zip file containing all songs modified in the date range and their publishing metadata
            </div>
            <div css={[t.flex]}>
              <div css={[t.mr_4]}>
                <AdminDatePicker
                  defaultValue={startDate}
                  min={dayjs().subtract(1, 'year').format('YYYY-MM-DD')}
                  max={endDate ? endDate : dayjs().format('YYYY-MM-DD')}
                  onChange={onStartChange}
                />
              </div>

              <div css={[t.mr_4]}>
                <AdminDatePicker defaultValue={endDate} min={startDate} max={dayjs().format('YYYY-MM-DD')} onChange={onEndChange} />
              </div>

              <div css={[t.flex, t.flex_col, t.justify_center, t.mr_4]}>
                <AdminButton
                  type="button"
                  label="Export with songs (WAV)"
                  onClick={() => {
                    const withSongs = true;
                    DownloadSongReport(startDate, endDate, withSongs);
                  }}
                />
              </div>
              <div css={[t.flex, t.flex_col, t.justify_center, t.mr_4]}>
                <AdminButton
                  type="button"
                  label="Export with songs (MP3)"
                  onClick={() => {
                    const withSongs = true;
                    DownloadSongReport(startDate, endDate, withSongs, 'mp3');
                  }}
                />
              </div>
              <div css={[t.flex, t.flex_col, t.justify_center]}>
                <AdminButton
                  type="button"
                  label="Export just CSV"
                  onClick={() => {
                    const withSongs = false;
                    DownloadSongReport(startDate, endDate, withSongs);
                  }}
                />
              </div>
            </div>
          </div>
        </AdminCard>
      </AdminPage>
    </AdminSearchContext>
  );
};

export const AdminSongs = withAdminAccount(AdminSongsComponent);
