import React from 'react'; // eslint-disable-line

import t from '../../theme/newstyles';
import { MainContainer } from '../../components/MainContainer';
import { Button } from '../../components/Button';
import { CloseIcon } from '../../icons/CloseIcon';
import { DiscoverySearchBox } from './ArtistSearchControls';

/** @jsxImportSource @emotion/react */

export const ArtistSearchDialogContents = (props: { toggleSearchModal: () => void }) => {
  return (
    <div css={[t.fixed, t.inset_0, t.content_between, t.flex, t.flex_col, t.justify_between]}>
      <MainContainer>
        <div css={t.block}>
          <div css={[t.flex, t.flex_row, t.justify_between, t.items_center, t.mt_6, t.mb_6]}>
            <div css={[t.gridTypeStyle_3]}>Search</div>
            <div css={[t.cursor_pointer]} onClick={props.toggleSearchModal}>
              <CloseIcon css={[t.w_5, t.h_5, t.fill_current]} />
            </div>
          </div>

          <DiscoverySearchBox toggleSearchModal={props.toggleSearchModal} />
        </div>
      </MainContainer>

      <div css={[t.w_full, t.flex, t.justify_center, t.pt_5, t.pb_5, t.border_t, t.border_solid, t.border_tint_3, t.shadow_header]}>
        <MainContainer>
          <Button type="button" label="Search" fullWidth={true} onClick={props.toggleSearchModal} />
        </MainContainer>
      </div>
    </div>
  );
};

export default ArtistSearchDialogContents;
