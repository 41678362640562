import React from 'react'; // eslint-disable-line
import { css } from '@emotion/react';

import t, { CSSStyle } from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

const borderRadius = css`
  border-radius: 50px;
`;

const pillPadding = css`
  padding-top: 5px;
  padding-bottom: 7px;
`;

const largePillPadding = css`
  padding-top: 8px;
  padding-bottom: 8px;
`;

export function DisplayTag(props: { label?: string; large?: boolean; isLink?: boolean; style?: CSSStyle }) {
  return (
    <div
      css={[
        t.inline_flex,
        props.large ? t.px_5 : t.px_4,
        props.large ? largePillPadding : pillPadding,
        t.items_center,
        t.text_dark_1,
        t.outline_none,
        t.select_none,
        t.relative,
        t.outline_none,
        t.border_dark_1,
        t.border_1,
        t.border_solid,
        borderRadius,
        props.isLink ? t.hover([t.border_primary_4, t.text_primary_4]) : null,
        props.style,
      ]}
    >
      <div css={props.large ? t.typeStyle_lg5 : t.typeStyle_lg7}>{props.label}</div>
    </div>
  );
}
