import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { AdminSearchTableCell } from '../components/AdminSearchTableCell';
import { useHits, UseHitsProps } from 'react-instantsearch-core';
import dayjs from 'dayjs';
import { AdminSearchTableRow, AdminSearchRowProps } from '../components/AdminSearchTableRow';
import { AdminSearchTableActionCell } from '../components/AdminSearchTableActionCell';
import { AdminIconButton } from '../components/AdminIconButton';
import { PopupIcon } from '../icons/PopupIcon';
import { AdminSongDetailsDialog } from './AdminSongDetailsDialog';
import { CheckboxIcon } from '../../../icons/CheckboxIcon';

/** @jsxImportSource @emotion/react */

interface SongSearchHit {
  name: string;
  artistName: string;
  playCount: number;
  customerId: string;
  artistId: string;
  lastModified: string;
  state: string;
  reviewState: 'reviewed' | 'new' | 'attention';
}

export const AdminSongsSearchHits = (props: UseHitsProps<SongSearchHit>) => {
  const [detailsActive, SetDetailsActive] = React.useState(false);
  const [objectID, SetObjectID] = React.useState<string | null>(null);
  const { items } = useHits(props);

  function HandleClose(event: React.MouseEvent | React.KeyboardEvent) {
    event.stopPropagation();
    SetDetailsActive(false);
    SetObjectID(null);
  }

  return (
    <tbody css={[t.p_0, t.text_sm, t.h_12]}>
      {items.map((hit, i: number) => {
        function HandleClick() {
          SetObjectID(hit.objectID);
          SetDetailsActive(true);
        }
        return (
          <AdminSearchTableRow key={i}>
            {(rowProps: AdminSearchRowProps) => {
              return (
                <React.Fragment>
                  <AdminSearchTableCell>{hit.name !== '' ? hit.name : 'Untitled'}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.artistName}</AdminSearchTableCell>
                  <AdminSearchTableCell center={true}>{hit.state}</AdminSearchTableCell>
                  {/* <AdminSearchTableCell center={true}>
                    <div css={[t.flex, t.justify_center, t.items_center]}>
                      <CheckboxIcon checked={true} />
                    </div>
                  </AdminSearchTableCell> */}
                  <AdminSearchTableCell>
                    {hit.reviewState === 'reviewed' ? (
                      <div css={[t.flex, t.justify_center, t.items_center]}>
                        <CheckboxIcon checked={true} />
                      </div>
                    ) : (
                      ''
                    )}
                  </AdminSearchTableCell>
                  <AdminSearchTableCell center={true}>{hit.playCount}</AdminSearchTableCell>
                  <AdminSearchTableCell>{dayjs(hit.lastModified).local().format('MMM DD, YYYY')} </AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.objectID}</AdminSearchTableCell>
                  <AdminSearchTableActionCell>
                    {rowProps.isHovered && (
                      <AdminIconButton id="artistDetailsButton" onClick={HandleClick}>
                        <PopupIcon css={[t.size('16px')]} />
                      </AdminIconButton>
                    )}
                  </AdminSearchTableActionCell>
                </React.Fragment>
              );
            }}
          </AdminSearchTableRow>
        );
      })}
      <AdminSongDetailsDialog isOpen={detailsActive} songId={objectID} onClose={HandleClose} />
    </tbody>
  );
};
