import React from 'react'; // eslint-disable-line

import t from '../../theme/newstyles';
import { FullScreenDialog } from '../dialogs/FullScreenDialog';
import { CloseIcon } from '../../icons/CloseIcon';
import { IconButton } from '../IconButton';
import { DownwriteIcon } from '../../icons/DownwriteIcon';
import { useThemeController } from '../../contexts/ThemeContext';

/** @jsxImportSource @emotion/react */

export function FullScreenMenu(props: {
  isOpen: boolean;
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  onClose: (event: React.MouseEvent) => void;
  children?: React.ReactNode;
}) {
  const { dark } = useThemeController();

  return (
    <FullScreenDialog isOpen={props.isOpen} onRequestClose={props.onRequestClose} dialogStyle={[t.min_h('620px')]} overlayStyle={[t.overflow_y_scroll]}>
      <div css={[t.flex, t.flex_col, t.flex_grow_2]}>
        <div css={[t.flex, t.mt_4, t.mx_5, dark ? t.text_tint_5 : t.text_dark_1]}>
          <div css={[t.flex_auto]}>
            <DownwriteIcon />
          </div>
          <IconButton type="button" onClick={props.onClose}>
            <CloseIcon css={[t.fill_current]} />
          </IconButton>
        </div>

        <nav role="navigation" css={[t.h_full, t.flex, t.flex_col]}>
          {props.children}
        </nav>
      </div>
    </FullScreenDialog>
  );
}
