import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const RefreshIcon = (props: {}) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <path d="M3 12C3 16.9706 7.02944 21 12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C9.10067 3 6.646 4.87097 5 7" css={[t.stroke_current]} />
      <path d="M5 3V7H9" css={[t.stroke_current]} />
    </svg>
  );
};
