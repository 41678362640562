import React from 'react'; // eslint-disable-line
import { FirebaseObjects } from '../FirebaseContext';
import { OrderProposal } from '../../types/shared-types';
import { OrderProposalControllerState } from './types';
import { useAccount } from '../AccountContext';
import { v4 as uuid } from 'uuid';

/** @jsxImportSource @emotion/react */

const GetOrderProposals = async (id: string) => {
  try {
    const proposals = await FirebaseObjects.visitorRequest({
      action: 'getOrderProposals',
      data: { id },
    });
    return proposals.data;
  } catch (error) {
    console.error(error);
  }
};

export const OrderProposalContext = React.createContext<OrderProposalControllerState>({} as OrderProposalControllerState);

export function OrderProposalProvider(props: { children: React.ReactNode }) {
  const account = useAccount();
  const [currentProposal, setCurrentProposal] = React.useState<OrderProposal>({} as OrderProposal);
  const [guestUUID, setGuestUUID] = React.useState<string | undefined>(undefined);
  const [guestProposals, setGuestProposals] = React.useState<OrderProposal[] | undefined>(undefined);
  const [userProposals, setUserProposals] = React.useState<OrderProposal[] | undefined>(undefined);

  React.useEffect(() => {
    // Check for and get/set guestUUID
    const storedIdentifier = window.localStorage.getItem('guestUUID');
    if ((!account || account.User === null) && storedIdentifier === null) {
      const identifier = uuid();
      setGuestUUID(identifier);
      window.localStorage.setItem('guestUUID', identifier);
    } else if (storedIdentifier) {
      setGuestUUID(storedIdentifier);
    }
    // Get user proposals
    let mounted = true;
    const id = account?.User.uid;
    const asyncWrapper = async () => {
      if (mounted && id) {
        const proposals = await GetOrderProposals(id);
        setUserProposals(proposals);
      }
    };
    asyncWrapper();

    return () => {
      mounted = false;
    };
  }, [account]);

  // Get proposals made by guest
  React.useEffect(() => {
    let mounted = true;
    const asyncWrapper = async () => {
      if (mounted && guestUUID && account === null) {
        const proposals = await GetOrderProposals(guestUUID);
        setGuestProposals(proposals);
      }
    };
    asyncWrapper();

    return () => {
      mounted = false;
    };
  }, [guestUUID, account]);

  const checkForNewProposals = async () => {
    const userId = account?.User.uid;
    if (userId) {
      const userProposals = await GetOrderProposals(userId);
      setUserProposals(userProposals);
    }
    if (guestUUID && account === null) {
      const guestProposals = await GetOrderProposals(guestUUID);
      setGuestProposals(guestProposals);
    }
  };

  const createOrderProposal = async (initialProposal: Partial<OrderProposal>, isSignedIn: boolean) => {
    try {
      let proposalId: string;
      if (isSignedIn) {
        const id = await FirebaseObjects.userRequest({
          action: 'createOrderProposal',
          data: initialProposal,
        });
        proposalId = id.data;
        if (account?.User.uid) {
          await GetOrderProposals(account?.User.uid);
        }
      } else {
        const id = await FirebaseObjects.visitorRequest({
          action: 'createOrderProposal',
          data: initialProposal,
        });
        proposalId = id.data;
        if (guestUUID && account === null) {
          await GetOrderProposals(guestUUID);
        }
      }
      return proposalId;
    } catch (error) {
      console.error(error);
      return Promise.reject(error);
    }
  };

  const getOrderProposal = async (proposalId: string) => {
    try {
      const proposal = await FirebaseObjects.visitorRequest({
        action: 'getOrderProposal',
        data: { proposalId },
      });
      setCurrentProposal(proposal.data);
      return proposal.data;
    } catch (error) {
      console.error(error);
      // return null;
      // setCurrentProposal({});
    }
  };

  const updateOrderProposal = async (proposalId: string, proposal: OrderProposal) => {
    try {
      const updatedProposal = await FirebaseObjects.visitorRequest({
        action: 'updateOrderProposal',
        data: { proposalId, proposal },
      });
      setCurrentProposal(updatedProposal.data);
      return updatedProposal.data;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  };

  const claimGuestProposals = async (userId: string) => {
    try {
      await FirebaseObjects.visitorRequest({
        action: 'claimGuestProposals',
        data: { userId, guestUUID },
      });
      await checkForNewProposals();
    } catch (error) {
      console.error(error);
    }
  };

  const linkOrderToProposal = async (orderId: string, proposalId: string) => {
    try {
      await FirebaseObjects.userRequest({
        action: 'linkProposalToOrder',
        data: { orderId, proposalId },
      });
    } catch (error) {
      console.error(error);
    }
  };

  const clearCurrentProposal = () => {
    setCurrentProposal({} as OrderProposal);
  };

  return (
    <OrderProposalContext.Provider
      value={{
        currentProposal,
        guestUUID,
        guestProposals,
        userProposals,
        createOrderProposal,
        getOrderProposal,
        updateOrderProposal,
        linkOrderToProposal,
        clearCurrentProposal,
        claimGuestProposals,
        setCurrentProposal,
        checkForNewProposals,
      }}
    >
      {props.children}
    </OrderProposalContext.Provider>
  );
}

export function useOrderProposal() {
  return React.useContext(OrderProposalContext);
}

export default useOrderProposal;
