import React from 'react'; // eslint-disable-line

import t from '../../theme/newstyles';
import { MainPage } from '../../components/MainPage';
import { MainContainer } from '../../components/MainContainer';
import { Button } from '../../components/Button';
import { Helmet } from 'react-helmet-async';

/** @jsxImportSource @emotion/react */

export function NotFound() {
  return (
    <MainPage>
      <Helmet>
        <title>Downwrite - Not found</title>
      </Helmet>
      <MainContainer>
        <div css={[t.gridTypeStyle_1, t.mt_5, t.mb_2, t.sm([t.my_6]), t.lg([t.my_7])]}>404</div>
        <div css={[t.breakpointGrid_12]}>
          <div css={[t.gridTypeStyle_4, t.mb_6, t.sm([t.mb_7, t.col_span_11]), t.md([t.col_span_9]), t.lg([t.col_span_7, t.mb_8])]}>
            <div css={[t.mb_5]}>Are you lost? It looks like this page doesn’t exist.</div>
            <div>Perhaps you’d like to browse our collection of talented artists instead.</div>
          </div>
          <div css={[t.col_start_1, t.mb_8, t.lg([t.mb_9])]}>
            <Button type="link" to="/artists" label="Browse artists" />
          </div>
        </div>
      </MainContainer>
    </MainPage>
  );
}

export default NotFound;
