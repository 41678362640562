import React from 'react'; // eslint-disable-line

import t, { CSSStyle } from '../theme/newstyles';
import { CaretIcon } from '../icons/CaretIcon';
import { motion } from 'framer-motion';

/** @jsxImportSource @emotion/react */

const modalVariants = {
  hide: {
    opacity: 0,
    y: 0,
    height: '0',
    transitionEnd: {
      display: 'none',
    },
    transition: { delay: 0.05, duration: 0.2 },
  },
  show: { opacity: 1, y: 0, height: 'auto', display: 'flex', transition: { delayChildren: 0.15, staggerChildren: 0.06 } },
};

export type SelectDropdownOptionType = { label: string; value: string };

export const SelectDropdown = (props: {
  label: string;
  options: SelectDropdownOptionType[];
  onSelect: (choice: string) => void;
  currentValue: string;
  style?: CSSStyle;
  buttonStyle?: CSSStyle;
  disabled?: boolean;
}) => {
  const { label, options, onSelect, currentValue, style, buttonStyle, disabled } = props;

  const [showDropdown, setShowDropdown] = React.useState(false);
  const toggleDropdown = () => setShowDropdown(!showDropdown);
  const handleClick = (value: string) => {
    onSelect(value);
    setShowDropdown(false);
  };

  return (
    <div css={[t.relative, style]}>
      {/* Anchor button */}
      <button css={[t.bg_transparent, t.h_7, buttonStyle]} onClick={toggleDropdown} disabled={disabled}>
        <div css={[t.flex, t.justify_between, t.items_center]}>
          <span css={[t.mr_2]}>{label}</span>
          <CaretIcon direction={showDropdown ? 'up' : 'down'} />
        </div>
      </button>
      {/* MENU */}
      <motion.div
        css={[t.absolute, t.top('80%'), t.right_0, t.z_50, t.mt_3, t.w('156px')]}
        initial="hide"
        animate={showDropdown ? 'show' : 'hide'}
        variants={modalVariants}
      >
        <div css={[t.flex_auto, t.flex, t.flex_col, t.p_3, t.overflow_hidden, t.bg_tint_5, t.shadow_header]}>
          {options.map(({ label, value }) => (
            <button
              key={value}
              name={value}
              onClick={() => handleClick(value)}
              css={[t.w_full, t.text_left, t.p_1, t.lineHeight('28px'), t.bg_transparent, t.text_color(value === currentValue ? 'blue' : 'inherit')]}
            >
              {label}
            </button>
          ))}
        </div>
      </motion.div>
    </div>
  );
};

export default SelectDropdown;
