import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import dayjs from 'dayjs';
import { useSong, AdminUseSong, SongState } from '../../../contexts/SongContext';
import { AdminSongPlayer, AdminSongPlayerProps } from '../components/songPlayer/AdminSongPlayer';
import { AdminPlayPauseButton } from '../components/songPlayer/AdminPlayPauseButton';
import { AdminButton } from '../components/AdminButton';
import { AdminPreviousTrackIcon } from '../icons/AdminPreviousTrack';
import { ProgressBar } from '../../../components/ProgressBar';

/** @jsxImportSource @emotion/react */

export const AdminSongDetailsPlayer = () => {
  const [isFetchingSongUrl, setIsFetchingSongUrl] = React.useState(false);
  const songUrl = React.useRef<string>('');

  const song = useSong();

  React.useEffect(() => {
    if (song) {
      const getSong = async () => {
        setIsFetchingSongUrl(true);
        const _songUrl = await AdminUseSong(song.id, 'stream');
        songUrl.current = _songUrl ? _songUrl : '';
        setIsFetchingSongUrl(false);
      };
      if (songUrl.current === '' && !isFetchingSongUrl) getSong();
    }
  }, [song, isFetchingSongUrl]);

  if (!song) return null;
  if (song.state === SongState.requested) return null;

  return (
    <div css={[t.pt_4, t.pb_6, t.px_6, t.flex, t.flex_col]}>
      <AdminSongPlayer songDuration={song.duration} containerStyle={[t.flex_col]} fixedUrl={songUrl.current}>
        {(playerProps: AdminSongPlayerProps) => (
          <div css={[t.px_6]}>
            <div css={[t.flex, t.justify_center]}>
              <div css={[t.mx_2]}>
                <AdminButton type="button" icon={<AdminPreviousTrackIcon />} styleType="secondary" onClick={playerProps.handleRestart} />
              </div>
              <div css={[t.mx_2]}>
                <AdminPlayPauseButton isPlaying={playerProps.isPlaying} onClick={playerProps.handlePlayPause} isLoading={playerProps.isLoading} />
              </div>
            </div>
            <div css={[t.mt_2, t.w_full, t.flex_auto, t.flex, t.justify_center, t.items_center]}>
              <div css={[t.flex_initial, t.text_xs, t.text_tint_1]}>{dayjs.utc(playerProps.playedSeconds * 1000).format('mm:ss')}</div>
              <ProgressBar outerStyle={[t.flex_auto, t.mx_3]} progress={playerProps.percentProgress} />
              <div css={[t.flex_initial, t.text_xs, t.text_tint_1]}>{dayjs.utc(song.duration * 1000).format('mm:ss')}</div>
            </div>
          </div>
        )}
      </AdminSongPlayer>
    </div>
  );
};
