import * as React from 'react';

export interface ThemeController {
  dark?: boolean;
}

export const ThemeContext = React.createContext({} as ThemeController);
export const ThemeProvider = (props: { dark?: boolean; children: React.ReactNode }) => {
  return (
    <ThemeContext.Provider
      value={{
        dark: props.dark,
      }}
    >
      {props.children}
    </ThemeContext.Provider>
  );
};

export function useThemeController() {
  return React.useContext(ThemeContext);
}
