import React from 'react'; // eslint-disable-line

import t from '../../theme/newstyles';
import { useAccount, HasUnseenNotifications } from '../../contexts/AccountContext';
import { IconButton } from '../IconButton';
import { NotificationIcon } from '../../icons/NotificationIcon';
import { NotificationsDropDown } from './NotificationsDropDown';
import { useSM } from '../../contexts/BreakpointContext';

/** @jsxImportSource @emotion/react */

export const NotificationsButton = (props: { hidden?: boolean }) => {
  const sm = useSM();
  const [showNotifications, setShowNotifications] = React.useState(false);
  const account = useAccount();

  if (props.hidden) return null;
  if (!account) return null;
  const notifications = account.Settings.notifications || [];

  const hasUnseenNotifications = HasUnseenNotifications(account);

  const toggleNotificationsDropDown = () => {
    setShowNotifications(!showNotifications);
  };

  return (
    <div css={[t.relative, t.flex, t.items_center]}>
      {!sm ? (
        <IconButton type="link" to="/notifications" ariaLabel="Notifications">
          <NotificationIcon css={[t.w_5, t.h_5]} alert={hasUnseenNotifications} />
        </IconButton>
      ) : (
        <IconButton type="button" ariaLabel="Notifications" onClick={toggleNotificationsDropDown}>
          <NotificationIcon css={[t.w_5, t.h_5]} alert={hasUnseenNotifications} />
        </IconButton>
      )}

      <NotificationsDropDown isOpen={showNotifications} notifications={notifications} setShowNotifications={setShowNotifications} />
    </div>
  );
};
