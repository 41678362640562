import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const YoutubeIcon = (props: object) => {
  return (
    <svg role="img" aria-labelledby="youtubeIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="youtubeIconTitleID">Youtube Icon</title>
      <mask id="path-1-inside-1" fill="white">
        <path d="M19.615 3.18412C23.516 3.45112 23.971 5.81509 24 12L23.9857 13.2915C23.8861 18.5273 23.2286 20.5692 19.615 20.8159C16.011 21.0619 7.985 21.0609 4.385 20.8159C0.484 20.5489 0.029 18.1849 0 12L0.00907135 11.0201C0.0893013 5.5535 0.700564 3.43561 4.385 3.18412C7.984 2.93913 16.011 2.93813 19.615 3.18412Z" />
      </mask>
      <path
        d="M19.615 3.18412C23.516 3.45112 23.971 5.81509 24 12L23.9857 13.2915C23.8861 18.5273 23.2286 20.5692 19.615 20.8159C16.011 21.0619 7.985 21.0609 4.385 20.8159C0.484 20.5489 0.029 18.1849 0 12L0.00907135 11.0201C0.0893013 5.5535 0.700564 3.43561 4.385 3.18412C7.984 2.93913 16.011 2.93813 19.615 3.18412Z"
        css={[t.stroke_current]}
        strokeWidth="2"
        mask="url(#path-1-inside-1)"
      />
      <path d="M10 15V9L15 12L10 15Z" css={[t.stroke_current]} />
    </svg>
  );
};
