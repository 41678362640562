import React from 'react'; // eslint-disable-line
import { css } from '@emotion/react';

import t from '../../theme/newstyles';
import { SuccessCheck } from '../../icons/SuccessCheck';
import { CloseIcon } from '../../icons/CloseIcon';

/** @jsxImportSource @emotion/react */

const boxShadow = css`
  box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.12);
`;

export type ToastType = 'success' | 'failure';

export const Toast = (props: { toastType: string; toastMessage: string }) => {
  if (props.toastType === 'success') {
    return (
      <div css={[t.bg_success, t.text_tint_5, t.flex, t.justify_center, t.items_center, t.py_2, t.px_5, boxShadow]}>
        <div css={[t.pr_3]}>
          <SuccessCheck />
        </div>
        <div css={[t.typeStyle_lg6]}>{props.toastMessage}</div>
      </div>
    );
  } else
    return (
      <div css={[t.bg_error_1, t.text_tint_5, t.flex, t.justify_center, t.items_center, t.py_2, t.px_5, boxShadow]}>
        <div css={[t.pr_3]}>
          <CloseIcon />
        </div>
        <div css={[t.typeStyle_lg6]}>{props.toastMessage}</div>
      </div>
    );
};
