import React, { JSX } from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { AdminTextFieldLabel } from './AdminTextFieldLabel';
import { AdminTextInputProps } from './AdminTextField';

/** @jsxImportSource @emotion/react */

export const AdminInputText = (
  props: {
    id: string;
    name?: string;
    value?: string | number | string[];
    defaultValue?: string | number | string[];
    step?: number;
    icon?: JSX.Element;
    startAdornment?: string;
    required?: boolean;
    invalid?: boolean;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
    onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
    onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  } & AdminTextInputProps
) => {
  const [focus, SetFocus] = React.useState(false);
  const inputElement = React.useRef<HTMLInputElement | null>(null);

  const inputPadding = props.description ? [t.px_4, t.pr_5] : [t.px_4];

  let container = [t.border_tint_2, t.hover(t.border_dark_1)];
  if (props.disabled) container = [t.border_tint_2];
  if (props.invalid) {
    container = [t.border_error_1];
  }
  if (focus) {
    container = [t.border_primary_4];
  }

  const disabledContainer = props.disabled ? t.bg_tint_4 : t.bg_white;

  function HandleClick(event: React.MouseEvent) {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }

  function HandleFocus(event: React.FocusEvent<HTMLInputElement>) {
    SetFocus(true);
    if (props.onFocus) props.onFocus(event);
  }

  function HandleBlur(event: React.FocusEvent<HTMLInputElement>) {
    SetFocus(false);
    if (props.onBlur) props.onBlur(event);
  }

  return (
    <div css={[t.flex, t.flex_col]}>
      {props.label && (
        <AdminTextFieldLabel
          label={props.label}
          description={props.description}
          id={props.id}
          optional={props.optional}
          labelOffscreen={props.labelOffscreen}
        />
      )}
      <div
        css={[t.relative, t.inline_flex, t.items_center, t.border_solid, t.border_1, container, t.text_sm, t.text_dark_1, inputPadding, disabledContainer]}
        onClick={HandleClick}
      >
        <InputIcon icon={props.icon} invalid={props.invalid} focus={focus} />
        <InputStartAdornment adornment={props.startAdornment} />
        <input
          ref={inputElement}
          css={[t.text_sm, t.w_full, t.bg_transparent, t.py_3, t.placeholder(t.text_tint_2)]}
          id={props.id}
          type={props.type ? props.type : 'text'}
          name={props.name}
          value={props.value}
          step={props.step}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder ? props.placeholder : ''}
          disabled={props.disabled}
          required={props.required}
          aria-required={props.required}
          aria-disabled={props.disabled}
          aria-describedby={props.id}
          aria-invalid={props.invalid}
          onChange={props.onChange}
          onBlur={HandleBlur}
          onFocus={HandleFocus}
        />
      </div>
    </div>
  );
};

const InputIcon = (props: { icon?: JSX.Element; invalid?: boolean; focus?: boolean }) => {
  if (!props.icon) return null;

  let iconColor = [t.text_tint_2];

  if (props.invalid) iconColor = [t.text_error_1];
  if (props.focus) iconColor = [t.text_primary_4];

  return <div css={[t.flex, t.items_center, t.justify_center, t.mr_3, t.size('24px'), iconColor]}>{props.icon}</div>;
};

const InputStartAdornment = (props: { adornment?: string }) => {
  if (!props.adornment) return null;
  return <div css={[t.flex, t.items_center, t.justify_center, t.mr_2, t.text_sm, t.text_tint_2]}>{props.adornment}</div>;
};
