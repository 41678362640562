import { css } from '@emotion/react';

export function listStyleType(style: string) {
  return css`
    list-style-type: "${style}";
  `;
}

export const listStyle = {
  listStyleType: listStyleType,

  listStyle_disc: css`
    list-style-type: disc;
  `,
  listStyle_decimal: css`
    list-style-type: decimal;
  `,
  listStyle_lowerAlpha: css`
    list-style-type: lower-alpha;
  `,
  listStyle_lowerRoman: css`
    list-style-type: lower-roman;
  `,
  listStyle_none: css`
    list-style-type: none;
  `,

  list_inside: css`
    list-style-position: inside;
  `,
  list_outside: css`
    list-style-position: outside;
  `,
};
