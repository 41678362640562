import React from 'react'; // eslint-disable-line
import { css } from '@emotion/react';

import t from '../theme/newstyles';
import { motion } from 'framer-motion';

/** @jsxImportSource @emotion/react */

const flip = css`
  transform: scaleY(-1);
`;
const rotateLeft = css`
  transform: rotateZ(-90deg);
`;
const rotateRight = css`
  transform: rotateZ(90deg);
`;

export const CaretIcon = (props: { direction?: 'up' | 'down' | 'left' | 'right' }) => {
  const { direction, ...rest } = props;
  const dir = direction ? direction : 'down';

  switch (dir) {
    case 'up':
      return (
        <div css={[flip]}>
          <motion.svg role="img" aria-labelledby="upArrowIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
            <title id="upArrowIconTitleID">Up Arrow Icon</title>
            <path d="M6 9L12 15L18 9" css={[t.stroke_current]} />
          </motion.svg>
        </div>
      );
    case 'down':
      return (
        <motion.svg role="img" aria-labelledby="downArrowIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
          <title id="downArrowIconTitleID">Down Arrow Icon</title>
          <path d="M6 9L12 15L18 9" css={[t.stroke_current]} />
        </motion.svg>
      );
    case 'right':
      return (
        <div css={[rotateLeft]}>
          <motion.svg role="img" aria-labelledby="rightArrowIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
            <title id="rightArrowIconTitleID">Right Arrow Icon</title>
            <path d="M6 9L12 15L18 9" css={[t.stroke_current]} />
          </motion.svg>
        </div>
      );
    default:
      return (
        <div css={[rotateRight]}>
          <motion.svg role="img" aria-labelledby="leftArrowIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
            <title id="leftArrowIconTitleID">Left Arrow Icon</title>
            <path d="M6 9L12 15L18 9" css={[t.stroke_current]} />
          </motion.svg>
        </div>
      );
  }
};
