import React from 'react'; // eslint-disable-line

import t from '../../theme/newstyles';
import { Link } from 'react-router-dom';
import { useArtistProfile } from '../../contexts/ArtistProfileContext';
import { useThemeController } from '../../contexts/ThemeContext';

/** @jsxImportSource @emotion/react */

export const ArtistAvailability = () => {
  const { dark } = useThemeController();
  const artistProfile = useArtistProfile();

  const profileData = artistProfile.Profile;
  if (!profileData) return null;
  if (profileData.availability.available) return null;

  return (
    <div css={[dark ? t.bg_dark_2 : t.bg_tint_4, t.px_5, t.py_6, t.mt_8, t.mb_6, t.md([t.p_4, t.my_0])]}>
      <div css={[t.flex, t.items_center]}>
        <div css={[t.bg_error_2, t.size('11px'), t.border_circle, t.mr_2, t.md([t.order_last, t.mr_0])]} />
        <div css={[t.flex_auto, dark ? t.text_tint_5 : t.text_dark_1, t.gridTypeStyle_4, t.md([t.gridTypeStyle_6])]}>Unavailable</div>
      </div>
      <Link
        to="/edit-profile/availability"
        css={[t.gridTypeStyle_6, dark ? t.text_tint_5 : t.text_tint_1, t.no_underline, t.md([t.gridTypeStyle_7, dark ? t.text_tint_5 : t.text_dark_1])]}
      >
        Update settings
      </Link>
    </div>
  );
};
