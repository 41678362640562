import React from 'react'; // eslint-disable-line

export function useFileDownload(url: string) {
  const [download, setDownload] = React.useState<string | undefined>(undefined);
  React.useEffect(() => {
    setDownload(undefined);
    if (!url) return;
    let mounted = true;
    let localURL: string | null = null;
    const asyncWrapper = async () => {
      const response = await fetch(url);
      const blob = await response.blob();

      if (mounted) {
        localURL = URL.createObjectURL(blob);
        setDownload(localURL);
      }
    };

    asyncWrapper();
    return () => {
      if (localURL) URL.revokeObjectURL(localURL);
      mounted = false;
    };
  }, [url]);

  return download;
}
