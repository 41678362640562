import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { CSSStyle } from '../../../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const AdminListButton = (props: { label: string; critical?: boolean; disabled?: boolean; onClick?: (event: React.MouseEvent) => void }) => {
  let style: CSSStyle = null;
  if (props.disabled) style = [t.text_tint_2];
  else if (props.critical) style = [t.text_error_1, t.hover([t.bg_error_1, t.text_tint_5])];
  else style = [t.text_tint_1, t.hover([t.bg_tint_3, t.text_dark_1])];

  return (
    <button onClick={props.onClick} css={[t.px_3, t.py_2, t.bg_transparent, t.text_sm, t.cursor_pointer, style]} disabled={props.disabled}>
      {props.label}
    </button>
  );
};
