import React, { JSX } from 'react'; // eslint-disable-line
import { css } from '@emotion/react';

import { CSSStyle } from '../../../theme/newstyles';
import t from '../../../theme/admin/adminStyles';
import { Link } from 'react-router-dom';
import * as H from 'history';
import { Spinner } from '../../../components/Spinner';

/** @jsxImportSource @emotion/react */

const baseStyle = css`
  min-width: 96px;
  padding: 13px 24px 15px 24px;
  min-height: 32px;
  max-height: 32px;
`;

const secondaryPaddingStyle = css`
  padding-top: 12px;
  padding-bottom: 14px;
`;

const labelAndIconContainerStyle = css`
  padding: 13px 24px 15px 32px;
`;

const secondaryLabelAndIconContainerStyle = css`
  padding: 12px 24px 14px 32px;
`;

const iconOnlyContainerStyle = [
  css`
    min-width: 32px;
    max-width: 32px;
    min-height: 32px;
    max-height: 32px;
    padding: 16px;
  `,
];

const iconOnlyStyle = [t.mr_0];

const focusSize = css`
  top: -3px;
  bottom: -3px;
  right: -3px;
  left: -3px;
`;

const primaryStyle = [baseStyle, t.bg_primary_4, t.text_tint_5, t.hover(t.bg_primary_3), t.active([t.bg_primary_2, t.text_tint_4])];
const focusPrimaryStyle = [t.bg_primary_3];
const disabledPrimaryStyle = [t.bg_tint_2, t.text_tint_5];

const secondaryStyle = [
  baseStyle,
  secondaryPaddingStyle,
  t.bg_transparent,
  t.text_dark_1,
  t.border_1,
  t.border_dark_1,
  t.border_solid,
  t.hover([t.text_primary_4, t.border_primary_4]),
  t.active([t.text_primary_2, t.border_primary_2]),
];
const focusSecondaryStyle = [t.text_primary_4, t.border_primary_4];
const disabledSecondaryStyle = [t.text_tint_2, t.border_tint_2];

const secondaryOnDarkStyle = (textAndIconColor: CSSStyle) => {
  return [
    baseStyle,
    secondaryPaddingStyle,
    t.bg_transparent,
    t.text_tint_5,
    t.border_1,
    t.border_tint_5,
    t.border_solid,
    t.hover([textAndIconColor, t.border_tint_5, t.bg_tint_5]),
    t.active([t.border_tint_2, t.bg_tint_2, textAndIconColor]),
  ];
};
const focusSecondaryOnDarkStyle = [t.border_tint_5];
const disabledSecondaryOnDarkStyle = [t.border_tint_1, t.text_tint_1];

const criticalStyle = [
  baseStyle,
  secondaryPaddingStyle,
  t.bg_transparent,
  t.text_error_1,
  t.border_1,
  t.border_error_1,
  t.border_solid,
  t.hover([t.text_tint_5, t.border_error_1, t.bg_error_1]),
  t.active([t.text_primary_2, t.text_tint_5, t.border_error_2, t.bg_error_2]),
];
const focusCriticalStyle = [t.text_tint_5, t.border_error_1, t.bg_error_1];
const disabledCriticalStyle = [t.text_tint_2, t.bg_transparent, t.border_tint_2];

const overlayStyle = [baseStyle, t.bg_tint_5, t.text_dark_1, t.hover([t.bg_primary_4, t.text_tint_5]), t.active([t.bg_primary_2, t.text_tint_5])];
const focusOverlayStyle = [t.bg_primary_4, t.text_tint_5];
const disabledOverlayStyle = [t.text_tint_2];

type SharedButtonProps = {
  label?: string;
  styleType?: 'primary' | 'secondary' | 'secondaryOnDark' | 'critical' | 'overlay';
  disabled?: boolean;
  icon?: JSX.Element;
  isInProgress?: boolean;
  textAndIconColor?: CSSStyle;
  outerStyle?: CSSStyle;
  innerStyle?: CSSStyle;
};

type ButtonLinkProps<S> = {
  type: 'link';
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
} & SharedButtonProps;

type ButtonProps<S> =
  | ({
      type: 'button';
      buttonType?: React.ButtonHTMLAttributes<HTMLButtonElement>['type'];
      onClick?: (event: React.MouseEvent) => void;
    } & SharedButtonProps)
  | ButtonLinkProps<S>
  | ({
      type: 'input';
      formName: string;
      inputType?: React.InputHTMLAttributes<HTMLInputElement>['type'];
      accept?: string;
      onChange?: (event: React.ChangeEvent) => void;
      onInput?: (event: React.FormEvent<HTMLInputElement>) => void;
    } & SharedButtonProps);

export function AdminButton<S = H.LocationState>(props: ButtonProps<S>) {
  const inputElement = React.useRef<HTMLInputElement | null>(null);
  const styleType = props.styleType ? props.styleType : 'primary';

  let innerStyle = primaryStyle;
  let focusStyle = focusPrimaryStyle;
  let disabledStyle = disabledPrimaryStyle;

  switch (styleType) {
    case 'secondary': {
      innerStyle = secondaryStyle;
      disabledStyle = disabledSecondaryStyle;
      focusStyle = focusSecondaryStyle;

      break;
    }
    case 'secondaryOnDark': {
      innerStyle = secondaryOnDarkStyle(props.textAndIconColor || t.text_dark_1);
      disabledStyle = disabledSecondaryOnDarkStyle;
      focusStyle = focusSecondaryOnDarkStyle;

      break;
    }
    case 'critical': {
      innerStyle = criticalStyle;
      disabledStyle = disabledCriticalStyle;
      focusStyle = focusCriticalStyle;

      break;
    }
    case 'overlay': {
      innerStyle = overlayStyle;
      disabledStyle = disabledOverlayStyle;
      focusStyle = focusOverlayStyle;
      break;
    }
  }

  const iconOnly = props.icon && !props.label;
  const labelAndIcon = props.label && props.icon;

  let containerStyle = null;
  let iconStyle = null;
  if (labelAndIcon)
    containerStyle =
      props.styleType === 'critical' || props.styleType === 'secondary' || props.styleType === 'secondaryOnDark'
        ? secondaryLabelAndIconContainerStyle
        : labelAndIconContainerStyle;
  if (iconOnly) {
    containerStyle = [iconOnlyContainerStyle, t.bg_tint_5];
    iconStyle = iconOnlyStyle;
  }
  if (iconOnly && props.styleType === 'secondaryOnDark') {
    containerStyle = [iconOnlyContainerStyle];
  }

  function HandleInnerFocus(event: React.FocusEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  const contents = (
    <span
      css={[
        t.relative,
        t.inline_flex,
        t.flex_row,
        t.justify_center,
        t.items_center,
        t.outline_none,
        t.text_sm,
        innerStyle,
        containerStyle,
        t.before([t.focusIndicator, focusSize, t.border_primary_4, t.content_none]),
        props.innerStyle,
      ]}
      tabIndex={-1}
      onFocus={HandleInnerFocus}
    >
      {props.isInProgress && <Spinner styleType={props.styleType} small={true} />}
      {props.icon && !props.isInProgress && (
        <div css={[t.inline_block, t.size('24px'), t.mr_2, t.flex, t.items_center, t.justify_center, iconStyle]}>{props.icon}</div>
      )}
      {props.label && !props.isInProgress && <span css={[t.whitespace_no_wrap]}>{props.label}</span>}
    </span>
  );

  const outerStyle = [
    t.relative,
    t.bg_transparent,
    t.outline_none,
    t.cursor_pointer,
    t.no_underline,
    t.p_0,
    t.disabled(t.cursor_not_allowed),
    t.disabledSelector('> span', disabledStyle),
    t.focusSelector('> span::before', t.content_some),
    t.focusSelector('> span', focusStyle),
    props.outerStyle,
  ];

  switch (props.type) {
    case 'button': {
      const buttonType = props.buttonType || 'button';

      const HandleClick = (event: React.MouseEvent) => {
        if (props.onClick) props.onClick(event);
      };

      return (
        <button type={buttonType} css={outerStyle} disabled={props.disabled} aria-disabled={props.disabled} onClick={HandleClick}>
          {contents}
        </button>
      );
    }
    case 'link': {
      if (!props.disabled) {
        return (
          <Link to={props.to} css={outerStyle}>
            {contents}
          </Link>
        );
      } else {
        return (
          <button css={outerStyle} disabled={props.disabled} aria-disabled={props.disabled}>
            {contents}
          </button>
        );
      }
    }
    case 'input': {
      const id = `button-${String(props.formName)}`;

      const HandleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter' || event.key === ' ') {
          if (inputElement.current) {
            inputElement.current.click();
          }
        }
      };

      return (
        <div css={[t.relative]}>
          <label htmlFor={id} tabIndex={0} onKeyPress={HandleKeyPress} css={outerStyle}>
            {contents}
            <input
              id={id}
              ref={inputElement}
              tabIndex={-1}
              css={[t.hidden_input]}
              type={props.inputType}
              accept={props.accept}
              onChange={props.onChange}
              onInput={props.onInput}
            />
          </label>
        </div>
      );
    }
  }
}
