import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { useArtistProfile } from '../../../contexts/ArtistProfileContext';
import { useUserAccount } from '../../../contexts/UserAccountContext';
import { AdminDetailsLinkButton } from '../components/Dialogs/AdminDetailsLinkButton';
import { AdminDetailsDialogInfo } from '../components/Dialogs/AdminDetailsDialogInfo';

/** @jsxImportSource @emotion/react */

export const AdminArtistDetailsOrders = () => {
  const artistProfile = useArtistProfile();
  const artistAccount = useUserAccount();
  const profileData = artistProfile.Profile;

  if (!profileData) return null;
  if (!artistAccount || artistAccount.deleted) return null;

  let pendingCount = 0;
  let activeCount = 0;
  let completedCount = 0;
  let rejectedCount = 0;
  if (profileData.orderStats) {
    pendingCount = profileData.orderStats.pendingOrders;
    activeCount = profileData.orderStats.activeOrders;
    completedCount = profileData.orderStats.completedOrders;
    rejectedCount = profileData.orderStats.rejectedOrders;
  }

  const totalCount = pendingCount + activeCount + completedCount + rejectedCount;

  return (
    <div css={[t.pt_3, t.pb_6, t.px_6, t.flex, t.flex_col]}>
      <div css={[t.text_sm, t.text_tint_1]}>Projects</div>
      <div css={[t.flex, t.mt_4]}>
        <div>
          <div css={[t.flex]}>
            <div css={[t.mr_8]}>
              <AdminDetailsDialogInfo label="Total">
                <AdminDetailsLinkButton to={`/admin/orders/${artistAccount.id}/[""]`} disabled={!totalCount}>
                  {GetCountString(totalCount)}
                </AdminDetailsLinkButton>
              </AdminDetailsDialogInfo>
            </div>

            <div css={[t.mr_8]}>
              <AdminDetailsDialogInfo label="Pending">
                <AdminDetailsLinkButton to={`/admin/orders/${artistAccount.id}/["pending"]`} disabled={!pendingCount}>
                  {GetCountString(pendingCount)}
                </AdminDetailsLinkButton>
              </AdminDetailsDialogInfo>
            </div>

            <div css={[t.mr_8]}>
              <AdminDetailsDialogInfo label="Active">
                <AdminDetailsLinkButton to={`/admin/orders/${artistAccount.id}/["active"]`} disabled={!activeCount}>
                  {GetCountString(activeCount)}
                </AdminDetailsLinkButton>
              </AdminDetailsDialogInfo>
            </div>

            <div css={[t.mr_8]}>
              <AdminDetailsDialogInfo label="Completed">
                <AdminDetailsLinkButton to={`/admin/orders/${artistAccount.id}/["completed"]`} disabled={!completedCount}>
                  {GetCountString(completedCount)}
                </AdminDetailsLinkButton>
              </AdminDetailsDialogInfo>
            </div>

            <div>
              <AdminDetailsDialogInfo label="Rejected">
                <AdminDetailsLinkButton to={`/admin/orders/${artistAccount.id}/["rejected"]`} disabled={!rejectedCount}>
                  {GetCountString(rejectedCount)}
                </AdminDetailsLinkButton>
              </AdminDetailsDialogInfo>
            </div>
          </div>
          {profileData.availability.bandwidth !== 0 && (
            <div css={[t.mt_4]}>
              <AdminDetailsDialogInfo label="Bandwidth" text={`${profileData.availability.bandwidth} concurrent`} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function GetCountString(count: number): string {
  if (!count) return 'None';
  return `${count} order${count > 1 ? 's' : ''}`;
}
