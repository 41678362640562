import React, { JSX } from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { Field, FieldProps, getIn } from 'formik';
import { AdminInputText } from './AdminInputText';
import { AdminTextArea } from './AdminTextArea';
import { CSSStyle } from '../../../theme/newstyles';

/** @jsxImportSource @emotion/react */

export type AdminTextInputProps = {
  type?: string;
  label?: string;
  labelOffscreen?: string;
  description?: string;
  maxLength?: number;
  placeholder?: string;
  optional?: boolean;
  disabled?: boolean;
  containerStyle?: CSSStyle;
  inputContainerStyle?: CSSStyle;
  inputStyle?: CSSStyle;
};

export function AdminTextField<T>(
  props: {
    formName: string;
    name: keyof T;
    icon?: JSX.Element;
    startAdornment?: string;
    multiline?: boolean;
    fullWidth?: boolean;
    rows?: number;
    formikValues?: T;
    onBlur?: () => void;
  } & AdminTextInputProps
) {
  const { formName, name, icon, startAdornment, multiline, rows, formikValues, type, onBlur, ...rest } = props;

  return (
    <Field name={name}>
      {({ field, form }: FieldProps) => {
        const invalid = form.touched[field.name] && form.errors[field.name] ? true : undefined;
        const fieldId = `field-${formName}-${field.name}`;

        let characterCount = 0;
        if (formikValues) {
          const value = getIn(formikValues, field.name);
          if (typeof value === 'string') characterCount = value.length;
        }

        const handleBlur = (event: React.FocusEvent) => {
          if (onBlur) onBlur();
          field.onBlur(event);
        };

        return (
          <div css={[t.relative, props.fullWidth ? t.flex_auto : null]}>
            {multiline ? (
              <AdminTextArea
                id={fieldId}
                type={type ? type : 'text'}
                invalid={invalid}
                onChange={field.onChange}
                onBlur={handleBlur}
                value={field.value}
                name={field.name}
                rows={rows}
                characterCount={characterCount}
                {...rest}
              />
            ) : (
              <AdminInputText
                id={fieldId}
                type={type ? type : 'text'}
                icon={icon}
                startAdornment={startAdornment}
                invalid={invalid}
                onChange={field.onChange}
                onBlur={handleBlur}
                value={field.value}
                name={field.name}
                {...rest}
              />
            )}
          </div>
        );
      }}
    </Field>
  );
}
