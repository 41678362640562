import * as React from 'react';
import { publicDownloadUrlBase } from './FirebaseContext';
import { ArtistOrderRating } from './ArtistProfileContext';
import dayjs from 'dayjs';

export enum ArtistRatingsState {
  Unknown = 'Unknown',
  Loading = 'Loading',
  Ready = 'Ready',
  Missing = 'Missing',
}

export interface ArtistRatingsData {
  artistRatings: ArtistOrderRating[];
}

export class ArtistRatings {
  constructor(state: ArtistRatingsState, artistProfileRoute: string, pageDocument: ArtistRatingsData | undefined) {
    this.State = state;
    if (pageDocument) this.Page = { ...pageDocument };
  }

  public State = ArtistRatingsState.Unknown;
  public Page: ArtistRatingsData | null = null;

  public Ready() {
    return this.State === ArtistRatingsState.Ready;
  }

  public GetPage() {
    return this.Page as ArtistRatingsData;
  }
}

export const ArtistRatingsContext = React.createContext<ArtistRatings>({} as ArtistRatings);
interface ArtistRatingsProviderProps {
  artistProfileRoute: string;
  children: React.ReactNode;
}

interface ArtistRatingsProviderState {
  contextState: ArtistRatings;
}

export class ArtistRatingsProvider extends React.Component<ArtistRatingsProviderProps, ArtistRatingsProviderState> {
  constructor(props: ArtistRatingsProviderProps) {
    super(props);
    this.state = {
      contextState: new ArtistRatings(ArtistRatingsState.Loading, '', undefined),
    };
  }
  _isMounted = false;

  private async fetchRatings() {
    const lowercaseRoute = this.props.artistProfileRoute;

    const url = `${publicDownloadUrlBase}/pages/artists/${lowercaseRoute}`;
    try {
      const fetchResult = await fetch(url);
      const fetchJson = await fetchResult.json();
      const artistRatings = {
        artistRatings: [...fetchJson.artistRatings],
      };
      if (this._isMounted) {
        this.setState({
          contextState: new ArtistRatings(ArtistRatingsState.Ready, lowercaseRoute, artistRatings),
        });
      }
    } catch (e) {
      this.setState({
        contextState: new ArtistRatings(ArtistRatingsState.Missing, lowercaseRoute, undefined),
      });
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    await this.fetchRatings();
  }

  async componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    return <ArtistRatingsContext.Provider value={this.state.contextState}>{this.props.children}</ArtistRatingsContext.Provider>;
  }
}

export function useArtistRatings() {
  return React.useContext(ArtistRatingsContext);
}

export function GetPublishableRatingData(artistRatings: ArtistOrderRating[]) {
  let sumOfRatings = 0;
  let hasReviews = false;
  const activeRatings: ArtistOrderRating[] = [];
  if (artistRatings) {
    artistRatings.forEach((rating) => {
      if (rating.isPublishable) {
        sumOfRatings += rating.starRating;
        activeRatings.push(rating);
        if (rating.writtenReview) hasReviews = true;
      }
    });
  }
  const hasRatings = activeRatings.length > 0;
  const totalRatings = activeRatings.length;
  const averageRating = Math.round((sumOfRatings / totalRatings) * 10) / 10;
  return {
    hasRatings,
    totalRatings,
    averageRating,
    hasReviews,
  };
}

export function GetMostCommonRatingTags(artistRatings: ArtistOrderRating[]) {
  const preFilteredRatings = artistRatings.filter((rating) => {
    return rating.tags && rating.tags.length && rating.starRating > 3 && rating.isPublishable;
  });

  const uniqueTags: string[] = [];
  const allTags: string[] = [];
  const combinedTags = preFilteredRatings
    .filter(({ tags }) => (tags ? tags.length : 0))
    .reduce(
      (acc, { tags }) => {
        const newUniqueTags = tags ? tags.filter((tag) => !acc.uniqueTags.includes(tag)) : [];

        return {
          allTags: acc.allTags.concat(tags ? tags : []),
          uniqueTags: acc.uniqueTags.concat(newUniqueTags),
        };
      },
      { allTags, uniqueTags }
    );

  const mostCommonTags = combinedTags.uniqueTags
    .map((tag) => ({
      tag,
      count: combinedTags.allTags.filter((_tag) => _tag === tag).length,
    }))
    .sort((a, b) => (a.count > b.count ? -1 : 1))
    .splice(0, 3)
    .reduce((acc, tag) => ({ ...acc, [tag.tag]: tag.tag.charAt(0).toUpperCase() + tag.tag.slice(1) }), {});

  return mostCommonTags;
}

export function HasRatingWindowExpired(date: string, expirationWindow: number) {
  const dateToCheck = dayjs(date);
  const currentDate = dayjs();
  const timeHasExpired = currentDate.diff(dateToCheck, 'day') > expirationWindow;
  return timeHasExpired;
}
