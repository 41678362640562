export enum spacing {
  s0 = '0',
  s1 = '0.25rem',
  s2 = '0.5rem',
  s3 = '0.75rem',
  s4 = '1rem',
  s5 = '1.25rem',
  s6 = '1.5rem',
  s8 = '2rem',
  s10 = '2.5rem',
  s12 = '3rem',
  s16 = '4rem',
}
