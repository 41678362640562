import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import dayjs from 'dayjs';
import { SongState, useSong, AdminUseSong } from '../../../contexts/SongContext';
import { Link } from 'react-router-dom';
import { useUserAccount } from '../../../contexts/UserAccountContext';
import { useArtistProfile } from '../../../contexts/ArtistProfileContext';
import { AdminDetailsDialogInfo } from '../components/Dialogs/AdminDetailsDialogInfo';
import { AdminDownloadIcon } from '../icons/AdminDownloadIcon';
import { AdminActionsButton } from '../components/AdminActionButton';
import { AdminActionsPopover } from '../components/AdminActionsPopover';
import { AdminListButton } from '../components/AdminListButton';
import { MoreDotsIcon } from '../icons/MoreDotsIcons';
import { EditSongReviewedState, useSongMetadata } from '../../../contexts/SongMetadataContext';
import { SuccessCheck } from '../../../icons/SuccessCheck';

/** @jsxImportSource @emotion/react */

export const AdminSongDetailsInfo = () => {
  const [downloadPopover, SetDownloadPopover] = React.useState(false);
  const [popoverRect, SetPopoverRect] = React.useState<DOMRect | undefined>(undefined);

  const song = useSong();
  const songMetadata = useSongMetadata();
  const commissionerAccount = useUserAccount();
  const artistProfile = useArtistProfile();
  const artistProfileData = artistProfile.Profile;

  const downloadSongFile = React.useCallback(
    async (format: 'mp3' | 'flac' | 'wav') => {
      SetDownloadPopover(false);
      if (!song) return null;
      await AdminUseSong(song.id, format);
    },
    [song]
  );

  if (!song) return null;
  if (!commissionerAccount) return null;
  if (!artistProfileData) return null;

  const handleDownloadClick = (rect: DOMRect | undefined, event: React.MouseEvent) => {
    SetDownloadPopover(true);
    SetPopoverRect(rect);
  };

  const handleDownloadClose = () => {
    SetDownloadPopover(false);
  };

  return (
    <div css={[t.border_b, t.border_solid, t.border_tint_3, t.pt_5, t.pb_6, t.pl_6, t.flex]}>
      <div css={[t.flex_auto]}>
        <div css={[t.flex, t.flex_row, t.items_center]}>
          {songMetadata && songMetadata.reviewed === 'reviewed' ? <SuccessCheck css={[t.text_primary_4, t.mr_2]} /> : null}
          <div css={[t.text_2xl, t.text_dark_1, t.font_medium]}>{song.name !== '' ? song.name : 'Untitled'}</div>
        </div>

        <div css={[t.text_xs, t.text_tint_1, t.mt_2]}>ID: {song.id}</div>
        {song.isrc ? <div css={[t.text_xs, t.text_tint_1, t.mt_2]}>ISRC: {song.isrc}</div> : null}
        <div css={[t.flex, t.mt_3]}>
          <div css={[t.flex, t.mr_6]}>
            <div css={[t.text_sm, t.text_tint_1, t.mr_1]}>By</div>
            <Link
              to={`/admin/artists/${song.artistId}/`}
              css={[t.text_sm, t.text_dark_1, t.flex, t.items_center, t.no_underline, t.cursor_pointer, t.hover(t.underline)]}
            >
              {artistProfileData.name}
            </Link>
          </div>
          <div css={[t.flex]}>
            <div css={[t.text_sm, t.text_tint_1, t.mr_1]}>For</div>
            <Link
              to={`/admin/users/${song.customerId}/["user","artist","deleted]`}
              css={[t.text_sm, t.text_dark_1, t.flex, t.items_center, t.no_underline, t.cursor_pointer, t.hover(t.underline)]}
            >
              {`${commissionerAccount.firstname} ${commissionerAccount.lastname}`}
            </Link>
          </div>
        </div>
        <div css={[t.flex, t.mt_3]}>
          {song.state !== SongState.requested && (
            <div css={[t.mr_6]}>
              <AdminDetailsDialogInfo label="Last Modified" text={dayjs(song.lastModified).format('MMM DD, YYYY')} />
            </div>
          )}
          {song.state === SongState.complete && (
            <div>
              <AdminDetailsDialogInfo label="Play Count" text={`${song.playCount ? song.playCount : 0}`} />
            </div>
          )}
        </div>
      </div>

      <div css={[t.flex_initial]}>
        <div css={[t.flex, t.flex_row, t.items_center, t.pr_4]}>
          {song.state !== SongState.requested && (
            <div>
              <AdminActionsButton onClick={handleDownloadClick}>
                <AdminDownloadIcon />
              </AdminActionsButton>
            </div>
          )}
          <AdminSongDialogActions songId={song.id} />
        </div>
      </div>
      {/* Rendered in the Modal Root */}
      {downloadPopover ? (
        <AdminActionsPopover anchor="right" positionRect={popoverRect} onClose={handleDownloadClose}>
          <AdminListButton label={'Download mp3'} onClick={() => downloadSongFile('mp3')} />
          <AdminListButton label={'Download flac'} onClick={() => downloadSongFile('flac')} />
          <AdminListButton label={'Download wav'} onClick={() => downloadSongFile('wav')} />
        </AdminActionsPopover>
      ) : null}
    </div>
  );
};

const AdminSongDialogActions = (props: { songId: string }) => {
  const [actionsPopover, SetActionsPopover] = React.useState(false);
  const [popoverRect, SetPopoverRect] = React.useState<DOMRect | undefined>(undefined);

  const songMetadata = useSongMetadata();

  const handleActionsClick = (rect: DOMRect | undefined, event: React.MouseEvent) => {
    SetActionsPopover(true);
    SetPopoverRect(rect);
  };

  const handleActionsClose = () => {
    SetActionsPopover(false);
  };

  const reviewed = songMetadata && songMetadata.reviewed === 'reviewed';
  const attention = songMetadata && songMetadata.reviewed === 'attention';

  return (
    <div>
      <AdminActionsButton buttonCSS={[t.ml_3]} onClick={handleActionsClick}>
        <MoreDotsIcon css={[t.size('16px')]} />
      </AdminActionsButton>
      {actionsPopover ? (
        <AdminActionsPopover anchor="right" positionRect={popoverRect} onClose={handleActionsClose}>
          {!reviewed ? (
            <AdminListButton
              label={`Mark Reviewed`}
              onClick={() => {
                EditSongReviewedState(props.songId, 'reviewed');
                SetActionsPopover(false);
              }}
            />
          ) : null}
          {!attention ? (
            <AdminListButton
              label={`Mark For Attention`}
              onClick={() => {
                EditSongReviewedState(props.songId, 'attention');
                SetActionsPopover(false);
              }}
            />
          ) : null}
        </AdminActionsPopover>
      ) : null}
    </div>
  );
};
