import * as React from 'react';
import { FirebaseSDK } from './FirebaseContext';
import { ClientSideOrder as Order } from '../types/shared-types';
import firebase from 'firebase/compat/app';

const ArtistOrderListContext = React.createContext<Order[] | null>(null);
export function ArtistOrderListProvider(props: { artistId: string | null; children: React.ReactNode }) {
  const [state, setState] = React.useState<Order[] | null>(null);
  React.useEffect(() => {
    const commissionCollection = FirebaseSDK.firestore().collection('/orders');
    const filteredQuery = commissionCollection.where('artistId', '==', props.artistId);

    const observer = (snapshot: firebase.firestore.QuerySnapshot) => {
      const orders: Order[] = snapshot.docs.map((order) => {
        const orderData = order.data() as Order;
        orderData.id = order.id;
        return orderData;
      });
      orders.sort((a, b) => {
        if (a.dueOn > b.dueOn) return 1;
        else if (a.dueOn < b.dueOn) return -1;
        return 0;
      });
      setState(orders);
    };

    const error = (error: Error) => {
      setState(null);
    };

    return filteredQuery.onSnapshot(observer, error);
  }, [props.artistId]);

  return <ArtistOrderListContext.Provider value={state}>{props.children}</ArtistOrderListContext.Provider>;
}

export function useArtistOrderList() {
  return React.useContext(ArtistOrderListContext);
}

const CustomerOrderListContext = React.createContext<Order[] | null>(null);
export function CustomerOrderListProvider(props: { customerId: string | null; children: React.ReactNode }) {
  const [state, setState] = React.useState<Order[] | null>(null);
  React.useEffect(() => {
    const commissionCollection = FirebaseSDK.firestore().collection('/orders');
    const filteredQuery = commissionCollection.where('customerId', '==', props.customerId);

    const observer = (snapshot: firebase.firestore.QuerySnapshot) => {
      const orders: Order[] = snapshot.docs.map((order) => {
        const orderData = order.data() as Order;
        orderData.id = order.id;
        return orderData;
      });
      orders.sort((a, b) => {
        if (a.dueOn > b.dueOn) return 1;
        else if (a.dueOn < b.dueOn) return -1;
        return 0;
      });
      setState(orders);
    };

    const error = (error: Error) => {
      setState(null);
    };

    return filteredQuery.onSnapshot(observer, error);
  }, [props.customerId]);

  return <CustomerOrderListContext.Provider value={state}>{props.children}</CustomerOrderListContext.Provider>;
}

export function useCustomerOrderList() {
  return React.useContext(CustomerOrderListContext);
}
