import { CreateDocumentContext } from '../contexts/FirebaseContext';

export interface SwitchesDocument {
  id: string;
  payouts: boolean;
  purchasing: boolean;
  notifications: boolean;
  verifyPaypal: boolean;
  noted: boolean;
  ratings: boolean;
  perfectMatch: boolean;
}

export const DefaultSwitches: SwitchesDocument = {
  id: '',
  payouts: true,
  purchasing: true,
  notifications: true,
  verifyPaypal: true,
  noted: false,
  ratings: false,
  perfectMatch: false,
};

const SwitchesDocumentContext = CreateDocumentContext<SwitchesDocument>('/switches');
export const SwitchesProvider = SwitchesDocumentContext.Provider;
export const useSwitches = SwitchesDocumentContext.Use;

export const getVerifyPaypal = (switches: SwitchesDocument | null) => {
  return switches?.verifyPaypal === undefined ? DefaultSwitches.verifyPaypal : switches.verifyPaypal;
};
