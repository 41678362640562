import React from 'react'; // eslint-disable-line

import { Form, Formik, FormikProps } from 'formik';
import { FirebaseObjects } from '../../contexts/FirebaseContext';
import t from '../../theme/admin/adminStyles';
import { withAdminAccount } from '../../hocs/withAdminAccount';
import { AdminButton } from './components/AdminButton';
import { AdminPage } from './components/AdminPage';
import { AdminCard } from './components/AdminCard';
import { EditPaymentSplit, PublishPaymentSplits, usePaymentSplitContent } from '../../contexts/PaymentSplitsContentContext';
import { useSiteWidePromo } from '../../contexts/SiteWidePromoContext';
import { SiteWidePromo } from '../../types/shared-types';
import { AdminCardHeader } from './components/AdminCardHeader';
import { AdminSplit } from './components/AdminSplit';
import { FormCheckbox } from '../../components/FormCheckbox';
import { TextField } from '../../components/TextField';

/** @jsxImportSource @emotion/react */

const AdminSiteSettingsComponent = () => {
  return (
    <AdminPage pageHeading="Settings">
      <SiteWidePromotionForm id="TODO" />
      <AdminDefaultSplits />
    </AdminPage>
  );
};

const AdminDefaultSplits = () => {
  let splitContent = usePaymentSplitContent();
  if (splitContent == null) splitContent = { percentage: 0 };

  const handleEditPaymentSplit = React.useCallback((percentage: number) => {
    EditPaymentSplit(percentage);
  }, []);

  const handlePublish = async () => {
    await PublishPaymentSplits();
  };

  return (
    <AdminCard>
      <AdminCardHeader>
        <div css={[t.flex_auto, t.text_dark_3, t.text_base, t.flex, t.flex_col, t.justify_center, t.px_4]}>Default Artist Splits</div>
        <AdminButton type="button" label="Publish" onClick={handlePublish} />
      </AdminCardHeader>
      <div css={[t.p_6]}>
        <div css={[t.text_dark_3, t.text_base, t.pb_4]}>Commission Splits</div>
        <AdminSplit split={splitContent} onEdit={handleEditPaymentSplit} />
        <p>!DANGER! EDITING THIS VALUE AUTOMATICALLY UPDATES THE LIVE SPLIT</p>
      </div>
    </AdminCard>
  );
};

const SiteWidePromotionForm = (props: { id: string }) => {
  const siteWidePromo = useSiteWidePromo();
  const [isRequestInFlight, setIsRequestInFlight] = React.useState(false);
  const handleSubmit = async (formData: Partial<SiteWidePromo>) => {
    setIsRequestInFlight(true);
    try {
      await FirebaseObjects.adminRequest({
        action: 'editSiteWidePromo',
        data: { promo: formData },
      });
      setIsRequestInFlight(false);
    } catch (error) {
      setIsRequestInFlight(false);
      console.log('ERROR', error);
    }
  };

  return (
    <AdminCard>
      <AdminCardHeader>
        <span css={[t.flex_auto, t.px_4]}>Site Wide Promotion</span>
      </AdminCardHeader>
      <div css={[t.p_6]}>
        <Formik
          initialValues={{
            active: siteWidePromo.active,
            percentage: siteWidePromo.percentage,
            name: siteWidePromo.name,
            description: siteWidePromo.description,
            homepageTitle: siteWidePromo.homepageTitle,
            homepageBody: siteWidePromo.homepageBody,
          }}
          onSubmit={handleSubmit}
          enableReinitialize={true}
        >
          {(formikProps: FormikProps<SiteWidePromo>) => {
            const promoCharacterCount = formikProps.values.name.length + formikProps.values.description.length;
            const characterCountTextColor = promoCharacterCount > 110 ? t.text_error_1 : t.text_black;
            return (
              <Form>
                <div css={[t.mb_5]}>
                  <FormCheckbox name="active" label="Promotion active" />
                </div>
                <div css={[t.mb_5, t.max_w('100px')]}>
                  <TextField type="number" name="percentage" label="Discount %" />
                </div>
                <div css={[t.mb_5, t.max_w('500px')]}>
                  <TextField name="name" placeholder="Name" label="Promotion name" />
                </div>
                <div css={[t.mb_5, t.max_w('500px')]}>
                  <TextField name="description" placeholder="Description" multiline hideCharacterCount={true} label="Promotion banner description" />
                </div>
                <div css={[t.mb_5, t.max_w('500px')]}>
                  <span css={[t.font_medium]}>Total promotion character count:</span>{' '}
                  <span css={[characterCountTextColor]}>{promoCharacterCount}/110 characters used</span>
                </div>
                <div css={[t.mb_5, t.max_w('500px')]}>
                  <TextField name="homepageTitle" placeholder="Homepage Headline" maxLength={150} multiline rows={2} label="Home page promotion headline" />
                </div>
                <div css={[t.mb_5, t.max_w('500px')]}>
                  <TextField name="homepageBody" placeholder="Homepage Body" maxLength={150} multiline rows={2} label="Home page promotion body text" />
                </div>
                <AdminButton type="button" buttonType="submit" label="Update Site Wide Promotion" disabled={isRequestInFlight} />
              </Form>
            );
          }}
        </Formik>
      </div>
    </AdminCard>
  );
};

export const AdminSiteSettings = withAdminAccount(AdminSiteSettingsComponent);
