import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const DownwriteIcon = (props: { lockup?: boolean }) => {
  const { lockup, ...rest } = props;
  const lockupText = lockup ? lockup : false;

  if (lockupText) {
    return (
      <svg role="img" aria-labelledby="downwriteIconTitleID" width="156" height="24" viewBox="0 0 156 24" fill="none" {...rest}>
        <title id="downwriteIconTitleID">Downwrite Icon</title>
        <path
          d="M10.7428 21H15.3335L15.3081 11.3825L15.1042 8.16891L15.7414 11.3825L18.2153 21H22.8821L18.0621 3H10.7428V21ZM33.7205 3H29.003L24.1634 21H28.8809L33.7205 3ZM4.72437 3H0L4.87187 21H9.59509L4.72437 3Z"
          css={[t.fill_current]}
        />
        <path
          d="M41.8096 5.74182H46.6492C50.8712 5.74182 53.4004 8.31059 53.4004 12.3282C53.4004 16.3457 50.8654 18.9156 46.6492 18.9156H41.8096V5.74182ZM46.6492 15.888C48.7602 15.888 49.8756 14.4752 49.8756 12.3282C49.8756 10.1811 48.7648 8.76951 46.6492 8.76951H45.2849V15.888H46.6492Z"
          css={[t.fill_current]}
        />
        <path
          d="M60.6217 5.52222C64.407 5.52222 67.0273 8.36518 67.0273 12.3281C67.0273 16.2911 64.407 19.1352 60.6217 19.1352C56.8549 19.1352 54.2346 16.2911 54.2346 12.3281C54.2346 8.36518 56.8549 5.52222 60.6217 5.52222ZM60.6217 8.64053C58.9659 8.64053 57.8194 10.0347 57.8194 12.3281C57.8194 14.6216 58.9659 16.0169 60.6217 16.0169C62.296 16.0169 63.4425 14.6227 63.4425 12.3281C63.4425 10.0335 62.296 8.64053 60.6217 8.64053Z"
          css={[t.fill_current]}
        />
        <path
          d="M77.0882 5.74182L78.9261 13.5585L80.7455 5.74182H84.3672L80.9276 18.9156H77.5065L75.5775 11.1315L73.6486 18.9156H70.2286L66.7891 5.74182H70.5006L72.3442 13.5585L74.1648 5.74182H77.0882Z"
          css={[t.fill_current]}
        />
        <path
          d="M97.0101 18.9156H93.9162L88.7125 11.2848V18.918H85.3098V5.74182H88.8035L93.5855 12.9114V5.74182H97.0066L97.0101 18.9156Z"
          css={[t.fill_current]}
        />
        <path
          d="M108.252 5.74182L110.096 13.5585L111.906 5.74182H115.527L112.088 18.9156H108.667L106.738 11.1315L104.809 18.9156H101.392L97.9534 5.74182H101.665L103.509 13.5585L105.328 5.74182H108.252Z"
          css={[t.fill_current]}
        />
        <path
          d="M120.04 18.9156H116.474V5.74182H122.187C125.414 5.74182 127.392 7.31957 127.392 10.1451C127.392 12.0168 126.446 13.411 124.789 14.0895L127.792 18.9156H123.716L121.15 14.5484H120.04V18.9156ZM122.005 11.7054C123.261 11.7054 123.861 11.1175 123.861 10.2369C123.861 9.30743 123.261 8.75092 122.005 8.75092H120.04V11.7054H122.005Z"
          css={[t.fill_current]}
        />
        <path d="M132.339 18.9156H128.767V5.74182H132.339V18.9156Z" css={[t.fill_current]} />
        <path d="M140.927 8.7881V18.9156H137.361V8.7881H133.52V5.74182H144.766V8.7881H140.927Z" css={[t.fill_current]} />
        <path d="M155.463 5.74182V8.7881H149.44V10.7516H154.426V13.7247H149.44V15.8705H155.463V18.9168H145.946V5.74182H155.463Z" css={[t.fill_current]} />
      </svg>
    );
  }

  return (
    <svg role="img" aria-labelledby="downwriteIconTitleID" width="34" height="24" viewBox="0 0 34 24" fill="none" {...rest}>
      <title id="downwriteIconTitleID">Downwrite Icon</title>
      <path
        css={[t.fill_current]}
        d="M10.8257 21H15.4542L15.4289 11.3834L15.2232 8.16519L15.8657 11.3834L18.3597 21H23.0721L18.2057 3H10.8257V21ZM33.9944 3H29.2372L24.3479 21H29.105L33.9944 3ZM4.75717 3H0L4.91118 21H9.66835L4.75717 3Z"
      />
    </svg>
  );
};
