import * as React from 'react';

export interface BehaviorData {
  isScrollAtTop: boolean;
  hasScrolled: boolean;
  scrollY: number;
}

const DEFAULT_STATE: BehaviorData = { isScrollAtTop: false, hasScrolled: false, scrollY: 0 };

export const BehaviorContext = React.createContext<BehaviorData>(DEFAULT_STATE);
export const BehaviorProvider = (props: { children: React.ReactNode }) => {
  const [hasScrolled, setHasScrolled] = React.useState(false);
  const [isScrollAtTop, setIsScrollAtTop] = React.useState(false);
  const [scrollY, setScrollY] = React.useState(0);

  React.useEffect(() => {
    const handleScroll = () => {
      if (!hasScrolled) setHasScrolled(true);
      setIsScrollAtTop(!!window.scrollY);
      setScrollY(window.scrollY);
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [hasScrolled]);
  return <BehaviorContext.Provider value={{ isScrollAtTop, hasScrolled, scrollY }}>{props.children}</BehaviorContext.Provider>;
};

export function useBehaviorData() {
  return React.useContext(BehaviorContext);
}
