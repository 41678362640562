import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { withAdminAccount } from '../../../hocs/withAdminAccount';
import { PartnerRequestsListProvider, useParterRequestList } from '../../../contexts/PartnerRequestListContext';
import { AdminCard } from '../components/AdminCard';
import { AdminCardHeader } from '../components/AdminCardHeader';
import { PopupIcon } from '../icons/PopupIcon';
import { AdminArtistDetailsDialog } from './AdminArtistDetailsDialog';
import dayjs from 'dayjs';

/** @jsxImportSource @emotion/react */

const AdminPendingRequestsComponent = () => {
  return (
    <PartnerRequestsListProvider>
      <AdminPendingPartnerList />
    </PartnerRequestsListProvider>
  );
};

const AdminPendingPartnerList = () => {
  const [detailsActive, SetDetailsActive] = React.useState(false);
  const [objectID, SetObjectID] = React.useState<string | null>(null);
  const list = useParterRequestList();

  if (!list) return null;

  function HandleDetailsClick(objectID: string) {
    SetObjectID(objectID);
    SetDetailsActive(true);
  }

  function HandleClose(event: React.MouseEvent | React.KeyboardEvent) {
    event.stopPropagation();
    SetDetailsActive(false);
  }

  return (
    <AdminCard>
      <AdminCardHeader>
        <div css={[t.flex_auto, t.flex, t.flex_row]}>
          <div css={[t.flex_auto, t.ml_4, t.text_dark_3, t.text_base, t.flex, t.flex_col, t.justify_center]}>Partner Requests</div>
        </div>
      </AdminCardHeader>
      <div css={[t.flex, t.flex_col]}>
        <div css={[t.p_6, t.bg_tint_3, t.shadow_md_inset]}>
          <div css={[t.flex, t.flex_col, t.shadow_md]}>
            {list.map((request) => {
              if (!request.Active) return null;
              return (
                <div key={request.id} css={[t.relative, t.bg_white, t.border_b, t.border_solid, t.border_tint_2, t.pl_3, t.pr_2, t.py_3, t.flex, t.flex_row]}>
                  <div css={[t.flex_auto, t.flex, t.flex_row, t.items_center, t.text_sm]}>
                    <div css={[t.text_tint_1, t.border_r, t.pr_2, t.mr_2, t.border_solid, t.border_tint_2]}>
                      {dayjs(request.Active.RequestDate).format('MM/DD/YY hh:mm A')}
                    </div>
                    <div css={[t.flex_none, t.mr_3]}>{request.FirstLastName}</div>
                    {request.FirstLastName !== request.ArtistName && <div css={[t.flex_none, t.mr_3]}>{request.ArtistName}</div>}
                    <div css={[t.flex_auto, t.text_xs, t.text_tint_1]}>{request.id}</div>
                    <div css={[t.flex_none]}>
                      <button
                        id="detailsButton"
                        css={[
                          t.bg_transparent,
                          t.cursor_pointer,
                          t.flex_row,
                          t.items_center,
                          t.justify_center,
                          t.w('32px'),
                          t.h_auto,
                          t.text_tint_1,
                          t.hover([t.text_dark_1]),
                        ]}
                        onClick={() => {
                          HandleDetailsClick(request.id);
                        }}
                      >
                        <PopupIcon css={[t.size('16px')]} />
                      </button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <AdminArtistDetailsDialog isOpen={detailsActive} userId={objectID} onClose={HandleClose} />
    </AdminCard>
  );
};

export const AdminPendingRequests = withAdminAccount(AdminPendingRequestsComponent);
