import { Variants, Transition } from 'framer-motion';
import { easing } from './easing';

export function CreateTransition(current: Transition, override?: Transition): Transition {
  if (!override) return current;
  return { ...current, ...override };
}

export const introVariants: { [key: string]: Variants } = {
  variant_riseFadeIn: {
    initial: { opacity: 0, y: 40 },
    to: (overrides?: Transition) => {
      const defaultTransition: Transition = { duration: 1.4, delay: 0, ease: easing.ease_1 };
      const transition = CreateTransition(defaultTransition, overrides);
      return {
        opacity: 1,
        y: 0,
        transition,
      };
    },
  },

  variant_rise: {
    initial: { y: 40 },
    to: (overrides?: Transition) => {
      const defaultTransition: Transition = { duration: 1.4, delay: 0, ease: easing.ease_1 };
      const transition = CreateTransition(defaultTransition, overrides);
      return {
        y: 0,
        transition,
      };
    },
  },

  variant_fadeIn: {
    initial: { opacity: 0 },
    to: (overrides?: Transition) => {
      const defaultTransition: Transition = { duration: 1.4, delay: 0, ease: easing.ease_1 };
      const transition = CreateTransition(defaultTransition, overrides);
      return {
        opacity: 1,
        transition,
      };
    },
  },

  variant_scaleIn_1: {
    initial: { opacity: 0, scale: 0.9 },
    to: (overrides?: Transition) => {
      const defaultTransition: Transition = { duration: 1.4, delay: 0, ease: easing.ease_1 };
      const transition = CreateTransition(defaultTransition, overrides);
      return {
        opacity: 1,
        scale: 1,
        transition,
      };
    },
  },

  variant_scaleIn_2: {
    initial: { opacity: 0, scale: 0.8 },
    to: (overrides?: Transition) => {
      const defaultTransition: Transition = { duration: 1.4, delay: 0, ease: easing.ease_1 };
      const transition = CreateTransition(defaultTransition, overrides);
      return {
        opacity: 1,
        scale: 1,
        transition,
      };
    },
  },

  variant_scaleIn_3: {
    initial: { opacity: 0, scale: 0.7 },
    to: (overrides?: Transition) => {
      const defaultTransition: Transition = { duration: 1.4, delay: 0, ease: easing.ease_1 };
      const transition = CreateTransition(defaultTransition, overrides);
      return {
        opacity: 1,
        scale: 1,
        transition,
      };
    },
  },

  variant_scaleInReveal_1: {
    initial: { scale: 0.9, clipPath: 'inset(0% 0% 100% 0%)' },
    to: (overrides?: Transition) => {
      const defaultTransition: Transition = { duration: 1.4, delay: 0, ease: easing.ease_1 };
      const transition = CreateTransition(defaultTransition, overrides);
      return {
        scale: 1,
        clipPath: 'inset(0% 0% 0% 0%)',
        transition,
      };
    },
  },

  variant_reveal_1: {
    initial: { y: 40, clipPath: 'inset(25% 0% 75% 0%)' },
    to: (overrides?: Transition) => {
      const defaultTransition: Transition = { duration: 1.4, delay: 0, ease: easing.ease_1 };
      const transition = CreateTransition(defaultTransition, overrides);
      return {
        y: 0,
        clipPath: 'inset(0% 0% 0% 0%)',
        transition,
      };
    },
  },

  variant_empty: {
    initial: {},
    to: {},
  },
};
