import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const AspectSquareIcon = (props: {}) => {
  return (
    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 0H1H0V1V10H1V1H10V0ZM29 0H20V1H29V10H30V1V0H29ZM1 29H10V30H1H0V29V20H1V29ZM29 29H20V30H29H30V29V20H29V29Z"
        css={t.fill_current}
      />
    </svg>
  );
};
