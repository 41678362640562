import { AlbumPackage } from './contexts/OrderContext';
import Dinero from 'dinero.js';

export interface AlbumOrderDetails {
  songCount: number;
}

export interface AlbumOrderDeliverable {
  name: string;
  lastModified: string;
}

export interface AlbumOrder {
  type: 'album';
  package: AlbumPackage;
  details: AlbumOrderDetails;
  deliverable: AlbumOrderDeliverable;
}

export function GetAlbumTotalPrice(albumPackage: AlbumPackage, details: AlbumOrderDetails, discount: number) {
  const total = albumPackage.price;

  return Dinero(total).percentage(100 - discount);
}
