import { Variants } from 'framer-motion';

export type MotionIntroToken = {
  initial: string;
  animate: string;
  variants: Variants;
};

export function motion_intro(variants: Variants): MotionIntroToken {
  return { initial: 'initial', animate: 'to', variants: variants };
}
