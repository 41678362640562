import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { useArtistProfile } from '../../../contexts/ArtistProfileContext';
import { useUserAccount } from '../../../contexts/UserAccountContext';
import { AdminDetailsLinkButton } from '../components/Dialogs/AdminDetailsLinkButton';
import { AdminDetailsDialogInfo } from '../components/Dialogs/AdminDetailsDialogInfo';

/** @jsxImportSource @emotion/react */

export const AdminArtistDetailsSongs = () => {
  const artistProfile = useArtistProfile();
  const artistAccount = useUserAccount();
  const profileData = artistProfile.Profile;

  if (!profileData) return null;
  if (!artistAccount) return null;

  let completedCount = 0;
  if (profileData.orderStats) {
    completedCount = profileData.orderStats.completedOrders;
  }
  return (
    <div css={[t.pt_3, t.pb_6, t.px_6, t.flex, t.flex_col]}>
      <div css={[t.text_sm, t.text_tint_1]}>Songs</div>
      <div css={[t.flex, t.flex_col, t.flex_wrap, t.mt_4]}>
        <div>
          <AdminDetailsDialogInfo label="Total">
            <AdminDetailsLinkButton to={`/admin/songs/${artistAccount.id}`} disabled={!completedCount}>
              {GetCountString(completedCount)}
            </AdminDetailsLinkButton>
          </AdminDetailsDialogInfo>
        </div>
      </div>
    </div>
  );
};

function GetCountString(count: number): string {
  if (!count) return 'None';
  return `${count} song${count > 1 ? 's' : ''}`;
}
