import * as React from 'react';
import { ToastType } from '../components/toasts/Toast';
import { v4 as uuid } from 'uuid';

export interface ToastController {
  toasts: ToastState[];
  showToast: (type: ToastType, message: string, duration: number) => void;
}

export interface ToastState {
  type: ToastType;
  id: string;
  message: string;
}

export const ToastContext = React.createContext({} as ToastController);
export const ToastProvider = (props: { children: React.ReactNode }) => {
  const [toasts, setToasts] = React.useState<ToastState[]>([]);

  return (
    <ToastContext.Provider
      value={{
        toasts,
        showToast: (type: ToastType, message: string, duration: number) => {
          const newToast: ToastState = {
            type,
            message,
            id: uuid(),
          };
          setToasts((oldToasts) => [...oldToasts, newToast]);
          setTimeout(() => {
            setToasts((oldToasts) => oldToasts.filter((x) => x.id !== newToast.id));
          }, duration * 1000);
        },
      }}
    >
      {props.children}
    </ToastContext.Provider>
  );
};

export function useToastController() {
  return React.useContext(ToastContext);
}
