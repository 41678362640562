import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { UserAccountProvider } from '../../../contexts/UserAccountContext';
import { AdminDialogUserHistory } from './AdminDialogUserHistory';
import { AdminDetailsDialog } from '../components/Dialogs/AdminDetailsDialog';
import { AdminUserDetailsHeader } from './AdminUserDetailsHeader';
import { AdminUserDetailsInfo } from './AdminUserDetailsInfo';
import { AdminUserDetailsOrders } from './AdminUserDetailsOrders';
import { UserRole } from './AdminUserSearchHits';

/** @jsxImportSource @emotion/react */

export const AdminUserDetailsDialog = (props: {
  isOpen: boolean;
  userRole?: UserRole | null;
  userId?: string | null;
  onClose: (event: React.MouseEvent | React.KeyboardEvent) => void;
}) => {
  if (!props.userId) return null;
  if (!props.userRole) return null;

  return (
    <UserAccountProvider userId={props.userId}>
      <AdminDetailsDialog isOpen={props.isOpen} onRequestClose={props.onClose}>
        <AdminUserDetailsHeader userRole={props.userRole} />
        <div css={[t.flex, t.flex_col, t.h_full]}>
          <AdminUserDetailsInfo userRole={props.userRole} />
          <AdminUserDetailsOrders />
          <AdminDialogUserHistory uid={props.userId} />
        </div>
      </AdminDetailsDialog>
    </UserAccountProvider>
  );
};
