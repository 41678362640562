import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const AspectHorizontalIcon = (props: {}) => {
  return (
    <svg width="40" height="30" viewBox="0 0 40 30" fill="none" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12 0H1H0V1V12H1V1H12V0ZM39 0H28V1H39V12H40V1V0H39ZM1 29H12V30H1H0V29V18H1V29ZM39 29H28V30H39H40V29V18H39V29Z"
        css={t.fill_current}
      />
    </svg>
  );
};
