export enum spacing {
  s0 = '0',
  s1 = '4px',
  s2 = '8px',
  s3 = '12px',
  s4 = '16px',
  s5 = '24px',
  s6 = '36px',
  s7 = '48px',
  s8 = '64px',
  s9 = '80px',
  s10 = '120px',
}
