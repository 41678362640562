import { css } from '@emotion/react';
import { CSSInterpolation } from '@emotion/serialize';

import { breakpoints } from './breakpoints';
import { FontFaces } from './fonts';
import { pseudoClass } from './pseudoClass';
import { display } from './display';
import { fontWeight } from './fontWeight';
import { flex } from './flex';
import { position } from './position';
import { cursor } from './cursor';
import { margin } from './margin';
import { padding } from './padding';
import { width } from './width';
import { height } from './height';
import { background } from './background';
import { textColor } from './textColor';
import { textDecoration } from './textDecoration';
import { border } from './border';
import { zIndex } from './zIndex';
import { text } from './text';
import { fill } from './fill';
import { stroke } from './stroke';
import { fontFamily } from './fontFamily';
import { typeStyle, breakpointTypeStyle, gridTypeStyle } from './typeStyle';
import { grid, breakpointGrid } from './grid';
import { pointerEvents } from './pointerEvents';
import { overflow } from './overflow';
import { custom } from './custom';
import { gridColumn } from './gridColumn';
import { resize } from './resize';
import { textTransform } from './textTransform';
import { whitespace } from './whitespace';
import { verticalAlign } from './verticalAlign';
import { palette } from './palette';
import { userSelect } from './userSelect';
import { outline } from './outline';
import { contentStyles } from './content';
import { textAlign } from './textAlign';
import { gridRow } from './gridRow';
import { backgroundImage } from './backgroundImage';
import { wordBreak } from './wordBreak';
import { visibility } from './visibility';
import { boxShadow } from './boxShadow';
import { spacing } from './spacing';
import { easing } from './easing';
import { introVariants } from './motionVariants';
import { motion } from './motion';
import { appearance } from './appearance';
import { dropShadow } from './dropShadow';
import { gridAuto } from './gridAuto';
import { opacity } from './opacity';
import { order } from './order';
import { listStyle } from './listStyle';

const HtmlReset = css`
  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    scroll-behavior: auto;
    overflow-x: hidden;
  }
`;

const BodyReset = css`
  body {
    margin: 0;
    overflow-x: hidden;
  }
`;

const MainReset = css`
  main {
    display: block;
  }
`;

const InputFontReset = css`
  button,
  input,
  optgroup,
  select,
  textarea {
    font-family: inherit;
    font-size: 100%;
    line-height: 1.15;
    margin: 0;
  }
`;

const InputReset = css`
  input,
  button {
    border: none;
    outline: none;
    padding: 0;
    overflow: visible;
  }
`;

const Reset = css`
  * {
    margin: 0;
    box-sizing: border-box;
  }
`;

const FontGlobals = css`
  * {
    font-family: 'Soehne', 'Helvetica Neue', 'Arial', sans-serif;
    font-variant-numeric: tabular-nums;
  }
`;

const HeadingReset = css`
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    font-size: inherit;
    font-weight: inherit;
  }
`;

const ListReset = css`
  ol,
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
`;

const BlockReset = css`
  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: block;
  }
`;

const BorderReset = css`
  *,
  *::before,
  *::after {
    border-width: 0;
    border-style: solid;
  }
`;

const FocusOutlineReset = css`
  *:focus,
  *::-moz-focus-inner {
    outline: none;
    border: 0;
  }
`;

export type CSSStyle = CSSInterpolation;
export const Global = [
  FontGlobals,
  HtmlReset,
  MainReset,
  BodyReset,
  InputFontReset,
  Reset,
  InputReset,
  HeadingReset,
  ListReset,
  BorderReset,
  BlockReset,
  FocusOutlineReset,
];

export default {
  Global,
  FontFaces,
  ...breakpoints,
  ...pseudoClass,
  ...display,
  ...fontWeight,
  ...flex,
  ...position,
  ...cursor,
  ...margin,
  ...padding,
  ...width,
  ...height,
  ...listStyle,
  ...background,
  ...textColor,
  ...textDecoration,
  ...border,
  ...zIndex,
  ...text,
  ...fill,
  ...stroke,
  ...fontFamily,
  ...typeStyle,
  ...breakpointTypeStyle,
  ...gridTypeStyle,
  ...grid,
  ...gridColumn,
  ...gridRow,
  ...breakpointGrid,
  ...pointerEvents,
  ...overflow,
  ...custom,
  ...resize,
  ...textTransform,
  ...whitespace,
  ...verticalAlign,
  ...palette,
  ...spacing,
  ...userSelect,
  ...outline,
  ...contentStyles,
  ...textAlign,
  ...backgroundImage,
  ...wordBreak,
  ...visibility,
  ...boxShadow,
  ...easing,
  ...introVariants,
  ...motion,
  ...appearance,
  ...dropShadow,
  ...gridAuto,
  ...opacity,
  ...order,
};
