import { css } from '@emotion/react';
import { palette } from './palette';

export const fill = {
  fill_current: css`
    fill: currentColor;
  `,

  fill_primary_4: css`
    fill: ${palette.primary_4};
  `,

  fill_error_1: css`
    fill: ${palette.error_1};
  `,
};
