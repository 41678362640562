import { css } from '@emotion/react';

export const verticalAlign = {
  align_baseline: css`
    vertical-align: baseline;
  `,
  align_top: css`
    vertical-align: top;
  `,
  align_middle: css`
    vertical-align: middle;
  `,
  align_bottom: css`
    vertical-align: bottom;
  `,
  align_text_top: css`
    vertical-align: text-top;
  `,
  align_text_bottom: css`
    vertical-align: text-bottom;
  `,
};
