import { CreateDocumentContext, FirebaseObjects } from './FirebaseContext';
export interface PartnerRequestItem {
  RequestDate: string;
  ApprovalState: 'pending' | 'approved' | 'rejected';
}
export interface PartnerRequests {
  id: string;
  ArtistName: string;
  FirstLastName: string;
  Active?: PartnerRequestItem;
  History: PartnerRequestItem[];
}
const PartnerRequestContext = CreateDocumentContext<PartnerRequests>('/partnerRequests');
export const PartnerRequestProvider = PartnerRequestContext.Provider;
export const usePartnerRequest = PartnerRequestContext.Use;
export async function RequestPartner() {
  await FirebaseObjects.artistRequest({ action: 'requestPartner', data: {} });
}
export async function ProcessPartner(artistId: string, state: boolean) {
  await FirebaseObjects.adminRequest({ action: 'processPartner', data: { artistId: artistId, result: state } });
}
