import * as React from 'react';

export interface SongPlayingController {
  songPlaying: string;
  setSongPlaying: (songPlaying: string) => void;
}

export const SongPlayingContext = React.createContext({} as SongPlayingController);
export const SongPlayingProvider = (props: { children: React.ReactNode }) => {
  const [songPlaying, setSongPlaying] = React.useState('');

  return (
    <SongPlayingContext.Provider
      value={{
        songPlaying: songPlaying,
        setSongPlaying: (songPlaying: string) => {
          setSongPlaying(songPlaying);
        },
      }}
    >
      {props.children}
    </SongPlayingContext.Provider>
  );
};

export function useSongPlayingController() {
  return React.useContext(SongPlayingContext);
}
