import React, { ReactNode } from 'react'; // eslint-disable-line

import t from '../../theme/admin/adminStyles';
import { withAdminAccount } from '../../hocs/withAdminAccount';
import { AdminPage } from './components/AdminPage';
import {
  useDiscoveryContent,
  PublishDiscovery,
  AddPopularGenre,
  RemovePopularGenre,
  SetMainFeatureArtist,
  SetMainFeatureImage,
  SetMainFeatureDescription,
  DiscoveryContentMainFeature,
  SetMainFeatureSample,
} from '../../contexts/DiscoveryContentContext';
import { AdminCard } from './components/AdminCard';
import { AdminButton } from './components/AdminButton';
import { AdminCardHeader } from './components/AdminCardHeader';
import { GenreTagDictionary } from '../../data/ProfileData';
import { AdminTag } from './components/AdminTag';
import { ArtistListDialog, ArtistSearchHitProps } from './components/Dialogs/ArtistListDialog';
import { AdminSearchTableCell } from './components/AdminSearchTableCell';
import { AdminSearchTableActionCell } from './components/AdminSearchTableActionCell';
import { AdminInputText } from './components/AdminInputText';
import { ArtistProfileProvider, useArtistProfile } from '../../contexts/ArtistProfileContext';
import { AdminTextFieldLabel } from './components/AdminTextFieldLabel';

/** @jsxImportSource @emotion/react */

const AdminDiscoveryComponent = () => {
  const handlePublish = async () => {
    await PublishDiscovery();
  };

  return (
    <AdminPage
      pageHeading="Discovery"
      headChildren={
        <div css={[t.flex_auto, t.flex, t.flex_row, t.justify_end, t.items_center]}>
          <AdminButton type="button" label="Publish" onClick={handlePublish} />
        </div>
      }
    >
      <DiscoveryHeadlinerSection />
      <DiscoveryPopularGenres />
    </AdminPage>
  );
};

const DiscoveryHeadlinerSection = () => {
  const [isArtistListOpen, setArtistListOpen] = React.useState(false);

  const discoveryContent = useDiscoveryContent();
  if (!discoveryContent) return null;
  const headliner = discoveryContent.mainFeature;

  const handleArtistList = () => {
    setArtistListOpen(true);
  };

  const handleArtistListCancel = () => {
    setArtistListOpen(false);
  };

  return (
    <AdminCard>
      <AdminCardHeader>
        <div css={[t.flex_auto, t.flex, t.flex_row, t.px_4]}>
          <div css={[t.flex_auto, t.text_dark_3, t.text_base, t.flex, t.flex_col, t.justify_center]}>Headliner</div>
          <div css={[t.flex_none]}>
            <AdminButton type="button" label="Select artist" onClick={handleArtistList} />
          </div>
        </div>
      </AdminCardHeader>

      <HeadlinerArtist headlinerId={headliner.artist?.id}>
        <HeadlinerDetails headliner={headliner} />
      </HeadlinerArtist>

      <ArtistListDialog isOpen={isArtistListOpen} label="Select Headliner" artists={headliner.artist} onClose={handleArtistListCancel}>
        {(artistHitProps: ArtistSearchHitProps) => {
          const handleSelectArtist = async () => {
            await SetMainFeatureArtist(artistHitProps.objectID);
          };

          return (
            <React.Fragment>
              <AdminSearchTableCell>
                {artistHitProps.featured && <div css={[t.absolute, t.inset_y_0, t.left_0, t.border_solid, t.border_l_2, t.border_primary_4]} />}
              </AdminSearchTableCell>
              <AdminSearchTableCell>{artistHitProps.artistHit.name}</AdminSearchTableCell>
              <AdminSearchTableCell>{artistHitProps.artistHit.artistId}</AdminSearchTableCell>
              <AdminSearchTableCell>{artistHitProps.objectID}</AdminSearchTableCell>
              <AdminSearchTableActionCell cellStyle={[t.py_2]} innerStyle={[t.pr_0]}>
                {artistHitProps.featured ? (
                  <div css={[t.text_primary_4]}>Current</div>
                ) : (
                  <AdminButton type="button" label="Select" styleType="secondary" onClick={handleSelectArtist} />
                )}
              </AdminSearchTableActionCell>
            </React.Fragment>
          );
        }}
      </ArtistListDialog>
    </AdminCard>
  );
};

const HeadlinerArtist = (props: { headlinerId?: string; children?: ReactNode }) => {
  if (!props.headlinerId) return null;
  return <ArtistProfileProvider artistId={props.headlinerId}>{props.children}</ArtistProfileProvider>;
};

const HeadlinerDetails = (props: { headliner: DiscoveryContentMainFeature }) => {
  const artistProfile = useArtistProfile();
  const profileData = artistProfile.Profile;

  function handleSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
    SetMainFeatureSample(e.target.value);
  }

  const handleSetDescription = async (event: React.FocusEvent<HTMLInputElement>) => {
    if (event.currentTarget.value !== props.headliner.description) await SetMainFeatureDescription(event.currentTarget.value);
  };

  if (!props.headliner.artist || !profileData)
    return (
      <div css={[t.p_6]}>
        <div css={[t.flex, t.flex_col]}>
          <div css={[t.mt_1, t.text_lg]}>NO HEADLINING ARTIST SELECTED</div>
        </div>
      </div>
    );

  const sampleKeys = Object.keys(profileData.samples);

  return (
    <React.Fragment>
      <div css={[t.p_6]}>
        <div css={[t.flex, t.flex_col]}>
          <div css={[t.text_sm, t.text_dark_2]}>Artist</div>
          <div css={[t.mt_1, t.text_lg]}>{props.headliner.artist.name}</div>
        </div>
      </div>
      <div css={[t.px_6, t.mb_2]}>
        <div css={[t.flex_auto, t.text_dark_3, t.text_base, t.flex, t.flex_col, t.justify_center]}>Images</div>
      </div>
      <div css={[t.pb_6, t.px_4, t.flex]}>
        <ImageUploadButton
          label="Center (3:2)"
          formName="headlinerCenter"
          imageURL={props.headliner.centerImage}
          onFileUpload={(file: File) => SetMainFeatureImage(file, 'setCenterImage')}
        />
      </div>

      <div css={[t.px_6, t.mb_4]}>
        <AdminInputText
          id={`mainfeature_description`}
          label="Description"
          name={`mainfeature_title`}
          defaultValue={props.headliner.description}
          onBlur={handleSetDescription}
        />
      </div>
      <div css={[t.px_6, t.mb_6]}>
        <AdminTextFieldLabel id="sample" label="Sample" />
        <select
          name="sample"
          id="sample"
          defaultValue={props.headliner.artist.sampleId}
          css={[t.bg_white, t.border_1, t.border_tint_2, t.border_solid, t.p_2, t.text_sm]}
          onChange={handleSelectChange}
        >
          {sampleKeys.map((sampleKey, i) => {
            return (
              <option key={`${sampleKey}`} value={sampleKey}>
                {profileData.samples[sampleKey].name}
              </option>
            );
          })}
        </select>
      </div>
    </React.Fragment>
  );
};

const ImageUploadButton = (props: { label: string; formName: string; imageURL: string; onFileUpload: (file: File) => void }) => {
  const handleUpload = async (event: React.FormEvent<HTMLInputElement>) => {
    if (event.currentTarget) {
      if (event.currentTarget.files && event.currentTarget.files.length > 0) {
        const file = event.currentTarget.files[0];
        event.currentTarget.value = '';
        await props.onFileUpload(file);
      }
    }
  };

  return (
    <div css={[t.flex0, t.mx_2, t.p_3, t.border, t.border_solid, t.border_tint_2]}>
      <div css={[t.text_sm, t.text_dark_4, t.mb_1]}>{props.label}</div>
      <img css={[t.w_full, t.mb_2]} alt={props.label} src={props.imageURL} />
      <AdminButton type="input" inputType="file" onInput={handleUpload} label="Upload" formName={props.formName} innerStyle={[t.w_full]} />
    </div>
  );
};

const DiscoveryPopularGenres = () => {
  const discoveryContent = useDiscoveryContent();
  if (!discoveryContent) return null;

  return (
    <AdminCard>
      <div css={[t.p_6]}>
        <div css={[t.text_dark_3, t.text_base, t.pb_4]}>Popular Genres</div>
        <div css={[t.flex, t.flex_row, t.flex_wrap]}>
          {Object.keys(GenreTagDictionary).map((genre) => {
            const handleGenreChanged = async (event: React.ChangeEvent<HTMLInputElement>) => {
              if (event.currentTarget.checked) await AddPopularGenre(genre);
              else await RemovePopularGenre(genre);
            };

            const checked = discoveryContent.popularGenres.includes(genre);
            return (
              <div key={genre} css={[t.mr_3, t.mb_2]}>
                <AdminTag
                  formName=""
                  name={GenreTagDictionary[genre]}
                  label={GenreTagDictionary[genre]}
                  value={GenreTagDictionary[genre]}
                  checked={checked}
                  onChange={handleGenreChanged}
                />
              </div>
            );
          })}
        </div>
      </div>
    </AdminCard>
  );
};

export const AdminDiscovery = withAdminAccount(AdminDiscoveryComponent);
