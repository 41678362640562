const smPX = 480;
const mdPX = 704;
const lgPX = 960;
const xlPX = 1120;

function breakpointQuery(breakpoint: number) {
  return `(min-width:${breakpoint}px)`;
}

function retinaBreakpointQuery(breakpoint: number) {
  return `screen and (min-width: ${breakpoint}px) and (-webkit-min-device-pixel-ratio: 2), screen and (min-width: ${breakpoint}px) and (min--moz-device-pixel-ratio: 2), screen and (min-width: ${breakpoint}px) and (-o-min-device-pixel-ratio: 2/1), screen and (min-width: ${breakpoint}px) and (min-device-pixel-ratio: 2), screen and (min-width: ${breakpoint}px) and (min-resoultion: 192dpi), screen and (min-width: ${breakpoint}px) and (min-resolution: 2dppx)`;
}

export const breakpoints = {
  sm: <T>(styles: T) => {
    return { [`@media screen and ${breakpointQuery(smPX)}`]: styles };
  },
  md: <T>(styles: T) => {
    return { [`@media screen and ${breakpointQuery(mdPX)}`]: styles };
  },
  lg: <T>(styles: T) => {
    return { [`@media screen and ${breakpointQuery(lgPX)}`]: styles };
  },
  xl: <T>(styles: T) => {
    return { [`@media screen and ${breakpointQuery(xlPX)}`]: styles };
  },
  media_hover: <T>(styles: T) => {
    return { '@media (hover:hover)': styles };
  },
  media_no_hover: <T>(styles: T) => {
    return { '@media (hover:none)': styles };
  },
  smPX,
  mdPX,
  lgPX,
  xlPX,

  media_sm: breakpointQuery(smPX),
  media_md: breakpointQuery(mdPX),
  media_lg: breakpointQuery(lgPX),
  media_xl: breakpointQuery(xlPX),

  media_sm_retina: retinaBreakpointQuery(smPX),
  media_xl_retina: retinaBreakpointQuery(xlPX),
};
