import React from 'react'; // eslint-disable-line

import t, { CSSStyle } from '../../theme/newstyles';
import * as H from 'history';
import { Link, useLocation } from 'react-router-dom';
import { useThemeController } from '../../contexts/ThemeContext';
import { PathMatches } from '../../util/PathMatches';

/** @jsxImportSource @emotion/react */

function LinksContainer(props: {
  onClick?: (event: React.MouseEvent) => void;
  children?: React.ReactNode;
  containerStyle?: CSSStyle;
  linkWrapperStyle?: CSSStyle;
}) {
  return (
    <div css={[t.px_5, t.flex, t.flex_col, t.lastType('div', [t.mb_0]), props.containerStyle]}>
      {React.Children.map(props.children, (child) => (
        <div css={[t.mb_2, t.flex, props.linkWrapperStyle]}>
          <div onClick={props.onClick}>{child}</div>
        </div>
      ))}
    </div>
  );
}

export function PrimaryLinksContainer(props: { onClick?: (event: React.MouseEvent) => void; availabilityShown?: boolean; children?: React.ReactNode }) {
  return (
    <LinksContainer containerStyle={[t.pb_7, t.flex_auto, props.availabilityShown ? t.mt_0 : t.mt_7]} onClick={props.onClick}>
      {props.children}
    </LinksContainer>
  );
}

export function SecondaryLinksContainer(props: { onClick?: (event: React.MouseEvent) => void; children?: React.ReactNode }) {
  return (
    <LinksContainer containerStyle={[t.pb_6]} onClick={props.onClick}>
      {props.children}
    </LinksContainer>
  );
}

export function PrimaryLink<S = H.LocationState>(props: {
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
  altRoute?: string;
  active?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
}) {
  function HandleInnerFocus(event: React.FocusEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  const location = useLocation<S>();
  const { dark } = useThemeController();
  const active = PathMatches(location, props.altRoute, props.to);
  const activeSelector = active ? t.customSelector('& > span::after', t.content_some) : null;
  const lightSelectedTextColor = active ? [t.text_primary_4, t.visited(t.text_primary_4)] : [t.text_dark_1, t.visited(t.text_dark_1)];
  const lightSelectedBorderColor = active ? t.border_primary_4 : t.border_dark_1;

  const selectedTextColor = dark ? t.text_tint_5 : lightSelectedTextColor;
  const selectedBorderColor = dark ? t.border_tint_5 : lightSelectedBorderColor;

  return (
    <div css={[t.relative]}>
      <Link
        to={props.to}
        css={[
          t.no_underline,
          t.outline_none,
          activeSelector,
          t.inline_flex,
          t.py('6px'),
          t.disabled(t.cursor_not_allowed),
          t.disabledSelector('> span', t.text_tint_2),
          t.hoverSelector('> span::after', t.content_some),
          t.focusSelector('> span::before', t.content_some),
          t.focusSelector('> span::after', t.content_some),
        ]}
      >
        <span
          tabIndex={-1}
          css={[
            t.inline_flex,
            t.border_transparent,
            t.typeStyle_3,
            t.cursor_pointer,
            selectedTextColor,
            t.outline_none,
            t.relative,
            t.before([t.focusIndicator, t.bottom('-3px'), t.left('-8px'), t.right('-8px'), t.border_primary_4, t.content_none]),
            t.after([t.absolute, t.inset_x_0, t.h_full, t.border_b_2, t.border_solid, selectedBorderColor, t.bottom_0, t.content_none]),
          ]}
          onFocus={HandleInnerFocus}
        >
          {props.children}
        </span>
      </Link>
    </div>
  );
}

type SharedLinkProps = {
  altRoute?: string;
  children?: React.ReactNode;
  id?: string;
};

type ButtonLinkProps<S> = {
  type: 'link';
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
} & SharedLinkProps;

type ButtonProps<S> =
  | ({
      type: 'button';
      onClick?: (event: React.MouseEvent) => void;
    } & SharedLinkProps)
  | ButtonLinkProps<S>;

export function SecondaryLink<S = H.LocationState>(props: ButtonProps<S>) {
  const location = useLocation<S>();

  function HandleInnerFocus(event: React.FocusEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  const outerStyle: CSSStyle = [
    t.bg_transparent,
    t.no_underline,
    t.outline_none,
    t.pt('2px'),
    t.pb('6px'),
    t.disabled(t.cursor_not_allowed),
    t.disabledSelector('> span', t.text_tint_2),
    t.hoverSelector('> span', t.text_primary_3),
    t.hoverSelector('> span::after', [t.content_some, t.border_primary_3]),
    t.focusSelector('> span::before', t.content_some),
    t.focusSelector('> span::after', t.content_some),
  ];

  const contents = (
    <span
      tabIndex={-1}
      css={[
        t.inline_flex,
        t.border_transparent,
        t.typeStyle_6,
        t.cursor_pointer,
        t.text_tint_1,
        t.visited(t.text_tint_1),
        t.outline_none,
        t.relative,
        t.before([t.focusIndicator, t.bottom('-3px'), t.left('-8px'), t.right('-8px'), t.border_primary_4, t.content_none]),
        t.after([t.absolute, t.inset_x_0, t.h_full, t.border_b, t.border_solid, t.border_tint_1, t.bottom_0, t.content_none]),
      ]}
      onFocus={HandleInnerFocus}
    >
      {props.children}
    </span>
  );

  switch (props.type) {
    case 'button': {
      const HandleClick = (event: React.MouseEvent) => {
        if (props.onClick) props.onClick(event);
      };

      return (
        <button css={outerStyle} onClick={HandleClick} id={props.id} data-cy="SignOutButtonCypress">
          {contents}
        </button>
      );
    }
    case 'link': {
      const activeSelector = PathMatches(location, props.altRoute, props.to) ? t.customSelector('& > span::after', t.content_some) : null;

      return (
        <div css={[t.relative, t.flex, t.flex_col]}>
          <Link to={props.to} css={[outerStyle, activeSelector]}>
            {contents}
          </Link>
        </div>
      );
    }
  }
}
