import React from 'react'; // eslint-disable-line
import { css } from '@emotion/react';

import t from '../../../theme/admin/adminStyles';
import { AdminCaretIcon } from '../icons/AdminCaretIcon';
import { usePagination, UsePaginationProps } from 'react-instantsearch';

/** @jsxImportSource @emotion/react */

const caretSize = css`
  width: 12px;
  height: 12px;
`;

export const AdminListFooter = (props: UsePaginationProps) => {
  const { currentRefinement, nbPages, refine } = usePagination(props);
  const handlePageForward = () => {
    if (currentRefinement < nbPages) refine(currentRefinement + 1);
  };

  const handlePageBackward = () => {
    if (currentRefinement > 1) refine(currentRefinement - 1);
  };

  return (
    <div css={[t.h_12, t.flex, t.flex_row, t.justify_end, t.items_center, t.text_xs, t.text_tint_1]}>
      Page {nbPages === 0 ? 0 : currentRefinement} of {nbPages}
      <div css={[t.w_6, t.mx_2, t.flex, t.flex_row, t.justify_center]} onClick={handlePageBackward}>
        <AdminCaretIcon direction="left" css={[caretSize, currentRefinement > 1 ? t.text_tint_1 : t.text_tint_2]} />
      </div>
      <div css={[t.w_6, t.mx_2, t.flex, t.flex_row, t.justify_center]} onClick={handlePageForward}>
        <AdminCaretIcon direction="right" css={[caretSize, currentRefinement < nbPages ? t.text_tint_1 : t.text_tint_2]} />
      </div>
    </div>
  );
};
