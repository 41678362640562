import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const DeleteIcon = (props: {}) => {
  return (
    <svg role="img" aria-labelledby="deleteIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="deleteIconTitleID">Delete Icon</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M17 6H7V20H17V6ZM6 5V21H18V5H6Z" css={[t.fill_current]} />
      <rect x="3" y="5" width="18" height="1" css={[t.fill_current]} />
      <rect x="8" y="2" width="8" height="1" css={[t.fill_current]} />
      <rect x="10" y="8" width="1" height="10" css={[t.fill_current]} />
      <rect x="13" y="8" width="1" height="10" css={[t.fill_current]} />
    </svg>
  );
};
