import React, { MouseEvent, ReactNode } from 'react'; // eslint-disable-line

import t from '../../theme/newstyles';

import { ProfileIcon } from '../../icons/ProfileIcon';
import { IconButton } from '../IconButton';
import { useThemeController } from '../../contexts/ThemeContext';
import { ModalDropdown } from '../ModalDropdown';

/** @jsxImportSource @emotion/react */

export const AccountButton = (props: { children?: ReactNode }) => {
  const { dark } = useThemeController();
  const [showAccountDropdown, setShowAccountDropdown] = React.useState(false);

  const toggleAccountDropDown = (event: MouseEvent) => {
    setShowAccountDropdown(!showAccountDropdown);
    event.stopPropagation();
  };

  return (
    <div css={[t.relative, t.flex, t.items_center]} data-cy="AccountMenuDropdownCypress">
      <IconButton type="button" ariaLabel="Profile" onClick={toggleAccountDropDown} id="AccountMenuDropdown">
        <ProfileIcon />
      </IconButton>

      <ModalDropdown isOpen={showAccountDropdown} dark={dark} setShowModal={setShowAccountDropdown}>
        {props.children}
      </ModalDropdown>
    </div>
  );
};
