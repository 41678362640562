import { css } from '@emotion/react';
import { fontSize, lineHeight, textSize } from '../utilities';

export const text = {
  fontSize: fontSize,
  lineHeight: lineHeight,
  textSize: textSize,

  text_xs: css`
    font-size: 0.75rem;
  `,
  text_sm: css`
    font-size: 0.875rem;
  `,
  text_base: css`
    font-size: 1rem;
  `,
  text_lg: css`
    font-size: 1.125rem;
  `,
  text_xl: css`
    font-size: 1.25rem;
  `,
  text_2xl: css`
    font-size: 1.5rem;
  `,
  text_3xl: css`
    font-size: 1.875rem;
  `,
  text_4xl: css`
    font-size: 2.25rem;
  `,
  text_5xl: css`
    font-size: 3rem;
  `,
  text_6xl: css`
    font-size: 4rem;
  `,
};
