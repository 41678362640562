import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import dayjs from 'dayjs';
import { OrderReviewReason } from '../../../contexts/OrderContext';
import { FirebaseObjects } from '../../../contexts/FirebaseContext';
import { AdminButton } from '../components/AdminButton';
import { useAdminPendingOperation } from './AdminPendingOperation';

/** @jsxImportSource @emotion/react */

function HasValidReviewReason(reasons: OrderReviewReason[]): boolean {
  if (reasons.length === 0) return false;
  for (const reason of reasons) {
    if (reason.type === 'payment_network_failure') {
      if (dayjs.utc().diff(dayjs(reason.time), 'minute') > 1) return true;
    }
  }
  return true;
}

function FilterReviewReasons(reasons: OrderReviewReason[]): OrderReviewReason[] {
  const filteredReasons: OrderReviewReason[] = [];
  for (const reason of reasons) {
    switch (reason.type) {
      case 'payment_distribution_failure':
      case 'payment_network_failure':
      case 'payment_settlement_failure':
        if (!filteredReasons.find((x) => x.type === reason.type)) filteredReasons.push(reason);
        break;
      case 'comment':
        filteredReasons.push(reason);
        break;
      case 'negative_commissioner_experience':
        filteredReasons.push(reason);
        break;
    }
  }
  return filteredReasons;
}

export const AdminOrderDetailsAlerts = (props: { orderId: string; orderState: string; reviewReasons: OrderReviewReason[] }) => {
  const pendingOperation = useAdminPendingOperation();
  if (!HasValidReviewReason(props.reviewReasons)) return null;
  const reasons = FilterReviewReasons(props.reviewReasons);
  const commentCount = reasons.filter((x) => x.type === 'comment').length;

  return (
    <div css={[t.flex_initial, t.flex, t.flex_col, t.bg_error_2, t.p_6]}>
      {reasons.map((reason, i) => {
        const handleResolve = async () => {
          pendingOperation.show(true);
          await FirebaseObjects.adminRequest({ action: 'resolveIssue', data: { orderId: props.orderId, issue: reason } });
          pendingOperation.show(false);
        };

        let alert: React.ReactNode = null;
        switch (reason.type) {
          case 'payment_distribution_failure':
            alert = (
              <React.Fragment>
                <div css={[t.flex, t.flex_col]}>
                  <div css={[t.text_white]}>Payment Distribution Error</div>
                  <div
                    css={[t.text_white, t.text_sm]}
                  >{`Unable to pay the artist. Reach out to them and see if their paypal info needs updating. Once this is resolved we'll try to pay them again the next time a batch is processed.`}</div>
                </div>
                <div css={[t.flex_initial]}>
                  <AdminButton type="button" styleType="secondaryOnDark" label="Mark as resolved" onClick={handleResolve} />
                </div>
              </React.Fragment>
            );
            break;

          case 'payment_network_failure':
            alert = (
              <React.Fragment>
                <div css={[t.flex, t.flex_col]}>
                  <div css={[t.text_white]}>Unknown Payment Error</div>
                  <div
                    css={[t.text_white, t.text_sm]}
                  >{`Payment was interrupted by catastrophic server failure. Cross reference Braintree, refund the customer if we received their money, and cancel the order. If this order is missing a transaction id the payment should be manually refunded from Braintree.`}</div>
                </div>
                <div css={[t.flex_initial]}>
                  <AdminButton type="button" styleType="secondaryOnDark" label="Mark as resolved" onClick={handleResolve} />
                </div>
              </React.Fragment>
            );
            break;

          case 'payment_settlement_failure':
            alert = (
              <React.Fragment>
                <div css={[t.flex, t.flex_col]}>
                  <div css={[t.text_white]}>Payment Settlement Error</div>
                  <div css={[t.text_white, t.text_sm]}>
                    The credit card transaction failed to settle with the bank and we did not receive payment. The order has been automatically canceled.
                  </div>
                </div>
                <div css={[t.flex_initial]}>
                  <AdminButton type="button" styleType="secondaryOnDark" label="Mark as resolved" onClick={handleResolve} />
                </div>
              </React.Fragment>
            );
            break;
        }
        if (props.orderState === 'review_requested') {
          alert = (
            <React.Fragment>
              <div css={[t.flex, t.flex_col]}>
                <div css={[t.text_white]}>Negative Commissioner Experience</div>
                <div css={[t.text_white, t.text_sm]}>
                  The commissioner for this project provided negative feedback. A member of the Downwrite team needs to contact them to resolve the issues
                  raised.
                </div>
              </div>
            </React.Fragment>
          );
        } else if (props.orderState === 'in_review') {
          alert = (
            <React.Fragment>
              <div css={[t.flex, t.flex_col]}>
                <div css={[t.text_white]}>Negative Commissioner Experience</div>
                <div css={[t.text_white, t.text_sm]}>
                  A member of the Downwrite team is in contact with the commissioner and artist to try to resolve the issues raised.
                </div>
              </div>
            </React.Fragment>
          );
        }

        return (
          <div key={i} css={[t.flex]}>
            {alert}
          </div>
        );
      })}
      {commentCount > 0 && <div css={[t.text_white]}>{`${commentCount} comment${commentCount > 1 ? `s` : ''} needs review`}</div>}
    </div>
  );
};
