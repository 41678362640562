import React from 'react'; // eslint-disable-line

import { CSSStyle } from '../../theme/newstyles';
import { Swiper } from 'swiper/react';
import { Swiper as SwiperClass, SwiperOptions } from 'swiper';
import 'swiper/swiper.scss';

/** @jsxImportSource @emotion/react */

export const ReactSwiper = (props: {
  spaceBetween: number;
  slidesPerView: SwiperOptions['slidesPerView'];
  breakpoints?: SwiperOptions['breakpoints'];
  children?: React.ReactNode;
  style?: CSSStyle;
}) => {
  const reactSwiper = useReactSwiper();

  const onSlideChange = (swiper: SwiperClass) => {
    reactSwiper.setActiveIndex(swiper.activeIndex);
  };

  return (
    <Swiper
      css={props.style}
      spaceBetween={props.spaceBetween}
      slidesPerView={props.slidesPerView}
      breakpoints={props.breakpoints}
      onSlideChange={onSlideChange}
      watchSlidesVisibility={true}
      shortSwipes={false}
      longSwipesRatio={0.1}
      longSwipesMs={100}
      threshold={8}
      onInit={(swiper) => {
        reactSwiper.setSwiperAPI(swiper);
      }}
    >
      {props.children}
    </Swiper>
  );
};

export interface ReactSwiperState {
  swiper: SwiperClass | null;
  activeIndex: number;
  setSwiperAPI: (swiper: SwiperClass | null) => void;
  setActiveIndex: (index: number) => void;
}

export const ReactSwiperContext = React.createContext({} as ReactSwiperState);
export const ReactSwiperProvider = (props: { children: React.ReactNode }) => {
  const [swiper, SetSwiper] = React.useState<SwiperClass | null>(null);
  const [activeIndex, setActiveIndex] = React.useState(0);

  const setSwiperAPI = React.useCallback((swiper: SwiperClass | null) => {
    SetSwiper(swiper);
  }, []);

  const callSetActiveIndex = (index: number) => {
    setActiveIndex(index);
  };

  return (
    <ReactSwiperContext.Provider
      value={{
        swiper: swiper,
        activeIndex: activeIndex,
        setSwiperAPI: setSwiperAPI,
        setActiveIndex: callSetActiveIndex,
      }}
    >
      {props.children}
    </ReactSwiperContext.Provider>
  );
};

export function useReactSwiper() {
  return React.useContext(ReactSwiperContext);
}
