import { css } from '@emotion/react';
import { top, bottom, left, right, pos } from './utilities';

export const position = {
  static: css`
    position: static;
  `,
  fixed: css`
    position: fixed;
  `,
  absolute: css`
    position: absolute;
  `,
  relative: css`
    position: relative;
  `,
  sticky: css`
    position: sticky;
  `,

  inset_0: css`
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  `,
  inset_y_0: css`
    top: 0;
    bottom: 0;
  `,
  inset_x_0: css`
    right: 0;
    left: 0;
  `,
  top_0: css`
    top: 0;
  `,
  right_0: css`
    right: 0;
  `,
  bottom_0: css`
    bottom: 0;
  `,
  left_0: css`
    left: 0;
  `,

  top: top,
  bottom: bottom,
  left: left,
  right: right,
  pos: pos,
};
