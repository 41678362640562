import React from 'react';
import { createRoot } from 'react-dom/client';
import ReactModal from 'react-modal';
import App from './App';
// import * as serviceWorker from './serviceWorker';

// if (process.env.NODE_ENV !== 'production' && process.env.REACT_APP_A11Y) {
//   // eslint-disable-next-line @typescript-eslint/no-var-requires
//   const axe = require('react-axe');
//   axe(React, ReactDOM, 100);
// }

ReactModal.setAppElement('#root');
const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<App />);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.unregister();
