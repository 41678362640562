import React from 'react'; // eslint-disable-line
import { Link } from 'react-router-dom';

import t from '../../../../theme/admin/adminStyles';
import { CSSStyle } from '../../../../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const AdminDetailsLinkButton = (props: { to: string; disabled?: boolean; containerCSS?: CSSStyle; children?: React.ReactNode }) => {
  const sharedStyles = [t.flex_auto, t.bg_transparent, t.text_sm, t.mt_1];
  if (props.disabled) {
    return <div css={[sharedStyles, t.text_tint_2, props.containerCSS]}>{props.children}</div>;
  }

  return (
    <Link to={props.to} css={[sharedStyles, t.text_dark_4, t.visited(t.text_dark_1), t.hover(t.text_primary_4), t.focus(t.text_primary_4), props.containerCSS]}>
      {props.children}
    </Link>
  );
};
