import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { OrderProvider, useOrder } from '../../../contexts/OrderContext';
import { AdminCaretIcon } from '../icons/AdminCaretIcon';
import { AdminTabs } from '../components/AdminTabs';
import { AdminTab } from '../components/AdminTab';
import { AdminTabPanel } from '../components/AdminTabPanel';
import { AdminDetailsDialog } from '../components/Dialogs/AdminDetailsDialog';
import { AdminOrderDetailsHeader } from './AdminOrderDetailsHeader';
import { AdminOrderDetailsInfo } from './AdminOrderDetailsInfo';
import { AdminOrderDetailsBrief } from './AdminOrderDetailsBrief';
import { AdminOrderDetailsComments } from './AdminOrderDetailsComments';
import { AdminOrderDetailsRatings } from './AdminOrderDetailsRatings';
import { AdminOrderDetailsEventLog } from './AdminOrderDetailsEventLog';
import { AdminOrderDetailsAlerts } from './AdminOrderDetailsAlerts';
import { AdminOrderDetailsPayments } from './AdminOrderDetailsPayments';
import { AdminDialogPage } from '../components/Dialogs/AdminDialogPage';
import { AdminOrderDetailsSongs } from './AdminOrderDetailsSongs';
import { AdminOrderDetailsCancelled } from './AdminOrderDetailsCancelled';
import { ArtistRatingsProvider } from '../../../contexts/ArtistRatingsContext';
import { SharedAsset, SharedAssetType } from '../../../contexts/SharedAssetContext';
import { FirebaseObjects } from '../../../contexts/FirebaseContext';

/** @jsxImportSource @emotion/react */

export const AdminOrderDetailsDialog = (props: { isOpen: boolean; orderId?: string; onClose: (event: React.MouseEvent | React.KeyboardEvent) => void }) => {
  if (!props.orderId) return null;
  return (
    <OrderProvider id={props.orderId}>
      <AdminDetailsDialog isOpen={props.isOpen} onRequestClose={props.onClose}>
        <AdminOrderDetailsHeader />
        <AdminOrderDetails orderId={props.orderId} />
      </AdminDetailsDialog>
    </OrderProvider>
  );
};

const AdminOrderDetails = (props: { orderId: string }) => {
  const [expanded, SetExpanded] = React.useState(true);
  const [value, SetValue] = React.useState(0);
  const [sharedAsset, setSharedAsset] = React.useState<SharedAsset | null | undefined>(undefined);
  const orderId = props.orderId;

  React.useEffect(() => {
    const getSharedAssetDetailsAdmin = async (assetType: SharedAssetType, orderId: string) => {
      const response = await FirebaseObjects.visitorRequest({ action: 'getSharedAssetDetailsAdmin', data: { assetType, orderId } });
      return response.data;
    };
    getSharedAssetDetailsAdmin('s', orderId).then((asset) => setSharedAsset(asset));
  }, [orderId]);

  const order = useOrder();
  if (!order) return null;

  function HandleExpandClick(event: React.MouseEvent) {
    SetExpanded(!expanded);
  }

  const handleValueChange = (event: React.ChangeEvent<unknown>, newValue: number) => {
    SetValue(newValue);
  };

  if (!sharedAsset) return null;

  const comments = [...order.comments].reverse();
  const history = (order.history ? [...order.history] : []).reverse();
  const orderState = order.state && order.state.type ? order.state.type : '';

  return (
    <ArtistRatingsProvider artistProfileRoute={sharedAsset.artistRoute}>
      <AdminDialogPage>
        <div css={[t.flex, t.flex_col, t.h_full]}>
          <AdminOrderDetailsAlerts orderId={order.id} orderState={orderState} reviewReasons={order.reviewReasons} />
          <AdminOrderDetailsCancelled />
          <AdminOrderDetailsInfo />
          <div css={[t.flex_auto, t.relative]}>
            <div css={[t.absolute, t.top_0, t.right_0, t.z_50, t.mt_3, t.mr_6]}>
              <button css={[t.p_0, t.h('18px'), t.text_tint_1, t.cursor_pointer, t.bg_transparent, t.hover(t.text_dark_1)]} onClick={HandleExpandClick}>
                <AdminCaretIcon direction={expanded ? 'up' : 'down'} css={[t.size('14px')]} />
              </button>
            </div>
            <div css={[t.h_full, t.absolute, t.inset_x_0, t.flex, t.flex_col, t.overflow_y_auto]}>
              <AdminOrderDetailsBrief order={order} expanded={expanded} />
              <AdminOrderDetailsSongs />
              <div css={[t.bg_tint_4, t.border_b, t.border_solid, t.border_tint_3, t.pt_3, t.flex_auto, t.flex, t.flex_col]}>
                <AdminTabs value={value} onChange={handleValueChange}>
                  <AdminTab label={`Event History`} />
                  <AdminTab label={`Comments (${order.comments.length + (order.deliveryMessage ? 1 : 0)})`} />
                  <AdminTab label={`Ratings & Reviews`} />
                  <AdminTab label={`Payments`} />
                </AdminTabs>
                <AdminTabPanel value={value} index={0}>
                  <AdminOrderDetailsEventLog events={history} />
                </AdminTabPanel>
                <AdminTabPanel value={value} index={1}>
                  <AdminOrderDetailsComments order={order} comments={comments} />
                </AdminTabPanel>
                <AdminTabPanel value={value} index={2}>
                  <AdminOrderDetailsRatings order={order} sharedAsset={sharedAsset} />
                </AdminTabPanel>
                <AdminTabPanel value={value} index={3}>
                  <AdminOrderDetailsPayments order={order} />
                </AdminTabPanel>
              </div>
            </div>
          </div>
        </div>
      </AdminDialogPage>
    </ArtistRatingsProvider>
  );
};
