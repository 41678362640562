/** @jsxImportSource @emotion/react */

import React from 'react'; // eslint-disable-line
import { motion } from 'framer-motion';
import t from '../../theme/newstyles';
import { Link } from 'react-router-dom';

export type TestArtist = {
  name: string;
  to: string;
  photoURL: string;
  genreTags: string[];
  instrumentTags?: string[];
  shortBio?: string;
  relatedProjects?: string[];
  additionalImages?: string[];
};

export function TestArtistCard(props: { name: string; to: string; genreTags: string[]; photoURL: string; preferredSampleGenres: string[] }) {
  return (
    <Link to={props.to} css={[t.flex, t.flex_col]} style={{ textDecoration: 'none' }}>
      <div css={[t.cursor_pointer, t.flex, t.flex_col, t.relative, t.overflow_hidden]}>
        <motion.img
          whileHover={{ scale: 1.15 }}
          transition={{ duration: 1, ease: t.ease_1 }}
          css={[t.w_full, t.h_auto]}
          src={props.photoURL}
          alt={props.name}
          width={300}
          height={400}
        />
        {/* <div css={[t.absolute, t.inset_x_0, t.bottom_0]}>
          {sampleSong ? <ArtistCardSamplePlayer url={sampleSong.url} sampleName={sampleSong.name} sampleDuration={sampleSong.duration} /> : null}
        </div> */}
      </div>
      <div css={[t.mt_4, t.gridTypeStyle_5, t.text_dark_1]}>{props.name}</div>
      <div css={[t.gridTypeStyle_7, t.text_dark_1]}>{props.genreTags.join(' / ')}</div>
    </Link>
  );
}
