import React from 'react'; // eslint-disable-line
import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const SubtractIcon = (props: object) => {
  return (
    <svg aria-labelledby="subtractIconTitleID" width="12" height="1" viewBox="0 0 12 1" css={[t.stroke_current]} {...props}>
      <title id="subtractIconTitleID">Subtract Icon</title>
      <rect css={[t.stroke_current]} x="12" width="1" height="12" transform="rotate(90 12 0)" />
    </svg>
  );
};
