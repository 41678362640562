import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { AdminShuffleCard, ShuffleDirection } from '../components//AdminShuffleCard';
import { FeaturedArtist } from '../../../contexts/FeaturedArtistContentContext';
import { AdminButton } from '../components//AdminButton';
import { DeleteIcon } from '../../../icons/DeleteIcon';
import { PopupIcon } from '../icons/PopupIcon';

/** @jsxImportSource @emotion/react */

export const AdminArtistShuffleCard = (props: {
  artist: FeaturedArtist;
  first?: boolean;
  last?: boolean;
  onDetailsClick?: (objectID: string) => void;
  onShuffle: (direction: ShuffleDirection) => void;
  onDelete?: (event: React.MouseEvent) => void;
}) => {
  function HandleDetailsClick(e: React.MouseEvent) {
    if (props.onDetailsClick) props.onDetailsClick(props.artist.id);
  }

  return (
    <div css={[t.flex, t.flex_row]}>
      <AdminShuffleCard
        first={props.first}
        last={props.last}
        onShuffle={props.onShuffle}
        containerCSS={[t.flex_auto, t.hover(t.bg_tint_4), t.hoverSelector(' #detailsButton', t.inline_flex)]}
      >
        <div css={[t.flex_auto, t.flex, t.flex_row, t.items_center, t.text_sm]}>
          <div css={[t.flex_auto]}>{props.artist.name}</div>
          <div css={[t.flex_none]}>
            <button
              id="detailsButton"
              css={[
                t.hidden,
                t.bg_transparent,
                t.cursor_pointer,
                t.flex_row,
                t.items_center,
                t.justify_center,
                t.w('32px'),
                t.h_auto,
                t.text_tint_1,
                t.hover([t.text_dark_1]),
              ]}
              onClick={HandleDetailsClick}
            >
              <PopupIcon css={[t.size('16px')]} />
            </button>
          </div>
          <div css={[t.flex_none, t.flex, t.justify_center, t.ml_3]}>
            <AdminButton type="button" styleType="critical" icon={<DeleteIcon />} onClick={props.onDelete} />
          </div>
        </div>
      </AdminShuffleCard>
    </div>
  );
};
