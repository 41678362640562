import React, { JSX, useState } from 'react'; // eslint-disable-line

import t from '../../theme/newstyles';

import { GlobalHeader } from '../../components/headers/GlobalHeader';
import { useAnimationTestController, MotionCollectionForm } from './AnimationTestContext';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { FilterIcon } from '../../icons/FilterIcon';
import { MainContainer } from '../../components/MainContainer';
import { useFormikContext, Field } from 'formik';
import { useLocation } from 'react-router-dom';
import { AnimationTokens } from './AnimationTestData';

/** @jsxImportSource @emotion/react */

export const AnimationTestHeader = () => {
  const [controlState, SetControlState] = useState(false);
  const animationController = useAnimationTestController();

  function handleRefresh() {
    animationController.refreshKey();
  }

  function handleFilter() {
    SetControlState(!controlState);
  }

  return (
    <React.Fragment>
      <GlobalHeader logoTo="/admin/animation/discovery">
        <nav role="navigation" css={[t.w_full, t.flex, t.flex_row, t.justify_end]}>
          <ul css={[t.flex, t.flex_row]}>
            <li css={[t.flex, t.flex_col, t.justify_center]}>
              <IconButton onClick={handleRefresh}>
                <RefreshIcon />
              </IconButton>
            </li>
            <li css={[t.ml_4, t.flex, t.flex_col, t.justify_center]}>
              <IconButton onClick={handleFilter}>
                <FilterIcon />
              </IconButton>
            </li>
          </ul>
        </nav>
      </GlobalHeader>
      {controlState ? <AnimationControls /> : null}
    </React.Fragment>
  );
};

const AnimationControls = () => {
  const { pathname } = useLocation();
  const { values } = useFormikContext<MotionCollectionForm>();

  const options = Object.keys(values.collections).map((value, i) => (
    <option key={`${value}_${i}`} value={value}>
      {value}
    </option>
  ));

  const tokens = Object.keys(AnimationTokens).map((value, i) => (
    <option key={`${value}_${i}`} value={value}>
      {value}
    </option>
  ));

  const tokenFields: React.ReactNode[] = [];
  switch (pathname) {
    case '/admin/animation/discovery':
      Object.entries(values.collections[values.active].discovery).forEach(([key, value]) => {
        tokenFields.push(<MotionComponent key={key} id={key} label={key} componentName={`collections.${values.active}.discovery.${key}`} tokenList={tokens} />);
      });
      break;
    case '/admin/animation/artistPage':
      Object.entries(values.collections[values.active].artistPage).forEach(([key, value]) => {
        tokenFields.push(
          <MotionComponent key={key} id={key} label={key} componentName={`collections.${values.active}.artistPage.${key}`} tokenList={tokens} />
        );
      });
      break;
    case '/admin/animation/search':
      Object.entries(values.collections[values.active].search).forEach(([key, value]) => {
        tokenFields.push(<MotionComponent key={key} id={key} label={key} componentName={`collections.${values.active}.search.${key}`} tokenList={tokens} />);
      });
      break;
  }

  return (
    <div css={[t.fixed, t.z_30, t.mt_header, t.inset_x_0, t.bg_primary_4, t.py_3]}>
      <MainContainer>
        <div css={[t.flex, t.flex_row]}>
          <div css={[t.flex_none, t.flex, t.flex_col, t.pr_6, t.border_r, t.border_solid, t.border_tint_5]}>
            <label htmlFor="motion-style" css={[t.text_tint_5, t.mb_1]}>
              Motion Style
            </label>
            <Field as="select" id="motion-style" name="active">
              {options}
            </Field>
          </div>
          <div css={[t.flex_auto, t.flex, t.flex_col, t.pl_6]}>
            <div css={[t.flex, t.flex_row, t.flex_wrap]}>{tokenFields}</div>
          </div>
        </div>
      </MainContainer>
    </div>
  );
};

const MotionComponent = (props: { id: string; label: string; componentName: string; tokenList: JSX.Element[] }) => {
  return (
    <div css={[t.flex, t.flex_col, t.mr_5, t.mb_3]}>
      <label htmlFor={`${props.id}-token`} css={[t.text_tint_5, t.mb_1]}>
        {props.label}
      </label>
      <MotionComponentSelect id={props.id} name={`${props.componentName}.tokenKey`} tokenList={props.tokenList} />
    </div>
  );
};

const MotionComponentSelect = (props: { id: string; name: string; tokenList: JSX.Element[] }) => {
  return (
    <Field as="select" id={`${props.id}-token`} name={props.name}>
      {props.tokenList}
    </Field>
  );
};

const IconButton = (props: { children?: React.ReactNode; onClick: () => void }) => {
  return (
    <button type="button" css={[t.cursor_pointer, t.bg_transparent, t.hover(t.text_primary_4)]} onClick={props.onClick}>
      {props.children}
    </button>
  );
};
