import * as H from 'history';

export function PathMatches<S = H.LocationState>(
  location: H.Location<S>,
  altRoute: string | undefined,
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>)
): boolean {
  let pathname: string | undefined = undefined;
  if (to) {
    if (typeof to === 'string') pathname = to;
    else {
      let pathObj: H.History.LocationDescriptor<S> | null = null;
      if (typeof to === 'function') pathObj = to(location);
      else pathObj = to;
      if (typeof pathObj === 'string') pathname = pathObj;
      else pathname = pathObj.pathname;
    }
  }
  return location.pathname === pathname || location.pathname === altRoute;
}
