import React from 'react';

export interface SongUploadController {
  progress: number;
  total: number;
  label: string;
  transcodingInProgress: boolean;
  setLabel: (label: string) => void;
  setTranscodingInProgress: (transcodingInProgress: boolean) => void;
  setState: (progress: number, total: number) => void;
}

export const SongUploadContext = React.createContext<SongUploadController>({} as SongUploadController);
export const SongUploadProvider = (props: { children: React.ReactNode }) => {
  const [progress, setProgress] = React.useState(0);
  const [total, setTotal] = React.useState(0);
  const [label, setLabel] = React.useState('');
  const [transcodingInProgress, setTranscodingInProgress] = React.useState(false);

  return (
    <SongUploadContext.Provider
      value={{
        progress: progress,
        total: total,
        label: label,
        transcodingInProgress: transcodingInProgress,
        setLabel: (label: string) => {
          setLabel(label);
        },
        setTranscodingInProgress: (transcodingInProgress: boolean) => {
          setTranscodingInProgress(transcodingInProgress);
        },
        setState: (newProgress: number, newTotal: number) => {
          setProgress(newProgress);
          setTotal(newTotal);
        },
      }}
    >
      {props.children}
    </SongUploadContext.Provider>
  );
};
export function useSongUploadController() {
  return React.useContext(SongUploadContext);
}
