import React from 'react'; // eslint-disable-line

import { ClassNames } from '@emotion/react';
import t, { CSSStyle } from '../theme/newstyles';
import { CollageImageProps } from './CollageImage';

/** @jsxImportSource @emotion/react */

type ImageAlign = 'Start' | 'Center' | 'End';
type SlotAlignments = { primary?: ImageAlign; secondary?: ImageAlign; tertiary?: ImageAlign };

export const Collage = (props: {
  cycling?: boolean;
  duoAlignment?: SlotAlignments;
  trioAlignment?: SlotAlignments;
  images?: string[];
  children?: (imageProps: CollageImageProps) => React.ReactNode;
}) => {
  const [focusElement, SetFocusElement] = React.useState(0);

  if (!props.images || props.images.length === 0) return null;
  const imageCount = props.images.length;

  return (
    <div css={[t.relative, t.select_none, props.cycling ? t.cursor_pointer : null]}>
      <div>
        <img
          css={[t.w_full, t.h_auto]}
          src={`data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 4'%3E%3C/svg%3E`}
          alt="Profile place holder"
          width={3}
          height={4}
        />
      </div>
      <ClassNames>
        {(css) => {
          if (!props.images) return null;
          return (
            <React.Fragment>
              {props.images.map((image, i) => {
                let slotAlignments: SlotAlignments | undefined = undefined;
                if (imageCount === 2) slotAlignments = props.duoAlignment;
                else if (imageCount === 3) slotAlignments = props.trioAlignment;

                function HandleClick() {
                  SetFocusElement(i);
                }

                let wrapperStyle: CSSStyle = [];
                switch (i) {
                  case 0: {
                    const alignment = t.justify_start;
                    let override: CSSStyle | null = null;
                    if (slotAlignments) override = GetSlotAlignment(slotAlignments, 'primary');

                    wrapperStyle = [t.absolute, t.inset_0, t.flex, t.flex_col, override ? override : alignment];

                    break;
                  }
                  case 1: {
                    const alignment = imageCount === 2 ? t.justify_end : t.justify_center;
                    let override: CSSStyle | null = null;
                    if (slotAlignments) override = GetSlotAlignment(slotAlignments, 'secondary');

                    wrapperStyle = [t.absolute, t.inset_x_0, t.bottom_0, t.flex, override ? override : alignment];
                    break;
                  }
                  case 2: {
                    const alignment = t.justify_start;
                    let override: CSSStyle | null = null;
                    if (slotAlignments) override = GetSlotAlignment(slotAlignments, 'tertiary');

                    wrapperStyle = [t.absolute, t.inset_y_0, t.right_0, !override && t.pt_8, t.flex, t.flex_col, t.items_end, override ? override : alignment];
                    break;
                  }
                }

                return (
                  <div key={`collageWrapper${i}`} css={wrapperStyle} onClick={props.cycling ? HandleClick : undefined}>
                    {props.children && props.children({ src: image, index: i, focusIndex: focusElement, imageCount: imageCount })}
                  </div>
                );
              })}
            </React.Fragment>
          );
        }}
      </ClassNames>
    </div>
  );
};

function GetSlotAlignment(slotAlignment: SlotAlignments, slot: 'primary' | 'secondary' | 'tertiary') {
  const imageAlign: ImageAlign | undefined = slotAlignment[slot];
  if (imageAlign) return GetAlignment(imageAlign);
}

function GetAlignment(align: ImageAlign) {
  switch (align) {
    case 'Start':
      return t.justify_start;
    case 'Center':
      return t.justify_center;
    case 'End':
      return t.justify_end;
  }
}
