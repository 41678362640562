import * as React from 'react';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/analytics';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';
import 'firebase/compat/storage';

export interface IFirebaseObjects {
  initialized: boolean;
  uploadBucket: firebase.storage.Storage;
  publicBucket: firebase.storage.Storage;
  adminRequest: firebase.functions.HttpsCallable;
  adminLongRequest: firebase.functions.HttpsCallable;
  userRequest: firebase.functions.HttpsCallable;
  artistRequest: firebase.functions.HttpsCallable;
  artistLongRequest: firebase.functions.HttpsCallable;
  visitorRequest: firebase.functions.HttpsCallable;
}

export const FirebaseObjects: IFirebaseObjects = {
  initialized: false,
  uploadBucket: null as any,
  publicBucket: null as any,
  adminRequest: null as any,
  adminLongRequest: null as any,
  userRequest: null as any,
  artistRequest: null as any,
  artistLongRequest: null as any,
  visitorRequest: null as any,
};

const isLocalhost = Boolean(
  window.location.hostname === 'localhost' ||
  window.location.hostname === '[::1]' ||
  window.location.hostname.match(/^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/)
);

const isDev = process.env.REACT_APP_IS_DEV === 'true';

export async function InitializeFirebase() {
  const configResponse = await fetch('/__/firebase/init.json');
  const config = await configResponse.json();
  firebase.initializeApp(config);

  if (process.env.REACT_APP_FIREBASE_EMULATION === 'true') {
    console.log('Initializing Server Emulation...');
    firebase.functions().useEmulator('localhost', 5001);

    const db = firebase.firestore();
    if (isLocalhost) {
      db.settings({
        host: 'localhost:8080',
        ssl: false,
      })
    }
  }

  FirebaseObjects.uploadBucket = firebase.app().storage(uploadURL);
  FirebaseObjects.publicBucket = firebase.app().storage(publicURL);
  FirebaseObjects.adminRequest = firebase.functions().httpsCallable('adminRequest');
  FirebaseObjects.adminLongRequest = firebase.functions().httpsCallable('adminRequest', { timeout: 540000 });
  FirebaseObjects.userRequest = firebase.functions().httpsCallable('userRequest');
  FirebaseObjects.artistRequest = firebase.functions().httpsCallable('artistRequest');
  FirebaseObjects.artistLongRequest = firebase.functions().httpsCallable('artistRequest', { timeout: 540000 });
  FirebaseObjects.visitorRequest = firebase.functions().httpsCallable('visitorRequest');
  FirebaseObjects.initialized = true;
}

// This isn't great, but seemed unavoidable with our current setup.
let uploadURL = '';
let publicURL = '';
let publicDownloadUrlBase = '';
let envPrefix: 'dev_' | 'stage_' | 'live_' = 'dev_';
let useSandboxes = true;
if (isDev || window.location.hostname.includes('downwrite-dev')) {
  uploadURL = 'gs://downwrite-dev-upload';
  publicURL = 'gs://downwrite-dev-public';
  envPrefix = 'dev_';
  publicDownloadUrlBase = 'https://storage.googleapis.com/downwrite-dev-public';
} else if (window.location.hostname.includes('downwrite-stage')) {
  uploadURL = 'gs://downwrite-stage-upload';
  publicURL = 'gs://downwrite-stage-public';
  envPrefix = 'stage_';
  publicDownloadUrlBase = 'https://storage.googleapis.com/downwrite-stage-public';
} else if (window.location.hostname.includes('downwrite-live') || window.location.hostname.includes('downwrite')) {
  uploadURL = 'gs://downwrite-live-upload';
  publicURL = 'gs://downwrite-live-public';
  envPrefix = 'live_';
  publicDownloadUrlBase = 'https://storage.googleapis.com/downwrite-live-public';
  useSandboxes = false;
}

export { envPrefix, publicDownloadUrlBase, useSandboxes };

export function CreateDocumentContext<T extends { id: string }>(collectionRoot: string) {
  const context = React.createContext<T | null>(null);
  const Provider = (props: { id: string; children: React.ReactNode }) => {
    const [state, setState] = React.useState<T | null>(null);
    React.useEffect(() => {
      const observer = (snapshot: firebase.firestore.DocumentSnapshot) => {
        const data = snapshot.data() as T;
        if (data) {
          data.id = props.id;
          setState(data);
        } else setState(null);
      };

      const error = (error: Error) => {
        setState(null);
      };

      const ref = firebase.firestore().doc(`${collectionRoot}/${props.id}`);
      return ref.onSnapshot(observer, error);
    }, [props.id]);

    return <context.Provider value={state}>{props.children}</context.Provider>;
  };

  const useContext = () => {
    return React.useContext(context);
  };

  return {
    Provider,
    Use: useContext,
  };
}

let emailRedirectBase = '';
if (isLocalhost) {
  emailRedirectBase = 'http://localhost:3000';
} else if (window.location.hostname.includes('downwrite-dev')) {
  emailRedirectBase = 'https://downwrite-dev.firebaseapp.com';
} else if (window.location.hostname.includes('downwrite-stage')) {
  emailRedirectBase = 'https://downwrite-stage.firebaseapp.com';
} else if (window.location.hostname.includes('downwrite-live')) {
  emailRedirectBase = 'https://downwrite-live.firebaseapp.com';
} else if (window.location.hostname.includes('beta.downwrite')) {
  emailRedirectBase = 'https://beta.downwrite.com';
} else if (window.location.hostname.includes('downwrite')) {
  emailRedirectBase = 'https://downwrite.com';
}

export const EmailRedirectBase = emailRedirectBase;
export const EmailRedirectURL = `${emailRedirectBase}/account/welcome`;
export const PasswordResetRedirectURL = `${emailRedirectBase}/signin`;
export const FirebaseSDK = firebase;
