import React from 'react'; // eslint-disable-line
import { useSearchBox, UseSearchBoxProps } from 'react-instantsearch-core';

/** @jsxImportSource @emotion/react */

// This enables searching with arbitrary strings
export default function HiddenSearch(props: UseSearchBoxProps & { query: string, defaultRefinement?: string }) {
  const { query, refine } = useSearchBox(props);
  if (props.query !== query) refine(props.query);
  return <React.Fragment></React.Fragment>;
}
