import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const GripIcon = (props: {}) => {
  return (
    <svg width="8" height="13" viewBox="0 0 8 13" fill="none" {...props}>
      <circle css={[t.fill_current]} cx="6.5" cy="1.5" r="1.5" />
      <circle css={[t.fill_current]} cx="6.5" cy="6.5" r="1.5" />
      <circle css={[t.fill_current]} cx="6.5" cy="11.5" r="1.5" />
      <circle css={[t.fill_current]} cx="1.5" cy="1.5" r="1.5" />
      <circle css={[t.fill_current]} cx="1.5" cy="6.5" r="1.5" />
      <circle css={[t.fill_current]} cx="1.5" cy="11.5" r="1.5" />
    </svg>
  );
};
