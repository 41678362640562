import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { AdminSearchTableCell } from '../components/AdminSearchTableCell';
import { useHits, UseHitsProps } from 'react-instantsearch-core';
import dayjs from 'dayjs';
import { AdminSearchTableRow, AdminSearchRowProps } from '../components/AdminSearchTableRow';
import { AdminUserDetailsDialog } from './AdminUserDetailsDialog';
import { AdminIconButton } from '../components/AdminIconButton';
import { PopupIcon } from '../icons/PopupIcon';
import { AdminSearchTableActionCell } from '../components/AdminSearchTableActionCell';

/** @jsxImportSource @emotion/react */

export enum UserRole {
  deleted = 'deleted',
  admin = 'admin',
  user = 'user',
  artist = 'artist',
}

interface UserSearchHit {
  firstname: string;
  lastname: string;
  email: string;
  role: UserRole;
  ordersPlaced: number;
  signupDate: string;
}

export const AdminUserSearchHits = (props: UseHitsProps<UserSearchHit>) => {
  const [detailsActive, SetDetailsActive] = React.useState(false);
  const [objectID, SetObjectID] = React.useState<string | null>(null);
  const [userRole, SetUserRole] = React.useState<UserRole | null>(null);
  const { items } = useHits(props);

  function HandleClose(event: React.MouseEvent | React.KeyboardEvent) {
    event.stopPropagation();
    SetDetailsActive(false);
    SetObjectID(null);
    SetUserRole(null);
  }

  return (
    <React.Fragment>
      <tbody css={[t.p_0, t.text_sm, t.h_12]}>
        {items.map((hit, i: number) => {
          function HandleClick() {
            SetObjectID(hit.objectID);
            SetDetailsActive(true);
            SetUserRole(hit.role);
          }

          return (
            <AdminSearchTableRow key={i}>
              {(rowProps: AdminSearchRowProps) => {
                return (
                  <React.Fragment>
                    <AdminSearchTableCell>{hit.firstname && hit.lastname && `${hit.firstname} ${hit.lastname}`}</AdminSearchTableCell>
                    <AdminSearchTableCell>{hit.email}</AdminSearchTableCell>
                    <AdminSearchTableCell>{hit.role}</AdminSearchTableCell>
                    <AdminSearchTableCell>{hit.ordersPlaced}</AdminSearchTableCell>
                    <AdminSearchTableCell>{dayjs(hit.signupDate).local().format('MMM DD, YYYY')}</AdminSearchTableCell>
                    <AdminSearchTableCell>{hit.objectID}</AdminSearchTableCell>

                    <AdminSearchTableActionCell>
                      {rowProps.isHovered && (
                        <AdminIconButton id="userDetailsButton" onClick={HandleClick}>
                          <PopupIcon css={[t.size('16px')]} />
                        </AdminIconButton>
                      )}
                    </AdminSearchTableActionCell>
                  </React.Fragment>
                );
              }}
            </AdminSearchTableRow>
          );
        })}
        <AdminUserDetailsDialog userId={objectID} userRole={userRole} isOpen={detailsActive} onClose={HandleClose} />
      </tbody>
    </React.Fragment>
  );
};
