/** @jsxImportSource @emotion/react */
import React from 'react'; // eslint-disable-line

import t from '../../theme/newstyles';
import { TextField } from '../../components/TextField';
import { Formik, Form, FormikHelpers, FormikProps } from 'formik';
import * as Yup from 'yup';
import { InstagramIcon } from '../../icons/InstagramIcon';
import { InputText } from '../../components/InputText';

const Schema = Yup.object().shape({
  test: Yup.string().required('Required field'),
  test2: Yup.string().required('Required field'),
  test3: Yup.string().required('Required field'),
  test4: Yup.string().required('Required field'),
  test5: Yup.string().required('Required field'),
  test6: Yup.string().required('Required field'),
});

interface TestDescriptor {
  test: string;
  test2: string;
  test3: string;
  test4: string;
  test5: string;
  test6: string;
}

const formName = 'test';

export const TextInputTest = () => {
  const handleSubmit = async (formData: TestDescriptor, actions: FormikHelpers<TestDescriptor>) => {
    console.log('test');
  };

  const initialValues: TestDescriptor = {
    test: '',
    test2: '',
    test3: '',
    test4: '',
    test5: '',
    test6: '',
  };

  return (
    <div css={[t.w_full, t.flex, t.flex_col]} >
      <Formik<TestDescriptor> initialValues={initialValues} validationSchema={Schema} validateOnMount={true} onSubmit={handleSubmit}>
        {(formikProps: FormikProps<TestDescriptor>) => {
          return (
            <Form>
              <div css={[t.pb_8]}>
                <div css={[t.gridTypeStyle_4, t.my_5]}>Text Inputs</div>
                <div css={[t.flex, t.flex_col]}>
                  <div css={[t.mb_7, t.flex, t.flex_row]}>
                    <div css={[t.mr_6]}>
                      <InputText<TestDescriptor>
                        id="test1"
                        name="test"
                        value={formikProps.values.test}
                        invalid={formikProps.touched.test && Boolean(formikProps.errors.test)}
                        onChange={formikProps.handleChange}
                        onBlur={formikProps.handleBlur}
                        label="Text input"
                        placeholder="Placeholder text"
                      />
                    </div>

                    <div css={[t.mr_6]}>
                      <TextField<TestDescriptor> formName={formName} name="test" label="Text input" placeholder="Placeholder text" disabled={true} />
                    </div>

                    <TextField<TestDescriptor> formName={formName} name="test" label="Text input" placeholder="Placeholder text" variant="filled" />
                  </div>

                  <div css={[t.mb_7, t.flex, t.flex_row]}>
                    <div css={[t.mr_6]}>
                      <TextField<TestDescriptor> formName={formName} name="test2" label="Text Input" icon={<InstagramIcon />} placeholder="Placeholder text" />
                    </div>

                    <TextField<TestDescriptor>
                      formName={formName}
                      name="test2"
                      label="Text Input"
                      icon={<InstagramIcon />}
                      placeholder="Placeholder text"
                      disabled={true}
                    />
                  </div>

                  <div css={[t.mb_7, t.flex, t.flex_row]}>
                    <div css={[t.mr_6]}>
                      <TextField<TestDescriptor> formName={formName} name="test6" label="Text Input" startAdornment="$" placeholder="Placeholder text" />
                    </div>

                    <TextField<TestDescriptor>
                      formName={formName}
                      name="test6"
                      label="Text Input"
                      startAdornment="$"
                      placeholder="Placeholder text"
                      disabled={true}
                    />
                  </div>

                  <div css={[t.mb_7, t.flex, t.flex_row]}>
                    <div css={[t.mr_6]}>
                      <TextField<TestDescriptor>
                        formName={formName}
                        name="test3"
                        label="Text input"
                        description="Description text giving context"
                        placeholder="Placeholder text"
                      />
                    </div>

                    <TextField<TestDescriptor>
                      formName={formName}
                      name="test3"
                      label="Text input"
                      description="Description text giving context"
                      placeholder="Placeholder text"
                      disabled={true}
                    />
                  </div>
                </div>
              </div>

              <div css={[t.pb_8]}>
                <div css={[t.gridTypeStyle_4, t.my_5]}>Text Areas</div>
                <div css={[t.flex, t.flex_col]}>
                  <div css={[t.mb_7, t.flex, t.flex_row]}>
                    <div css={[t.mr_6]}>
                      <TextField<TestDescriptor>
                        formName={formName}
                        name="test4"
                        label="Text input"
                        placeholder="Placeholder text"
                        multiline={true}
                        maxLength={300}
                      />
                    </div>

                    <TextField<TestDescriptor>
                      formName={formName}
                      name="test4"
                      label="Text input"
                      placeholder="Placeholder text"
                      multiline={true}
                      disabled={true}
                      maxLength={300}
                    />
                  </div>

                  <div css={[t.mb_7, t.flex, t.flex_row]}>
                    <div css={[t.mr_6]}>
                      <TextField<TestDescriptor>
                        formName={formName}
                        name="test5"
                        label="Text input"
                        description="Description text giving context"
                        placeholder="Placeholder text"
                        multiline={true}
                        maxLength={300}
                      />
                    </div>

                    <TextField<TestDescriptor>
                      formName={formName}
                      name="test5"
                      label="Text input"
                      description="Description text giving context"
                      placeholder="Placeholder text"
                      multiline={true}
                      disabled={true}
                      maxLength={300}
                    />
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </div >
  );
};
