import React, { useState } from 'react'; // eslint-disable-line
import { withAdminAccount } from '../../hocs/withAdminAccount';
import t from '../../theme/newstyles';
import { useDiscoveryContent } from '../../contexts/DiscoveryContentContext';
import { motion, Transition } from 'framer-motion';
import { AdminPage } from '../admin/components/AdminPage';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { MotionIntroToken } from '../../theme/motionUtilities';
import { AdminCaretIcon } from '../admin/icons/AdminCaretIcon';
import { AdminButton } from '../admin/components/AdminButton';
import { AdminInputText } from '../admin/components/AdminInputText';
import { AnimationTokens } from './AnimationTestData';

/** @jsxImportSource @emotion/react */

// function CreateTransition(times: number[]) {
//   let duration = 0;
//   for (const time of times) duration += time;

//   let totalTime = 0;
//   const percentages: number[] = [0];
//   for (const time of times) {
//     totalTime += time;
//     percentages.push(totalTime / duration);
//   }
//   return { duration, times: percentages };
// }

function Animations(props: { motionToken: MotionIntroToken; duration?: number; delay?: number }) {
  let transition: Transition = props.duration !== undefined ? { duration: props.duration } : {};
  if (props.delay !== undefined) transition = { ...transition, delay: props.delay };
  return (
    <div css={[t.w_full, t.h_full, t.flex, t.flex_row, t.justify_center, t.items_center]}>
      <motion.div {...props.motionToken} custom={t.override(transition)} css={[t.size({ width: '180px', height: '240px' }), t.bg_primary_4]} />
    </div>
  );
}

function TokenMenu(props: { value: string; onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void }) {
  const options = Object.keys(AnimationTokens).map((value, i) => (
    <option key={`${value}_${i}`} value={value}>
      {value}
    </option>
  ));
  return (
    <select css={[t.bg_white, t.border_1, t.border_tint_2, t.border_solid]} onChange={props.onChange} value={props.value}>
      {options}
    </select>
  );
}

const AnimationTestComponent = () => {
  const [key, SetKey] = React.useState(0);
  const [tokenIndex, SetTokenIndex] = useState(0);
  const [duration, SetDuration] = useState<number | undefined>(undefined);
  const [delay, SetDelay] = useState<number | undefined>(undefined);

  const discoveryContent = useDiscoveryContent();
  if (!discoveryContent) return null;

  const animationKeys = Object.keys(AnimationTokens);
  const currentTokenKey = animationKeys[tokenIndex];

  function handleRefresh() {
    SetKey(Math.random());
  }

  function handleNext() {
    SetKey(Math.random());
    SetTokenIndex(tokenIndex === animationKeys.length - 1 ? 0 : tokenIndex + 1);
  }

  function handlePrevious() {
    SetKey(Math.random());
    SetTokenIndex(tokenIndex === 0 ? animationKeys.length - 1 : tokenIndex - 1);
  }

  function handleDurationChange(e: React.ChangeEvent<HTMLInputElement>) {
    SetKey(Math.random());
    if (e.target.validity.valid && e.target.value !== undefined && e.target.value !== '') SetDuration(e.currentTarget.valueAsNumber);
    else SetDuration(undefined);
  }

  function handleDelayChange(e: React.ChangeEvent<HTMLInputElement>) {
    SetKey(Math.random());

    if (e.target.validity.valid && e.target.value !== undefined && e.target.value !== '') SetDelay(e.currentTarget.valueAsNumber);
    else SetDelay(undefined);
  }

  function handleSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
    SetKey(Math.random());
    SetTokenIndex(e.currentTarget.selectedIndex);
  }

  return (
    <AdminPage pageHeading="Animation">
      <div css={[t.mt_4, t.mb_6]}>
        <AdminButton type="link" to="/admin/animation/discovery" styleType="secondary" label="Animation Page Flow" />
      </div>
      <div css={[t.flex, t.flex_row]}>
        <div css={[t.flex_none, t.w('100px'), t.mr_2]}>
          <AdminInputText id="duration" type="number" step={0.1} label="duration" defaultValue={duration} onChange={handleDurationChange} />
        </div>
        <div css={[t.flex_none, t.w('100px')]}>
          <AdminInputText id="delay" type="number" step={0.1} label="delay" defaultValue={delay} onChange={handleDelayChange} />
        </div>
        <div css={[t.flex_auto]}>
          <div css={[t.flex, t.flex_row, t.justify_end]}>
            <AdminButton buttonType="button" type="button" styleType="secondary" icon={<RefreshIcon css={[t.size('18px')]} />} onClick={handleRefresh} />

            <div css={[t.flex, t.ml_2]}>
              <TokenMenu value={currentTokenKey} onChange={handleSelectChange} />
            </div>
            <div css={[t.ml_2]}>
              <AdminButton
                buttonType="button"
                type="button"
                styleType="secondary"
                icon={<AdminCaretIcon direction="left" css={[t.size('18px')]} />}
                onClick={handlePrevious}
              />
            </div>
            <div css={[t.ml_2]}>
              <AdminButton
                buttonType="button"
                type="button"
                styleType="secondary"
                icon={<AdminCaretIcon direction="right" css={[t.size('18px')]} />}
                onClick={handleNext}
              />
            </div>
          </div>
        </div>
      </div>
      <div css={[t.mt_8]}>
        <Animations key={key} motionToken={AnimationTokens[currentTokenKey]} duration={duration} delay={delay} />
      </div>
    </AdminPage>
  );
};

export const AnimationTest = withAdminAccount(AnimationTestComponent);
