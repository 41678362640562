import { ArtistSearchState } from './types';
import { alphabeticalIndexName, popularityIndexName, ageIndexName, featuredIndexName, ratingsIndexName } from './constants';

export const getSortIndex = (searchState: ArtistSearchState): string => {
  let sortIndex = '';

  switch (searchState.sortIndex as string) {
    case 'alphabetical':
      sortIndex = alphabeticalIndexName;
      break;
    case 'popularity':
      sortIndex = popularityIndexName;
      break;
    case 'age':
      sortIndex = ageIndexName;
      break;
    case 'featured':
      sortIndex = featuredIndexName;
      break;
    case 'ratings':
      sortIndex = ratingsIndexName;
  }

  return sortIndex;
};
