import React from 'react'; // eslint-disable-line
import { css } from '@emotion/react';
import { motion, Variants } from 'framer-motion';

import t from '../../theme/newstyles';
import { MainPage } from '../../components/MainPage';
import { MainContainer } from '../../components/MainContainer';
import { withAdminAccount } from '../../hocs/withAdminAccount';
import { TestArtistCard, TestArtist } from './TestArtistCard';
import { ArtistSearchControls } from '../search/ArtistSearchControls';
import { ArtistActiveFiltersList } from '../search/ArtistActiveFiltersList';
import { useAnimationTestController, MotionCollectionForm } from './AnimationTestContext';
import { useFormikContext } from 'formik';
import { AnimationTokens } from './AnimationTestData';

/** @jsxImportSource @emotion/react */

const searchArtistList: TestArtist[] = [
  {
    name: 'Shane Henderson',
    to: '/admin/animation/artistPage',
    photoURL: '/6f9e91a0-707c-11ea-903b-51ac86c935a7.jpg',
    genreTags: ['Acoustic', 'Pop', 'Punk Rock'],
  },
  { name: 'Alyssa Beyer', to: '/admin/animation/artistPage', photoURL: '/b9204a00-707f-11ea-ac87-49d2be68a06f.jpg', genreTags: ['Rock', 'Pop', 'Electronic'] },
  { name: 'David Bello', to: '/admin/animation/artistPage', photoURL: '/442e1980-7083-11ea-ab9e-1fd9c03fed17.jpg', genreTags: ['Punk Rock', 'Indie'] },
  {
    name: 'Kristina Cottone',
    to: '/admin/animation/artistPage',
    photoURL: '/265f66e0-7081-11ea-903b-51ac86c935a7.jpg',
    genreTags: ['Blues', 'Soul', 'Acoustic'],
  },
  { name: 'Brian Pretus', to: '/admin/animation/artistPage', photoURL: '/6fcbc6b0-707d-11ea-ac87-49d2be68a06f.jpg', genreTags: ['Punk Rock', 'Rock'] },
  {
    name: 'Tara Jean',
    to: '/admin/animation/artistPage',
    photoURL: '/dbd4bc40-7078-11ea-bd02-754d8c925103.jpeg',
    genreTags: ['RnB', 'Soul', 'Pop', 'Electronic'],
  },
];

const overlayVariants: Variants = {
  visible: { opacity: 0.3 },
  hidden: { opacity: 0 },
};

function ArtistPageHits(props: { filterMenuActive: boolean }) {
  return (
    <MainContainer>
      <div css={[t.pt_6, t.lg([t.pt_7])]}>
        <ArtistCardGrid preferredGenres={['Rock']} />
        <div css={[t.w_full, t.mt_5, t.mb_7, t.flex, t.justify_center]}></div>
      </div>
      <motion.div
        css={[t.absolute, t.inset_0, t.bg_dark_1, t.pointer_events_none]}
        initial="hidden"
        animate={props.filterMenuActive ? 'visible' : 'hidden'}
        variants={overlayVariants}
      />
    </MainContainer>
  );
}

const cardGrid = [
  t.grid_1,
  t.grid_gap_x8,
  t.sm([t.grid_2, t.grid_gap_y5]),
  t.lg([
    t.grid_3,
    t.grid_gap_x7,
    css`
      grid-column-gap: 24px;
    `,
  ]),
  t.xl([
    t.grid_4,
    css`
      grid-column-gap: 40px;
    `,
  ]),
];

function ArtistCardGrid(props: { preferredGenres: string[] }) {
  const { values } = useFormikContext<MotionCollectionForm>();
  const tokens = values.collections[values.active].search;

  return (
    <motion.div css={[t.w_full, cardGrid]} {...AnimationTokens[tokens.grid.tokenKey]}>
      {searchArtistList.map((hit, i) => {
        return (
          <motion.div key={hit.name} {...(i < 12 ? { ...AnimationTokens[tokens.card.tokenKey] } : null)}>
            <TestArtistCard name={hit.name} to={hit.to} photoURL={hit.photoURL} genreTags={hit.genreTags} preferredSampleGenres={props.preferredGenres} />
          </motion.div>
        );
      })}
    </motion.div>
  );
}

const AnimationTestSearchPage = () => {
  const [showFilterMenu, setShowFilterMenu] = React.useState(false);
  const { values } = useFormikContext<MotionCollectionForm>();
  const tokens = values.collections[values.active].search;

  const HandleFilterToggle = () => {
    setShowFilterMenu(!showFilterMenu);
  };

  return (
    <MainPage>
      <div css={[t.flex, t.flex_row]}>
        <MainContainer containerCSS={[t.pt_6, t.lg(t.pt_7), t.pb_0]}>
          <motion.h1 {...AnimationTokens[tokens.h1.tokenKey]} css={[t.flex_auto, t.gridTypeStyle_1, t.text_dark_1]}>
            Our Artists
          </motion.h1>
          <motion.div {...AnimationTokens[tokens.h2.tokenKey]} css={[t.gridTypeStyle_4, t.text_dark_1, t.mt_5, t.md(t.mt_6), t.lg(t.mt_7)]}>
            Showing all artists
          </motion.div>
        </MainContainer>
      </div>
      <ArtistSearchControls filterMenuActive={showFilterMenu} onFilterToggle={HandleFilterToggle} />
      <ArtistActiveFiltersList />
      <ArtistPageHits filterMenuActive={showFilterMenu} />
    </MainPage>
  );
};

const AnimationTestSearchComponent = () => {
  const animationController = useAnimationTestController();

  return <AnimationTestSearchPage key={animationController.key} />;
};

export const AnimationTestSearch = withAdminAccount(AnimationTestSearchComponent);
