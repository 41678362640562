import React from 'react'; // eslint-disable-line
import { useInfiniteHits, UseInfiniteHitsProps } from 'react-instantsearch';

/** @jsxImportSource @emotion/react */

export default function CachedSearch(props: UseInfiniteHitsProps & { pageBump: number; setResults: (results: { hits: any[]; hasMore: boolean }) => void }) {
  const { items, isLastPage } = useInfiniteHits(props);
  // TODO(peter) reimplmement
  // const [pageBump, setPageBump] = React.useState(props.pageBump);
  // const refineNext = props.refineNext;
  // React.useEffect(() => {
  //   if (pageBump !== props.pageBump) {
  //     refineNext();
  //     setPageBump(props.pageBump);
  //   }
  // }, [props.pageBump, pageBump, refineNext]);

  const { setResults } = props;
  React.useEffect(() => {
    setResults({ hits: items, hasMore: !isLastPage });
  }, [items, isLastPage, setResults]);
  return <React.Fragment></React.Fragment>;
}
