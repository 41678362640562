import * as React from 'react';
import { Helmet } from 'react-helmet-async';

import { default as SoehneBuch } from '../fonts/soehne-web-buch.woff';
import { default as SoehneBuch2 } from '../fonts/soehne-web-buch.woff2';
import { default as SoehneKraftig } from '../fonts/soehne-web-kraftig.woff';
import { default as SoehneKraftig2 } from '../fonts/soehne-web-kraftig.woff2';

import { default as Pitch } from '../fonts/pitch-web-semibold.woff';
import { default as Pitch2 } from '../fonts/pitch-web-semibold.woff2';

export const FontFaces = (
  <Helmet>
    <style>
      {`
            @font-face {
                font-family: 'Soehne';
                font-weight: 400;
                font-style: normal;
                font-display: block;
                src: url(${SoehneBuch2}) format('woff2'), url(${SoehneBuch}) format('woff');
            }

            @font-face {
                font-family: 'Soehne';
                font-weight: 500;
                font-style: normal;
                font-display: block;
                src: url(${SoehneKraftig2}) format('woff2'), url(${SoehneKraftig}) format('woff');
            }

            @font-face {
              font-family: 'Pitch';
              font-weight: 600;
              font-style: normal;
              font-display: block;
              src: url(${Pitch2}) format('woff2'), url(${Pitch}) format('woff');
          }
`}
    </style>
  </Helmet>
);
