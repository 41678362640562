import React from 'react'; // eslint-disable-line

import { Footer } from './Footer';
import { useAccount } from '../../contexts/AccountContext';

/** @jsxImportSource @emotion/react */

export const AccountFooter = () => {
  const account = useAccount();
  if (account === undefined) return null;
  if (!account) return null;

  return <Footer />;
};
