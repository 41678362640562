import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const TwitterIcon = (props: {}) => {
  return (
    <svg role="img" aria-labelledby="twitterIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="twitterIconTitleID">Twitter Icon</title>
      <path
        d="M7.91856 20C16.2205 20 20.7613 13.8438 20.7613 8.50515C20.7613 8.3303 20.7613 8.15623 20.7481 7.98295C22.5 7 22.6654 4.87514 22.5 4.05318C21.614 4.52376 20.5366 5.09507 19.5258 5.27346C18.8452 4.62577 17.9452 4.19689 16.9648 4.05318C15.9845 3.90947 14.9786 4.05894 14.1028 4.47848C13.227 4.89801 12.53 5.56421 12.1198 6.37398C11.7097 7.18376 11.6091 8.09196 11.8337 8.95805C10.0391 8.87753 8.28358 8.46013 6.68096 7.73294C5.07835 7.00576 3.6645 5.98505 2.5312 4.73708C1.954 5.62645 1.77722 6.67929 2.03684 7.68123C2.29647 8.68317 2.97298 9.55888 3.92864 10.1301C3.21033 10.111 2.50769 9.93757 1.88 9.62439C1.88 9.64093 1.88 9.65826 1.88 9.67559C1.88029 10.6083 2.24103 11.5123 2.90105 12.2341C3.56107 12.9559 4.47972 13.4512 5.5012 13.6359C4.83669 13.7981 4.13947 13.8218 3.46312 13.7052C3.75155 14.5079 4.31308 15.2099 5.06918 15.7129C5.82528 16.216 6.73814 16.4949 7.68008 16.5107C6.08177 17.6351 4.10733 18.2454 2.07448 18.2436C1.71536 18.2429 1.35658 18.2235 1 18.1853C3.06416 19.3709 5.46592 19.9998 7.91856 19.9968"
        css={[t.stroke_current]}
      />
    </svg>
  );
};
