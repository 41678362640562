import { css } from '@emotion/react';

export const flex = {
  flex_initial: css`
    flex: 0 1 auto;
  `,
  flex0: css`
    flex: 1 1 0;
  `,
  flex1: css`
    flex: 1 1 0%;
  `,
  flex_0_auto: css`
    flex: 0 0 auto;
  `,
  flex_auto: css`
    flex: 1 1 auto;
  `,
  flex_none: css`
    flex: none;
  `,
  flex_grow_2: css`
    flex-grow: 2;
  `,
  flex_row: css`
    flex-direction: row;
  `,
  flex_row_reverse: css`
    flex-direction: row-reverse;
  `,
  flex_col: css`
    flex-direction: column;
  `,
  flex_col_reverse: css`
    flex-direction: column-reverse;
  `,

  flex_no_wrap: css`
    flex-wrap: nowrap;
  `,
  flex_wrap: css`
    flex-wrap: wrap;
  `,
  flex_wrap_reverse: css`
    flex-wrap: wrap-reverse;
  `,

  items_stretch: css`
    align-items: stretch;
  `,
  items_start: css`
    align-items: flex-start;
  `,
  items_center: css`
    align-items: center;
  `,
  items_end: css`
    align-items: flex-end;
  `,
  items_baseline: css`
    align-items: baseline;
  `,

  justify_start: css`
    justify-content: flex-start;
  `,
  justify_center: css`
    justify-content: center;
  `,
  justify_end: css`
    justify-content: flex-end;
  `,
  justify_between: css`
    justify-content: space-between;
  `,
  justify_around: css`
    justify-content: space-around;
  `,
  content_between: css`
    align-content: space-between;
  `,
  content_around: css`
    align-content: space-around;
  `,
  content_start: css`
    align-content: flex-start;
  `,
  content_end: css`
    align-content: flex-end;
  `,
  content_center: css`
    align-content: center;
  `,
  content_stretch: css`
    align-content: stretch;
  `,
};
