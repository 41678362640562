import React from 'react'; // eslint-disable-line

import t from '../../theme/admin/adminStyles';
import { withAdminAccount } from '../../hocs/withAdminAccount';
import { AdminButton } from './components/AdminButton';
import { AdminPage } from './components/AdminPage';
import { FirebaseObjects } from '../../contexts/FirebaseContext';
import { SwitchesDocument, DefaultSwitches, useSwitches } from '../../contexts/SwitchesContext';

/** @jsxImportSource @emotion/react */

function SwitchButton(props: { switchKey: keyof SwitchesDocument; label: string; switches: SwitchesDocument }) {
  const state = props.switches[props.switchKey];
  const toggleSwitch = async () => {
    await FirebaseObjects.adminRequest({ action: 'setSiteSwitch', data: { switchKey: props.switchKey, state: !state } });
  };

  return (
    <div css={[t.p_1]}>
      <AdminButton
        type="button"
        label={`${props.label} [${state ? 'TURN OFF' : 'TURN ON'}]`}
        styleType={state ? 'primary' : 'critical'}
        onClick={toggleSwitch}
      />
    </div>
  );
}

function SwitchesList() {
  let switches = useSwitches();
  if (!switches) switches = DefaultSwitches;

  return (
    <React.Fragment>
      <SwitchButton switchKey="payouts" switches={switches} label="Artist Payments" />
      <SwitchButton switchKey="purchasing" switches={switches} label="Checkout Flow" />
      <SwitchButton switchKey="notifications" switches={switches} label="Notification Emails" />
      <SwitchButton switchKey="verifyPaypal" switches={switches} label="Verify PayPal" />
      <SwitchButton switchKey="noted" switches={switches} label="Noted Program" />
      <SwitchButton switchKey="ratings" switches={switches} label="Ratings and Reviews" />
      <SwitchButton switchKey="perfectMatch" switches={switches} label="Perfect Match" />
    </React.Fragment>
  );
}

const AdminSiteSwitchesComponent = () => {
  return (
    <AdminPage pageHeading="Switches">
      <SwitchesList />
    </AdminPage>
  );
};

export const AdminSiteSwitches = withAdminAccount(AdminSiteSwitchesComponent);
