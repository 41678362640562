import { css } from '@emotion/react';

export const fontWeight = {
  // font_hairline: css`
  //   font-weight: 100;
  // `,
  // font_thin: css`
  //   font-weight: 200;
  // `,
  // font_light: css`
  //   font-weight: 300;
  // `,
  font_normal: css`
    font-weight: 400;
  `,
  font_medium: css`
    font-weight: 500;
  `,
  // font_semibold: css`
  //   font-weight: 600;
  // `,
  // font_bold: css`
  //   font-weight: 700;
  // `,
  // font_extrabold: css`
  //   font-weight: 800;
  // `,
  // font_black: css`
  //   font-weight: 900;
  // `,
};
