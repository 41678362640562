import React from 'react'; // eslint-disable-line
import { useRange, UseRangeProps } from 'react-instantsearch';

/** @jsxImportSource @emotion/react */

type RangeRefinement = { min: number | undefined; max: number | undefined };
// This enables searching with arbitrary strings
export default function HiddenRange(props: UseRangeProps & {
  attribute: string,
  defaultRefinement?: RangeRefinement,
  min: number | undefined,
  max: number | undefined,
  currentRefinement?: RangeRefinement
}) {
  const { start, refine } = useRange(props);
  if (props.min !== start[0] || props.max !== start[1]) {
    refine([props.min, props.max]);
  }

  return <React.Fragment></React.Fragment>;
}
