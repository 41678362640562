export enum palette {
  dark_1 = '#111',
  dark_1_a40 = '#11111166',
  dark_2 = '#303030',
  dark_3 = '#484848',
  dark_4 = '#5f5952',

  primary_1 = '#000D80',
  primary_2 = '#183CA9',
  primary_3 = '#004BDA',
  primary_4 = '#1163FF',
  primary_5 = '#4987ff',

  tint_1 = '#9C9387',
  tint_2 = '#DAD4CC',
  tint_3 = '#F4F0EC',
  tint_3_a50 = '#F4F0EC50',
  tint_4 = '#FBF8F5',
  tint_5 = '#FFFEFC',

  error_1 = '#FF2828',
  error_2 = '#DD0000',

  disabledPage_1 = '#ab7ee4',
}
