import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';

/** @jsxImportSource @emotion/react */

export const AdminRefreshIcon = (props: object) => {
  return (
    <svg width="48" height="39.4" viewBox="0 0 48 39.4" fill="none" {...props}>
      <path
        css={[t.fill_current]}
        d={
          'M28.76,14.46l-2.47,2.46,10.89,10.9L48,17l-2.46-2.46L39.37,20.7A19.72,19.72,0,1,0,19,39.38l.47,0,4.41-.46.37-.08a19.7,19.7,0,0,0,9.36-5.22l-3.11-3.11A15.37,15.37,0,1,1,35,20.67Z'
        }
      />
    </svg>
  );
};
