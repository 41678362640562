import React from 'react'; // eslint-disable-line
import { Link } from 'react-router-dom';
import { FirebaseObjects } from '../../../contexts/FirebaseContext';

import t from '../../../theme/admin/adminStyles';
import { useArtistProfile } from '../../../contexts/ArtistProfileContext';
import { useUserAccount } from '../../../contexts/UserAccountContext';
import { GenreTagDictionary } from '../../../data/ProfileData';
import { AdminActionsPopover } from '../components/AdminActionsPopover';
import { AdminActionsButton } from '../components/AdminActionButton';
import { AdminListButton } from '../components/AdminListButton';
import { MoreDotsIcon } from '../icons/MoreDotsIcons';
import { AdminDetailsDialogInfo } from '../components/Dialogs/AdminDetailsDialogInfo';
import { AdminArtistFeaturedSelect } from './AdminArtistFeaturedSelect';

/** @jsxImportSource @emotion/react */

export const AdminArtistDetailsInfo = () => {
  const [actionsPopover, SetActionsPopover] = React.useState(false);
  const [popoverRect, SetPopoverRect] = React.useState<DOMRect | undefined>(undefined);

  const artistProfile = useArtistProfile();
  const artistAccount = useUserAccount();
  const profileData = artistProfile.Profile;

  if (!profileData) return null;
  if (!artistAccount || artistAccount.deleted) return null;

  const handleDisable = async () => {
    await FirebaseObjects.adminRequest({ action: 'disableArtistPage', data: { artistId: artistAccount.id, disabled: !profileData.pageDisabled } });
  };

  const handleBan = async () => {
    await FirebaseObjects.adminRequest({ action: 'banUser', data: { userId: artistAccount.id, ban: !artistAccount.banned } });
  };

  const handleActionsClick = (rect: DOMRect | undefined, event: React.MouseEvent) => {
    SetActionsPopover(true);
    SetPopoverRect(rect);
  };

  const handleActionsClose = () => {
    SetActionsPopover(false);
  };

  const genres =
    profileData.genreTags &&
    profileData.genreTags.map((value) => {
      return GenreTagDictionary[value];
    });
  return (
    <div css={[t.border_b, t.border_solid, t.border_tint_3, t.pt_5, t.pb_6, t.pl_6, t.flex, t.flex_col]}>
      <div css={[t.flex, t.flex_row]}>
        {profileData.photoURL && (
          <div css={[t.flex_none, t.mr_5]}>
            <img css={[t.w('118px')]} src={profileData.photoURL} alt="Artist Portrait" />
          </div>
        )}
        <div css={[t.flex_auto, t.flex, t.flex_col, t.overflow_x_hidden]}>
          <div css={[t.flex, t.flex_row]}>
            <div css={[t.flex_auto, t.flex, t.flex_row, t.overflow_x_hidden]}>
              <Link
                to={`/artists/${profileData.profileRoute}`}
                target="_blank"
                css={[t.text_2xl, t.text_dark_1, t.font_medium, t.flex, t.items_center, t.truncate, t.no_underline, t.cursor_pointer, t.hover(t.underline)]}
              >
                {profileData.name}
              </Link>
              {profileData.partnered && (
                <div css={[t.flex, t.items_center, t.ml_2]}>
                  <div css={[t.bg_primary_4, t.size('20px'), t.rounded_full]}></div>
                </div>
              )}
            </div>

            <div css={[t.flex0, t.flex, t.flex_row, t.items_center, t.justify_end, t.pr_4]}>
              <AdminActionsButton buttonCSS={[t.ml_3]} onClick={handleActionsClick}>
                <MoreDotsIcon css={[t.size('16px')]} />
              </AdminActionsButton>
            </div>
          </div>
          <a
            href={`mailto:${artistAccount.email}`}
            target="_blank"
            rel="noopener noreferrer"
            css={[t.mt_1, t.text_xs, t.text_tint_1, t.flex, t.items_center, t.cursor_pointer, t.hover(t.text_dark_1)]}
          >
            {artistAccount.email}
          </a>
          <div css={[t.text_xs, t.text_tint_1, t.mt_2]}>{artistAccount.id}</div>
          <div css={[t.flex, t.flex_row, t.flex_wrap, t.mt_3]}>
            {genres && <AdminDetailsDialogInfo label="Genres" text={genres.join(', ')} />}
            {profileData.relatedProjects && profileData.relatedProjects.length > 0 ? (
              <AdminDetailsDialogInfo label="Related Projects" text={profileData.relatedProjects.join(', ')} containerStyle={[t.ml_8]} />
            ) : null}
          </div>
          <div css={[t.mt_3]}>
            <AdminArtistFeaturedSelect />
          </div>
        </div>
      </div>
      {/* Rendered in the Modal Root */}
      {actionsPopover ? (
        <AdminActionsPopover anchor="right" positionRect={popoverRect} onClose={handleActionsClose}>
          {/* {profileData.partnered ? (
            <AdminListButton label={'Unpartner'} onClick={handleUnpartner} />
          ) : (
            <AdminListButton label={'Make Partner'} onClick={handleMakePartner} />
          )} */}
          <AdminListButton label={profileData.pageDisabled ? 'Enable page' : 'Disable page'} onClick={handleDisable} />
          <AdminListButton label={artistAccount.banned ? 'Unban user' : 'Ban user'} critical={true} onClick={handleBan} />
        </AdminActionsPopover>
      ) : null}
    </div>
  );
};
