/** @jsxImportSource @emotion/react */
import React from 'react'; // eslint-disable-line

import t from '../../theme/newstyles';
import { AddCircle } from '../../icons/AddCircle';
import { AddIcon } from '../../icons/AddIcon';
import { ArrowIcon } from '../../icons/ArrowIcon';
import { SearchIcon } from '../../icons/SearchIcon';
import { AspectHorizontalIcon } from '../../icons/AspectHorizontalIcon';
import { FacebookIcon } from '../../icons/FacebookIcon';
import { AspectPortraitIcon } from '../../icons/AspectPortraitIcon';
import { AspectSquareIcon } from '../../icons/AspectSquareIcon';
import { BandcampIcon } from '../../icons/BandcampIcon';
import { CaretIcon } from '../../icons/CaretIcon';
import { CheckboxIcon } from '../../icons/CheckboxIcon';
import { ClearSearchIcon } from '../../icons/ClearSearchIcon';
import { CloseIcon } from '../../icons/CloseIcon';
import { DownloadIcon } from '../../icons/DownloadIcon';
import { DownwriteIcon } from '../../icons/DownwriteIcon';
import { FilterIcon } from '../../icons/FilterIcon';
import { GripIcon } from '../../icons/GripIcon';
import { HamburgerIcon } from '../../icons/HamburgerIcon';
import { InstagramIcon } from '../../icons/InstagramIcon';
import { LogoutIcon } from '../../icons/LogoutIcon';
import { NotificationIcon } from '../../icons/NotificationIcon';
import { PauseIcon } from '../../icons/PauseIcon';
import { PlayIcon } from '../../icons/PlayIcon';
import { PlayPauseIcon } from '../../icons/PlayPauseIcon';
import { ProfileIcon } from '../../icons/ProfileIcon';
import { RefreshIcon } from '../../icons/RefreshIcon';
import { ReorderIcon } from '../../icons/ReorderIcon';
import { RushOrderIcon } from '../../icons/RushOrderIcon';
import { SendIcon } from '../../icons/SendIcon';
import { SpotifyIcon } from '../../icons/SpotifyIcon';
import { SubtractIcon } from '../../icons/SubtractIcon';
import { SuccessCheck } from '../../icons/SuccessCheck';
import { TwitterIcon } from '../../icons/TwitterIcon';
import { UploadIcon } from '../../icons/UploadIcon';
import { VerticalKebab } from '../../icons/VerticalKebab';
import { WebsiteIcon } from '../../icons/WebsiteIcon';
import { YoutubeIcon } from '../../icons/YoutubeIcon';
import { AltArrowIcon } from '../../icons/AltArrowIcon';
import { DeleteIcon } from '../../icons/DeleteIcon';
import { ThumbsUpIcon } from '../../icons/ThumbsUpIcon';
import { ZoomIcon } from '../../icons/ZoomIcon';
import { LinkIcon } from '../../icons/LinkIcon';

export const IconTest = () => {
  return (
    <div css={[t.w_full, t.flex, t.flex_col]}>
      <div css={[t.pb_6]}>
        <div css={[t.flex, t.flex_row, t.flex_wrap]}>
          <Icons />
        </div>
      </div>
      <div css={[t.pb_6]}>
        <div css={[t.flex, t.flex_row, t.flex_wrap, t.text_primary_4]}>
          <Icons />
        </div>
      </div>
    </div>
  );
};

const Icons = () => {
  return (
    <React.Fragment>
      <IconListItem icon={<AddCircle />} />
      <IconListItem icon={<AddIcon />} />
      <IconListItem icon={<ArrowIcon direction="right" />} />
      <IconListItem icon={<ArrowIcon direction="left" />} />
      <IconListItem icon={<ArrowIcon direction="up" />} />
      <IconListItem icon={<ArrowIcon direction="down" />} />
      <IconListItem icon={<AltArrowIcon direction="right" />} />
      <IconListItem icon={<AltArrowIcon direction="left" />} />
      <IconListItem icon={<SearchIcon />} />
      <IconListItem icon={<AspectHorizontalIcon />} />
      <IconListItem icon={<AspectPortraitIcon />} />
      <IconListItem icon={<AspectSquareIcon />} />
      <IconListItem icon={<BandcampIcon />} />
      <IconListItem icon={<CaretIcon direction="up" />} />
      <IconListItem icon={<CaretIcon direction="down" />} />
      <IconListItem icon={<CaretIcon direction="left" />} />
      <IconListItem icon={<CaretIcon direction="right" />} />
      <IconListItem icon={<CheckboxIcon checked={true} />} />
      <IconListItem icon={<ClearSearchIcon />} />
      <IconListItem icon={<CloseIcon />} />
      <IconListItem icon={<DeleteIcon />} />
      <IconListItem icon={<DownloadIcon />} />
      <IconListItem icon={<DownwriteIcon />} />
      <IconListItem icon={<FacebookIcon />} />
      <IconListItem icon={<FilterIcon />} />
      <IconListItem icon={<GripIcon />} />
      <IconListItem icon={<HamburgerIcon />} />
      <IconListItem icon={<InstagramIcon />} />
      <IconListItem icon={<LinkIcon />} />
      <IconListItem icon={<LogoutIcon />} />
      <IconListItem icon={<NotificationIcon />} />
      <IconListItem icon={<NotificationIcon alert={true} />} />
      <IconListItem icon={<PauseIcon />} />
      <IconListItem icon={<PlayIcon />} />
      <IconListItem icon={<PlayPauseIcon />} />
      <IconListItem icon={<ProfileIcon />} />
      <IconListItem icon={<RefreshIcon />} />
      <IconListItem icon={<ReorderIcon direction="up" />} />
      <IconListItem icon={<ReorderIcon direction="down" />} />
      <IconListItem icon={<RushOrderIcon />} />
      <IconListItem icon={<SendIcon />} />
      <IconListItem icon={<SpotifyIcon />} />
      <IconListItem icon={<SubtractIcon />} />
      <IconListItem icon={<SuccessCheck />} />
      <IconListItem icon={<ThumbsUpIcon />} />
      <IconListItem icon={<ThumbsUpIcon liked={true} />} />
      <IconListItem icon={<TwitterIcon />} />
      <IconListItem icon={<UploadIcon />} />
      <IconListItem icon={<VerticalKebab />} />
      <IconListItem icon={<WebsiteIcon />} />
      <IconListItem icon={<YoutubeIcon />} />
      <IconListItem icon={<ZoomIcon direction="in" />} />
      <IconListItem icon={<ZoomIcon direction="out" />} />
    </React.Fragment>
  );
};

const IconListItem = (props: { icon: React.ReactNode }) => {
  return <div css={[t.mr_4, t.mb_4]}>{props.icon}</div>;
};
