import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { AdminRefreshIcon } from '../icons/AdminRefreshIcon';
import { AdminFilterIcon } from '../icons/AdminFilterIcon';
import { AdminCustomSearchBox } from './AdminCustomSearchBox';
import { AdminFilter, AdminFilterDescriptor } from './AdminFilter';

/** @jsxImportSource @emotion/react */

export const AdminListToolbar = (props: { defaultSearch?: string; placeholderSearch?: string; filters: AdminFilterDescriptor[]; onRefresh?: () => void }) => {
  const [showFilterMenu, setShowFilterMenu] = React.useState(false);

  const HandleRefresh = (event: React.MouseEvent<HTMLButtonElement>) => {
    if (props.onRefresh) props.onRefresh();
  };

  const HandleFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
    setShowFilterMenu(!showFilterMenu);
  };

  return (
    <div css={[t.bg_tint_3, t.px_4, t.flex, t.flex_col]}>
      <div css={[t.flex, t.flex_row, t.items_center]}>
        <AdminCustomSearchBox defaultRefinement={props.defaultSearch} placeholder={props.placeholderSearch} />
        <div css={[t.h('56px'), t.min_h('56px'), t.flex, t.flex_row, t.flex_none, t.items_center, t.relative]}>
          <button
            type="button"
            onClick={HandleFilter}
            css={[
              t.w_10,
              t.h_10,
              t.flex,
              t.flex_col,
              t.items_center,
              t.justify_center,
              t.bg_transparent,
              t.cursor_pointer,
              t.text_tint_1,
              t.hover(t.text_dark_1),
            ]}
          >
            <AdminFilterIcon css={[t.size('16px')]} />
          </button>

          <AdminFilter shown={showFilterMenu} filters={props.filters} />
        </div>
        <div css={[t.ml_4, t.flex_none]}>
          <button
            type="button"
            onClick={HandleRefresh}
            css={[
              t.w_10,
              t.h_10,
              t.flex,
              t.flex_col,
              t.items_center,
              t.justify_center,
              t.bg_transparent,
              t.cursor_pointer,
              t.text_tint_1,
              t.hover(t.text_dark_1),
            ]}
          >
            <AdminRefreshIcon css={[t.size('20px')]} />
          </button>
        </div>
      </div>
    </div>
  );
};
