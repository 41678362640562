import React from 'react'; // eslint-disable-line

import { withAdminAccount } from '../../../hocs/withAdminAccount';
import { AdminPage } from '../components/AdminPage';
import { AdminListControl } from '../components/AdminListControl';
import { ListColumnDescriptor } from '../components/AdminListControl';
import { AdminUserSearchHits } from './AdminUserSearchHits';
import { AdminSearchContext } from '../../../contexts/AdminSearchContext';
import { useParams } from 'react-router-dom';

type AdminUserListColumn = 'email' | 'signupDate';

/** @jsxImportSource @emotion/react */

const UserListColumns: ListColumnDescriptor<AdminUserListColumn>[] = [
  { label: 'Name', width: '16%' },
  { name: 'email', label: 'Email', width: '20%' },
  { label: 'Role', width: '78' },
  { label: 'Orders', width: '54' },
  { name: 'signupDate', label: 'Created', width: '110' },
  { label: 'User ID', width: '21%' },
  { width: '66' },
];

const AdminUsersComponent = () => {
  return (
    <AdminSearchContext indexName="USERS">
      <AdminPage pageHeading="Users">
        <AdminUserPageContents />
      </AdminPage>
    </AdminSearchContext>
  );
};

const AdminUserPageContents = () => {
  const [refreshKey, setRefreshKey] = React.useState(0);
  const onRefresh = React.useCallback(() => {
    setRefreshKey(refreshKey + 1);
  }, [refreshKey]);

  const params = useParams<{ userId: string; filters?: string }>();
  const stateFilterDefault = params.filters ? JSON.parse(params.filters) : ['user', 'artist'];
  const filters = [{ label: 'Role', attribute: 'role', options: ['user', 'artist', 'admin', 'deleted'], defaults: stateFilterDefault }];
  return (
    <AdminListControl listColumns={UserListColumns} sortColumn="name" defaultSearch={params.userId} filters={filters} onRefresh={onRefresh}>
      <AdminUserSearchHits key={`search_${refreshKey}`} />
    </AdminListControl>
  );
};

export const AdminUsers = withAdminAccount(AdminUsersComponent);
