import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const ZoomIcon = (props: { direction?: 'in' | 'out' }) => {
  const { direction, ...rest } = props;
  const dir = direction ? direction : 'in';

  switch (dir) {
    case 'in':
      return (
        <svg role="img" aria-labelledby="zoomInIconTitleID" width="16" height="16" viewBox="0 0 16 16" fill="none" {...rest}>
          <title id="zoomInIconTitleID">Zoom In</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0095 7.00516C13.0095 10.3213 10.3213 13.0096 7.00513 13.0096C3.68899 13.0096 1.00073 10.3213 1.00073 7.00516C1.00073 3.68901 3.68899 1.00074 7.00513 1.00074C10.3213 1.00074 13.0095 3.68901 13.0095 7.00516ZM11.5922 12.2998C10.3632 13.3654 8.75947 14.0103 7.00513 14.0103C3.1363 14.0103 0 10.874 0 7.00516C0 3.13632 3.1363 0 7.00513 0C10.874 0 14.0103 3.13632 14.0103 7.00516C14.0103 8.75947 13.3654 10.3632 12.2998 11.5921L16 15.2924L15.2924 16L11.5922 12.2998Z"
            css={[t.fill_current]}
          />
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.5055 4.00293H6.50476V6.50477H4.00293L4.00293 7.50551H6.50476V10.0074H7.5055V7.50551H10.0073V6.50477H7.5055V4.00293Z"
            css={[t.fill_current]}
          />
        </svg>
      );
    case 'out':
      return (
        <svg role="img" aria-labelledby="zoomOutIconTitleID" width="16" height="16" viewBox="0 0 16 16" fill="none" {...rest}>
          <title id="zoomOutIconTitleID">Zoom Out</title>
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M13.0095 7.00516C13.0095 10.3213 10.3213 13.0096 7.00513 13.0096C3.68899 13.0096 1.00073 10.3213 1.00073 7.00516C1.00073 3.68901 3.68899 1.00074 7.00513 1.00074C10.3213 1.00074 13.0095 3.68901 13.0095 7.00516ZM11.5922 12.2998C10.3632 13.3654 8.75947 14.0103 7.00513 14.0103C3.1363 14.0103 0 10.874 0 7.00516C0 3.13632 3.1363 0 7.00513 0C10.874 0 14.0103 3.13632 14.0103 7.00516C14.0103 8.75947 13.3654 10.3632 12.2998 11.5921L16 15.2924L15.2924 16L11.5922 12.2998Z"
            css={[t.fill_current]}
          />
          <path d="M10.0073 6.50488V7.50562L4.00293 7.50562L4.00293 6.50488L10.0073 6.50488Z" css={[t.fill_current]} />
        </svg>
      );
  }
};
