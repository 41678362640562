import { css } from '@emotion/react';
import { content } from './utilities';

export const contentStyles = {
  content: content,

  content_none: css`
    content: none;
  `,
  content_some: css`
    content: '';
  `,
};
