import React, { useEffect } from 'react'; // eslint-disable-line
import { css } from '@emotion/react';
import { motion } from 'framer-motion';

import t from '../../theme/newstyles';

import { Link } from 'react-router-dom';
import { ArtistPageSearchRecord } from '../../types/shared-types';
import { ArtistSample } from '../../contexts/ArtistProfileContext';
import { SongPlayerProps } from '../../components/songPlayer/SongPlayer';
import { Button } from '../../components/Button';
import { PlayIcon } from '../../icons/PlayIcon';
import { NotedBadgeAndTooltip } from '../../components/NotedBadge';

import { useReactSwiper } from '../../components/carousel/ReactSwiper';
import { ProgressBar } from '../../components/ProgressBar';
import { PauseIcon } from '../../icons/PauseIcon';
import { useXL } from '../../contexts/BreakpointContext';
import { useSwitches } from '../../contexts/SwitchesContext';
import { GetPublishableRatingData } from '../../contexts/ArtistRatingsContext';
import { StaticStarIcon } from '../admin/icons/StaticStarIcon';

/** @jsxImportSource @emotion/react */

const SongPlayerFixedUrl = React.lazy(() => import('../../components/songPlayer/SongPlayer'));

function PickSample(samples: { [key: string]: ArtistSample }, preferredGenres: string[]) {
  const sampleKeys = Object.keys(samples);
  const sampleMatches: { [key: string]: number } = {};
  for (const sampleKey of sampleKeys) {
    const sample = samples[sampleKey];
    sampleMatches[sampleKey] = 0;
    if (sample.genres) {
      for (const sampleGenre of sample.genres) {
        if (preferredGenres.includes(sampleGenre)) sampleMatches[sampleKey]++;
      }
    }
  }

  sampleKeys.sort((a, b) => {
    const aMatches = sampleMatches[a];
    const bMatches = sampleMatches[b];
    if (aMatches === bMatches) {
      const aExactMatches = aMatches === preferredGenres.length;
      const bExactMatches = bMatches === preferredGenres.length;

      if (aExactMatches && bExactMatches) {
        return samples[a].order - samples[b].order;
      } else if (aExactMatches) return -1;
      else if (bExactMatches) return 1;
    }
    return bMatches - aMatches;
  });

  return samples[sampleKeys[0]];
}

export function ArtistCard(props: {
  artistPage: ArtistPageSearchRecord;
  preferredSampleGenres: string[];
  sortIndex?: string;
  isVisible?: boolean;
  isEndOfRow?: boolean;
  isDarkMode?: boolean;
  suppressTooltips?: boolean;
}) {
  const { isNoted } = props.artistPage;
  const [hideNotedBadge, setHideNotedBadge] = React.useState(false);

  const switches = useSwitches();
  const sampleSong = PickSample(props.artistPage.samples, props.preferredSampleGenres);

  const isXL = useXL();
  const absoluteOffset = isXL ? '24px' : '36px';

  if (sampleSong)
    return (
      <React.Suspense fallback={<div>&nbsp;</div>}>
        <div css={[t.relative]}>
          {switches && switches.noted && isNoted && isXL && !hideNotedBadge && (
            <span css={[t.absolute, t.top(absoluteOffset), t.right(absoluteOffset), t.z_40]}>
              <NotedBadgeAndTooltip
                label="Noted artist"
                description="Highlighting our best songwriters and musicians."
                iconSize="md"
                tooltipDirection={props.isEndOfRow ? 'left' : undefined}
                suppressTooltips={props.suppressTooltips}
              >
                <Link to="/noted" css={[t.text_white, t.typeStyle_md7, t.ml_1]}>
                  Learn more
                </Link>
              </NotedBadgeAndTooltip>
            </span>
          )}
          {switches && switches.noted && isNoted && !isXL && !hideNotedBadge && (
            <span css={[t.absolute, t.top(absoluteOffset), t.right(absoluteOffset), t.z_40]}>
              <NotedBadgeAndTooltip
                label="Noted artist"
                description="Highlighting our best songwriters and musicians."
                iconSize="lg"
                tooltipDirection={props.isEndOfRow ? 'left' : undefined}
                suppressTooltips={props.suppressTooltips}
              >
                <Link to="/noted" css={[t.text_white, t.typeStyle_md7, t.ml_1]}>
                  Learn more
                </Link>
              </NotedBadgeAndTooltip>
            </span>
          )}
          <SongPlayerFixedUrl url={sampleSong.url} songDuration={sampleSong.duration} viewportAware={true}>
            {(playerProps: SongPlayerProps) => {
              React.useEffect(() => {
                setHideNotedBadge(playerProps.isPlaying);
              }, [playerProps.isPlaying]);
              return (
                <div css={[t.w_full, t.max_w_full, t.flex, t.flex_col]}>
                  <div css={[t.relative, t.flex, t.flex_col]}>
                    <ArtistCardLink artistPage={props.artistPage} isVisible={props.isVisible} />
                    {playerProps.isPlaying && (
                      <div css={[t.absolute, t.inset_0, t.bg_primary_1, t.pointer_events_none]}>
                        <div css={[t.typeStyle_sm2, t.text_tint_5, t.ml_4, t.mr_5, t.mt_5, t.mb_9]}>{sampleSong.name}</div>
                      </div>
                    )}
                    <div css={[t.absolute, t.inset_x_0, t.bottom_0, t.p_4, t.pointer_events_none]}>
                      <div css={[t.flex]}>
                        <Button
                          type="button"
                          icon={playerProps.isPlaying ? <PauseIcon /> : <PlayIcon />}
                          styleType="overlay"
                          onClick={playerProps.handlePlayPause}
                          isInProgress={playerProps.isLoading}
                          tabIndex={props.isVisible !== undefined && !props.isVisible ? -1 : 0}
                          outerStyle={[t.pointer_events_auto]}
                        />
                        {playerProps.isPlaying && (
                          <div css={[t.flex_auto, t.flex, t.flex_col, t.justify_end, t.pb('14px'), t.ml_4]}>
                            <div css={[t.typeStyle_mono, t.text_tint_5, t.mb_1]}>-{playerProps.timeRemaining}</div>
                            <ProgressBar progress={playerProps.percentProgress} backgroundStyle={t.bg_tint_3_a50} barStyle={t.bg_tint_5} />
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <ArtistCardDetails artistPage={props.artistPage} isVisible={props.isVisible} sortIndex={props.sortIndex} isDarkMode={props.isDarkMode} />
                </div>
              );
            }}
          </SongPlayerFixedUrl>
        </div>
      </React.Suspense>
    );
  else
    return (
      <div>
        <ArtistCardLink artistPage={props.artistPage} isVisible={props.isVisible} />
        <ArtistCardDetails artistPage={props.artistPage} isVisible={props.isVisible} sortIndex={props.sortIndex} isDarkMode={props.isDarkMode} />
      </div>
    );
}

export const ArtistCardLink = (props: { artistPage: ArtistPageSearchRecord; isVisible?: boolean }) => {
  const [focus, SetFocus] = React.useState(false);
  const swiper = useReactSwiper();

  function HandleKeyDown(event: React.KeyboardEvent) {
    if (swiper.swiper) {
      if (event.key === 'ArrowRight') swiper.swiper.slideNext();
      else if (event.key === 'ArrowLeft') swiper.swiper.slidePrev();
    }
  }

  return (
    <Link
      to={`/artists/${props.artistPage.objectID}`}
      css={[t.flex, t.flex_col, t.relative, t.outline_none]}
      style={{ textDecoration: 'none' }}
      onFocus={() => SetFocus(true)}
      onBlur={() => SetFocus(false)}
      onKeyDown={HandleKeyDown}
      tabIndex={props.isVisible !== undefined && !props.isVisible ? -1 : 0}
    >
      {focus && <div css={[t.absolute, t.pos('-3px'), t.bg_transparent, t.border_4, t.border_solid, t.border_primary_4, t.opacity_60, t.rounded_sm]}></div>}
      <div css={[t.cursor_pointer, t.flex, t.flex_col, t.relative, t.overflow_hidden]}>
        <motion.img
          whileHover={{ scale: 1.15 }}
          transition={{ duration: 1, ease: t.ease_1 }}
          css={[
            t.w_full,
            t.h_auto,
            !props.artistPage.available &&
            css`
                opacity: 0.4;
              `,
          ]}
          src={props.artistPage.photoURL}
          alt={props.artistPage.name}
          width={300}
          height={400}
        />
      </div>
    </Link>
  );
};

export const ArtistCardDetails = (props: { artistPage: ArtistPageSearchRecord; isVisible?: boolean; sortIndex?: string; isDarkMode?: boolean }) => {
  const [hoverLink, setHoverLink] = React.useState(false);
  const { artistPage, sortIndex } = props;

  const artistAvailable = artistPage.available;
  const hasRelatedProjects = artistPage.relatedProjects.length > 0;
  const relatedProjects = artistPage.relatedProjects;
  const showRelatedProjects = artistAvailable && hasRelatedProjects && sortIndex !== 'ratings';

  const ratingData = GetPublishableRatingData(artistPage.artistRatings ? artistPage.artistRatings : []);
  const { hasRatings, totalRatings, averageRating } = ratingData;
  const showRatings = sortIndex === 'ratings';

  const contrastText = props.isDarkMode ? t.text_white : t.text_black;
  const subtleText = props.isDarkMode ? t.text_white : t.text_tint_2;
  const darkText = props.isDarkMode ? t.text_white : t.text_dark_1;

  return (
    <React.Fragment>
      <Link
        to={`/artists/${artistPage.objectID}`}
        css={[t.flex, t.flex_col, t.max_w_full, t.overflow_x_hidden, t.overflow_y_hidden]}
        style={{ textDecoration: 'none' }}
        tabIndex={-1}
      >
        <div css={[t.mt_4, t.gridTypeStyle_5, t.truncate, artistAvailable ? darkText : t.text_tint_1]}>{artistPage.name}</div>
        {showRelatedProjects && artistAvailable && <div css={[t.gridTypeStyle_7, darkText, t.truncate]}>{relatedProjects.join(' / ')}</div>}
        {!artistAvailable ? <div css={[t.gridTypeStyle_7, t.text_tint_1]}>Artist currently unavailable</div> : null}
      </Link>
      {showRatings && artistAvailable ? (
        <div css={[t.flex, t.items_center, t.gridTypeStyle_7]}>
          <span>
            <StaticStarIcon shouldFill={hasRatings ? true : false} starSize="sm" />
          </span>
          <span css={[t.pl_1]}>
            <span css={[contrastText, t.font_medium]}>{hasRatings ? averageRating : null}</span>
            <span css={[hasRatings ? t.pl_1 : t.pl_0, hoverLink ? t.text_black : subtleText]}>
              {'('}
              <Link
                to={`/artists/${artistPage.objectID}?section=ratings`}
                css={[hoverLink ? t.text_black : subtleText]}
                onMouseEnter={() => setHoverLink(true)}
                onMouseLeave={() => setHoverLink(false)}
              >{`${hasRatings ? totalRatings : 'No'} rating${totalRatings === 1 ? '' : 's'}`}</Link>
              {')'}
            </span>
          </span>
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default ArtistCard;
