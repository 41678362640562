import React from 'react'; // eslint-disable-line
import { css } from '@emotion/react';

import t from '../../../theme/admin/adminStyles';
import { motion } from 'framer-motion';

/** @jsxImportSource @emotion/react */

const flip = css`
  transform: scaleY(-1);
`;
export const AdminCaretIcon = (props: { direction?: 'up' | 'down' | 'left' | 'right' }) => {
  const { direction, ...rest } = props;
  const dir = direction ? direction : 'left';

  switch (dir) {
    case 'up':
      return (
        <div css={[flip]}>
          <motion.svg width="14" height="9" viewBox="0 0 14 9" fill="none" {...rest}>
            <path d="M1 1L7 7L13 1" strokeWidth="2" css={[t.stroke_current]} />
          </motion.svg>
        </div>
      );
    case 'down':
      return (
        <motion.svg width="14" height="9" viewBox="0 0 14 9" fill="none" {...rest}>
          <path d="M1 1L7 7L13 1" strokeWidth="2" css={[t.stroke_current]} />
        </motion.svg>
      );
    case 'right':
      return (
        <motion.svg width="28.12" height="48" viewBox="0 0 28.12 48" fill="none" {...rest}>
          <polygon points="28.12 24 4.12 0 0 4.12 19.88 24 0 43.88 4.12 48 28.12 24" css={[t.fill_current]} />
        </motion.svg>
      );
    default:
      return (
        <motion.svg width="28.12" height="48" viewBox="0 0 28.12 48" fill="none" {...rest}>
          <polygon points="0 24 24 48 28.12 43.88 8.24 24 28.12 4.12 24 0 0 24" css={[t.fill_current]} />
        </motion.svg>
      );
  }
};
