import React from 'react'; // eslint-disable-line
import t from '../theme/newstyles';
import { CreateProfileImageLink } from '../contexts/AccountContext';

/** @jsxImportSource @emotion/react */

export function ProfileImage(props: { userId: string; width?: string; height?: string } & React.ImgHTMLAttributes<HTMLImageElement>) {
  const { userId, ...imgProps } = props;
  const [useSVG, setUseSVG] = React.useState(false);

  const handleError = React.useCallback(() => {
    setUseSVG(true);
  }, []);

  return useSVG ? (
    <div css={[t.min_w(props.width || '100%'), t.min_h(props.height || '100%'), t.bg_primary_3]} />
  ) : (
    <img
      alt=""
      {...imgProps}
      src={CreateProfileImageLink(userId)}
      onError={handleError}
      css={[t.min_w(props.width || '100%'), t.min_h(props.height || '100%')]}
    />
  );
}
