import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { AdminSearchTableCell } from '../components/AdminSearchTableCell';
import { useHits, UseHitsProps } from 'react-instantsearch-core';
import dayjs from 'dayjs';
import { AdminSearchTableRow, AdminSearchRowProps } from '../components/AdminSearchTableRow';
import { AdminOrderDetailsDialog } from './AdminOrderDetailsDialog';
import { OrderPaymentState, OrderState } from '../../../contexts/OrderContext';
import Dinero from 'dinero.js';
import { AdminSearchTableActionCell } from '../components/AdminSearchTableActionCell';
import { AdminIconButton } from '../components/AdminIconButton';
import { PopupIcon } from '../icons/PopupIcon';

/** @jsxImportSource @emotion/react */

interface OrderSearchHit {
  customerName: string;
  artistName: string;
  total: Dinero.DineroObject;
  createdOn: string;
  submittedOn?: string;
  customerId: string;
  artistId: string;
  state: OrderState;
  paymentState: OrderPaymentState;
  flaggedForReview: boolean;
  refunded: boolean;
}

export const AdminOrdersSearchHits = (props: UseHitsProps<OrderSearchHit>) => {
  const [detailsActive, SetDetailsActive] = React.useState(false);
  const [objectID, SetObjectID] = React.useState<string>();
  const { items } = useHits(props);

  function HandleClose(event: React.MouseEvent | React.KeyboardEvent) {
    event.stopPropagation();
    SetDetailsActive(false);
    SetObjectID(undefined);
  }

  return (
    <tbody css={[t.p_0, t.text_sm, t.h_12]}>
      {items.map((hit, i: number) => {
        return (
          <AdminSearchTableRow key={i}>
            {(rowProps: AdminSearchRowProps) => {
              function HandleClick() {
                SetObjectID(hit.objectID);
                SetDetailsActive(true);
              }

              return (
                <React.Fragment>
                  <AdminSearchTableCell>
                    {hit.flaggedForReview && <div css={[t.absolute, t.inset_y_0, t.left_0, t.border_solid, t.border_l_2, t.border_error_1]} />}
                  </AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.customerName}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.artistName}</AdminSearchTableCell>
                  <AdminSearchTableCell>{Dinero(hit.total).toFormat('$0.00')}</AdminSearchTableCell>
                  <AdminSearchTableCell>
                    <div css={[t.capitalize]}>{hit.refunded ? 'Refunded' : hit.state.type}</div>
                  </AdminSearchTableCell>
                  <AdminSearchTableCell>
                    <div css={[t.capitalize]}>{hit.paymentState}</div>
                  </AdminSearchTableCell>
                  <AdminSearchTableCell>{dayjs(hit.createdOn).local().format('MMM DD, YYYY')}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.submittedOn ? dayjs(hit.submittedOn).local().format('MMM DD, YYYY') : null}</AdminSearchTableCell>
                  <AdminSearchTableCell>{hit.objectID}</AdminSearchTableCell>

                  <AdminSearchTableActionCell>
                    {rowProps.isHovered && (
                      <AdminIconButton id="orderDetailsButton" onClick={HandleClick}>
                        <PopupIcon css={[t.size('16px')]} />
                      </AdminIconButton>
                    )}
                  </AdminSearchTableActionCell>
                </React.Fragment>
              );
            }}
          </AdminSearchTableRow>
        );
      })}
      <AdminOrderDetailsDialog isOpen={detailsActive} orderId={objectID} onClose={HandleClose} />
    </tbody>
  );
};
