/** @jsxImportSource @emotion/react */

import React from 'react'; // eslint-disable-line

import t from '../../theme/newstyles';
import * as Yup from 'yup';
import { CaretIcon } from '../../icons/CaretIcon';
import { SimpleSelect } from '../../components/SimpleSelect';
import { TagInput } from '../../components/TagInput';
import { GenreTagDictionary } from '../../data/ProfileData';
import { Form, Formik, FormikHelpers, FormikProps } from 'formik';
import { MultiSelect } from '../../components/MultiSelect';
import { FormCheckbox } from '../../components/FormCheckbox';

const optionList = [
  'apple',
  'avocado',
  'banana',
  'bread',
  'bacon',
  'butter',
  'mango',
  'turkey',
  'ham',
  'flan',
  'chorizo',
  'lettuce',
  'lime',
  'orange',
  'pineapple',
  'peanut',
  'salami',
  'beef',
];

const maxSelections = 5;

const Schema = Yup.object().shape({
  genreTags: Yup.array().required().min(1).max(maxSelections),
});

type TestType = {
  genreTags?: string[] | undefined;
};

interface TestDescriptor {
  single: boolean;
  genreTags: string[];
}

export const SelectTest = () => {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const buttonElement = React.useRef<HTMLDivElement | null>(null);
  const [selected, SetSelected] = React.useState<string | undefined>(undefined);
  const [focusIndex, SetFocusIndex] = React.useState<number | undefined>(undefined);
  const [selectedIndex, SetSelectedIndex] = React.useState<number>(0);
  const [open, SetOpen] = React.useState(false);

  function handleSelectChange(e: React.ChangeEvent<HTMLSelectElement>) {
    SetSelected(e.target.value);
  }

  function handleClick() {
    SetOpen(!open);
    SetSelectedIndex(focusIndex ? focusIndex : 0);
  }

  function handleSubmit(formData: TestType, actions: FormikHelpers<TestType>) {
    console.log(formData);
  }

  function HandleListKeyDown(event: React.KeyboardEvent) {
    //event.preventDefault();
    if (open) {
      switch (event.key) {
        case 'ArrowDown': {
          SetSelectedIndex(selectedIndex === optionList.length - 1 ? selectedIndex : selectedIndex + 1);
          break;
        }
        case 'ArrowUp': {
          SetSelectedIndex(selectedIndex === 0 ? 0 : selectedIndex - 1);
          break;
        }
        case 'Enter':
        case ' ': {
          SetSelected(optionList[selectedIndex]);
          SetFocusIndex(selectedIndex);
          SetOpen(false);
          buttonElement.current?.focus();
          break;
        }
        case 'Escape': {
          SetOpen(false);
          buttonElement.current?.focus();
        }
      }
    } else {
      if (event.key === 'Enter' || event.key === ' ') {
        buttonElement.current?.click();
      }
    }
  }

  const options = optionList.map((value, i) => (
    <option key={`${value}_${i}`} value={value}>
      {value}
    </option>
  ));

  const listOptions = optionList.map((value, i) => {
    function handleOptionClick() {
      SetSelected(value);
      SetFocusIndex(i);
      SetOpen(false);
    }

    return (
      <SelectOption
        key={`${value}_${i}`}
        selected={selectedIndex === i}
        focus={!focusIndex && i === 0 ? true : focusIndex === i}
        label={value}
        onClick={handleOptionClick}
      />
    );
  });

  React.useEffect(() => {
    if (!open) return;

    const handleClick = (event: MouseEvent) => {
      if (ref.current && event.target instanceof Node && !ref.current.contains(event.target)) {
        SetOpen(false);
      }
    };

    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, [open, ref]);

  const initialValues: TestType = {
    genreTags: [],
  };

  return (
    <div css={[t.w_full, t.flex, t.flex_col]}>
      <div css={[t.pb_6]}>
        <div css={[t.gridTypeStyle_4, t.my_5]}>Default</div>
        <div css={[t.flex, t.flex_row, t.flex_wrap]}>
          <div css={[t.mr_6, t.mb_3]}>
            <select css={[t.bg_white, t.border_1, t.border_tint_2, t.border_solid]} onChange={handleSelectChange}>
              {options}
            </select>
          </div>
        </div>
      </div>
      <div css={[t.pb_6]}>
        <div css={[t.gridTypeStyle_4, t.my_5]}>Simple</div>
        <div css={[t.flex, t.flex_row, t.flex_wrap]}>
          <div css={[t.mr_6, t.mb_3]}>
            <SimpleSelect onChange={handleSelectChange} name="test">
              {options}
            </SimpleSelect>
          </div>
        </div>
      </div>
      <div css={[t.pb_6]}>
        <div css={[t.gridTypeStyle_4, t.my_5]}>Styled</div>
        <div css={[t.flex, t.flex_row, t.flex_wrap]}>
          <div css={[t.mr_6, t.mb_3]}>
            <div ref={ref} onKeyDown={HandleListKeyDown}>
              <div
                ref={buttonElement}
                css={[
                  t.min_w('300px'),
                  t.border,
                  t.border_solid,
                  open ? t.border_primary_4 : t.border_tint_2,
                  t.cursor_pointer,
                  t.pl('18px'),
                  t.pr('10px'),
                  t.py('14px'),
                  t.flex,
                  t.flex_row,
                  t.hover(open ? t.border_primary_4 : t.border_tint_1),
                ]}
                role="button"
                tabIndex={0}
                onClick={handleClick}
              >
                <div css={[t.flex_auto, t.typeStyle_lg6, t.text_dark_1]}>{selected ? selected : 'Initial Selection'}</div>
                <div css={[t.flex_initial, open ? t.text_primary_4 : t.text_dark_1]}>
                  <CaretIcon direction={open ? 'up' : 'down'} />
                </div>
              </div>
              {open && (
                <ul css={[t.mt('-1px'), t.pt('7px'), t.pb_4, t.border, t.border_solid, t.border_primary_4, t.z_50, t.overflow_y_scroll, t.max_h('177px')]}>
                  {listOptions}
                </ul>
              )}
            </div>
          </div>
        </div>
      </div>
      <div css={[t.pb_6]}>
        <div css={[t.gridTypeStyle_4, t.my_5]}>Tag Input</div>
        <div css={[t.flex, t.flex_row, t.flex_wrap]}>
          <Formik<TestType> initialValues={initialValues} validationSchema={Schema} onSubmit={handleSubmit} validateOnMount enableReinitialize>
            {(formikProps: FormikProps<TestType>) => {
              return (
                <Form>
                  <TagInput
                    id="GenreTagInput"
                    options={GenreTagDictionary}
                    values={formikProps.values.genreTags}
                    onChange={(newValue: string[]) => formikProps.setFieldValue('genreTags', newValue)}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <div css={[t.pb_6]}>
        <div css={[t.gridTypeStyle_4, t.my_5]}>Multi Option Select</div>
        <div css={[t.flex, t.flex_row, t.flex_wrap]}>
          <Formik<TestType> initialValues={initialValues} validationSchema={Schema} onSubmit={handleSubmit} validateOnMount enableReinitialize>
            {(formikProps: FormikProps<TestType>) => {
              return (
                <Form>
                  <MultiSelect<typeof GenreTagDictionary>
                    id="GenreTagInput"
                    label="Menu label"
                    options={GenreTagDictionary}
                    values={formikProps.values.genreTags}
                    listItemRender={(checkboxProps) => {
                      return (
                        <FormCheckbox<TestDescriptor>
                          name="genreTags"
                          value={checkboxProps.value}
                          checked={checkboxProps.checked}
                          label={checkboxProps.label}
                          focused={checkboxProps.focused}
                          suppressError={true}
                          fullWidth={true}
                        />
                      );
                    }}
                  />
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      <p>Just some extra content to test overlapping</p>
    </div>
  );
};

const SelectOption = (props: { label: string; focus: boolean; selected: boolean; onClick: () => void }) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    if (props.focus) ref.current?.focus();
  }, [props.focus]);

  return (
    <li>
      <div
        ref={ref}
        css={[
          t.px('18px'),
          t.p({ top: '5px', right: '18px', bottom: '7px', left: '18px' }),
          t.typeStyle_lg6,
          t.text_dark_1,
          t.cursor_pointer,
          t.outline_none,
          t.hover([t.bg_tint_3]),
          props.focus ? t.bg_tint_3 : null,
          props.selected ? t.bg_tint_3 : null,
        ]}
        tabIndex={props.focus ? 0 : -1}
        onClick={props.onClick}
      >
        {props.label}
      </div>
    </li>
  );
};
