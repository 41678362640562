import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { withAdminAccount } from '../../../hocs/withAdminAccount';
import { AdminPage } from '../components/AdminPage';
import { AdminListControl } from '../components/AdminListControl';
import { ListColumnDescriptor } from '../components/AdminListControl';
import { AdminOrdersSearchHits } from './AdminOrdersSearchHits';
import { AdminSearchContext } from '../../../contexts/AdminSearchContext';
import { useParams } from 'react-router-dom';
import { AdminLinkTab } from '../components/AdminLinkTab';
import { AdminCard } from '../components/AdminCard';
import dayjs from 'dayjs';
import { AdminDatePicker } from '../components/AdminDatePicker';
import { AdminButton } from '../components/AdminButton';
import { FirebaseObjects } from '../../../contexts/FirebaseContext';

/** @jsxImportSource @emotion/react */

const OrderListColumns: ListColumnDescriptor<string>[] = [
  { width: '2' },
  { label: 'Customer', width: '20%' },
  { label: 'Artist', width: '16%' },
  { label: 'Total', width: '10%' },
  { label: 'Order State', width: '94' },
  { label: 'Payment State', width: '96' },
  { label: 'Requested', width: '110' },
  { label: 'Submitted Date', width: '110' },
  { label: 'Order ID', width: '25%' },
  { width: '66' },
];

export async function DownloadOrdersReport(startDate?: string, endDate?: string) {
  const result = await FirebaseObjects.adminRequest({
    action: 'exportOrders',
    data: { startDate: startDate ? startDate : '', endDate: endDate ? endDate : '' },
  });
  if (result.data.url) {
    const link = document.createElement('a');
    link.href = result.data.url;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}

const AdminOrdersComponent = () => {
  const [startDate, setStartDate] = React.useState<string>(dayjs().subtract(6, 'month').format('YYYY-MM-DD'));
  const [endDate, setEndDate] = React.useState<string>(dayjs().format('YYYY-MM-DD'));
  const [refreshKey, setRefreshKey] = React.useState(0);

  const onStartChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setStartDate(e.target.value);
  }, []);

  const onEndChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setEndDate(e.target.value);
  }, []);

  const onRefresh = React.useCallback(() => {
    setRefreshKey(refreshKey + 1);
  }, [refreshKey]);

  const params = useParams<{ userId: string; filters?: string }>();
  const stateFilterDefault: string[] = params.filters ? JSON.parse(params.filters) : [];
  const reviewFilterDefault: string[] = [];
  const overdueFilterDefault: string[] = [];
  if (stateFilterDefault && stateFilterDefault.includes('flagged')) {
    stateFilterDefault.splice(stateFilterDefault.indexOf('flagged'), 1);
    reviewFilterDefault.push('true');
  }
  if (stateFilterDefault && stateFilterDefault.includes('overdue')) {
    stateFilterDefault.splice(stateFilterDefault.indexOf('overdue'), 1);
    overdueFilterDefault.push('true');
  }

  const filters = [
    {
      label: 'Order Status',
      attribute: 'state',
      options: ['pending', 'active', 'submitted', 'revision', 'completed', 'canceled'],
      defaults: stateFilterDefault,
    },
    {
      label: 'Needs Review',
      attribute: 'flaggedForReview',
      options: ['true', 'false'],
      defaults: reviewFilterDefault,
    },
    {
      label: 'Overdue',
      attribute: 'overdue',
      options: ['true', 'false'],
      defaults: overdueFilterDefault,
    },
  ];

  return (
    <AdminSearchContext indexName="ORDERS">
      <AdminPage
        pageHeading="Orders"
        tabChildren={
          <div css={[t.flex, t.flex_row]}>
            <AdminLinkTab label="Flagged" to={`/admin/orders/["flagged"]`} />
            <AdminLinkTab label="Overdue" to={`/admin/orders/["overdue"]`} />
            <AdminLinkTab label="All Orders" to={`/admin/orders`} />
          </div>
        }
      >
        <AdminListControl
          key={params.filters}
          listColumns={OrderListColumns}
          sortColumn="customer"
          defaultSearch={params.userId}
          filters={filters}
          placeholderSearch="Search by customer, artist, or order id"
          onRefresh={onRefresh}
        >
          <AdminOrdersSearchHits key={`search_${refreshKey}`} />
        </AdminListControl>

        <AdminCard containerStyle={[t.mb_0, t.mt_8]}>
          <div css={[t.p_6]}>
            <div css={[t.text_dark_3, t.text_base, t.pb_2]}>Order Export</div>
            <div css={[t.text_tint_1, t.text_xs, t.pb_4]}>Download a CSV containing information about all orders placed in the date range</div>
            <div css={[t.flex]}>
              <div css={[t.mr_4]}>
                <AdminDatePicker
                  defaultValue={startDate}
                  min={dayjs().subtract(1, 'year').format('YYYY-MM-DD')}
                  max={endDate ? endDate : dayjs().format('YYYY-MM-DD')}
                  onChange={onStartChange}
                />
              </div>

              <div css={[t.mr_4]}>
                <AdminDatePicker defaultValue={endDate} min={startDate} max={dayjs().format('YYYY-MM-DD')} onChange={onEndChange} />
              </div>

              <div css={[t.flex, t.flex_col, t.justify_center]}>
                <AdminButton type="button" label="Export" onClick={() => DownloadOrdersReport(startDate, endDate)} />
              </div>
            </div>
          </div>
        </AdminCard>
      </AdminPage>
    </AdminSearchContext>
  );
};

export const AdminOrders = withAdminAccount(AdminOrdersComponent);
