import React from 'react'; // eslint-disable-line

import t, { CSSStyle } from '../theme/newstyles';
import { motion } from 'framer-motion';

/** @jsxImportSource @emotion/react */

const modalVariants = {
  hidden: {
    opacity: 0,
    y: 0,
    height: '0',
    transitionEnd: {
      display: 'none',
    },
    transition: { delay: 0.05, duration: 0.2 },
  },
  show: { opacity: 1, y: 0, height: 'auto', display: 'flex', transition: { delayChildren: 0.15, staggerChildren: 0.06 } },
};

export const ModalDropdown = (props: {
  isOpen?: boolean;
  children?: React.ReactNode;
  width?: string;
  dark?: boolean;
  containerStyle?: CSSStyle;
  isCompletedOrder?: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { dark = false, isOpen = false, setShowModal } = props;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const isCompletedOrder = props.isCompletedOrder;

  React.useEffect(() => {
    const handleClickOutsideModal = (event: MouseEvent) => {
      if (isOpen && containerRef.current && event.currentTarget instanceof Node && !containerRef.current.contains(event.currentTarget)) {
        setShowModal(false);
      }
    };

    document.addEventListener('click', handleClickOutsideModal);
    return () => {
      document.removeEventListener('click', handleClickOutsideModal);
    };
  }, [setShowModal, isOpen]);

  return (
    <div css={[t.absolute, t.bottom_0, isCompletedOrder ? t.left_0 : t.right_0]}>
      <motion.div
        ref={containerRef}
        css={[
          t.absolute,
          t.top_0,
          isCompletedOrder ? t.left_0 : t.right_0,
          t.z_50,
          t.mt_3,
          props.width ? t.w(props.width) : t.w('156px'),
          props.containerStyle,
        ]}
        initial="hidden"
        animate={isOpen ? 'show' : 'hidden'}
        variants={modalVariants}
      >
        <div
          css={[
            t.flex_auto,
            t.flex,
            t.flex_col,
            t.overflow_hidden,
            dark ? [t.bg_dark_1, t.border_dark_2, t.border_1, t.border_solid] : [t.bg_tint_5, t.shadow_header],
          ]}
        >
          <div css={[t.flex_auto, t.flex, t.flex_col]}>{props.children}</div>
        </div>
      </motion.div>
    </div>
  );
};
