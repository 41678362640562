import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { AdminTextField } from '../components/AdminTextField';
import { Form, Formik, FormikProps } from 'formik';
import { AdminButton } from '../components/AdminButton';
import { ArtistPageProvider, useArtistPage } from '../../../contexts/ArtistPageContext';
import { FirebaseObjects } from '../../../contexts/FirebaseContext';
import { useArtistProfile } from '../../../contexts/ArtistProfileContext';

/** @jsxImportSource @emotion/react */

interface MetaData {
  metaTitle: string;
  metaDescription: string;
}

export const AdminArtistDetailsMetaDataContent = (props: { id: string }) => {
  const [isRequestInFlight, setIsRequestInFlight] = React.useState(false);
  const artistPage = useArtistPage();
  if (!artistPage || !artistPage.Page) return null;
  const page = artistPage.Page;

  const handleSubmit = async (formData: MetaData) => {
    setIsRequestInFlight(true);
    try {
      await FirebaseObjects.adminRequest({
        action: 'editArtistMetaData',
        data: { formData: formData, id: props.id },
      });
      setIsRequestInFlight(false);
    } catch (error) {
      setIsRequestInFlight(false);
      console.log('ERROR', error);
    }
  };

  const formName = 'metaData';

  const initialValues = {
    metaTitle: page.metaData?.metaTitle ? page.metaData?.metaTitle : '',
    metaDescription: page.metaData?.metaDescription ? page.metaData?.metaDescription : '',
  };

  return (
    <div css={[t.border_b, t.border_solid, t.border_tint_3, t.pt_5, t.pb_6, t.pl_6, t.flex, t.flex_col]}>
      <Formik<MetaData> initialValues={initialValues} onSubmit={handleSubmit} enableReinitialize={true}>
        {(formikProps: FormikProps<MetaData>) => {
          return (
            <Form>
              <div css={[t.mb_5, t.max_w('500px')]}>
                <AdminTextField formName={formName} name="metaTitle" label="Meta title" />
              </div>
              <div css={[t.mb_5]}>
                <AdminTextField formName={formName} name="metaDescription" multiline={true} label="Meta description" />
              </div>
              <AdminButton type="button" buttonType="submit" label="Add custom metadata" disabled={isRequestInFlight} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
};

export const AdminArtistDetailsMetaData = (props: { id: string }) => {
  const artist = useArtistProfile();
  const profile = artist.Profile;
  if (!profile) return null;
  const profileRoute = profile.profileRoute;

  return (
    <ArtistPageProvider route={profileRoute || ''}>
      <AdminArtistDetailsMetaDataContent id={props.id} />
    </ArtistPageProvider>
  );
};
