import React from 'react'; // eslint-disable-line

import t, { CSSStyle } from '../theme/newstyles';
import { motion } from 'framer-motion';

/** @jsxImportSource @emotion/react */

export const CloseIcon = (props: { pathStyles?: CSSStyle }) => {
  return (
    <motion.svg aria-labelledby="closeIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="closeIconTitleID">Close Icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.6465 19.3536L4.64648 5.35359L5.35359 4.64648L19.3536 18.6465L18.6465 19.3536Z"
        css={[t.fill_current, props.pathStyles]}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.64648 18.6465L18.6465 4.64648L19.3536 5.35359L5.35359 19.3536L4.64648 18.6465Z"
        css={[t.fill_current, props.pathStyles]}
      />
    </motion.svg>
  );
};
