import React from 'react'; // eslint-disable-line

import t, { CSSStyle } from '../../theme/newstyles';

/** @jsxImportSource @emotion/react */

export function DialogActions(props: { children?: React.ReactNode; containerStyle?: CSSStyle }) {
  return (
    <div
      css={[
        t.px_5,
        t.pb_5,
        t.flex,
        t.flex_col_reverse,
        t.flex_wrap,
        t.sm([t.flex_row]),
        t.md([t.px_7, t.pb_7]),
        t.firstType('div', [t.mb_0]),
        t.lastType('div', [t.mr_0]),
        props.containerStyle,
      ]}
    >
      {React.Children.map(props.children, (child) => (
        <div css={[t.mb_3, t.sm([t.mb_0, t.mr_5]), t.md([t.mr_4])]}>{child}</div>
      ))}
    </div>
  );
}
