import React from 'react'; // eslint-disable-line

/** @jsxImportSource @emotion/react */
import t from '../../theme/newstyles';
import { Button } from '../../components/Button';
import { AddIcon } from '../../icons/AddIcon';
import { DeleteIcon } from '../../icons/DeleteIcon';

export const ButtonTest = () => {
  const HandleClick = () => {
    console.log('click');
  };

  return (
    <div css={[t.w_full, t.flex, t.flex_col]}>
      <div css={[t.pb_6]}>
        <div css={[t.gridTypeStyle_4, t.my_5]}>Primary</div>
        <div css={[t.flex, t.flex_row, t.flex_wrap]}>
          <div css={[t.mr_6, t.mb_3]}>
            <Button type="button" label="Primary button" onClick={HandleClick} />
          </div>
          <div css={[t.mr_6, t.mb_3]}>
            <Button type="button" label="Primary button" onClick={HandleClick} disabled={true} />
          </div>
          <div css={[t.mr_6, t.mb_3]}>
            <Button type="button" icon={<AddIcon />} label="Primary button" onClick={HandleClick} />
          </div>
          <div css={[t.mr_6, t.mb_3]}>
            <Button type="button" icon={<AddIcon />} label="Primary button" onClick={HandleClick} disabled={true} />
          </div>
          <div css={[t.mr_6, t.mb_3]}>
            <Button type="button" label="Primary Color Override" color={t.dark_1} onClick={HandleClick} />
          </div>
        </div>
      </div>
      <div css={[t.pb_6]}>
        <div css={[t.gridTypeStyle_4, t.my_5]}>Secondary</div>
        <div css={[t.flex, t.flex_row, t.flex_wrap]}>
          <div css={[t.mr_6, t.mb_3]}>
            <Button type="button" label="Secondary button" styleType="secondary" onClick={HandleClick} />
          </div>
          <div css={[t.mr_6, t.mb_3]}>
            <Button type="button" label="Secondary button" styleType="secondary" onClick={HandleClick} disabled={true} />
          </div>
          <div css={[t.mr_6, t.mb_3]}>
            <Button type="button" icon={<AddIcon />} label="Secondary button" styleType="secondary" onClick={HandleClick} />
          </div>
          <div css={[t.mr_6, t.mb_3]}>
            <Button type="button" icon={<AddIcon />} label="Secondary button" styleType="secondary" onClick={HandleClick} disabled={true} />
          </div>
          <div css={[t.mr_6, t.mb_3]}>
            <Button type="button" label="Secondary Color Override" styleType="secondary" color={t.primary_4} onClick={HandleClick} />
          </div>
        </div>
      </div>
      <div css={[t.pb_6, t.bg_dark_1]}>
        <div css={[t.gridTypeStyle_4, t.my_5, t.text_tint_5]}>Secondary on dark</div>
        <div css={[t.flex, t.flex_row, t.flex_wrap]}>
          <div css={[t.mr_6, t.mb_3]}>
            <Button type="button" label="Secondary button" styleType="secondaryOnDark" onClick={HandleClick} />
          </div>
          <div css={[t.mr_6, t.mb_3]}>
            <Button type="button" label="Secondary button" styleType="secondaryOnDark" onClick={HandleClick} disabled={true} />
          </div>
          <div css={[t.mr_6, t.mb_3]}>
            <Button type="button" icon={<AddIcon />} label="Secondary button" styleType="secondaryOnDark" onClick={HandleClick} />
          </div>
          <div css={[t.mr_6, t.mb_3]}>
            <Button type="button" icon={<AddIcon />} label="Secondary button" styleType="secondaryOnDark" onClick={HandleClick} disabled={true} />
          </div>
          <div css={[t.mr_6, t.mb_3]}>
            <Button
              type="button"
              label="Secondary Color Override"
              styleType="secondaryOnDark"
              color={t.primary_4}
              hoverColor={t.primary_3}
              activeColor={t.primary_2}
              onClick={HandleClick}
            />
          </div>
        </div>
      </div>
      <div css={[t.pb_8]}>
        <div css={[t.gridTypeStyle_4, t.my_5]}>Icon</div>
        <div css={[t.flex, t.flex_row, t.flex_wrap]}>
          <div css={[t.mr_6]}>
            <Button type="button" icon={<AddIcon />} styleType="secondary" onClick={HandleClick} />
          </div>
          <div css={[t.mr_6]}>
            <Button type="button" icon={<AddIcon />} styleType="secondary" onClick={HandleClick} disabled={true} />
          </div>
          <div css={[t.mr_6]}>
            <Button type="button" icon={<AddIcon />} styleType="secondary" color={t.primary_4} onClick={HandleClick} />
          </div>
          <div css={[t.mr_6]}>
            <Button type="button" icon={<AddIcon />} styleType="secondary" smallIcon={true} onClick={HandleClick} />
          </div>
        </div>
      </div>
      <div css={[t.pb_8]}>
        <div css={[t.gridTypeStyle_4, t.my_5]}>Delete</div>
        <div css={[t.flex, t.flex_row]}>
          <div css={[t.mr_6]}>
            <Button type="button" icon={<DeleteIcon />} styleType="critical" label="Delete" onClick={HandleClick} />
          </div>
          <div css={[t.mr_6]}>
            <Button type="button" icon={<DeleteIcon />} styleType="critical" label="Delete" onClick={HandleClick} disabled={true} />
          </div>
        </div>
      </div>
      <div css={[t.pb_8]}>
        <div css={[t.gridTypeStyle_4, t.my_5]}>In a row</div>
        <div css={[t.flex, t.flex_row]}>
          <div css={[t.mr_3]}>
            <Button type="link" label="Primary button" to="/" />
          </div>
          <div css={[t.mr_3]}>
            <Button type="input" inputType="file" formName="input" label="Input button" />
          </div>
          <div css={[t.mr_3]}>
            <Button type="input" inputType="file" formName="input" disabled={true} label="Input button" />
          </div>
          <div css={[t.mr_3]}>
            <Button type="button" icon={<AddIcon />} label="Primary button" onClick={HandleClick} />
          </div>
          <div css={[t.mr_3]}>
            <Button type="link" icon={<AddIcon />} label="Secondary button" styleType="secondary" to="/" />
          </div>
          <div css={[t.mr_3]}>
            <Button type="input" inputType="file" formName="test" icon={<AddIcon />} styleType="secondary" />
          </div>
          <div css={[t.mr_3]}>
            <Button type="button" icon={<DeleteIcon />} styleType="critical" label="Delete" onClick={HandleClick} />
          </div>
        </div>
      </div>
    </div>
  );
};
