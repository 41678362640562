import React from 'react'; // eslint-disable-line

import t from '../../../../theme/admin/adminStyles';
import { CSSStyle } from '../../../../theme/newstyles';

/** @jsxImportSource @emotion/react */

export function AdminDialogTitle(props: { id?: string; children?: React.ReactNode; style?: CSSStyle }) {
  return (
    <h1 id={props.id} css={[t.pl_5, t.pr_8, t.py_4, props.style]}>
      {props.children}
    </h1>
  );
}
