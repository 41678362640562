import React, { Children, ReactNode } from 'react'; // eslint-disable-line

import t from '../../theme/newstyles';
import { isEmptyChildren } from 'formik';

/** @jsxImportSource @emotion/react */

export const HeaderIcons = (props: { children?: ReactNode }) => {
  if (isEmptyChildren(props.children)) return null;
  return (
    <div css={[t.flex, t.flex_row, t.flex_initial, t.ml_5, t.firstType('div', t.ml_0)]}>
      {Children.toArray(props.children).map((child, i) => {
        return (
          <div key={i} css={[t.flex, t.ml_4]}>
            {child}
          </div>
        );
      })}
    </div>
  );
};
