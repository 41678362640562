import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const InputErrorInline = (props: { id?: string; children?: React.ReactNode }) => {
  if (!props.children) return null;
  return (
    <div aria-live="polite" role="status" css={[t.text_error_1, t.typeStyle_lg7, t.mt_2]}>
      <span id={props.id}>{props.children}</span>
    </div>
  );
};
