import { css } from '@emotion/react';

export const gridColumn = {
  col_auto: css`
    grid-column: auto;
  `,
  col_span_1: css`
    grid-column: span 1 / span 1;
  `,
  col_span_2: css`
    grid-column: span 2 / span 2;
  `,
  col_span_3: css`
    grid-column: span 3 / span 3;
  `,
  col_span_4: css`
    grid-column: span 4 / span 4;
  `,
  col_span_5: css`
    grid-column: span 5 / span 5;
  `,
  col_span_6: css`
    grid-column: span 6 / span 6;
  `,
  col_span_7: css`
    grid-column: span 7 / span 7;
  `,
  col_span_8: css`
    grid-column: span 8 / span 8;
  `,
  col_span_9: css`
    grid-column: span 9 / span 9;
  `,
  col_span_10: css`
    grid-column: span 10 / span 10;
  `,
  col_span_11: css`
    grid-column: span 11 / span 11;
  `,
  col_span_12: css`
    grid-column: span 12 / span 12;
  `,

  col_start_1: css`
    grid-column-start: 1;
  `,
  col_start_2: css`
    grid-column-start: 2;
  `,
  col_start_3: css`
    grid-column-start: 3;
  `,
  col_start_4: css`
    grid-column-start: 4;
  `,
  col_start_5: css`
    grid-column-start: 5;
  `,
  col_start_6: css`
    grid-column-start: 6;
  `,
  col_start_7: css`
    grid-column-start: 7;
  `,
  col_start_8: css`
    grid-column-start: 8;
  `,
  col_start_9: css`
    grid-column-start: 9;
  `,
  col_start_10: css`
    grid-column-start: 10;
  `,
  col_start_11: css`
    grid-column-start: 11;
  `,
  col_start_12: css`
    grid-column-start: 12;
  `,
  col_start_auto: css`
    grid-column-start: auto;
  `,

  col_end_1: css`
    grid-column-end: 1;
  `,
  col_end_2: css`
    grid-column-end: 2;
  `,
  col_end_3: css`
    grid-column-end: 3;
  `,
  col_end_4: css`
    grid-column-end: 4;
  `,
  col_end_5: css`
    grid-column-end: 5;
  `,
  col_end_6: css`
    grid-column-end: 6;
  `,
  col_end_7: css`
    grid-column-end: 7;
  `,
  col_end_8: css`
    grid-column-end: 8;
  `,
  col_end_9: css`
    grid-column-end: 9;
  `,
  col_end_10: css`
    grid-column-end: 10;
  `,
  col_end_11: css`
    grid-column-end: 11;
  `,
  col_end_12: css`
    grid-column-end: 12;
  `,
  col_end_auto: css`
    grid-column-end: auto;
  `,
};
