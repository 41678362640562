import React from 'react'; // eslint-disable-line

import { motion } from 'framer-motion';
import t, { CSSStyle } from '../theme/newstyles';
import { MotionIntroToken } from '../theme/motionUtilities';

/** @jsxImportSource @emotion/react */

export enum AspectRatio {
  Unknown = 'unknown',
  Portrait = 'portrait',
  Square = 'square',
  Landscape = 'landscape',
}

export function GetAspectRatio(width: number, height: number): AspectRatio {
  if (width === 0 || height === 0) return AspectRatio.Unknown;
  if (width === height) return AspectRatio.Square;
  if (width > height) return AspectRatio.Landscape;
  return AspectRatio.Portrait;
}

export interface CollageImageProps {
  src: string;
  index: number;
  focusIndex: number;
  imageCount: number;
}

export function CollageImage(props: {
  src: string;
  alt?: string;
  index?: number;
  focusIndex?: number;
  imageCount: number;
  ignoreAspect?: boolean;
  imageStyle?: CSSStyle;
  motionToken?: MotionIntroToken;
}) {
  const [aspectRatio, SetAspectRatio] = React.useState<AspectRatio>(AspectRatio.Unknown);

  const onImageLoaded = React.useCallback((event: React.SyntheticEvent<HTMLImageElement, Event>) => {
    SetAspectRatio(GetAspectRatio(event.currentTarget.naturalWidth, event.currentTarget.naturalHeight));
  }, []);

  let width = t.w_full;
  if (props.imageCount === 2) {
    width = t.w('90%');
    if (!props.ignoreAspect && (aspectRatio === AspectRatio.Landscape || aspectRatio === AspectRatio.Square)) width = t.w('95%');
  } else if (props.imageCount === 3) {
    width = t.w('80%');
    if (!props.ignoreAspect && aspectRatio === AspectRatio.Landscape) width = t.w('90%');
  }

  const zIndices = [t.z_30, t.z_10, t.z_20];
  let zIndex: CSSStyle | null = null;
  if (props.focusIndex !== undefined && props.index !== undefined) {
    if (props.focusIndex === props.index) {
      zIndex = zIndices[0];
    } else zIndex = zIndices[props.index];
  }

  const motionOffset = (props.index ? props.index : 0) * 0.15;
  const motionToken: MotionIntroToken = props.motionToken ? props.motionToken : t.motion_reveal_1;

  return (
    <motion.img
      {...motionToken}
      custom={t.override({ duration: 1.4 - motionOffset, delay: 0.1 + motionOffset })}
      css={[t.border_2, t.border_solid, t.border_tint_5, width, zIndex, props.index === 1 && t.h_full, props.imageStyle]}
      src={props.src}
      alt={props.alt}
      onLoad={onImageLoaded}
    />
  );
}
