import React from 'react'; // eslint-disable-line

import t from '../../../../theme/admin/adminStyles';
import { CSSStyle } from '../../../../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const AdminDetailsDialogInfo = (props: { label?: string; text?: string; children?: React.ReactNode; containerStyle?: CSSStyle }) => {
  return (
    <div css={[t.flex, t.flex_col, props.containerStyle]}>
      {props.label && <div css={[t.text_xs, t.text_tint_1, t.font_normal]}>{props.label}</div>}
      {props.text && <div css={[t.text_xs, t.text_dark_4, t.mt_1]}>{props.text}</div>}
      {props.children}
    </div>
  );
};
