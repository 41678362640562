import React from 'react'; // eslint-disable-line

import dayjs from 'dayjs';
import t from '../../../theme/admin/adminStyles';
import { useUserAccount } from '../../../contexts/UserAccountContext';
import { FirebaseObjects } from '../../../contexts/FirebaseContext';
import { MoreDotsIcon } from '../icons/MoreDotsIcons';
import { AdminActionsPopover } from '../components/AdminActionsPopover';
import { AdminActionsButton } from '../components/AdminActionButton';
import { AdminListButton } from '../components/AdminListButton';
import { AdminDetailsDialogInfo } from '../components/Dialogs/AdminDetailsDialogInfo';
import { AdminDetailsLinkButton } from '../components/Dialogs/AdminDetailsLinkButton';
import { UserRole } from './AdminUserSearchHits';
import { AdminDialog } from '../components/Dialogs/AdminDialog';
import { AdminDialogTitle } from '../components/Dialogs/AdminDialogTitle';
import { AdminButton } from '../components/AdminButton';

/** @jsxImportSource @emotion/react */

export const AdminUserDetailsInfo = (props: { userRole: UserRole }) => {
  const [showConfirmDelete, setShowConfirmDelete] = React.useState(false);
  const [actionsPopover, SetActionsPopover] = React.useState(false);
  const [popoverRect, SetPopoverRect] = React.useState<DOMRect | undefined>(undefined);

  const user = useUserAccount();
  if (!user) return null;
  if (user.deleted) return <div>[USER DELETED]]</div>;

  const handleBan = async () => {
    await FirebaseObjects.adminRequest({ action: 'banUser', data: { userId: user.id, ban: !user.banned } });
  };

  const handleActionsClick = (rect: DOMRect | undefined, event: React.MouseEvent) => {
    SetActionsPopover(true);
    SetPopoverRect(rect);
  };

  const handleActionsClose = () => {
    SetActionsPopover(false);
  };

  const toggleConfirmDelete = () => {
    SetActionsPopover(false);
    setShowConfirmDelete(!showConfirmDelete);
  };

  const handleDialogClose = () => {
    setShowConfirmDelete(false);
  };

  return (
    <div css={[t.border_b, t.border_solid, t.border_tint_3, t.pt_5, t.pb_8, t.pl_6, t.flex, t.flex_col]}>
      <ConfirmUserDeleteDialog isOpen={showConfirmDelete} handleClose={handleDialogClose} />

      <div css={[t.flex, t.flex_row]}>
        {user.profileImage && (
          <div css={[t.flex_none, t.mr_5]}>
            <img css={[t.w('118px')]} src={user.profileImage} alt="User Profile" />
          </div>
        )}
        <div css={[t.flex_auto]}>
          <div css={[t.flex, t.flex_row]}>
            <div css={[t.text_2xl, t.text_dark_1, t.font_medium]}>{user.firstname && user.lastname ? `${user.firstname} ${user.lastname}` : user.email}</div>

            <div css={[t.flex_auto, t.flex, t.flex_row, t.items_center, t.justify_end, t.pr_4]}>
              <AdminActionsButton buttonCSS={[t.ml_3]} onClick={handleActionsClick}>
                <MoreDotsIcon css={[t.size('16px')]} />
              </AdminActionsButton>
            </div>
          </div>

          <a
            href={`mailto:${user.email}`}
            target="_blank"
            rel="noopener noreferrer"
            css={[t.mt_1, t.text_xs, t.text_tint_1, t.flex, t.items_center, t.cursor_pointer, t.hover(t.text_dark_1)]}
          >
            {user.email}
          </a>
          <div css={[t.text_xs, t.text_tint_1, t.mt_2]}>{user.id}</div>

          {props.userRole === UserRole.artist && (
            <div css={[t.mt_3]}>
              <AdminDetailsLinkButton to={`/admin/artists/${user.id}`}>View artist details</AdminDetailsLinkButton>
            </div>
          )}
          <div css={[t.flex, t.flex_row, t.flex_wrap, t.mt_4]}>
            <AdminDetailsDialogInfo label="Signed Up" text={dayjs(user.signupDate).format('MMM DD, YYYY')} />
            <AdminDetailsDialogInfo label="Last Seen" text={dayjs(user.lastNotificationSeenTime).format('MMM DD, YYYY')} containerStyle={[t.ml_8]} />
          </div>
        </div>
      </div>
      {/* Rendered in the Modal Root */}
      {actionsPopover ? (
        <AdminActionsPopover anchor="right" positionRect={popoverRect} onClose={handleActionsClose}>
          <AdminListButton label={user.banned ? 'Unban user' : 'Ban user'} critical={true} onClick={handleBan} />
          <AdminListButton label={'Delete user'} critical={true} onClick={toggleConfirmDelete} />
        </AdminActionsPopover>
      ) : null}
    </div>
  );
};

function ConfirmUserDeleteDialog(props: { isOpen: boolean; handleClose: () => void }) {
  const [errorString, setErrorString] = React.useState('');
  const user = useUserAccount();
  if (!user) return null;

  const handleDelete = async () => {
    const request = await FirebaseObjects.adminRequest({ action: 'deleteAccountByUid', data: { uid: user.id } });
    const data: { success: boolean; reason?: 'customer_order' | 'artist_order' | 'featured' } = request.data;
    if (!data.success) {
      switch (data.reason) {
        case 'customer_order':
          setErrorString('Failed. Outstanding order as a customer. Try again when the order has been completed or canceled.');
          break;
        case 'artist_order':
          setErrorString('Failed. Outstanding order as an artist. Try again when the order has been completed or canceled.');
          break;
        case 'featured':
          setErrorString('Failed. Artist is currently featured.');
          break;
      }
    } else {
      setErrorString('');
    }
  };

  return (
    <AdminDialog
      isOpen={props.isOpen}
      onRequestClose={props.handleClose}
      dialogStyle={[t.w('440px'), t.max_w('440px')]}
      buttonStyle={[t.text_tint_5, t.hover(t.text_tint_5)]}
    >
      <AdminDialogTitle style={[t.bg_error_1, t.text_tint_5]}>{`Delete ${user.firstname} ${user.lastname} (${user.email})?`}</AdminDialogTitle>
      <div css={[t.px_5, t.pt_6, t.pb_5]}>
        <div css={[t.text_dark_3]}>Careful now. This is a permanent action.</div>
        <div css={[t.mt_6]}>
          <AdminButton type="button" styleType="critical" label="DELETE USER" onClick={handleDelete} />
        </div>
        {errorString ? <div>{errorString}</div> : null}
      </div>
    </AdminDialog>
  );
}
