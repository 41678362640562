import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { useUserAccount } from '../../../contexts/UserAccountContext';
import { UserRole } from './AdminUserSearchHits';

/** @jsxImportSource @emotion/react */

export const AdminUserDetailsHeader = (props: { userRole: UserRole }) => {
  const user = useUserAccount();
  if (!user) return null;

  let statusElement: React.ReactNode | null = null;
  const sharedStatusStyle = [t.flex, t.flex_row, t.items_center, t.text_tint_5, t.uppercase, t.h_full, t.px_6];
  if (user.deleted) {
    statusElement = <div css={[sharedStatusStyle, t.bg_error_2]}>Deleted</div>;
  } else if (user.banned) {
    statusElement = <div css={[sharedStatusStyle, t.bg_error_2]}>Banned</div>;
  }

  let roleElement: React.ReactNode | null = null;
  switch (props.userRole) {
    case 'admin': {
      roleElement = <div css={[sharedStatusStyle, t.bg_color('#ffc107')]}>Admin</div>;
      break;
    }
    case 'artist': {
      roleElement = <div css={[sharedStatusStyle, t.bg_primary_4]}>Artist</div>;
      break;
    }
    case 'deleted': {
      roleElement = <div css={[sharedStatusStyle, t.bg_error_1]}>Deleted</div>;
      break;
    }
  }

  return (
    <div css={[t.flex, t.flex_row, t.items_center, t.text_sm, t.h('55px'), t.border_b, t.border_solid, t.border_tint_3]}>
      {statusElement}
      {roleElement}
    </div>
  );
};
