import React from 'react'; // eslint-disable-line
import { FirebaseSDK, FirebaseObjects } from '../contexts/FirebaseContext';
import firebase from 'firebase/compat/app';

export interface FeaturedArtist {
  id: string;
  name: string;
  sampleId?: string;
}

export interface FeaturedArtistContent {
  featured: FeaturedArtist[];
}

const FeaturedArtistsContentContext = React.createContext<FeaturedArtistContent | null>(null);
export function FeaturedArtistsContentProvider(props: { children?: React.ReactNode }) {
  const [state, setState] = React.useState<FeaturedArtistContent | null>(null);
  React.useEffect(() => {
    const observer = (snapshot: firebase.firestore.DocumentSnapshot) => {
      const content = snapshot.data() as FeaturedArtistContent;
      setState(content);
    };

    const error = (error: Error) => {
      console.error(error);
    };

    const discoveryDoc = FirebaseSDK.firestore().doc(`/content/featured`);
    return discoveryDoc.onSnapshot(observer, error);
  }, []);
  return <FeaturedArtistsContentContext.Provider value={state}>{props.children}</FeaturedArtistsContentContext.Provider>;
}

export function useFeaturedArtistsContent() {
  return React.useContext(FeaturedArtistsContentContext);
}

export async function PublishFeaturedArtists() {
  await FirebaseObjects.adminRequest({ action: 'publishFeaturedArtists', data: {} });
}

export async function AddFeaturedArtist(id: string) {
  await FirebaseObjects.adminRequest({ action: 'editFeaturedArtists', data: { feature: { action: 'addArtist', id } } });
}

export async function RemoveFeaturedArtist(id: string) {
  await FirebaseObjects.adminRequest({ action: 'editFeaturedArtists', data: { feature: { action: 'removeArtist', id } } });
}

export async function MoveFeaturedArtist(id: string, direction: 'up' | 'down') {
  await FirebaseObjects.adminRequest({ action: 'editFeaturedArtists', data: { feature: { action: 'moveArtist', id, direction } } });
}
