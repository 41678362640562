import React from 'react'; // eslint-disable-line

import { Tag } from '../../../components/Tag';

/** @jsxImportSource @emotion/react */

export function AdminTag(props: {
  formName: string;
  name: string;
  label: string;
  value: string;
  checked?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent) => void;
}) {
  return <Tag {...props} />;
}
