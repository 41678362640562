import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const ReorderIcon = (props: { direction?: 'down' | 'up' }) => {
  const { direction, ...rest } = props;
  const dir = direction ? direction : 'up';

  switch (dir) {
    case 'down':
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
          <path
            css={[t.fill_current]}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7.1001 13.9L12.0001 18.8L16.9001 13.9L16.2001 13.2L12.6001 16.8V7H11.6001V16.8L8.0001 13.2L7.1001 13.9Z"
          />
        </svg>
      );
    default:
      return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
          <path
            css={[t.fill_current]}
            fillRule="evenodd"
            clipRule="evenodd"
            d="M16.9016 10.1008L12.0016 5.30078L7.10156 10.2008L7.80156 10.9008L11.4016 7.30078V17.0008H12.4016V7.20078L16.0016 10.8008L16.9016 10.1008Z"
          />
        </svg>
      );
  }
};
