import React from 'react'; // eslint-disable-line
import { css } from '@emotion/react';

import t, { CSSStyle } from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

const borderRadius = css`
  border-radius: 50px;
`;

export function Tag<T>(props: {
  formName: string;
  name: keyof T;
  value: keyof T;
  label?: string;
  large?: boolean;
  checked?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent) => void;
  labelStyle?: CSSStyle;
}) {
  const inputElement = React.useRef<HTMLInputElement | null>(null);
  const fieldId = `field-${String(props.formName)}-${String(props.name)}${props.value && `-${String(props.value)}`}`;

  const HandleKeyPress = (event: React.KeyboardEvent) => {
    event.stopPropagation();
    if (event.key === ' ') {
      if (inputElement.current) {
        inputElement.current.click();
      }
    }
  };

  const HandleBlur = (event: React.FocusEvent) => {
    if (inputElement.current) {
      inputElement.current.blur();
    }
  };

  function HandleInnerFocus(event: React.FocusEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  return (
    <div
      css={[
        t.relative,
        t.outline_none,
        t.inline_flex,
        t.hoverSelector('> label', props.large ? [t.border_dark_1] : [t.border_primary_4, t.text_primary_4]),
        t.activeSelector('> label', [t.text_primary_2, t.border_primary_2]),
        t.focusSelector('> label::before', t.content_some),
      ]}
      tabIndex={0}
      onKeyPress={HandleKeyPress}
      onBlur={HandleBlur}
    >
      <input
        ref={inputElement}
        tabIndex={-1}
        css={[
          t.hidden_input,
          t.outline_none,
          t.disabledSelector('+ label', [t.text_tint_2, t.border_tint_2, t.cursor_not_allowed]),
          t.checkedSelector('+ label', [t.text_primary_4, t.border_primary_4]),
        ]}
        id={fieldId}
        type="checkbox"
        name={props.name as string}
        value={props.value as string}
        checked={props.checked}
        disabled={props.disabled}
        readOnly={props.readonly}
        aria-describedby={fieldId}
        onChange={props.onChange}
        onBlur={props.onBlur}
        onFocus={HandleInnerFocus}
      />
      <label
        tabIndex={-1}
        css={[
          t.inline_flex,
          t.items_center,
          t.cursor_pointer,
          t.text_dark_1,
          t.outline_none,
          t.select_none,
          props.large ? t.px_5 : t.px_4,
          props.large ? t.py('8px') : [t.pt('5px'), t.pb('7px')],
          props.large ? t.border_tint_3 : t.border_dark_1,
          t.border_1,
          t.border_solid,
          borderRadius,
          t.before([t.focusIndicator, t.border_primary_4, t.pos('-3px'), borderRadius, t.content_none]),
          props.labelStyle,
        ]}
        htmlFor={fieldId}
        onFocus={HandleInnerFocus}
      >
        <div css={[props.large ? t.typeStyle_lg5 : t.typeStyle_lg7, props.large ? [t.pt('2px'), t.pb('6px')] : null]}>{props.label}</div>
      </label>
    </div>
  );
}
