import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const ThumbsUpIcon = (props: { liked?: boolean }) => {
  const { liked, ...rest } = props;

  if (liked) {
    return (
      <svg role="img" aria-labelledby="thumbsUpIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
        <title id="thumbsUpIconTitleID">Thumbs Up Icon</title>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M16.9196 19.4832L16.8869 19.5H9V11.118L12.809 3.5H14.9098L13.9098 9.5H20V14C20 16.1055 19.1861 17.4875 18.3845 18.3463C17.9806 18.7791 17.577 19.0816 17.2765 19.2747C17.1265 19.3711 17.0031 19.4397 16.9196 19.4832ZM8 11.5H4.5V19.5H8V11.5Z"
          css={[t.fill_current]}
        />
      </svg>
    );
  } else {
    return (
      <svg role="img" aria-labelledby="thumbsUpIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...rest}>
        <title id="thumbsUpIconTitleID">Thumbs Up Icon</title>
        <path d="M20.5 14C20.5 18.5 17 20 17 20H4V11H8.5L12.5 3H15.5L14.5 9H20.5V14Z" css={[t.stroke_current]} />
        <path d="M8.5 11V20" css={[t.stroke_current]} />
      </svg>
    );
  }
};
