import { css } from '@emotion/react';

export const whitespace = {
  whitespace_normal: css`
    white-space: normal;
  `,
  whitespace_no_wrap: css`
    white-space: nowrap;
  `,
  whitespace_pre: css`
    white-space: pre;
  `,
  whitespace_pre_line: css`
    white-space: pre-line;
  `,
  whitespace_pre_wrap: css`
    white-space: pre-wrap;
  `,
};
