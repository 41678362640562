/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react'

import React from 'react';
import t from '../../theme/newstyles';

const indexStyle = [
  t.flex,
  t.justify_end,
  t.mr_5,
  t.flex_none,
  css`
        width: 120px;
    `,
];

export const TypeTest = () => {
  return (
    <div css={[t.w_full, t.flex, t.flex_col]}>
      <Typescale />
      <Typestyles />
      <TypeComponents />
    </div>
  );
};

const Typescale = () => {
  return (
    <div css={[t.pb_8]}>
      <div css={[t.typeStyle_lg3, t.my_5]}>Typescale</div>
      <div css={[t.text_22, t.flex, t.flex_row]}>
        <div css={indexStyle}>22</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_21, t.flex, t.flex_row]}>
        <div css={indexStyle}>21</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_20, t.flex, t.flex_row]}>
        <div css={indexStyle}>20</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_19, t.flex, t.flex_row]}>
        <div css={indexStyle}>19</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_18, t.flex, t.flex_row]}>
        <div css={indexStyle}>18</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_17, t.flex, t.flex_row]}>
        <div css={indexStyle}>17</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_16, t.flex, t.flex_row]}>
        <div css={indexStyle}>16</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_15, t.flex, t.flex_row]}>
        <div css={indexStyle}>15</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_14, t.flex, t.flex_row]}>
        <div css={indexStyle}>14</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_13, t.flex, t.flex_row]}>
        <div css={indexStyle}>13</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_12, t.flex, t.flex_row]}>
        <div css={indexStyle}>12</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_11, t.flex, t.flex_row]}>
        <div css={indexStyle}>11</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_10, t.flex, t.flex_row]}>
        <div css={indexStyle}>10</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_9, t.flex, t.flex_row]}>
        <div css={indexStyle}>9</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_8, t.flex, t.flex_row]}>
        <div css={indexStyle}>8</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_7, t.flex, t.flex_row]}>
        <div css={indexStyle}>7</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_6, t.flex, t.flex_row]}>
        <div css={indexStyle}>6</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_5, t.flex, t.flex_row]}>
        <div css={indexStyle}>5</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_4, t.flex, t.flex_row]}>
        <div css={indexStyle}>4</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_3, t.flex, t.flex_row]}>
        <div css={indexStyle}>3</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_2, t.flex, t.flex_row]}>
        <div css={indexStyle}>2</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_1, t.flex, t.flex_row]}>
        <div css={indexStyle}>1</div>Music distributor, Forced Exposure
      </div>
      <div css={[t.text_0, t.flex, t.flex_row]}>
        <div css={indexStyle}>0</div>Music distributor, Forced Exposure
      </div>
    </div>
  );
};

const Typestyles = () => {
  return (
    <div css={[t.pb_8]}>
      <div css={[t.typeStyle_lg3, t.my_5]}>Type styles</div>
      <div css={[t.mb_6]}>
        <div css={[t.typeStyle_lg4, t.mb_6]}>Large</div>
        <div css={[t.typeStyle_lg1]}>LG1 - Music distributor, Forced Exposure</div>
        <div css={[t.typeStyle_lg2]}>LG2 - Reducing reverberations to better control the sound inside a space ultimately failed.</div>
        <div css={[t.typeStyle_lg3]}>LG3 - Digital time is time designed for machines. We benefit in all kinds of ways from the convenience that makes possible.</div>
        <div css={[t.typeStyle_lg4]}>LG4 - Net neutrality may sound like a dry topic for a rock band, but it’s one of the rallying cries of Downtown Boys</div>
        <div css={[t.typeStyle_lg5]}>LG5 - Sit down lads, the tea is on its way.</div>
        <div css={[t.typeStyle_lg6]}>
          LG6 - At the ripe age of twelve, a guitar was bestowed upon D.H. Currier for Christmas. Since then, he has been seen playing in something like ten different bands, Cut Teeth, The
          Felix Culpa, and Venna, to name a few, picking up a slew of new instruments all along the way. He now fancies himself a player of the piano, organ, harmonium, melodica, clarinet,
          bass, mandolin, banjo, ukulele, trumpet, glockenspiel, vibraphone, and other various percussion instruments - he even thinks he can sing, too. In addition to writing and recording
          as a solo artist and tirelessly playing in various bands, he also enjoys making instrumental tunes for film, commercial, and theatre-related projects.
        </div>
        <div css={[t.typeStyle_lg7]}>
          LG7 - He now fancies himself a player of the piano, organ, harmonium, melodica, clarinet, bass, mandolin, banjo, ukulele, trumpet, glockenspiel, vibraphone, and other various
          percussion instruments - he even thinks he can sing, too.
        </div>
        <div css={[t.typeStyle_lg8]}>LG8 - Country of origin</div>
        <div css={[t.typeStyle_mono]}>MONO - WAYS OF HEARING</div>
      </div>
      <div css={[t.mb_6]}>
        <div css={[t.typeStyle_lg4, t.mb_6]}>Medium</div>
      </div>
      <div css={[t.mb_6]}>
        <div css={[t.typeStyle_lg4, t.mb_6]}>Small</div>
        <div css={[t.typeStyle_sm1]}>SM1 - Music distributor, Forced Exposure</div>
        <div css={[t.typeStyle_sm2]}>SM2 - Reducing reverberations to better control the sound inside a space ultimately failed.</div>
        <div css={[t.typeStyle_sm3]}>SM3 - Digital time is time designed for machines. We benefit in all kinds of ways from the convenience that makes possible.</div>
        <div css={[t.typeStyle_sm4]}>SM4 - Net neutrality may sound like a dry topic for a rock band, but it’s one of the rallying cries of Downtown Boys</div>
        <div css={[t.typeStyle_sm5]}>SM5 - Sit down lads, the tea is on its way.</div>
        <div css={[t.typeStyle_sm6]}>
          SM6 - At the ripe age of twelve, a guitar was bestowed upon D.H. Currier for Christmas. Since then, he has been seen playing in something like ten different bands, Cut Teeth, The
          Felix Culpa, and Venna, to name a few, picking up a slew of new instruments all along the way. He now fancies himself a player of the piano, organ, harmonium, melodica, clarinet,
          bass, mandolin, banjo, ukulele, trumpet, glockenspiel, vibraphone, and other various percussion instruments - he even thinks he can sing, too. In addition to writing and recording
          as a solo artist and tirelessly playing in various bands, he also enjoys making instrumental tunes for film, commercial, and theatre-related projects.
        </div>
        <div css={[t.typeStyle_sm7]}>
          SM7 - He now fancies himself a player of the piano, organ, harmonium, melodica, clarinet, bass, mandolin, banjo, ukulele, trumpet, glockenspiel, vibraphone, and other various
          percussion instruments - he even thinks he can sing, too.
        </div>
      </div>
    </div>
  );
};

const TypeComponents = () => {
  return (
    <div css={[t.pb_8]}>
      <div css={[t.typeStyle_lg3, t.my_5]}>Type components</div>
      <div css={[t.mb_6]}>
        <div css={[t.gridTypeStyle_1]}>LG1 - Page statement</div>
        <div css={[t.gridTypeStyle_2]}>LG2 - Large copy. Digital time is time designed for machines. We benefit in all kinds of ways from that convenience.</div>
        <div css={[t.gridTypeStyle_3]}>LG3 - Section header</div>
        <div css={[t.gridTypeStyle_4]}>LG4 - Subheader. Net neutrality may sound like a dry topic for a rock band, but it’s one of the rallying cries of Downtown Boys</div>
        <div css={[t.gridTypeStyle_5]}>LG5 - Copy title. Sit down lads, the tea is on its way.</div>
        <div css={[t.gridTypeStyle_6]}>
          LG6 - Body — At the ripe age of twelve, a guitar was bestowed upon D.H. Currier for Christmas. Since then, he has been seen playing in something like ten different bands, Cut
          Teeth, The Felix Culpa, and Venna, to name a few, picking up a slew of new instruments all along the way. He now fancies himself a player of the piano, organ, harmonium, melodica,
          clarinet, bass, mandolin, banjo, ukulele, trumpet, glockenspiel, vibraphone, and other various percussion instruments.
        </div>
        <div css={[t.gridTypeStyle_7]}>
          LG7 - Small Body — He now fancies himself a player of the piano, organ, harmonium, melodica, clarinet, bass, mandolin, banjo, ukulele, trumpet, glockenspiel, vibraphone, and other
          various percussion instruments - he even thinks he can sing, too.
        </div>
      </div>
    </div>
  );
};
