import React from 'react'; // eslint-disable-line
import { useSortBy, UseSortByProps } from 'react-instantsearch';

/** @jsxImportSource @emotion/react */

// This allows you to change the sort index being queried. Indicies are defined in the Algolia dashboard.
// NOTE: If you add a new replica index to sort by be sure to also grant public access to it
// by updating the relevant public API keys in the Algolia dashboard.
export default function HiddenSort(props: UseSortByProps & { value: string; }) {
  const { currentRefinement, refine } = useSortBy(props);
  const { value } = props;
  React.useEffect(() => {
    if (value !== currentRefinement) refine(value);
  }, [value, currentRefinement, refine]);
  return <React.Fragment></React.Fragment>;
}
