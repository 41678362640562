import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { useUserAccount } from '../../../contexts/UserAccountContext';
import { AdminDetailsDialogInfo } from '../components/Dialogs/AdminDetailsDialogInfo';
import { AdminDetailsLinkButton } from '../components/Dialogs/AdminDetailsLinkButton';

/** @jsxImportSource @emotion/react */

export const AdminUserDetailsOrders = () => {
  const user = useUserAccount();

  if (!user) return null;

  return (
    <div css={[t.border_b, t.border_solid, t.border_tint_3, t.pt_3, t.pb_6, t.px_6, t.flex, t.flex_col]}>
      <div css={[t.text_sm, t.text_tint_1]}>Orders</div>
      <div css={[t.flex, t.flex_row, t.flex_wrap, t.mt_4]}>
        <div css={[t.mr_8]}>
          <AdminDetailsDialogInfo label="Total">
            <AdminDetailsLinkButton to={`/admin/orders/${user.id}/[""]`} disabled={!user.ordersPlaced}>
              {GetCountString(user.ordersPlaced)}
            </AdminDetailsLinkButton>
          </AdminDetailsDialogInfo>
        </div>
      </div>
    </div>
  );
};

function GetCountString(count: number): string {
  if (!count) return 'None';
  return `${count} order${count > 1 ? 's' : ''}`;
}
