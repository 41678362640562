import React from 'react'; // eslint-disable-line
import { css } from '@emotion/react';

import t, { CSSStyle } from '../theme/newstyles';
import { Link, useLocation } from 'react-router-dom';
import * as H from 'history';
import { useThemeController } from '../contexts/ThemeContext';

/** @jsxImportSource @emotion/react */

const focusSize = css`
  top: 9px;
  bottom: -3px;
  right: -8px;
  left: -8px;
`;

export function HeaderLink<S = H.LocationState>(props: {
  to: H.LocationDescriptor<S> | ((location: H.Location<S>) => H.LocationDescriptor<S>);
  altRoute?: string;
  label: string;
  active?: boolean;
  disabled?: boolean;
  children?: React.ReactNode;
  containerStyle?: CSSStyle;
  innerStyle?: CSSStyle;
}) {
  function HandleInnerFocus(event: React.FocusEvent) {
    event.stopPropagation();
    event.preventDefault();
  }

  const { pathname } = useLocation();
  const { dark } = useThemeController();
  const active = pathname === props.to || pathname === props.altRoute;
  const activeSelector = active ? t.customSelector('& > span::after', t.content_some) : null;
  const lightSelectedTextColor = active ? [t.text_primary_4, t.visited(t.text_primary_4)] : [t.text_dark_1, t.visited(t.text_dark_1)];
  const lightSelectedBorderColor = active ? t.border_primary_4 : t.border_dark_1;

  const selectedTextColor = dark ? t.text_tint_5 : lightSelectedTextColor;
  const selectedBorderColor = dark ? t.border_tint_5 : lightSelectedBorderColor;

  return (
    <div css={[t.relative, t.flex, t.flex_col, t.items_center, props.containerStyle]}>
      <Link
        to={props.to}
        css={[
          t.no_underline,
          t.outline_none,
          activeSelector,
          t.disabled(t.cursor_not_allowed),
          t.disabledSelector('> span', t.text_tint_2),
          t.hoverSelector('> span::after', t.content_some),
          t.focusSelector('> span::before', t.content_some),
          t.focusSelector('> span::after', t.content_some),
        ]}
      >
        <span
          tabIndex={-1}
          css={[
            t.inline_flex,
            t.items_center,
            t.pt_4,
            t.pb_3,
            t.border_transparent,
            t.typeStyle_6,
            t.cursor_pointer,
            selectedTextColor,
            t.outline_none,
            t.before([t.focusIndicator, focusSize, t.border_primary_4, t.content_none]),
            t.after([t.absolute, t.inset_x_0, t.h_full, t.border_b_2, t.border_solid, selectedBorderColor, t.bottom_0, t.content_none]),
            props.innerStyle,
          ]}
          onFocus={HandleInnerFocus}
        >
          {props.label}
        </span>
      </Link>
    </div>
  );
}
