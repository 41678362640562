import * as React from 'react';
import { TransparentModal } from '../../../components/TransparentModal';

export interface AdminPendingOperationController {
  show: (enabled: boolean) => void;
}

export const AdminPendingOperationContext = React.createContext({} as AdminPendingOperationController);
export const AdminPendingOperationProvider = (props: { children: React.ReactNode }) => {
  const [shown, setShown] = React.useState(false);
  const [value] = React.useState<AdminPendingOperationController>({
    show: (enabled: boolean) => {
      setShown(enabled);
    },
  });

  return (
    <AdminPendingOperationContext.Provider value={value}>
      {props.children}
      {shown ? <TransparentModal /> : null}
    </AdminPendingOperationContext.Provider>
  );
};

export function useAdminPendingOperation() {
  return React.useContext(AdminPendingOperationContext);
}
