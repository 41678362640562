import React from 'react'; // eslint-disable-line
import { SingleValue, MultiValue } from 'react-select';
import { FilterConfig, PerfectMatchControllerState } from './types';
import { PRICE_RANGE_OPTIONS, PAGE_SIZE } from './constants';

import usePackageSearch from '../PackageSearchContext';
import { GenreTagDictionary, InstrumentTagDictionary } from '../../data/ProfileData';
import { ArtistPackageSearchRecord } from '../../types/shared-types';

/** @jsxImportSource @emotion/react */

const priceOptionsSortingFunction = (a: string, b: string): number => {
  if (a[0] === '$') {
    return 1;
  } else {
    if (/under/.test(a)) return 1;
  }

  return 0;
};

const getPerfectMatches = (allMatches: ArtistPackageSearchRecord[]): ArtistPackageSearchRecord[] => {
  if (allMatches.length <= 1) return allMatches;

  const orderedArtistIDs: string[] = [];
  const packagesByArtist: Record<string, ArtistPackageSearchRecord[]> = {};

  allMatches.forEach((match: ArtistPackageSearchRecord) => {
    const artistId = match.artistId;
    if (!orderedArtistIDs.includes(artistId)) orderedArtistIDs.push(artistId);
    packagesByArtist[artistId] = packagesByArtist[artistId] || [];
    packagesByArtist[artistId].push(match);
  }, {});

  const perfectMatches = orderedArtistIDs.map((artistId) => packagesByArtist[artistId].reverse()[0]);

  return perfectMatches;
};

export const PerfectMatchContext = React.createContext<PerfectMatchControllerState>({} as PerfectMatchControllerState);

export function PerfectMatchProvider(props: { initialState?: Partial<PerfectMatchControllerState>; children: React.ReactNode }) {
  const packageSearchContext = usePackageSearch();
  const [searchResults, setSearchResults] = React.useState<ArtistPackageSearchRecord[]>(packageSearchContext.hits);
  const perfectMatches = React.useMemo(() => getPerfectMatches(searchResults), [searchResults]);
  const [page, setPage] = React.useState<number>(1);

  const currentPriceRangeSelection = React.useMemo(() => {
    return packageSearchContext.priceMin === 'undefined' && packageSearchContext.priceMax === 'undefined'
      ? []
      : [`${packageSearchContext.priceMin}|${packageSearchContext.priceMax}`];
  }, [packageSearchContext.priceMin, packageSearchContext.priceMax]);

  const filterOptions: Record<string, FilterConfig<Record<string, string>>> = React.useMemo(() => {
    return {
      genre: {
        domId: 'GenreTagInput',
        title: 'Genre(s)',
        subtitle: 'What style of music?',
        isMulti: true,
        options: GenreTagDictionary,
        currentSelections: packageSearchContext.genreFilters,
        onOptionToggle: (options: MultiValue<any>) => packageSearchContext.setGenreFilters(options.map(({ value }) => value)),
      },
      instrument: {
        domId: 'InstrumentTagInput',
        title: 'Instrument',
        subtitle: 'Instrument',
        isMulti: true,
        options: InstrumentTagDictionary,
        currentSelections: packageSearchContext.instrumentFilters,
        onOptionToggle: (options: MultiValue<any>) => packageSearchContext.setInstrumentFilters(options.map(({ value }) => value)),
      },
      price: {
        domId: 'PriceRangeInput',
        title: 'Budget',
        subtitle: 'What price range?',
        isMulti: false,
        options: PRICE_RANGE_OPTIONS,
        currentSelections: currentPriceRangeSelection,
        sortingFunction: priceOptionsSortingFunction,
        onOptionToggle: (option: SingleValue<any>) =>
          packageSearchContext.updatePriceRange(option && option.value ? option.value.split('|') : ['undefined', 'undefined']),
      },
    };
  }, [packageSearchContext, currentPriceRangeSelection]);

  return (
    <PerfectMatchContext.Provider value={{ filterOptions, perfectMatches, page, PAGE_SIZE, setSearchResults, setPage }}>
      {props.children}
    </PerfectMatchContext.Provider>
  );
}

export function usePerfectMatch() {
  return React.useContext(PerfectMatchContext);
}

export default usePerfectMatch;
