import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const RushOrderIcon = (props: {}) => {
  return (
    <svg role="img" aria-labelledby="rushOrderIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="rushOrderIconTitleID">Rush Order Icon</title>
      <path d="M22.5 4H10.5L5.5 14.5H18L22.5 4Z" css={[t.stroke_current]} />
      <circle cx="14.5" cy="19.5" r="2" css={[t.stroke_current]} />
      <circle cx="5.5" cy="19.5" r="2" css={[t.stroke_current]} />
      <rect x="2" y="4" width="5" height="1" css={[t.fill_current]} />
      <rect x="1" y="8" width="4" height="1" css={[t.fill_current]} />
      <rect y="12" width="3" height="1" css={[t.fill_current]} />
    </svg>
  );
};
