import { Transition } from 'framer-motion';
import { motion_intro } from './motionUtilities';
import { introVariants } from './motionVariants';

export const motion = {
  override: (transition: Transition) => transition,
  motion_intro: motion_intro,
  motion_rise: motion_intro(introVariants.variant_rise),
  motion_riseFadeIn: motion_intro(introVariants.variant_riseFadeIn),
  motion_scaleIn_1: motion_intro(introVariants.variant_scaleIn_1),
  motion_scaleIn_2: motion_intro(introVariants.variant_scaleIn_2),
  motion_scaleIn_3: motion_intro(introVariants.variant_scaleIn_3),
  motion_scaleInReveal_1: motion_intro(introVariants.variant_scaleInReveal_1),
  motion_fadeIn: motion_intro(introVariants.variant_fadeIn),
  motion_reveal_1: motion_intro(introVariants.variant_reveal_1),
  motion_empty: motion_intro(introVariants.variant_empty),
};
