import React, { JSX } from 'react'; // eslint-disable-line
import { css } from '@emotion/react';

import t from '../../../theme/admin/adminStyles';
import { ListColumnDescriptor } from './AdminListControl';
import { AdminListFooter } from './AdminListFooter';
import { ArrowIcon } from '../../../icons/ArrowIcon';

/** @jsxImportSource @emotion/react */

type AdminListSortDirection = 'asc' | 'desc';
const tableStyle = css`
  border-spacing: 0;
  border-collapse: separate;
  text-align: left;
  table-layout: fixed;
`;

export function AdminList<T extends string | number | symbol>(props: {
  listColumns: ListColumnDescriptor<T>[];
  sortColumn: T;
  resultsPerPage: number;
  sortDirections?: { [key in T]: AdminListSortDirection };
  onColumnSort?: (column: T, direction: AdminListSortDirection) => void;
  children?: React.ReactNode;
}) {
  const cols: JSX.Element[] = [];
  const headers: JSX.Element[] = [];

  for (let i = 0; i < props.listColumns.length; i++) {
    let sortDirection: AdminListSortDirection | undefined = undefined;
    const sortKey = props.listColumns[i].name;
    if (sortKey) {
      sortDirection = props.sortDirections ? props.sortDirections[sortKey] : undefined;
    }

    cols.push(<col key={`col_${i}`} width={props.listColumns[i].width} />);
    headers.push(
      <AdminListHeader
        key={`header_${i}`}
        active={props.sortColumn === props.listColumns[i].name}
        sortDirection={sortDirection}
        name={props.listColumns[i].name}
        label={props.listColumns[i].label}
        center={props.listColumns[i].center}
        onColumnSort={props.onColumnSort}
      />
    );
  }

  return (
    <div>
      <table css={[t.w_full, tableStyle]}>
        <colgroup>{cols}</colgroup>
        <thead>
          <tr>{headers}</tr>
        </thead>
        {props.children}
      </table>
      <AdminListFooter />
    </div>
  );
}

function AdminListHeader<T>(props: {
  active: boolean;
  name?: T;
  label?: string;
  center?: boolean;
  sortDirection?: AdminListSortDirection;
  onColumnSort?: (column: T, direction: AdminListSortDirection) => void;
}) {
  const [isHovered, SetHovered] = React.useState(false);
  const HandleClick = (event: React.MouseEvent<HTMLTableCellElement>) => {
    if (props.onColumnSort && props.name) props.onColumnSort(props.name, props.sortDirection === 'asc' ? 'desc' : 'asc');
  };

  const HandleMouseEnter = () => {
    SetHovered(true);
  };

  const HandleMouseLeave = () => {
    SetHovered(false);
  };

  return (
    <th
      onClick={props.name ? HandleClick : undefined}
      onMouseEnter={HandleMouseEnter}
      onMouseLeave={HandleMouseLeave}
      css={[
        t.text_xs,
        t.font_medium,
        t.border_b,
        t.border_tint_2,
        t.border_solid,
        props.center ? t.px_3 : t.px_6,
        t.pt_3,
        t.pb_2,
        t.bg_tint_3,
        props.active ? t.text_dark_1 : t.text_dark_3,
        props.name ? t.cursor_pointer : t.text_tint_1,
      ]}
    >
      <div css={[t.flex, t.flex_row, props.center ? t.justify_center : null]}>
        {props.label}
        {props.sortDirection ? (
          <ArrowIcon
            direction={props.sortDirection === 'asc' ? 'up' : 'down'}
            css={[t.flex_none, t.ml_2, t.w_3, t.h_3, props.active || isHovered ? t.block : t.hidden]}
          />
        ) : null}
      </div>
    </th>
  );
}
