import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const PlayIcon = (props: {}) => {
  return (
    <svg role="img" aria-labelledby="playIconTitleID" width="24" height="24" viewBox="0 0 24 24" fill="none" {...props}>
      <title id="playIconTitleID">Play Icon</title>
      <path fillRule="evenodd" clipRule="evenodd" d="M6 20L20 12L6 4V20ZM7 5.72318V18.2768L17.9844 12L7 5.72318Z" css={[t.fill_current]} />
    </svg>
  );
};
