import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';

/** @jsxImportSource @emotion/react */

export function AdminTextFieldLabel(props: { label: string; description?: string; id: string; optional?: boolean; labelOffscreen?: string }) {
  const labelStyle = props.description ? [t.pb_3] : [t.pb_2];
  if (props.labelOffscreen) {
    return (
      <div css={[t.offscreen]}>
        <label htmlFor={props.id}>{props.labelOffscreen}</label>
      </div>
    );
  } else if (props.optional && !props.labelOffscreen) {
    return (
      <div css={[labelStyle]}>
        <div css={[t.flex, t.justify_between]}>
          <div>
            <label css={[t.text_sm, t.text_dark_1]} htmlFor={props.id}>
              {props.label}
            </label>
            <div css={[t.text_sm, t.mr_4]}>{props.description}</div>
          </div>
          <div css={[t.text_sm, t.text_tint_1, t.font_normal, t.mt_1]}>*Optional</div>
        </div>
      </div>
    );
  } else
    return (
      <div css={labelStyle}>
        <label css={[t.text_sm, t.text_dark_1]} htmlFor={props.id}>
          {props.label}
        </label>
        <div css={[t.text_sm]}>{props.description}</div>
      </div>
    );
}
