import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import dayjs from 'dayjs';
import { MoreDotsIcon } from '../icons/MoreDotsIcons';
import { AdminActionsPopover } from '../components/AdminActionsPopover';
import { AdminActionsButton } from '../components/AdminActionButton';
import { AdminCompletedOrderRatingsRequest, OrderHistoryEvent } from '../../../contexts/OrderContext';
import { ClientSideOrder as Order } from '../../../types/shared-types';
import { FirebaseObjects } from '../../../contexts/FirebaseContext';
import { useAdminPendingOperation } from './AdminPendingOperation';
import { ArtistOrderRating } from '../../../contexts/ArtistProfileContext';
import { AdminResolveOrderDialog } from './AdminResolveOrderDialog';
import { HasRatingWindowExpired, useArtistRatings } from '../../../contexts/ArtistRatingsContext';
import { SharedAsset } from '../../../contexts/SharedAssetContext';
import { Button } from '../../../components/Button';
import CopyToClipboard from 'react-copy-to-clipboard';
import { LinkIcon } from '../../../icons/LinkIcon';
import { SuccessCheck } from '../../../icons/SuccessCheck';

/** @jsxImportSource @emotion/react */

const CompletedCommissionFollowUp = (props: { orderId: string; orderHistory: OrderHistoryEvent[] }) => {
  const [showLink, setShowLink] = React.useState(false);
  const [linkCopied, setLinkCopied] = React.useState(false);
  const { orderId, orderHistory } = props;

  React.useEffect(() => {
    if (orderHistory.find((h) => h.type === 'completedCommissionRatingFollowUp')) setShowLink(true);
  }, [orderHistory]);

  const pendingOperation = useAdminPendingOperation();

  const MAX_RATING_WINDOW = 30;
  let hasExpired;
  const historyObject = orderHistory.find((h) => h.type === 'completedCommissionRatingFollowUp');
  if (historyObject) hasExpired = HasRatingWindowExpired(historyObject.time, MAX_RATING_WINDOW);

  const hanleClick = async () => {
    pendingOperation.show(true);
    await AdminCompletedOrderRatingsRequest(orderId);
    pendingOperation.show(false);
  };

  const handleFocusSelect = (event: { target: HTMLInputElement }) => event.target.select();

  return (
    <div css={[t.mx_auto, t.my_auto]}>
      {!showLink && !hasExpired ? (
        <Button type="button" styleType="primary" label="Get link" onClick={hanleClick} />
      ) : showLink && !hasExpired ? (
        <div css={[t.flex, t.border, t.border_solid, t.border_tint_2, t.mt_5, t.pl_4, t.items_center]}>
          <input
            type="text"
            value={`${window.location.origin}/rate-completed-commission/${orderId}`}
            onFocus={handleFocusSelect}
            css={[t.flex_auto, t.text_base, t.text_dark_1, t.overflow_x_hidden, t.truncate, t.pr_4]}
            readOnly={true}
          ></input>
          <div css={[t.flex_initial]}>
            <CopyToClipboard text={`${window.location.origin}/rate-completed-commission/${orderId}`}>
              <Button
                type="button"
                label={linkCopied ? 'Copied' : 'Copy Link'}
                styleType="secondary"
                icon={linkCopied ? <SuccessCheck /> : <LinkIcon />}
                focused={true}
                onClick={() => setLinkCopied(true)}
                onBlur={() => setLinkCopied(false)}
              />
            </CopyToClipboard>
          </div>
        </div>
      ) : (
        <div>Time has expired to leave a rating</div>
      )}
    </div>
  );
};

export const AdminOrderDetailsRatings = (props: { order: Order; sharedAsset: SharedAsset }) => {
  const [actionsPopover, SetActionsPopover] = React.useState(false);
  const [popoverRect, SetPopoverRect] = React.useState<DOMRect | undefined>(undefined);
  const [selectedOrderId, SetSelectedOrderId] = React.useState<string | undefined>(undefined);
  const [showConfirmationModal, setShowConfirmationModal] = React.useState(false);
  const { order, sharedAsset } = props;

  const pendingOperation = useAdminPendingOperation();
  const artistRatings = useArtistRatings().Page;

  if (!sharedAsset) return null;
  if (!artistRatings) return null;
  const ratings = artistRatings.artistRatings;

  const ratingsArray: ArtistOrderRating[] | null = [];
  ratings.forEach((r) => {
    if (r.orderId === order.id) ratingsArray.unshift(r);
  });
  const showFollowUp = !ratingsArray.length && order.state.type === 'completed';
  if (showFollowUp) return <CompletedCommissionFollowUp orderId={order.id} orderHistory={order.history} />;
  if (!ratingsArray.length) return null;

  const needsReview = order.reviewReasons.find((x) => x.type === 'negative_commissioner_experience') !== undefined;
  const validOrderState = order.state.type === 'review_requested' || order.state.type === 'in_review';

  const displayConfirmationModal = () => setShowConfirmationModal(!showConfirmationModal);

  const handleActionsClick = (rect: DOMRect | undefined, event: React.MouseEvent) => {
    SetActionsPopover(true);
    SetPopoverRect(rect);
  };

  const handleActionsClose = () => {
    SetActionsPopover(false);
  };

  const handleBeginReview = async () => {
    if (!selectedOrderId) return;
    const issue = order.reviewReasons.find((x) => x.type === 'negative_commissioner_experience');
    if (!issue) return;
    SetActionsPopover(false);
    pendingOperation.show(true);
    await FirebaseObjects.adminRequest({ action: 'adminReviewRating', data: { orderId: order.id } });
    pendingOperation.show(false);
    SetSelectedOrderId(undefined);
  };

  const handleResolve = async () => {
    if (!selectedOrderId) return;
    const issue = order.reviewReasons.find((x) => x.type === 'negative_commissioner_experience');
    if (!issue) return;
    setShowConfirmationModal(false);
    SetActionsPopover(false);
    pendingOperation.show(true);
    await FirebaseObjects.adminRequest({ action: 'resolveNegativeRating', data: { orderId: order.id, artistRoute: sharedAsset.artistRoute } });
    pendingOperation.show(false);
    SetSelectedOrderId(undefined);
  };

  return (
    <React.Fragment>
      <div css={[t.flex, t.flex_col, t.mt_4, t.px_6, t.pb_6]}>
        <div css={[t.bg_white, t.text_tint_1, t.shadow]}>
          {ratingsArray.map((rating, i) => {
            return (
              <AdminDailogRatingsItem
                key={i}
                rating={rating}
                needsReview={needsReview}
                validOrderState={validOrderState}
                onClick={(r, e) => {
                  SetSelectedOrderId(order.id);
                  handleActionsClick(r, e);
                }}
              />
            );
          })}
        </div>
      </div>
      {/* Rendered in the Modal Root */}
      {actionsPopover ? (
        <AdminActionsPopover anchor="right" positionRect={popoverRect} onClose={handleActionsClose}>
          {order.state.type === 'review_requested' ? (
            <span css={[t.py_2, t.flex, t.flex_col]}>
              <div css={[t.inline, t.px_4, t.py_2, t.cursor_pointer, t.text_sm, t.hover([t.bg_tint_3])]} onClick={handleBeginReview}>
                Begin Review
              </div>
              <div css={[t.inline, t.px_4, t.py_2, t.text_sm, t.text_tint_2]}>Resolve</div>
            </span>
          ) : (
            <span css={[t.py_2, t.flex, t.flex_col]}>
              <div css={[t.inline, t.px_4, t.py_2, t.text_sm, t.text_tint_2]}>Begin Review</div>
              <div css={[t.inline, t.px_4, t.py_2, t.cursor_pointer, t.text_sm, t.hover([t.bg_tint_3])]} onClick={displayConfirmationModal}>
                Resolve
              </div>
            </span>
          )}
        </AdminActionsPopover>
      ) : null}
      <AdminResolveOrderDialog isOpen={showConfirmationModal} onClose={() => setShowConfirmationModal(false)} handleResolve={handleResolve} />
    </React.Fragment>
  );
};

const AdminDailogRatingsItem = (props: {
  rating: ArtistOrderRating;
  needsReview: boolean;
  onClick?: (rect: DOMRect | undefined, event: React.MouseEvent) => void;
  validOrderState: boolean;
}) => {
  const { rating, needsReview, validOrderState, onClick } = props;

  return (
    <div
      css={[
        t.relative,
        t.px_3,
        t.py_3,
        t.text_xs,
        t.border_b,
        t.border_tint_3,
        t.border_solid,
        t.flex,
        t.flex_row,
        t.hover([t.bg_tint_4]),
        t.hoverSelector(' #actions', [t.visible]),
      ]}
    >
      {needsReview ? <div css={[t.absolute, t.left_0, t.inset_y_0, t.w_1, t.bg_error_1]} /> : null}
      <div css={[t.flex_none, t.text_tint_1, t.border_r, t.pr_2, t.mr_2, t.border_solid, t.border_tint_2]}>
        {dayjs(rating.createdAt).format('MM/DD/YY hh:mm A')}
      </div>
      <div css={[t.flex_auto, t.flex_col, t.text_dark_3, t.text_base]}>
        <p>{`Star rating: ${rating.starRating}`}</p>
        {rating.tags && <p>{`Tags: ${rating.tags.join(', ')}`}</p>}
        {rating.writtenReview && <p>{`Review: ${rating.writtenReview}`}</p>}
        <p>{`Order Id: ${rating.orderId}`}</p>
        <p>{`Commissioner Id: ${rating.commissionerId}`}</p>
        <p>{`Is active: ${rating.isActive}`}</p>
        <p>{`Is publishable: ${rating.isPublishable}`}</p>
      </div>
      {validOrderState && (
        <div id="actions" css={[t.invisible, t.flex_none]}>
          <AdminActionsButton buttonCSS={[t.ml_3]} onClick={onClick}>
            <MoreDotsIcon css={[t.size('16px')]} />
          </AdminActionsButton>
        </div>
      )}
    </div>
  );
};
