import React from 'react'; // eslint-disable-line


import { useRouteMatch } from 'react-router-dom';

import { getAuth } from 'firebase/auth';
import t from '../../theme/newstyles';
import { HeaderLink } from '../HeaderLink';
import { GlobalHeader } from './GlobalHeader';
import { useAccount } from '../../contexts/AccountContext';
import { useSwitches } from '../../contexts/SwitchesContext';

import { PrimaryLink, PrimaryLinksContainer, SecondaryLinksContainer, SecondaryLink } from '../dialogs/FullScreenMenuLinks';
import { ArtistAvailability } from './ArtistAvailability';
import { LogoutIcon } from '../../icons/LogoutIcon';
import { useArtistProfile } from '../../contexts/ArtistProfileContext';
import { NotificationsButton } from './NotificationsButton';
import { AccountButton } from './AccountButton';
import { ListButton } from '../ListButton';
import { AccountDropdownList } from './AccountDropdownList';

import { ThemeProvider } from '../../contexts/ThemeContext';
import { LogoLink } from '../LogoLink';
import { useLG, useMD } from '../../contexts/BreakpointContext';
import { HeaderNav } from './HeaderNav';
import { HeaderNavLinks } from './HeaderNavLinks';
import { HeaderIcons } from './HeaderIcons';
import { HamburgerMenu } from './HamburgerMenu';
import { HeaderBanner } from './HeaderBanner';
import { useLocation } from 'react-router-dom';
import { WizardHeader } from './WizardHeader';

/** @jsxImportSource @emotion/react */

const CustomHeaderBannerContext = React.createContext<{
  customHeader: React.ReactNode;
  isCustomHeaderVisible: boolean;
  setCustomHeaderContent: (header: React.ReactNode | null) => void;
  setIsCustomHeaderVisible: (isVisible: boolean) => void;
}>({
  customHeader: null,
  isCustomHeaderVisible: false,
  setCustomHeaderContent: () => null,
  setIsCustomHeaderVisible: () => null,
});

export const CustomeHeaderBannerProvider = (props: { children?: React.ReactNode }) => {
  const [customHeader, setCustomHeaderContent] = React.useState<React.ReactNode | null>(null);
  const [isCustomHeaderVisible, setIsCustomHeaderVisible] = React.useState<boolean>(false);
  return (
    <CustomHeaderBannerContext.Provider value={{ customHeader, setCustomHeaderContent, isCustomHeaderVisible, setIsCustomHeaderVisible }}>
      {props.children}
    </CustomHeaderBannerContext.Provider>
  );
};

export function useCustomHeaderBannerContext() {
  return React.useContext(CustomHeaderBannerContext);
}

export const GlobalHeaderV2 = (props: { artistId: string | null; uid: string | null }) => {
  const account = useAccount();
  const artistProfile = useArtistProfile();
  const location = useLocation<{ step: number; totalSteps: number }>();
  const md = useMD();
  const lg = useLG();
  const switches = useSwitches();
  const matchingEnabled = switches && switches.perfectMatch;

  // Promo banner routes
  const artistPageRouteProps = useRouteMatch({ path: '/artists/:artistId', strict: true });
  const artistSearchPageRouteProps = useRouteMatch({ path: '/artists', strict: true });
  const matchSearchPageRouteProps = useRouteMatch({ path: '/match', strict: true });

  // Dark header routes
  const becomeArtistPageRouteProps = useRouteMatch({ path: '/become-an-artist', strict: true });
  const discoverPageRouteProps = useRouteMatch({ path: '/discover', strict: true });
  const homePageRouteProps = useRouteMatch({ path: '/', strict: true });
  const notedPageRouteProps = useRouteMatch({ path: '/noted', strict: true });
  const privateSharePageRouteProps = useRouteMatch({ path: '/share/:type/:orderId', strict: true });
  const privateWarningPageRouteProps = useRouteMatch({ path: '/private-page-warning', strict: true });
  const publicSharePageRouteProps = useRouteMatch({ path: '/share/:type/:orderId/public', strict: true });

  // Sign in/up routes
  const signUpArtistPageRouteProps = useRouteMatch({ path: '/artist-signUp', strict: true });
  const signUpCommissionerPageRouteProps = useRouteMatch({ path: '/commissioner-signUp', strict: true });
  const signUpDisambigPageRouteProps = useRouteMatch({ path: '/signUp', strict: true });
  const signInPageRouteProps = useRouteMatch({ path: '/signin', strict: true });

  // Onboarding routes
  const onboardingPageRouteProps = useRouteMatch({ path: '/create', strict: true });

  // State
  const isArtist = account && props.artistId;
  const isCommissioner = account && !props.artistId && props.uid;
  const isGuest = !isArtist && !isCommissioner;
  const profileData = artistProfile.Profile;
  const customBannerContext = useCustomHeaderBannerContext();
  const [isDarkHeader, setIsDarkHeader] = React.useState<boolean>(false);
  const [isSignupHeader, setIsSignupHeader] = React.useState<React.ReactNode | null>(null);
  const [isSigninHeader, setIsSigninHeader] = React.useState<React.ReactNode | null>(null);
  const [isOnboardingHeader, setIsOnboardingHeader] = React.useState<React.ReactNode | null>(null);
  const [promoBannerEnabled, setPromoBannerEnabled] = React.useState<boolean>(false);

  // Show the signup header
  React.useEffect(() => {
    setIsSignupHeader(!!signUpArtistPageRouteProps?.isExact || !!signUpCommissionerPageRouteProps?.isExact || !!signUpDisambigPageRouteProps?.isExact);
  }, [signUpArtistPageRouteProps, signUpCommissionerPageRouteProps, signUpDisambigPageRouteProps, setIsSignupHeader]);

  // show the signin header
  React.useEffect(() => {
    setIsSigninHeader(!!signInPageRouteProps?.isExact);
  }, [signInPageRouteProps, setIsSigninHeader]);

  // show the onboarding header
  React.useEffect(() => {
    setIsOnboardingHeader(!!onboardingPageRouteProps?.isExact);
  }, [onboardingPageRouteProps, setIsOnboardingHeader]);

  // Manage dark mode
  React.useEffect(() => {
    setIsDarkHeader(
      !!becomeArtistPageRouteProps?.isExact ||
        !!discoverPageRouteProps?.isExact ||
        !!homePageRouteProps?.isExact ||
        !!notedPageRouteProps?.isExact ||
        !!privateSharePageRouteProps?.isExact ||
        !!privateWarningPageRouteProps?.isExact ||
        !!publicSharePageRouteProps?.isExact
    );
  }, [
    becomeArtistPageRouteProps,
    discoverPageRouteProps,
    homePageRouteProps,
    notedPageRouteProps,
    privateSharePageRouteProps,
    privateWarningPageRouteProps,
    publicSharePageRouteProps,
    setIsDarkHeader,
  ]);

  // Manage promo banner state
  React.useEffect(() => {
    setPromoBannerEnabled(
      !!artistPageRouteProps?.isExact || !!artistSearchPageRouteProps?.isExact || !!matchSearchPageRouteProps?.isExact || !!homePageRouteProps?.isExact
    );
  }, [artistPageRouteProps, artistSearchPageRouteProps, matchSearchPageRouteProps, setPromoBannerEnabled, homePageRouteProps]);

  let profileRoute: { pathname: string } = { pathname: `/create/begin` };
  if (account && !account.User.emailVerified) profileRoute = { pathname: '/account/verify' };
  if (profileData && profileData.profileRoute) profileRoute = { pathname: `/artists/${profileData.profileRoute}` };

  const hasOrders = account && account.Settings.ordersPlaced;

  const logout = async (event: React.MouseEvent) => {
    event.stopPropagation();
    await getAuth().signOut();
    document.location = '/';
  };

  return (
    <ThemeProvider dark={isDarkHeader}>
      {/* Sign in */}
      {isSigninHeader && (
        <WizardHeader logoLockup={true} headerStyle={[t.py_0]}>
          <div css={[t.hidden, t.sm([t.flex, t.flex_auto, t.justify_end])]}>
            <div css={[t.mr_3, t.typeStyle_6, t.text_tint_1, t.pt_4, t.pb_3]}>{`Don't have an account?`}</div>
            <HeaderLink to={{ pathname: '/signup', state: location.state }} label="Sign up" />
          </div>
        </WizardHeader>
      )}

      {/* Sign up */}
      {isSignupHeader && (
        <WizardHeader logoLockup={true} headerStyle={[t.py_0]}>
          <div css={[t.h('56px'), t.sm([t.hidden])]} />
          <div css={[t.hidden, t.sm([t.flex, t.flex_auto, t.justify_end])]}>
            <div css={[t.mr_3, t.typeStyle_6, t.text_tint_1, t.pt_4, t.pb_3]}>{`Already have an account?`}</div>
            <HeaderLink to={{ pathname: '/signin', state: location.state }} label="Sign in" />
          </div>
        </WizardHeader>
      )}

      {/* Onboarding */}
      {isOnboardingHeader && location.state && (
        <WizardHeader label="Profile setup" progress={((location.state.step - 1) / (location.state.totalSteps - 1)) * 100} />
      )}

      {/* Default */}
      {!isSigninHeader && !isSignupHeader && (
        <GlobalHeader isHeaderVisible={customBannerContext.isCustomHeaderVisible} promoBannerEnabled={promoBannerEnabled}>
          <LogoLink lockup={lg} />
          <HeaderNav>
            {isArtist && (
              <HeaderNavLinks align="left">
                <HeaderLink to="/dashboard" label="Dashboard" />
                <HeaderLink to="/projects" label="Projects" />
              </HeaderNavLinks>
            )}
            {isCommissioner && switches && (
              <HeaderNavLinks align="left">
                {matchingEnabled && <HeaderLink to="/match" label="Get started" />}
                <HeaderLink to="/artists" label="Browse artists" />
                <HeaderLink to="/commissions" label="Commissions" />
              </HeaderNavLinks>
            )}
            {isGuest && switches && (
              <HeaderNavLinks align="right">
                {matchingEnabled && <HeaderLink to="/match" label="Get started" />}
                <HeaderLink to="/artists" label="Browse artists" />
                <HeaderLink to="/signin" label="Sign in" />
              </HeaderNavLinks>
            )}
            <HeaderIcons>
              {!isGuest && <NotificationsButton />}
              {md && isArtist && (
                <AccountButton>
                  <ArtistAvailability />
                  <AccountDropdownList>
                    <ListButton type="link" label="Profile" to={profileRoute} />
                    <ListButton type="link" label="Edit profile" to="/edit-profile" />
                    <ListButton type="link" label="Account" to="/account" />
                    {hasOrders ? <ListButton type="link" label="Commissions" to="/commissions" /> : null}
                  </AccountDropdownList>
                </AccountButton>
              )}
              {md && isCommissioner && (
                <AccountButton>
                  <AccountDropdownList>
                    <ListButton type="link" label="Account" to="/account" />
                  </AccountDropdownList>
                </AccountButton>
              )}
              {!md ? (
                <HamburgerMenu showLabel={isGuest}>
                  {(onClose) => {
                    if (isArtist) {
                      return (
                        <React.Fragment>
                          <ArtistAvailability />
                          <PrimaryLinksContainer onClick={onClose} availabilityShown={!profileData?.availability.available}>
                            <PrimaryLink to="/dashboard">Dashboard</PrimaryLink>
                            <PrimaryLink to="/projects">Projects</PrimaryLink>
                            {matchingEnabled && <PrimaryLink to="/match">Get started</PrimaryLink>}
                            <PrimaryLink to="/artists">Browse artists</PrimaryLink>
                          </PrimaryLinksContainer>
                          <SecondaryLinksContainer onClick={onClose}>
                            <SecondaryLink type="link" to={profileRoute}>
                              Profile
                            </SecondaryLink>
                            <SecondaryLink type="link" to="/edit-profile">
                              Edit profile
                            </SecondaryLink>
                            <SecondaryLink type="link" to="/account">
                              Account
                            </SecondaryLink>
                            {hasOrders ? (
                              <SecondaryLink type="link" to="/commissions">
                                Commissions
                              </SecondaryLink>
                            ) : null}
                            <SecondaryLink type="button" onClick={logout} id="SignOutButton">
                              <div css={[t.flex, t.items_center]}>
                                Sign out
                                <LogoutIcon css={[t.ml_3]} />
                              </div>
                            </SecondaryLink>
                          </SecondaryLinksContainer>
                        </React.Fragment>
                      );
                    } else if (isCommissioner) {
                      return (
                        <React.Fragment>
                          <PrimaryLinksContainer onClick={onClose}>
                            <PrimaryLink to="/">Home</PrimaryLink>
                            {matchingEnabled && <PrimaryLink to="/match">Get started</PrimaryLink>}
                            <PrimaryLink to="/artists">Browse artists</PrimaryLink>
                            <PrimaryLink to="/commissions">Commissions</PrimaryLink>
                          </PrimaryLinksContainer>
                          <SecondaryLinksContainer onClick={onClose}>
                            <SecondaryLink type="link" to="/account">
                              Account
                            </SecondaryLink>
                            <SecondaryLink type="button" onClick={logout} id="SignOutButton">
                              <div css={[t.flex, t.items_center]}>
                                Sign out
                                <LogoutIcon css={[t.ml_3]} />
                              </div>
                            </SecondaryLink>
                          </SecondaryLinksContainer>
                        </React.Fragment>
                      );
                    }

                    return (
                      <PrimaryLinksContainer onClick={onClose}>
                        <PrimaryLink to="/">Home</PrimaryLink>
                        {matchingEnabled && <PrimaryLink to="/match">Get started</PrimaryLink>}
                        <PrimaryLink to="/artists">Browse artists</PrimaryLink>
                        <PrimaryLink to="/signin">Sign in</PrimaryLink>
                      </PrimaryLinksContainer>
                    );
                  }}
                </HamburgerMenu>
              ) : null}
            </HeaderIcons>
          </HeaderNav>
        </GlobalHeader>
      )}

      {/* Custom Bottom Banner */}
      {customBannerContext.customHeader && <HeaderBanner customHeader={customBannerContext.customHeader} />}
    </ThemeProvider>
  );
};

export default GlobalHeaderV2;
