import React from 'react'; // eslint-disable-line

import t from '../../../theme/admin/adminStyles';
import { useOrder } from '../../../contexts/OrderContext';

/** @jsxImportSource @emotion/react */

const sectionStyle = [t.flex_initial, t.flex, t.flex_col, t.bg_tint_3, t.p_6];

export const AdminOrderDetailsCancelled = () => {
  const order = useOrder();

  if (!order) return null;
  if (order.state.type !== 'canceled') return null;
  const cancelReason = order.state.reason;

  if (cancelReason.byAdminEmail) return <div css={[sectionStyle, t.text_xl, t.text_error_2, t.font_medium, t.mb_2]}>Canceled by an admin</div>;
  return (
    <div css={[sectionStyle]}>
      <div css={[t.text_xl, t.text_error_2, t.font_medium, t.mb_2]}>Order canceled by the artist</div>
      {cancelReason.reason ? (
        <figure>
          <figcaption css={[t.text_sm, t.text_dark_4, t.font_medium, t.mb_1]}>Reasons: </figcaption>
          <ul css={[t.text_sm, t.text_dark_4, t.mb_2]}>
            {cancelReason.reason.map((reason, index) => (
              <li key={index}>{reason}</li>
            ))}
          </ul>
        </figure>
      ) : null}
      <div css={[t.text_sm, t.text_dark_4]}>
        <span css={[t.text_sm, t.text_dark_4, t.font_medium, t.mr_1]}>Message: </span>
        {cancelReason.textField}
      </div>
    </div>
  );
};
