import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const ButtonGroup = (props: { children?: React.ReactNode }) => {
  return (
    <div css={[t.flex, t.flex_row, t.flex_wrap, t.lastType('div', [t.mr_0])]}>
      {React.Children.map(props.children, (child) => (
        <div css={[t.mr_2, t.mb_2]}>{child}</div>
      ))}
    </div>
  );
};
