import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';
import { SmallCloseIcon } from '../icons/SmallCloseIcon';
import { InputErrorInline } from './InputErrorInline';
import { InputLabel } from './InputLabel';

/** @jsxImportSource @emotion/react */

export function TagInput(props: {
  id: string;
  options: { [key: string]: string };
  values?: string[];
  label?: string;
  disabled?: boolean;
  showMax?: boolean;
  maxSelected?: number;
  invalid?: boolean;
  error?: string;
  description?: string;
  onBlur?: (event: React.FocusEvent) => void;
  onFocus?: (event: React.FocusEvent) => void;
  onChange: (newValues: string[]) => void;
}) {
  const ref = React.useRef<HTMLDivElement | null>(null);
  const inputElement = React.useRef<HTMLInputElement | null>(null);
  const [inputValue, SetInputValue] = React.useState<string>('');
  const [focus, SetFocus] = React.useState(false);
  const [hover, SetHover] = React.useState(false);
  const [listFocusIndex, SetListFocusIndex] = React.useState<number | undefined>(undefined);
  const selected = props.values || [];
  const disabled = props.disabled;
  const labelId = `${props.id}_label`;

  const remaining: string[] = [];
  for (const key in props.options) {
    if (!selected.includes(key) && key.toLowerCase().includes(inputValue.toLowerCase())) remaining.push(key);
  }

  React.useEffect(() => {
    if (disabled) {
      SetFocus(false);
    }
  }, [disabled]);

  function HandleClick(event: React.MouseEvent) {
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }

  function HandleInputChange(event: React.ChangeEvent<HTMLInputElement>) {
    SetInputValue(event.currentTarget.value);
  }

  function HandleMouseEnter(event: React.MouseEvent) {
    SetHover(true);
  }

  function HandleMouseLeave(event: React.MouseEvent) {
    SetHover(false);
  }

  function HandleFocus(event: React.FocusEvent) {
    SetFocus(true);
    if (props.onFocus) props.onFocus(event);
  }

  function HandleBlur(event: React.FocusEvent) {
    if (ref.current && !ref.current.contains(event.relatedTarget as Node)) {
      SetFocus(false);
    }
    if (props.onBlur) props.onBlur(event);
  }

  function HandleInputFocus(event: React.FocusEvent<HTMLInputElement>) {
    HandleFocus(event);
  }

  function HandleInputBlur(event: React.FocusEvent<HTMLInputElement>) {
    HandleBlur(event);
  }

  function AddSelected(selection: string) {
    if (!selected.includes(selection)) {
      props.onChange([...selected, selection]);
      SetInputValue('');
    }
    if (inputElement.current) {
      inputElement.current.focus();
    }
  }

  function RemoveSelected(selection: string) {
    const newSelected = props.values ? [...props.values] : [];
    newSelected.splice(newSelected.indexOf(selection), 1);
    props.onChange(newSelected);
    if (inputElement.current) inputElement.current.focus();
  }

  function HandleKeyDown(event: React.KeyboardEvent) {
    if (focus) {
      switch (event.key) {
        case 'ArrowDown': {
          if (remaining.length > 0) {
            let newIndex = 0;
            if (listFocusIndex !== undefined) {
              if (listFocusIndex === remaining.length - 1) newIndex = listFocusIndex;
              else newIndex = listFocusIndex + 1;
            }
            SetListFocusIndex(listFocusIndex === undefined ? 0 : newIndex);
          }

          break;
        }
        case 'ArrowUp': {
          if (listFocusIndex !== undefined) {
            if (listFocusIndex === 0) {
              SetListFocusIndex(undefined);
              if (inputElement.current) inputElement.current.focus();
            } else SetListFocusIndex(listFocusIndex - 1);
          }

          break;
        }
      }
    }
  }

  function handleOptionClick(option: string) {
    AddSelected(option);
    SetFocus(true);
  }

  return (
    <React.Fragment>
      {props.label && (
        <div css={[t.mb_2]}>
          <InputLabel id={labelId} label={props.label} for={props.id} />
        </div>
      )}
      {props.description && <div css={[t.gridTypeStyle_7, t.pb_3]}>{props.description}</div>}
      <div ref={ref} onClick={HandleClick} onKeyDown={HandleKeyDown} onMouseEnter={HandleMouseEnter} onMouseLeave={HandleMouseLeave} css={[t.relative]}>
        <div
          css={[
            t.relative,
            t.w_full,
            t.min_w('300px'),
            t.min_h('56px'),
            t.inline_flex,
            t.flex_wrap,
            t.items_center,
            t.border_solid,
            t.border_1,
            t.border_tint_2,
            t.typeStyle_lg6,
            t.text_dark_1,
            t.pt_3,
            t.pb_1,
            t.px_4,
            t.lastType('div', [t.mr_3]),
            disabled ? t.bg_tint_4 : null,
            hover ? t.border_dark_1 : null,
            focus ? t.border_primary_4 : null,
            props.invalid ? t.border_error_1 : null,
          ]}
        >
          {selected?.map((value, i) => {
            return (
              <RemovableTag
                key={`${value}_${i}`}
                value={value}
                label={props.options[value]}
                onClick={RemoveSelected}
                onFocus={HandleFocus}
                onBlur={HandleBlur}
              />
            );
          })}
          <input
            id={props.id}
            ref={inputElement}
            css={[t.mb_2, t.typeStyle_lg6, t.text_dark_1, t.flex_auto, t.bg_transparent, t.placeholder(t.text_tint_2)]}
            type={'text'}
            value={inputValue}
            disabled={disabled}
            onChange={HandleInputChange}
            onBlur={HandleInputBlur}
            onFocus={HandleInputFocus}
          />
        </div>
        <TagInputDropdown open={focus && !disabled}>
          {remaining.length === 0 && <div css={[t.gridTypeStyle_6, t.text_dark_1, t.mt_3, t.mx_3]}>No options available</div>}
          {remaining.map((option, i) => {
            return (
              <SelectOption
                key={`${option}_${i}`}
                value={option}
                label={props.options[option]}
                focus={listFocusIndex === i}
                onClick={handleOptionClick}
                onFocus={HandleFocus}
                onBlur={HandleBlur}
              />
            );
          })}
        </TagInputDropdown>
      </div>
      {props.showMax && (
        <div css={[t.typeStyle_lg7, t.text_dark_2, t.mt_2]}>
          {props.values ? props.values.length : 0}/{props.maxSelected} selected
        </div>
      )}
      <InputErrorInline>{props.invalid && props.error}</InputErrorInline>
    </React.Fragment>
  );
}

const TagInputDropdown = (props: { open: boolean; children?: React.ReactNode }) => {
  if (!props.open) return null;

  return (
    <div css={[t.absolute, t.bottom_0, t.inset_x_0]}>
      <ul
        css={[
          t.absolute,
          t.inset_x_0,
          t.mt('-1px'),
          t.pt('7px'),
          t.pb_4,
          t.bg_tint_5,
          t.border,
          t.border_solid,
          t.border_primary_4,
          t.z_50,
          t.overflow_y_scroll,
          t.max_h('177px'),
        ]}
      >
        {props.children}
      </ul>
    </div>
  );
};

const RemovableTag = (props: {
  value: string;
  label: string;
  onClick: (option: string) => void;
  onFocus?: (event: React.FocusEvent) => void;
  onBlur?: (event: React.FocusEvent) => void;
}) => {
  const [focus, SetFocus] = React.useState(false);

  function HandleClick() {
    props.onClick(props.value);
  }

  function HandleFocus(event: React.FocusEvent) {
    SetFocus(true);
    if (props.onFocus) props.onFocus(event);
  }

  function HandleBlur(event: React.FocusEvent) {
    SetFocus(false);
    if (props.onBlur) props.onBlur(event);
  }

  function HandleKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      HandleClick();
    }
  }

  return (
    <div
      tabIndex={0}
      css={[
        t.relative,
        t.flex,
        t.mr_2,
        t.mb_2,
        t.p({ top: '5px', right: '12px', bottom: '7px', left: '16px' }),
        t.cursor_pointer,
        t.border_1,
        t.border_solid,
        t.border_dark_1,
        t.rounded_full,
        t.text_dark_1,
        t.outline_none,
        t.hover([t.bg_dark_1, t.text_tint_5]),
        t.before([t.focusIndicator, t.border_primary_4, t.pos('-3px'), t.rounded_full, t.content_none]),
        t.before([focus ? t.content_some : null]),
      ]}
      onClick={HandleClick}
      onKeyDown={HandleKeyDown}
      onFocus={HandleFocus}
      onBlur={HandleBlur}
    >
      <span css={[t.typeStyle_lg7]}>{props.label}</span>
      <SmallCloseIcon css={[t.mt_2, t.ml_2]} />
    </div>
  );
};

const SelectOption = (props: {
  value: string;
  label: string;
  focus: boolean;
  onClick: (option: string) => void;
  onFocus?: (event: React.FocusEvent) => void;
  onBlur?: (event: React.FocusEvent) => void;
}) => {
  const ref = React.useRef<HTMLLIElement | null>(null);

  React.useEffect(() => {
    if (props.focus) ref.current?.focus();
  }, [props.focus]);

  function HandleClick() {
    props.onClick(props.value);
  }

  function HandleKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault();
      HandleClick();
    }
  }

  function HandleFocus(event: React.FocusEvent) {
    if (props.onFocus) props.onFocus(event);
  }

  function HandleBlur(event: React.FocusEvent) {
    if (props.onBlur) props.onBlur(event);
  }

  return (
    <li
      ref={ref}
      css={[
        t.p({ top: '5px', right: '18px', bottom: '7px', left: '18px' }),
        t.typeStyle_lg6,
        t.text_dark_1,
        t.cursor_pointer,
        t.outline_none,
        t.hover([t.bg_tint_3]),
        props.focus ? t.bg_tint_3 : null,
      ]}
      tabIndex={props.focus ? 0 : -1}
      onClick={HandleClick}
      onKeyDown={HandleKeyDown}
      onFocus={HandleFocus}
      onBlur={HandleBlur}
    >
      <span>{props.label}</span>
    </li>
  );
};
