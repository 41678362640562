import React from 'react'; // eslint-disable-line

import { CSSStyle } from '../../../theme/newstyles';
import t from '../../../theme/admin/adminStyles';

/** @jsxImportSource @emotion/react */

export function AdminIconButton(props: { id: string; onClick?: () => void; children?: React.ReactNode; buttonStyle?: CSSStyle }) {
  return (
    <button
      id={props.id}
      css={[
        t.bg_transparent,
        t.cursor_pointer,
        t.flex_row,
        t.items_center,
        t.justify_center,
        t.size('40px'),
        t.text_tint_1,
        t.hover([t.text_dark_1]),
        props.buttonStyle,
      ]}
      onClick={props.onClick}
    >
      {props.children}
    </button>
  );
}
