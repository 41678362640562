import { SongPackage, SongOrderDetails, BucketedPrice } from './types/shared-types';
import Dinero from 'dinero.js';

export function ComputeSongPretaxPrice(songPackage: SongPackage, details: SongOrderDetails, discountPercent?: number): Dinero.Dinero {
  let total = Dinero(songPackage.price);
  if (details.additionalInstrumentation) total = total.add(Dinero(songPackage.additionalInstrumentation!.cost));
  if (details.fullLength) total = total.add(Dinero(songPackage.fullLength!.cost));
  if (details.handWrittenLyrics) total = total.add(Dinero(songPackage.handWrittenLyricsCost));
  if (details.studioRecording) total = total.add(Dinero(songPackage.studioRecording!.cost));
  if (details.videoGreeting) total = total.add(Dinero(songPackage.videoGreetingCost));
  if (details.videoPerformance) total = total.add(Dinero(songPackage.videoPerformanceCost));
  if (details.rushOrder) total = total.add(Dinero(songPackage.rushCost));
  if (details.vinyl7in) total = total.add(Dinero(songPackage.vinyl7in!.cost));
  if (discountPercent) total = total.percentage(100 - discountPercent);
  return total.convertPrecision(2);
}

export function ComputeSongPretaxPriceBucketed(songPackage: SongPackage, details: SongOrderDetails, discountPercent?: number): BucketedPrice {
  let preTaxBasePrice = Dinero(songPackage.price);

  let preTaxAddonTotal = Dinero();
  if (details.additionalInstrumentation) preTaxAddonTotal = preTaxAddonTotal.add(Dinero(songPackage.additionalInstrumentation!.cost));
  if (details.fullLength) preTaxAddonTotal = preTaxAddonTotal.add(Dinero(songPackage.fullLength!.cost));
  if (details.studioRecording) preTaxAddonTotal = preTaxAddonTotal.add(Dinero(songPackage.studioRecording!.cost));
  if (details.videoGreeting) preTaxAddonTotal = preTaxAddonTotal.add(Dinero(songPackage.videoGreetingCost));
  if (details.videoPerformance) preTaxAddonTotal = preTaxAddonTotal.add(Dinero(songPackage.videoPerformanceCost));
  if (details.rushOrder) preTaxAddonTotal = preTaxAddonTotal.add(Dinero(songPackage.rushCost));

  let digitalTotal = Dinero(songPackage.price).add(preTaxAddonTotal);

  let physicalTotal = Dinero();
  if (details.handWrittenLyrics) physicalTotal = physicalTotal.add(Dinero(songPackage.handWrittenLyricsCost));
  if (details.vinyl7in) physicalTotal = physicalTotal.add(Dinero(songPackage.vinyl7in!.cost));

  let discountTotal = Dinero();
  if (discountPercent) {
    const preDiscountTotal = digitalTotal.add(physicalTotal);
    discountTotal = preDiscountTotal.percentage(discountPercent);
    preTaxBasePrice = preTaxBasePrice.percentage(100 - discountPercent);
    digitalTotal = digitalTotal.percentage(100 - discountPercent);
    preTaxAddonTotal = preTaxAddonTotal.percentage(100 - discountPercent);
    physicalTotal = physicalTotal.percentage(100 - discountPercent);
  }

  const total = digitalTotal.add(physicalTotal);

  return {
    preTaxDigitalTotal: digitalTotal.convertPrecision(2),
    preTaxPhysicalTotal: physicalTotal.convertPrecision(2),
    preTaxAddonTotal: preTaxAddonTotal.convertPrecision(2),
    preTaxCommissionTotal: preTaxBasePrice.convertPrecision(2),
    discountTotal: discountTotal.convertPrecision(2),
    total: total.convertPrecision(2),
  };
}
