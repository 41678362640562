import React from 'react'; // eslint-disable-line
import t from '../theme/newstyles';
import { MainPage } from '../components/MainPage';
import { useAccount, LastSeenUpdater, CreateNotificationLink, AccountNotification, MarkSingleNotificationRead } from '../contexts/AccountContext';
import { ProfileImage } from '../components/ProfileImage';
import { MainContainer } from '../components/MainContainer';
import dayjs from 'dayjs';
import { Button } from '../components/Button';
import { Link } from 'react-router-dom';
import { useThemeController } from '../contexts/ThemeContext';
import { Helmet } from 'react-helmet-async';

/** @jsxImportSource @emotion/react */

function GetTruncatedMessage(fullText: string) {
  return fullText.length < 100 ? fullText : `${fullText.slice(0, 100)}...`;
}

export function NotificationComponent(props: { notification: AccountNotification; smallView?: boolean }) {
  const { dark } = useThemeController();
  const notification = props.notification;
  const textColor = dark ? t.text_tint_5 : t.text_dark_1;
  const hoverColor = dark ? t.bg_dark_2 : t.bg_tint_4;
  const borderColor = dark ? t.border_dark_2 : t.border_tint_3;
  const activeColor = dark ? t.bg_dark_2 : t.bg_tint_3;
  let notificationContent;

  const occasionQuotes = `\u201C${notification.occasion}\u201D`;
  const occasionPeriodQuotes = `\u201C${notification.occasion}.\u201D`;

  switch (notification.type) {
    case 'orderAccepted': {
      notificationContent = (
        <div css={[textColor]}>
          <span css={[t.font_medium]}>{`${notification.artistName} `}</span>
          {`accepted your request for ${occasionPeriodQuotes} Magic is about to happen!`}
        </div>
      );
      break;
    }
    case 'orderCanceled': {
      notificationContent = (
        <div
          css={[textColor]}
        >{`Unfortunately ${notification.artistName} had to decline your request for ${occasionPeriodQuotes} Check out other talented artists.`}</div>
      );
      break;
    }
    case 'orderComment': {
      if (props.smallView) {
        notificationContent = (
          <div css={[textColor]}>
            <div css={[t.font_medium, t.block]}>{`${notification.artistName} commented `}</div>
            <div css={[t.block]}>&ldquo;{`${GetTruncatedMessage(notification.msg)}`}&rdquo;</div>
          </div>
        );
      } else
        notificationContent = (
          <div css={[textColor]}>
            <span css={[t.font_medium]}>{`${notification.artistName} commented  `}</span>
            &ldquo;{`${GetTruncatedMessage(notification.msg)}`}&rdquo;
          </div>
        );
      break;
    }
    case 'orderComplete': {
      notificationContent = <div css={[textColor]}>Your commission has arrived!</div>;
      break;
    }
    case 'projectComment': {
      if (props.smallView) {
        notificationContent = (
          <div css={[textColor]}>
            <div css={[t.font_medium]}>{`${notification.customerName} commented `}</div>
            <div>&ldquo;{`${GetTruncatedMessage(notification.msg)}`}&rdquo;</div>
          </div>
        );
      } else
        notificationContent = (
          <div css={[textColor]}>
            <span css={[t.font_medium]}>{`${notification.customerName} commented `}</span>
            &ldquo;{`${GetTruncatedMessage(notification.msg)}`}&rdquo;
          </div>
        );
      break;
    }
    case 'projectChanges': {
      if (props.smallView) {
        notificationContent = (
          <div css={[textColor]}>
            <div css={[t.font_medium]}>{`${notification.customerName} requested changes `}</div>
            <div>&ldquo;{`${GetTruncatedMessage(notification.msg)}`}&rdquo;</div>
          </div>
        );
      } else
        notificationContent = (
          <div css={[textColor]}>
            <span css={[t.font_medium]}>{`${notification.customerName} requested changes `}</span>
            &ldquo;{`${GetTruncatedMessage(notification.msg)}`}&rdquo;
          </div>
        );
      break;
    }
    case 'projectDue': {
      const whenDue = () => {
        if (notification.daysLeft > 1) {
          return `in ${notification.daysLeft} days`;
        }
        if (notification.daysLeft === 1) {
          return `tomorrow`;
        }
        if (notification.daysLeft < 1) {
          return 'today';
        }
      };
      notificationContent = (
        <div css={[textColor]}>
          <span css={[t.font_medium]}>Reminder: </span>
          {`your project, ${occasionQuotes} is due ${whenDue()}`}
        </div>
      );
      break;
    }
    case 'projectExpired': {
      notificationContent = <div css={[textColor]}>{`The request for ${occasionQuotes} has expired`}</div>;
      break;
    }
    case 'projectExpiringSoon': {
      notificationContent = <div css={[textColor]}>{`The request for ${occasionQuotes} is about to expire in 24 hours`}</div>;
      break;
    }
    case 'projectRequest': {
      notificationContent = (
        <div css={[textColor]}>
          <span css={[t.font_medium]}>{`${notification.customerName}`}</span>
          {` sent you a project request: ${occasionQuotes}`}
        </div>
      );
      break;
    }
    case 'updateRating': {
      notificationContent = (
        <div css={[textColor]}>
          <span css={[t.font_medium]}>Customer service:</span>
          {` would you like to update your rating for ${notification.artistName}?`}
        </div>
      );
      break;
    }
  }

  const notificationId = notification.time;
  const notificationType = notification.type;
  const notificationRead = notification.read;
  const handleClick = React.useCallback(() => {
    if (notificationRead) return;
    const asyncWrapper = async () => {
      switch (notificationType) {
        case 'orderCanceled':
          await MarkSingleNotificationRead(notificationId);
          break;
      }
    };
    asyncWrapper();
  }, [notificationId, notificationType, notificationRead]);

  return (
    <Link
      to={CreateNotificationLink(notification)}
      css={[t.border_b, borderColor, t.border_solid, t.block, t.text_dark_1, t.no_underline, t.flex, t.hover([hoverColor]), t.active([activeColor])]}
      onClick={handleClick}
    >
      <div css={[t.min_w('5px'), t.mr('-5px'), notification.read ? t.bg_transparent : t.bg_primary_3]} />
      <div css={[t.py_4, t.px_5]}>
        <div css={[t.flex]}>
          <div css={[t.size('48px'), t.min_w('48px'), props.smallView ? t.mt_1 : t.mt_2, t.mr_5, t.overflow_hidden]}>
            <ProfileImage userId={notification.from} alt="profile picture" width="48px" />
          </div>
          <div css={[t.flex, t.flex_col]}>
            <div css={[props.smallView ? t.gridTypeStyle_7 : t.gridTypeStyle_5, t.text_left]}>{notificationContent}</div>
            <div css={[t.gridTypeStyle_7, notification.read ? t.text_tint_1 : t.text_primary_3, t.text_left]}>{dayjs(notification.time).fromNow()}</div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export function NotificationPageComponent(props: object) {
  const startingEarlierComments = 8;
  const newCommentsDisplayNum = 4;
  const [numberEarlierCommentsShown, setNumberEarlierCommentsShown] = React.useState(startingEarlierComments);
  const account = useAccount();
  if (!account) return null;

  const notifications = account.Settings.notifications || [];
  const maxShown = notifications.length;

  const showMore = () => {
    const newNumberEarlierCommentsShown = Math.min(notifications.length, numberEarlierCommentsShown + 4);
    setNumberEarlierCommentsShown(newNumberEarlierCommentsShown);
  };

  const newComments = notifications.slice(0, newCommentsDisplayNum);
  const earlierComments = notifications.slice(newCommentsDisplayNum, numberEarlierCommentsShown + newCommentsDisplayNum);

  return (
    <MainPage>
      <Helmet>
        <title>Downwrite - Notifications</title>
      </Helmet>
      <LastSeenUpdater />
      <div css={[t.border_b, t.border_tint_3, t.mb_5, t.md([t.mb_6]), t.lg([t.mb_7])]}>
        <MainContainer>
          <h1 css={[t.gridTypeStyle_1, t.my_5, t.sm([t.my_6]), t.lg([t.my_7])]}>Notifications</h1>
        </MainContainer>
      </div>
      <MainContainer>
        <div css={[t.breakpointGrid_12, t.border_b, t.border_tint_3]}>
          <h2 css={[t.col_span_12, t.mb_4, t.md([t.mb_5]), t.lg([t.col_span_4]), t.gridTypeStyle_4]}>Recent</h2>
          <div css={[t.col_span_12, t.lg([t.col_span_8]), t.mb_8, t.lastType('a', t.border_none)]}>
            {notifications.length === 0 ? <div css={[t.gridTypeStyle_4, t.text_tint_1, t.mb_5]}>There are no recent notifications</div> : null}
            {newComments.map((notification, index) => {
              return <NotificationComponent notification={notification} key={index} />;
            })}
          </div>
        </div>
        <div css={[t.breakpointGrid_12, t.border_b, t.border_tint_3, t.mt_6]}>
          <h2 css={[t.col_span_12, t.mb_4, t.md([t.mb_5]), t.lg([t.col_span_4]), t.gridTypeStyle_4]}>Earlier</h2>
          <div css={[t.col_span_12, t.lg([t.col_span_8]), t.mb_8, t.lastType('a', t.border_none)]}>
            {notifications.length === 0 || notifications.length <= newCommentsDisplayNum ? (
              <div css={[t.gridTypeStyle_4, t.text_tint_1, t.mb_5]}>No earlier notifications</div>
            ) : null}
            {earlierComments.map((notification, index) => {
              return <NotificationComponent notification={notification} key={index} />;
            })}
          </div>
          {notifications.length !== 0 && notifications.length > newCommentsDisplayNum ? (
            <div css={[t.col_span_12, t.flex, t.justify_center, t.mb_9]}>
              <Button
                type="button"
                label="Load more"
                styleType="secondary"
                onClick={showMore}
                disabled={numberEarlierCommentsShown + newCommentsDisplayNum >= maxShown}
              />
            </div>
          ) : null}
        </div>
      </MainContainer>
    </MainPage>
  );
}

export const NotificationPage = NotificationPageComponent;

export default NotificationPage;
