import React from 'react'; // eslint-disable-line

import t from '../../../../theme/admin/adminStyles';
import { CSSStyle } from '../../../../theme/newstyles';

/** @jsxImportSource @emotion/react */

export function AdminDialogPage(props: { children?: React.ReactNode; pageStyle?: CSSStyle }) {
  return <div css={[t.mt('55px'), t.h_full, t.overflow_y_auto, props.pageStyle]}>{props.children}</div>;
}
