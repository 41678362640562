import React from 'react'; // eslint-disable-line
import { useRefinementList, UseRefinementListProps } from 'react-instantsearch';

/** @jsxImportSource @emotion/react */

// This allows you to add filter queries
export default function HiddenFilter(props: UseRefinementListProps & { values: string[] }) {
  // Despite its name, refine toggles the refinement
  const { items, refine } = useRefinementList(props);
  const { values } = props;
  React.useEffect(() => {
    const currentRefinements = new Set(
      items
        .filter((item) => item.isRefined)
        .map((item) => item.value)
    );
    const targetRefinements = new Set(values);
    for (const refinement of currentRefinements) {
      if (!targetRefinements.has(refinement)) refine(refinement);
    }
    for (const refinement of targetRefinements) {
      if (!currentRefinements.has(refinement)) refine(refinement);
    }
  }, [items, values, refine]);
  return <React.Fragment></React.Fragment>;
}
