import React, { JSX } from 'react'; // eslint-disable-line

import t, { CSSStyle } from '../theme/newstyles';
import { getIn, useFormikContext } from 'formik';
import { InputErrorInline } from './InputErrorInline';
import { InputText } from './InputText';
import { TextArea } from './TextArea';
import { FilledInput } from './FilledInput';

/** @jsxImportSource @emotion/react */

type Prev = [never, 0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10,
  11, 12, 13, 14, 15, 16, 17, 18, 19, 20, ...0[]]

type Join<K, P> = K extends string | number ?
  P extends string | number ?
  `${K}${"" extends P ? "" : "."}${P}`
  : never : never;

type Leaves<T, D extends number = 10> = [D] extends [never] ? never : T extends object ?
  { [K in keyof T]-?: Join<K, Leaves<T[K], Prev[D]>> }[keyof T] : "";

export const textInputVariant = {
  standard: InputText,
  filled: FilledInput,
};
type TextInputVariant = typeof textInputVariant;

export type TextInputProps<T> = {
  name: keyof T | Leaves<T, 3>;
  type?: string;
  label?: string;
  labelOffscreen?: string;
  description?: string;
  maxLength?: number;
  placeholder?: string;
  focused?: boolean;
  optional?: boolean;
  disabled?: boolean;
  tooltipRender?: () => React.ReactNode;
  containerStyle?: CSSStyle;
  inputContainerStyle?: CSSStyle;
  inputStyle?: CSSStyle;
  hideCharacterCount?: boolean;
  characterCountStyle?: CSSStyle;
};

export function TextField<T>(
  props: {
    formName?: string;
    icon?: JSX.Element;
    startAdornment?: string;
    multiline?: boolean;
    rows?: number;
    variant?: keyof TextInputVariant;
  } & TextInputProps<T>
) {
  const formikProps = useFormikContext<T>();
  const { formName, icon, startAdornment, multiline, rows, type = 'text', variant = 'standard', ...rest } = props;
  const InputComponent = textInputVariant[variant];

  if (!formikProps) return null;

  const name = props.name as string;
  const invalid = getIn(formikProps.touched, name as string) && getIn(formikProps.errors, name as string) ? true : undefined;
  const fieldId = `field-${formName}-${name}`;

  let characterCount = 0;
  const value = getIn(formikProps.values, name);
  if (typeof value === 'string') characterCount = value.length;

  return (
    <div css={[t.flex, t.flex_col]}>
      {multiline ? (
        <TextArea
          id={fieldId}
          type={type}
          value={value}
          invalid={invalid}
          characterCount={characterCount}
          rows={rows}
          onChange={formikProps.handleChange}
          onBlur={formikProps.handleBlur}
          {...rest}
        />
      ) : (
        <InputComponent<T>
          id={fieldId}
          type={type}
          value={value}
          icon={icon}
          startAdornment={startAdornment}
          invalid={invalid}
          onChange={formikProps.handleChange}
          onBlur={formikProps.handleBlur}
          {...rest}
        />
      )}
      <InputErrorInline id={fieldId}>{invalid && getIn(formikProps.errors, name)}</InputErrorInline>
    </div>
  );
}
