import React from 'react'; // eslint-disable-line
import { motion } from 'framer-motion';
import t from '../../theme/newstyles';
import { useThemeController } from '../../contexts/ThemeContext';

/** @jsxImportSource @emotion/react */

export const HeaderBanner = (props: { customHeader?: React.ReactNode | null }) => {
  const { dark } = useThemeController();

  return (
    <motion.div
      css={[
        t.fixed,
        t.top('56px'),
        t.left_0,
        t.right_0,
        t.z_70,
        t.px('6vw'),
        t.border_b,
        t.border_solid,
        dark ? t.border_dark_2 : t.border_tint_3,
        dark ? t.bg_dark_1 : t.bg_tint_5,
        dark ? t.text_white : t.text_dark_1,
      ]}
    >
      <div css={[t.max_w('1440px'), t.my('0'), t.mx('auto')]}>{props.customHeader}</div>
    </motion.div>
  );
};
