import React from 'react'; // eslint-disable-line

import { ClassNames } from '@emotion/react';
import t from '../../../../theme/admin/adminStyles';
import ReactModal from 'react-modal';
import { CSSStyle } from '../../../../theme/newstyles';
import { CloseIcon } from '../../../../icons/CloseIcon';

/** @jsxImportSource @emotion/react */

export function AdminDialog(props: {
  isOpen: boolean;
  aria?: ReactModal.Aria;
  onRequestClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  children?: React.ReactNode;
  overlayStyle?: CSSStyle;
  dialogStyle?: CSSStyle;
  buttonStyle?: CSSStyle;
}) {
  const overlayStyle: CSSStyle = [t.fixed, t.inset_0, t.bg_dark_1_a40, t.z_40, t.flex, t.flex_col, t.items_center, t.px_4, props.overlayStyle];
  const dialogSyle: CSSStyle = [
    t.relative,
    t.outline_none,
    t.mt('120px'),
    t.w('70%'),
    t.max_w('1080px'),
    t.min_h('80px'),
    t.w_full,
    t.bg_tint_5,
    t.shadow_md,
    t.flex,
    t.flex_col,
    props.dialogStyle,
  ];

  return (
    <ClassNames>
      {(css) => {
        const overlayClassName = css.css(overlayStyle);
        const dialogClassName = css.css(dialogSyle);
        const bodyClassName = css.css([t.overflow_y_hidden]);
        return (
          <ReactModal
            isOpen={props.isOpen}
            bodyOpenClassName={bodyClassName}
            overlayClassName={overlayClassName}
            className={dialogClassName}
            onRequestClose={props.onRequestClose}
            shouldCloseOnOverlayClick={true} /* Or should this be false? */
            aria={props.aria}
          >
            <button
              css={[
                t.absolute,
                t.right_0,
                t.z_50,
                t.size('30px'),
                t.my_3,
                t.mr_1,
                t.text_tint_1,
                t.bg_transparent,
                t.cursor_pointer,
                t.hover(t.text_dark_1),
                props.buttonStyle,
              ]}
              onClick={props.onRequestClose}
            >
              <CloseIcon css={[t.size('16px'), t.fill_current]} />
            </button>
            {props.children}
          </ReactModal>
        );
      }}
    </ClassNames>
  );
}
