import * as React from 'react';

interface TipController {
  activeTipId?: string | null;
  show: (id: string | null) => void;
  isShown: (id: string) => boolean;
}

export const TipContext = React.createContext({} as TipController);
export const TipProvider = (props: { children: React.ReactNode }) => {
  const [activeTipId, SetActiveTipId] = React.useState<string | null>(null);

  const show = React.useCallback((id: string | null) => {
    SetActiveTipId(id);
  }, []);

  return (
    <TipContext.Provider
      value={{
        activeTipId,
        show: show,
        isShown: (id: string) => {
          return id === activeTipId;
        },
      }}
    >
      {props.children}
    </TipContext.Provider>
  );
};

export function useTipController() {
  return React.useContext(TipContext);
}
