import algoliasearch, { SearchClient } from 'algoliasearch';
import { envPrefix } from '../contexts/FirebaseContext';

export const createSearchClient = () => {
  let searchClient: SearchClient | null = null;
  // These public keys are defined in the Algolia dashboard
  switch (envPrefix) {
    case 'dev_':
      searchClient = algoliasearch('QKXMUJRSCM', '554ef71a1ee366c34212fd3ef152794b');
      break;

    case 'stage_':
      searchClient = algoliasearch('QKXMUJRSCM', '45907d7004730309e75c1598142f4644');
      break;

    case 'live_':
      searchClient = algoliasearch('QKXMUJRSCM', '91d2232b267d8164688b9abad0467a1c');
      break;
  }

  return searchClient;
};

export default createSearchClient;
