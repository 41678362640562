import { FirebaseObjects, CreateDocumentContext } from '../contexts/FirebaseContext';

export interface SongMetadataComment {
  id: string;
  author: string;
  time: string;
  message: string;
}

export interface SongMetadata {
  id: string;
  reviewed: 'new' | 'reviewed' | 'attention';
  comments: SongMetadataComment[];
}

const SongMetadataDocumentContext = CreateDocumentContext<SongMetadata>('/songMetadata');
export const SongMetadataProvider = SongMetadataDocumentContext.Provider;
export const useSongMetadata = SongMetadataDocumentContext.Use;

export async function EditSongReviewedState(songId: string, state: 'reviewed' | 'attention') {
  await FirebaseObjects.adminRequest({ action: 'editSongMetadata', data: { songId, editReviewed: { state } } });
}

export async function AddSongMetadataComment(songId: string, message: string) {
  await FirebaseObjects.adminRequest({ action: 'editSongMetadata', data: { songId, addComment: { message } } });
}

export async function RemoveSongMetadataComment(songId: string, id: string) {
  await FirebaseObjects.adminRequest({ action: 'editSongMetadata', data: { songId, removeComment: { id } } });
}
