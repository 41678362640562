import React from 'react'; // eslint-disable-line


/** @jsxImportSource @emotion/react */

export const ClearSearchIcon = (props: {}) => {
  return (
    <svg aria-labelledby="clearSearchIcon" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <title id="clearSearchIcon">Clear Search Icon</title>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 2C0 0.89543 0.895431 0 2 0H22C23.1046 0 24 0.895431 24 2V22C24 23.1046 23.1046 24 22 24H2C0.89543 24 0 23.1046 0 22V2ZM6 6.63159L6.63158 6.00001L12.0001 11.3685L17.3687 6L18.0002 6.63158L12.6317 12.0001L18 17.3684L17.3684 18L12.0001 12.6317L6.63181 18L6.00023 17.3684L11.3685 12.0001L6 6.63159Z"
        fill="#DAD4CC"
        stroke="#fff"
      />
    </svg>
  );
};
