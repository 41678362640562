import { css } from '@emotion/react';

export const textDecoration = {
  underline: css`
    text-decoration: underline;
  `,
  line_through: css`
    text-decoration: line-through;
  `,
  no_underline: css`
    text-decoration: none;
  `,
};
