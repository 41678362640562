import React from 'react'; // eslint-disable-line

import t from '../theme/newstyles';
import { Link } from 'react-router-dom';
import { DownwriteIcon } from '../icons/DownwriteIcon';
import { useThemeController } from '../contexts/ThemeContext';

/** @jsxImportSource @emotion/react */

export const LogoLink = (props: { to?: string; ariaLabel?: string; lockup?: boolean; onClick?: (event: React.MouseEvent) => void }) => {
  const { dark } = useThemeController();
  const linkColor = dark ? t.text_tint_5 : t.text_dark_1;

  return (
    <div css={[t.inline_flex, t.items_stretch, t.relative, t.h_full]}>
      <Link
        to={props.to ? props.to : '/'}
        aria-label={props.ariaLabel ? props.ariaLabel : 'Home'}
        css={[t.w_full, t.flex, t.justify_center, t.items_center, t.no_underline, t.typeStyle_lg8, linkColor, t.visited(linkColor)]}
      >
        <DownwriteIcon lockup={props.lockup} />
      </Link>
    </div>
  );
};
