import React from 'react'; // eslint-disable-line

import t from '../../theme/newstyles';
import { LastSeenUpdater, AccountNotification } from '../../contexts/AccountContext';
import { Link } from 'react-router-dom';
import { ModalDropdown } from '../ModalDropdown';
import { NotificationComponent } from '../../pages/NotificationPage';
import { useThemeController } from '../../contexts/ThemeContext';

/** @jsxImportSource @emotion/react */

export const NotificationsDropDown = (props: {
  notifications: AccountNotification[];
  isOpen?: boolean;
  setShowNotifications: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { dark } = useThemeController();
  const textColor = dark ? t.text_tint_5 : t.text_dark_1;
  const backgroundColor = dark ? t.bg_dark_1 : t.bg_tint_5;
  const borderColor = dark ? t.border_dark_2 : t.border_tint_3;

  return (
    <ModalDropdown isOpen={props.isOpen} dark={dark} width="375px" setShowModal={props.setShowNotifications}>
      {props.isOpen ? <LastSeenUpdater /> : null}
      <div css={[t.py_4, t.h('415px'), t.overflow_scroll]}>
        <div css={[t.mb_7, t.lastType('a', t.border_none)]}>
          <div css={[t.gridTypeStyle_5, t.mb_4, t.text_left, t.cursor_default, t.px_5, textColor]}>Notifications</div>
          {props.notifications.length === 0 && <div css={[t.gridTypeStyle_5, t.text_tint_1, t.mb_5, t.mx_5]}>There are no notifications</div>}
          {props.notifications.slice(0, 10).map((notification, index) => {
            return <NotificationComponent notification={notification} key={index} smallView={true} />;
          })}
        </div>
        <div
          css={[
            t.flex,
            t.justify_end,
            t.absolute,
            t.bottom_0,
            t.left_0,
            t.right_0,
            backgroundColor,
            t.px_5,
            t.pt_4,
            t.pb_5,
            dark ? t.border : t.border_t,
            borderColor,
            t.z_30,
          ]}
        >
          <Link to="/notifications" css={[t.no_underline, textColor]}>
            View all
          </Link>
        </div>
      </div>
    </ModalDropdown>
  );
};
