import React from 'react'; // eslint-disable-line

import t, { CSSStyle } from '../theme/newstyles';

/** @jsxImportSource @emotion/react */

export const MainPage = (props: {
  scrollLock?: boolean;
  mobileScrollLock?: boolean;
  children?: React.ReactNode;
  dark?: boolean;
  black?: boolean;
  overrideStyle?: CSSStyle;
}) => {
  let background = t.bg_tint_5;
  let textColor = t.text_black;

  if (props.black) {
    background = t.bg_black;
    textColor = t.text_white;
  } else if (props.dark) {
    background = t.bg_dark_1;
  }

  return (
    <main
      css={[
        background,
        textColor,
        t.pt_header,
        t.flex_auto,
        t.flex,
        t.flex_col,
        props.overrideStyle ? props.overrideStyle : null,
        props.scrollLock ? [t.max_h_screen, t.overflow_y_hidden] : null,
        props.mobileScrollLock ? [t.max_h_screen, t.overflow_y_hidden, t.sm([t.max_h_full, t.overflow_y_visible])] : null,
      ]}
    >
      {props.children}
    </main>
  );
};

export default MainPage;
